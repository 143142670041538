import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Button, IconButton } from 'z-frontend-elements';
import { Flex, Heading, Image, TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import { styled } from 'z-frontend-theme';
import { color, space } from 'z-frontend-theme/utils';

import { StartMicroTrial } from '../gqlTypes';
import { MicroTrial } from '../../schema/schemaTypes';
import { DISMISS_MICROTRIAL_BANNER_KEY, DISMISS_PROMO_TILES_KEY, MicroTrialBanner } from '../constants';
import { doFeatureListsMatch } from '../utils';

const startMicroTrialFromBannerMutation = gql`
  mutation StartMicroTrialFromBanner($key: String!) {
    startMicroTrial(key: $key) {
      success
      errorCode
      errorMessage
      microTrialSubscription {
        id
      }
    }
  }
`;

const DismissButton = styled(IconButton)`
  color: ${color('grayscale.e')};
  position: absolute;
  margin-top: ${space(2)};
  margin-right: ${space(2)};
`;

const DescriptionText = styled(TextBlock)`
  line-height: 1.5;
  color: ${color('text.off')};
`;

const DisclaimerText = styled(TextBlock)`
  font-size: 8px;
  line-height: 10px;
  color: ${color('text.off')};
`;

const arePromoTilesDismissed = () => {
  return window.localStorage.getItem(DISMISS_PROMO_TILES_KEY) === 'true';
};

const isMicroTrialBannerDismissed = () => {
  return window.localStorage.getItem(DISMISS_MICROTRIAL_BANNER_KEY) === 'true';
};

type MicroTrialPromoSideBannerProps = {
  microTrial: MicroTrial;
  bannerContent: MicroTrialBanner[];
};

const MicroTrialPromoSideBanner: React.FunctionComponent<MicroTrialPromoSideBannerProps> = props => {
  const [doStartMicroTrialMutation, { loading: isMicroTrialMutationInProgress }] = useMutation<
    StartMicroTrial.Mutation,
    StartMicroTrial.Variables
  >(startMicroTrialFromBannerMutation);

  const promoTilesDismissed = arePromoTilesDismissed();
  const [microTrialBannerDismissed, setMicroTrialBannerDismissed] = useState(isMicroTrialBannerDismissed());

  if (!props.microTrial || !props.bannerContent) return null;

  const dismissPromoBanner = () => {
    window.localStorage.setItem(DISMISS_MICROTRIAL_BANNER_KEY, 'true');
    setMicroTrialBannerDismissed(true);
  };

  const selectedBannerContent: MicroTrialBanner =
    props.bannerContent.find(banner => doFeatureListsMatch([...props.microTrial?.features], banner.features)) || null;

  const startMicroTrial = async () => {
    const result = await doStartMicroTrialMutation({ variables: { key: props.microTrial.key } });
    if (result.data.startMicroTrial.success) {
      window.location.href = selectedBannerContent.redirectUrl;
    }
  };

  return (
    selectedBannerContent &&
    promoTilesDismissed &&
    !microTrialBannerDismissed && (
      <Card>
        <Flex justify="right">
          <DismissButton onClick={dismissPromoBanner} s="xsmall" iconName="close" />
        </Flex>
        <Card.Row p={3}>
          {selectedBannerContent.image && (
            <Flex justify="center">
              <Image src={selectedBannerContent.image} />
            </Flex>
          )}
          <Heading level={5} pb={3} pt={3}>
            Try {props.microTrial.name} for free for {props.microTrial.durationInDays} days
          </Heading>
          <DescriptionText fontStyle="paragraphs.s">{selectedBannerContent.description}</DescriptionText>
          <Flex pb={3} pt={3}>
            <Button mode="primary" onClick={startMicroTrial} inProgress={isMicroTrialMutationInProgress} width={1}>
              Start Trial
            </Button>
          </Flex>
          <DisclaimerText fontStyle="paragraphs.s">
            * We won’t automatically charge your credit card after the trial has ended, so don’t worry if you forget to
            cancel.
          </DisclaimerText>
        </Card.Row>
      </Card>
    )
  );
};

export default MicroTrialPromoSideBanner;
