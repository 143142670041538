import { useEffect } from 'react';

export function useSetFullPageReactClass() {
  useEffect(() => {
    document.body.classList.add('full-page-react');
    return () => {
      document.body.classList.remove('full-page-react');
    };
  }, []);
}
