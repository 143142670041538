import React from 'react';

import { TextBlock, TextBlockProps } from 'zbase';
import { Link } from 'z-frontend-elements';

import { Mention } from 'z-frontend-forms';

export type MentionEntry = {
  /** Label to display inline. */
  label: string;
  /** Text to display for more info */
  tooltipText?: string;
};

export type MentionsMap = { [key: string]: MentionEntry };

type PostTextOwnProps = {
  /** Text to display that includes mention references in the form [@123], where 123 is the ID. */
  text: string;
  /** Map of mentions to be inserted into text, where the keys are IDs present in `text`. */
  mentions: MentionsMap;
};

export type PostTextProps = TextBlockProps & PostTextOwnProps;

// in the future, we may support types of mentions
export const userMentionRegex = /(\[@\w+\])/g;

export const nonIDCharRegex = /\W/g;

export const URL_REGEX = /(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;\(\)]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const COMBINED_REGEX = /(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;\(\)]*[-A-Z0-9+&@#\/%=~_|])|(\[@\w+\])/gi;
// i.e., userMentionRegex OR URL_REGEX

type SplitOptions = {
  usePlaceholders?: boolean;
};

export function splitIntoMentionsAndLinks(
  text: string,
  mentions: MentionsMap,
  options: SplitOptions = {},
): (JSX.Element | string)[] {
  if (!text) {
    return [];
  }
  // Split into mentions, links and others using the combined regex
  return text.split(COMBINED_REGEX).reduce((memo, current) => {
    // Check for Mentioned
    if (userMentionRegex.test(current)) {
      const mentionKey = current.replace(nonIDCharRegex, ''); // strip all but ID
      const mention = mentions[mentionKey];
      if (mention) {
        const entry = options.usePlaceholders ? (
          `<span class="mention-placeholder" data-mention="${mentionKey}">${mention.label}</span>`
        ) : (
          <Mention key={mention.label} data-mention={mentionKey} {...mention} />
        );
        return memo.concat(entry);
      }
      // Check for Url
    } else if (URL_REGEX.test(current)) {
      const entry = (
        <Link target="_blank" rel="noopener noreferrer" href={current}>
          {current}
        </Link>
      );
      return memo.concat(entry);
    }
    return current ? memo.concat(current) : memo;
  }, []);
}

const PostText: React.FunctionComponent<PostTextProps> = (props: PostTextProps) => {
  const { text, mentions, ...rest } = props;
  const textWithMentionsAndLinks = splitIntoMentionsAndLinks(text, mentions);
  return <TextBlock {...rest}>{textWithMentionsAndLinks}</TextBlock>;
};

export default PostText;
