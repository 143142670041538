import React, { Component } from 'react';
import { getIn, FieldProps } from 'formik';

import Field from '../Field';
import FormFieldWrapper, { FormFieldProps } from '../FormFieldWrapper';
import MaskedInput, { MaskedInputProps } from '../../masked-input/MaskedInput';
import { getAriaInputProps } from '../formAccessibility';
import { InputDisplay } from '../../..';

type FormMaskedInputProps = MaskedInputProps &
  FormFieldProps & {
    /**
     * Use to toggle the component from edit to display but keep exact spacing, eg in an EditableTable.
     * @default false
     */
    displayOnly?: boolean;
  };

class FormMaskedInput extends Component<FormMaskedInputProps> {
  render() {
    const {
      name,
      label,
      containerProps,
      optional,
      limitRerender,
      dependencies,
      'aria-label': ariaLabel,
      additionalText,
      disableError,
      displayOnly,
      prefix,
      suffix,
      ...rest
    } = this.props;
    return (
      <Field name={name} limitRerender={limitRerender} dependencies={dependencies}>
        {({ field, form }: FieldProps) => {
          const error: any = getIn(form.touched, name) && getIn(form.errors, name);
          return (
            <FormFieldWrapper
              name={name}
              label={label}
              error={error}
              containerProps={containerProps}
              optional={optional}
              additionalText={additionalText}
              disableError={disableError}
              isDisplayMode={displayOnly}
            >
              {displayOnly ? (
                <InputDisplay prefix={prefix} suffix={suffix} value={field.value || rest.value} />
              ) : (
                <MaskedInput
                  id={name}
                  {...field}
                  {...rest}
                  hasError={Boolean(error)}
                  {...getAriaInputProps(name, error, ariaLabel)}
                  mb={0}
                  prefix={prefix}
                  suffix={suffix}
                />
              )}
            </FormFieldWrapper>
          );
        }}
      </Field>
    );
  }
}

export default FormMaskedInput;
