import { CheckoutPackageProductSku } from '../schema/schemaTypes';

export const basePlanSkus = [
  CheckoutPackageProductSku.V3_TIER_ONE,
  CheckoutPackageProductSku.V3_TIER_TWO,
  CheckoutPackageProductSku.V3_TIER_THREE,
];

export const IOM = 'iom';
export const CONTRACTOR_PAYMENTS = 'contractorPayments';
