import React, { Component } from 'react';

import { EmberRouteLink, Link, LinkButton } from 'z-frontend-elements';
import { Box, Flex, Heading, ResponsiveFontStyleProp, ResponsiveUtilProp } from 'zbase';
import { styled } from 'z-frontend-theme';

import { ZAppActionUrl } from '../../schema/schemaTypes';

export type QuickLink = Pick<ZAppActionUrl, 'title' | 'actionUrl'> & {
  href?: string;
  onClick?: (event: React.MouseEvent) => void;
  'data-testid'?: string;
  className?: string;
  target?: string;
  badge?: string;
};

const QuickLinkBadge = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  padding: 2px 4px;
  font-size: 10px;
`;

const QuickLink = (props: QuickLink) => {
  const { actionUrl, onClick, href, title, badge, ...rest } = props;
  const commonProps = {
    children: title,
    fontStyle: 'controls.m' as ResponsiveFontStyleProp,
    ...rest,
  };

  let link;
  if (href) {
    link = <Link href={href} onClick={onClick} {...commonProps} />;
  } else if (onClick) {
    link = <LinkButton onClick={onClick} {...commonProps} />;
  } else {
    link = <EmberRouteLink key={actionUrl} to={actionUrl} {...commonProps} />;
  }

  return (
    <Box mb={3}>
      {link}
      {badge && (
        <QuickLinkBadge ml={2} fontStyle="controls.s" color="affirmation.a" bg="affirmation.c">
          {badge}
        </QuickLinkBadge>
      )}
    </Box>
  );
};

interface QuickLinksProps {
  links: QuickLink[];
  heading: string;
  w?: ResponsiveUtilProp;
}

class QuickLinks extends Component<QuickLinksProps> {
  render() {
    const { links, heading, w } = this.props;
    if (!links || !links.length) {
      return null;
    }
    return (
      <Box mt={0} w={w} data-testid="QuickLinks">
        <Heading level={6} color="text.light" mb={2}>
          {heading}
        </Heading>
        <Flex column>
          {links.map(quickLink => (
            <QuickLink data-testid="QuickLink" key={quickLink.title} {...quickLink} />
          ))}
        </Flex>
      </Box>
    );
  }
}

export default QuickLinks;
