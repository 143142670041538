import React, { useContext, FunctionComponent } from 'react';
import { isEmpty } from 'lodash';

import { checkPermission, PermissionsContext, PermissionsContextProps, Query } from 'z-frontend-network';

import { RedirectRequirementsQuery } from './gqlTypes';
import { redirectRequirementsQuery } from './AppRedirectsQueries';

function getCompanyIdFromUrl() {
  // TODO: use location.search instead
  const matches = window.location.href.match(/\/app\/boot\/?\?[^#]*company=(\d+)/);
  return matches && Number.parseInt(matches[1], 10);
}

// NOTE: these redirects are preserved from Ember:
// yourPeople3/client-app/boot/pods/application/route.js
// yourPeople3/client-app/packages/dashboard/pods/index/route.js
const AppRedirects: FunctionComponent = () => {
  const permissionsContext = useContext<PermissionsContextProps>(PermissionsContext);

  return (
    <Query<RedirectRequirementsQuery.Query>
      query={redirectRequirementsQuery}
      handleLoading={false}
      handleError={false}
      fetchPolicy="network-only"
      isBackgroundQuery
    >
      {({ data, error, loading }) => {
        if (error) {
          console.error('GraphQL error with RedirectRequirementsQuery:', error);
          return null;
        }
        if (loading || !data || isEmpty(data)) {
          return null;
        }

        const {
          dashboard: { isMTAPartnerUser, switches, emailVerified, permission, employee },
        } = data;

        if (isMTAPartnerUser && !getCompanyIdFromUrl()) {
          const { permissionsLoaded, permissions } = permissionsContext;

          if (
            permissionsLoaded &&
            (checkPermission(permissions, 'is_benefits_admin', null) ||
              checkPermission(permissions, 'is_hr_admin', null) ||
              checkPermission(permissions, 'is_payroll_admin', null) ||
              checkPermission(permissions, 'is_time_admin', null) ||
              checkPermission(permissions, 'is_contractor_admin', null))
          ) {
            return null;
          } else {
            // For partner users, it's mandatory to have the company id in the URL so that we ensure
            // that the data is loaded for the specific company. Company dashboard is company specific
            // and so we should never allow a partner user who has multiple company accounts to visit
            // the company dashboard without a company id in the URL.
            window.location.href = '/app/benconnect/#/';
            return null;
          }
        }

        // Check if the admin has an unverified email. If so, redirect them
        // to email verification landing page.
        // For now, we block dashboard from admins but not normal employees.
        const verifyEmailSwitchActive = switches && switches['registration_verify_email'];
        const hasAdminPermission = permission && permission.isAdmin;
        if (verifyEmailSwitchActive && hasAdminPermission && !emailVerified) {
          window.location.href = '/accounts/verify-email';
          return null;
        }

        // make sure contractors go to their basicinfo page by default
        // TODO: remove once we totally deprecate the old contractor (moving to employmentType 'CO')
        if (employee.type === 'HC') {
          window.location.href = '/dashboard/#/redirect-to-route?to=contractor';
          return null;
        }

        return null; // no need to redirect
      }}
    </Query>
  );
};
export default AppRedirects;
