import React from 'react';

import { buildEmberRouteRedirect, getEventLogger, PrerequisiteRedirect } from 'z-frontend-app-bootstrap';

import { PREREQUISITE_REDIRECT_BODY } from '../../redirects/prerequisiteRedirectBody';
import ModalWithQuery from './ModalWithQuery';
import RedirectConfirmationModal from './RedirectConfirmationModal';

type RedirectModalProps = {
  redirects: PrerequisiteRedirect[];
};

const RedirectModal: React.FC<RedirectModalProps> = props => {
  const { redirects } = props;
  const [redirect] = redirects;
  const body =
    redirect && PREREQUISITE_REDIRECT_BODY[redirect.group] && PREREQUISITE_REDIRECT_BODY[redirect.group].content;

  if (!body) {
    getEventLogger().logError(new Error(`redirect_modal - key DNE :${redirect.group}`));
    return null;
  }
  const modalBody = redirect.objectType === 'CO' ? body.CO : body.EE;
  const contentRequiredQuery = modalBody && modalBody.query;

  if (contentRequiredQuery) {
    return <ModalWithQuery modalConfig={modalBody} prerequisiteRedirect={redirect} />;
  }

  const content = modalBody.body();
  const showFooter = modalBody?.showFooter;
  const title = modalBody?.title;
  const redirectEmberRoute = modalBody?.redirectEmberRoute;
  const redirectUrl = modalBody?.redirectUrl;
  const footerText = showFooter && modalBody?.footerButtonText;
  const onSubmit = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else if (redirectEmberRoute) {
      window.location.href = buildEmberRouteRedirect({ to: redirectEmberRoute });
    }
  };

  return (
    <RedirectConfirmationModal
      modalContent={content}
      modalTitle={title}
      submitActionText={footerText}
      onSubmit={onSubmit}
    />
  );
};

export default RedirectModal;
