import React from 'react';

import { Flex } from 'zbase';

import SectionHeading from '../SectionHeading';

const AppListWrapper: React.FunctionComponent<{ heading?: string }> = props => {
  return (
    <>
      <SectionHeading>{props.heading || 'Apps'}</SectionHeading>
      <Flex wrap mx={-3} className="js-walkme-dashboard-apps">
        {props.children}
      </Flex>
    </>
  );
};

export default AppListWrapper;
