import React, { Component } from 'react';

import { TextBlock } from 'zbase';

import ActionModal, { ActionModalProps } from './ActionModal';

export type ConfirmationModalProps = ActionModalProps & {
  /** Content for the body of the modal (`Modal.Body`). */
  content: React.ReactNode;
  /**
   * Label for the primary button.
   * @default "Submit"
   */
  submitActionText?: string;
  /**
   * Label for the cancel button. Optional, if not provided default text will be used.
   */
  cancelActionText?: string;
  /**
   * Action to take on submit.
   */
  onSubmit: (e: any) => void;
  isSubmitting?: boolean;
  /**
   * The type of the button. Example: 'submit' will submit the enclosing form.
   * @default button
   */
  buttonType?: 'button' | 'submit' | 'reset';
};

class ConfirmationModal extends Component<ConfirmationModalProps> {
  static defaultProps = {
    submitActionText: 'Submit',
    isSubmitting: false,
    size: 'small',
    buttonType: 'button',
  };

  render() {
    const { content, submitActionText, onSubmit, buttonType, ...modalProps } = this.props;
    const button = { text: submitActionText, onClick: onSubmit, inProgress: modalProps.isSubmitting, type: buttonType };
    return (
      <ActionModal buttons={[button]} {...modalProps}>
        <TextBlock color="text.default" textAlign="left">
          {content}
        </TextBlock>
      </ActionModal>
    );
  }
}

export default ConfirmationModal;
