import React, { Component, FunctionComponent } from 'react';

import { Flex, TextBlock } from 'zbase';
import { Avatar } from 'z-frontend-composites';
import { Link, Skeleton } from 'z-frontend-elements';
import { theme } from 'z-frontend-theme';

type WorkerAvatarProps = {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  contractor: boolean;
  'data-testid'?: string;
  className?: string;
};

export default class WorkerAvatar extends Component<WorkerAvatarProps> {
  render() {
    const { id, contractor, 'data-testid': dataTestId, ...avatarProps } = this.props;
    return (
      <Link href={`/dashboard/#/redirect/tearsheet/${id}`} data-testid={dataTestId}>
        <Flex mr={[2, null, 0]} mt={3} column align="center">
          <Avatar s="xlarge" mb={2} {...avatarProps} badge={contractor ? 'contingent' : undefined} />
          <TextBlock fontStyle="paragraphs.m" ellipsis maxWidth={80}>
            {avatarProps.firstName}
          </TextBlock>
        </Flex>
      </Link>
    );
  }
}

type WorkerAvatarSkeletonProps = {
  'data-testid'?: string;
};

const avatarSize = theme.heights['xlarge'];

export const WorkerAvatarSkeleton: FunctionComponent<WorkerAvatarSkeletonProps> = props => {
  return (
    <Flex mt={3} column align="center" {...props}>
      <Skeleton width={avatarSize} height={avatarSize} isCircle mb={2} />
      <TextBlock fontStyle="paragraphs.m" ellipsis maxWidth={80}>
        <Skeleton width={avatarSize} />
      </TextBlock>
    </Flex>
  );
};
