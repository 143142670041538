import React, { Component } from 'react';
import gql from 'graphql-tag';

import { Query } from 'z-frontend-network';
import { Card } from 'z-frontend-composites';
import { withErrorBoundary } from 'z-frontend-app-bootstrap';
import { Flex, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { space } from 'z-frontend-theme/utils';
import { Ellipsis, LoadingSpinner } from 'z-frontend-elements';

import { GetPostAudience } from '../../gqlTypes';

const DEFAULT_POST_ID = '0';

export const getPostAudience = gql`
  query GetPostAudience($postId: ID!) {
    postAudience(postId: $postId) {
      myTeam
      entireCompany
      locations {
        id
        name
      }
      departments {
        id
        name
      }
      individuals {
        id
        preferredOrFirstName
        last_name
      }
    }
  }
`;

const StyledContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

type Props = {
  postId: string;
};

const StyledCard = styled(Card)`
  padding: 0;
  margin: 0;
`;

const StyledCardRow = styled(Card.Row)`
  padding: 0;
  padding-bottom: ${space(3)};
  margin: 0;
  border-bottom: none;
  &:last-child {
    padding: 0;
  }
`;

class VisibilityPopoverCard extends Component<Props> {
  getGroupText(myTeam: boolean, entireCompany: boolean) {
    if (myTeam) {
      return 'My Team (manager, peers, and direct reports)';
    }
    if (entireCompany) {
      return 'Entire Company';
    }
    return '';
  }

  getLocationsText(locations: GetPostAudience.Locations[]) {
    return locations.map((loc: GetPostAudience.Locations) => loc.name).join(', ');
  }
  getDepartmentsText(departments: GetPostAudience.Departments[]) {
    return departments.map((dep: GetPostAudience.Departments) => dep.name).join(', ');
  }
  getIndividualsText(individuals: any) {
    return individuals
      .map((individual: any) => `${individual.preferredOrFirstName} ${individual.last_name}`)
      .join(', ');
  }

  render() {
    const { postId } = this.props;

    if (postId === DEFAULT_POST_ID) {
      return (
        <StyledContainer>
          <LoadingSpinner s="small" />
        </StyledContainer>
      );
    }

    return (
      <Query<GetPostAudience.Query>
        query={getPostAudience}
        variables={{ postId }}
        renderLoading={() => (
          <StyledContainer>
            <LoadingSpinner s="small" />
          </StyledContainer>
        )}
      >
        {({ data }) => {
          const { myTeam, entireCompany, locations, departments, individuals } = data.postAudience;
          return (
            <StyledCard border={false}>
              {(myTeam || entireCompany) && (
                <StyledCardRow>
                  <TextBlock fontStyle="controls.m" bold>
                    Groups
                  </TextBlock>
                  <TextBlock fontStyle="paragraphs.m">{this.getGroupText(myTeam, entireCompany)}</TextBlock>
                </StyledCardRow>
              )}
              {locations.length > 0 && (
                <StyledCardRow>
                  <TextBlock fontStyle="controls.m" bold>
                    Locations
                  </TextBlock>
                  <TextBlock fontStyle="paragraphs.m">{this.getLocationsText(locations)}</TextBlock>
                </StyledCardRow>
              )}
              {departments.length > 0 && (
                <StyledCardRow>
                  <TextBlock fontStyle="controls.m" bold>
                    Departments
                  </TextBlock>
                  <TextBlock fontStyle="paragraphs.m">{this.getDepartmentsText(departments)}</TextBlock>
                </StyledCardRow>
              )}
              {individuals.length > 0 && (
                <StyledCardRow>
                  <TextBlock fontStyle="controls.m" bold>
                    Individuals
                  </TextBlock>
                  <TextBlock fontStyle="paragraphs.m">
                    <Ellipsis> {this.getIndividualsText(individuals)}</Ellipsis>
                  </TextBlock>
                </StyledCardRow>
              )}
            </StyledCard>
          );
        }}
      </Query>
    );
  }
}

export default withErrorBoundary<Props>()(VisibilityPopoverCard);
