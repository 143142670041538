import React, { FunctionComponent } from 'react';

import { ConfirmationModal, DialogManager, Modal } from 'z-frontend-overlays';

type RedirectConfirmationModalProps = {
  modalContent: any;
  modalTitle: string;
  submitActionText: string;
  onSubmit: () => void;
};

const RedirectConfirmationModal: FunctionComponent<RedirectConfirmationModalProps> = props => {
  const { modalContent, modalTitle, onSubmit, submitActionText } = props;

  return (
    <DialogManager
      openByDefault
      render={dialog => {
        const modalProps = {
          submitActionText,
          content: modalContent,
          title: 'Redirect Modal',
          renderHeader: () => {
            return <Modal.Header>{modalTitle}</Modal.Header>;
          },
          isVisible: dialog.isVisible,
          onCancel: () => {},
          omitCancelButton: true,
          controlEl: dialog.controlEl,
          onSubmit: () => {
            onSubmit();
            dialog.close();
          },
        };

        return <ConfirmationModal {...modalProps} />;
      }}
    />
  );
};

export default RedirectConfirmationModal;
