import React from 'react';
import gql from 'graphql-tag';

import { Box, Heading, TextBlock } from 'zbase';
import { useQueryWithProgress } from 'z-frontend-network';
import { Card } from 'z-frontend-composites';
import { ColorString } from 'z-frontend-theme';

import { QuickStartOnboardingHelp } from './QuickStartOnboardingHelp';
import { GuidedOnboardingHelp } from './GuidedOnboardingHelp';
import { ImplementationProjectDetails } from '../gqlTypes';
import {
  ImplementationProjectMethod,
  ImplementationProjectStage,
  ImplementationProjectStatus,
} from '../../schema/schemaTypes';
import { LearnToUseZenefits } from './LearnToUseZenefits';

const CONTRACTOR_PAYMENTS = 'contractorPayments';

export type CardSize = 'small' | 'medium';

export const implementationProjectDetails = gql`
  query ImplementationProjectDetails {
    dashboard {
      id
      company {
        id
        name
        activeImplementationProjectDetails {
          implementationMethod
          implementationStatus
          implementationStage
          managerName
          managerTitle
          managerEmail
          managerPhone
          calendlyLink
        }
      }
      features
    }
    businessHours {
      isBusinessHours
    }
  }
`;

type OnboardingHelpCardProp = {
  /**
   * The size of the card depending on the application
   * @default: 'medium'
   */
  size?: CardSize;
  /**
   * The background color of the component
   * @default: 'grayscale.white'
   */
  bg?: ColorString;

  /**
   * showLearnToUseZenefits
   * @default: false
   */
  showVideo?: boolean;
};

const OnboardingImplementationHelpCard: React.FunctionComponent<OnboardingHelpCardProp> = props => {
  const { loading, QueryError, data } = useQueryWithProgress<ImplementationProjectDetails.Query>(
    implementationProjectDetails,
    {},
  );

  if (loading) return null;
  if (QueryError) return QueryError;

  const implementationProject = data?.dashboard?.company.activeImplementationProjectDetails;
  const smallSize = props.size === 'small';
  const isBusinessHours = data?.businessHours?.isBusinessHours;
  const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);

  const isActiveImplementationStatus = [
    ImplementationProjectStatus.IN_PROGRESS,
    ImplementationProjectStatus.WAITING_ON_CUSTOMER,
    ImplementationProjectStatus.NEW_PROJECT,
  ].includes(implementationProject?.implementationStatus);

  const hideComponent =
    !isActiveImplementationStatus ||
    (implementationProject?.implementationMethod === ImplementationProjectMethod.GUIDED &&
      (implementationProject?.implementationStage === ImplementationProjectStage.S1_NEW_PROJECT ||
        !implementationProject?.managerName));

  if (hideComponent) {
    return null;
  }

  return (
    <>
      {!isContractorPaymentsCompany && props.showVideo && <LearnToUseZenefits />}
      <Box p={0} textAlign={['center', null, 'left']}>
        {smallSize ? (
          <Heading level={6} mb={2} color="text.light">
            Onboarding Help
          </Heading>
        ) : (
          <Card.Header bg={props.bg}>Onboarding Help</Card.Header>
        )}
        <Card.Row minWidth="100px" bg={props.bg} px={smallSize ? 0 : 4} py={smallSize ? 0 : 4}>
          <TextBlock color="grayscale.c" mb={4}>
            Need help onboarding? Our experts are here for you. Contact the team via one of the options below.
          </TextBlock>

          {implementationProject.implementationMethod === ImplementationProjectMethod.QUICKSTART && (
            <QuickStartOnboardingHelp isBusinessHours={isBusinessHours} size={props.size} />
          )}

          {implementationProject.implementationMethod === ImplementationProjectMethod.GUIDED && (
            <GuidedOnboardingHelp
              implementationProject={implementationProject}
              companyName={data?.dashboard.company.name}
              size={props.size}
            />
          )}
        </Card.Row>
      </Box>
    </>
  );
};

OnboardingImplementationHelpCard.defaultProps = {
  bg: 'grayscale.white',
  size: 'medium',
};

export default OnboardingImplementationHelpCard;
