import React, { useEffect } from 'react';

import { Flex, TextBlock } from 'zbase';
import { getEventLogger } from 'z-frontend-app-bootstrap';
import { IconButton, Link } from 'z-frontend-elements';
import { styled } from 'z-frontend-theme';

import { AbsoluteNav, AbsoluteNavPlaceholder } from './TopNavBarContainer';

const purplePink = '#FDE9F8';

export const isBenefitsShoppingBannerDismissedLocalStorageKey = 'isBenefitsShoppingBannerDismissed';

const AbsoluteNavBanner = styled(AbsoluteNav)`
  background-color: ${purplePink};
  border-top: inherit;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  align-self: flex-start;
`;

type BenefitsShoppingBannerState = {
  /**
   * onClick handler for the close button.
   */
  onCloseButtonClick: () => void;
};

const BenefitsShoppingBanner: React.FunctionComponent<BenefitsShoppingBannerState> = ({ onCloseButtonClick }) => {
  useEffect(() => {
    // Send track event to Pendo
    getEventLogger().log('benefits_shopping_banner_shown');
  }, []); // Only run the effect once

  return (
    <AbsoluteNavPlaceholder>
      <AbsoluteNavBanner pl={3} pr={1} py={2}>
        <Flex width={1}>
          {/* `margin-left: auto;` centers the TextBlock horizontally. */}
          <TextBlock color="secondary.a" ml="auto">
            Finding the best benefits packages for your employees has never been easier.{' '}
            <Link href="/app/benconnect/#/shopping" target="_blank" data-testid="BenefitsShoppingBannerLink">
              Shop Benefits
            </Link>
          </TextBlock>
          {/* `margin-left: auto;` pushes the StyledIconButton to the right end. */}
          <StyledIconButton
            color="secondary.a"
            ml="auto"
            s="small"
            iconName="close"
            aria-label="Close"
            onClick={onCloseButtonClick}
            data-testid="BenefitsShoppingBannerCloseButton"
          />
        </Flex>
      </AbsoluteNavBanner>
    </AbsoluteNavPlaceholder>
  );
};

export default BenefitsShoppingBanner;
