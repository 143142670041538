import React from 'react';

import { Box, Flex } from 'zbase';
import { Button, Link } from 'z-frontend-elements';
import { Render } from 'z-frontend-theme';

import TrialAccountButton from '../../trial-account-button/TrialAccountButton';
import AvatarDropdown from './AvatarDropdown';
import { tabletBreakpoints } from '../constants';
import { getBuyLink } from '../../utils/growth';
import { getLeadInfo } from '../utils';

declare global {
  interface Window {
    drift: any;
  }
}

const prospectPostData = getLeadInfo();

type DemoCenterRightColumnProps = {
  companyTypeIsDemo: boolean;
  trialHasFreeLimitedCompany: boolean;
  firstName: string;
  lastName: string;
  employeeId: string;
  employeeNumber: string;
  companyId: string;
  photoUrl: string;
  userEmail: string;
  isSelfServeTrial?: boolean;
};

class DemoCenterRightColumn extends React.Component<DemoCenterRightColumnProps> {
  constructor(props: any) {
    super(props);
    prospectPostData.formData.email = this.props.userEmail;
  }

  render() {
    const {
      companyTypeIsDemo,
      trialHasFreeLimitedCompany,
      firstName,
      lastName,
      photoUrl,
      isSelfServeTrial,
    } = this.props;

    const demoCenterAvatar = (
      <AvatarDropdown
        firstName={firstName}
        lastName={lastName}
        photoUrl={photoUrl}
        showInbox={false}
        showMyAccounts={false}
        showAccountSettings={false}
        dropdownItems={undefined}
      />
    );

    const buyLink = getBuyLink();

    return (
      <Flex align="center">
        <TrialAccountButton
          companyTypeIsDemo={companyTypeIsDemo}
          trialHasFreeLimitedCompany={trialHasFreeLimitedCompany}
          isSelfServeTrial={isSelfServeTrial}
          ml={4}
          styleOptions={{
            fontWeight: 'bold',
          }}
          eventLogSource="top_nav_bar"
          eventLogName="toggle_trial_account_companies"
        />
        <Flex ml={[2, 4, 4, 4, 4]} mr={[2, 3]} align="center">
          <Link href={buyLink} className="js-walkme-top-nav-buy-now">
            <Button mode="primary" s="small" px={4}>
              <Box>
                <Render forBreakpoints={tabletBreakpoints}>{'See '}</Render>Pricing
              </Box>
            </Button>
          </Link>
        </Flex>
        <Flex align="center">{demoCenterAvatar}</Flex>
      </Flex>
    );
  }
}

export default DemoCenterRightColumn;
