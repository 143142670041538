import React from 'react';

import { styled } from 'z-frontend-theme';
import { Box, Flex, Icon, TextBlock } from 'zbase';
import { Button, Ellipsis, Link } from 'z-frontend-elements';
import { Avatar } from 'z-frontend-composites';

import { ImplementationProjectDetails } from '../gqlTypes';
import { CardSize } from './OnboardingImplementationHelpCard';

type GuidedOnboardingHelpProp = {
  implementationProject: ImplementationProjectDetails.ActiveImplementationProjectDetails;
  companyName: string;
  size: CardSize;
};

const getEmailBody = (managerName?: string | null) => {
  return `Hi ${managerName ||
    ''},\n\nI have few questions about the implementation process:\n1.\n2.\n3.\n\nHope to hear from you soon!\n\nThank you,\n
  `.replace(/\n/g, '%0D%0A');
};

const getManagerFirstLastName = (managerName?: string | null) => {
  if (!managerName) {
    return [null];
  }
  const splitManagerName = managerName.split(' ');
  if (splitManagerName.length === 1) {
    return [splitManagerName[0], null];
  } else if (splitManagerName.length === 2) {
    return [splitManagerName[0], splitManagerName[1]];
  } else {
    return [splitManagerName[0], splitManagerName[splitManagerName.length - 1]];
  }
};

const StyledLink = styled(Link)`
  overflow-wrap: anywhere;
`;

export const GuidedOnboardingHelp: React.FunctionComponent<GuidedOnboardingHelpProp> = props => {
  const { implementationProject } = props;
  const emailSubject = `Implementation Help: ${props.companyName}`;

  const managerName = getManagerFirstLastName(implementationProject.managerName);
  const emailBody = getEmailBody(managerName[0]);
  const smallSize = props.size === 'small';

  return (
    <Box p={0} textAlign={['center', null, 'left']}>
      <Flex justify={['center', 'center', 'left']}>
        <Avatar
          mr={smallSize ? 2 : 3}
          s={smallSize ? 'small' : 'large'}
          firstName={managerName[0]}
          lastName={managerName[1]}
        />
        <Box>
          <TextBlock color="grayscale.b" fontStyle={smallSize ? 'paragraphs.m' : 'paragraphs.xl'}>
            {implementationProject.managerName}
          </TextBlock>
          <TextBlock color="grayscale.d" fontStyle="paragraphs.s">
            {implementationProject.managerTitle || 'Onboarding Specialist'}
          </TextBlock>
        </Box>
      </Flex>
      <Flex mt={1} ml={1} justify={['center', 'center', 'left']}>
        <Icon
          fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
          color="link.normal"
          pr={2}
          mt={1}
          iconName="email"
        />
        <StyledLink
          fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
          href={`mailto:${implementationProject.managerEmail}?subject=${emailSubject}&body=${emailBody}`}
          color="link.normal"
        >
          {implementationProject.managerEmail}
        </StyledLink>
      </Flex>
      {implementationProject.managerPhone && (
        <Flex mt={1} ml={1} justify={['center', 'center', 'left']}>
          <Icon color="grayscale.d" pr={2} mt="2px" iconName="phone" />
          <Ellipsis color="grayscale.d">{implementationProject.managerPhone}</Ellipsis>
        </Flex>
      )}
      {implementationProject.calendlyLink ? (
        <Button.Link mt={2} s="small" href={`${implementationProject.calendlyLink}`} target="_blank">
          Schedule a Call
        </Button.Link>
      ) : (
        <Button.Link
          mt={2}
          s="small"
          href={`mailto:${implementationProject.managerEmail}?subject=${emailSubject}&body=${emailBody}`}
          target="_blank"
        >
          Send an Email
        </Button.Link>
      )}
    </Box>
  );
};
