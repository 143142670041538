import React, { FunctionComponent } from 'react';

import { DialogsManager } from 'z-frontend-overlays';
import { Box } from 'zbase';

import DetailsModal from './components/DetailsModal';
import DismissInboxActionModal from './components/DismissInboxActionModal';
import ViewInboxActionModal from './components/ViewInboxActionModal';
import InboxActionItem from './InboxActionItem';

type InboxActionItemContainerProps = {
  inboxActionId: string;
  currentEmployeeId: string;
  text: string;
  iconUrl: string;
  isRead?: boolean;
  onActionClick: () => void;
  refreshList?: () => Promise<any>;
  showViewAction?: boolean;
  showDismissAction?: boolean;
  showDetailsAction?: boolean;
  details?: Record<string, string>;
  companyId?: string;
  isForPartnerUser?: boolean;
};

const InboxActionItemContainer: FunctionComponent<InboxActionItemContainerProps> = props => {
  const {
    inboxActionId,
    currentEmployeeId,
    text,
    onActionClick,
    refreshList,
    showViewAction,
    showDismissAction,
    showDetailsAction,
    details,
    companyId,
    isForPartnerUser = false,
    ...rest
  } = props;
  return (
    <DialogsManager
      dialogsCount={3}
      render={([viewInboxActionModalDialog, dismissInboxActionDialog, detailsModalDialog]) => {
        const onDismissButtonClick =
          showDismissAction &&
          ((event: React.MouseEvent) => {
            // Do not trigger onClick on the InboxActionItem itself
            event.stopPropagation();
            dismissInboxActionDialog.open();
          });

        const onDetailsButtonClick =
          showDetailsAction &&
          ((event: React.MouseEvent) => {
            // Do not trigger onClick on the InboxActionItem itself
            event.stopPropagation();
            detailsModalDialog.open();
          });

        return (
          <Box data-testid="InboxActionItem" mb={1}>
            <DismissInboxActionModal
              dialog={dismissInboxActionDialog}
              inboxActionId={inboxActionId}
              currentEmployeeId={currentEmployeeId}
              refreshList={refreshList}
              isForPartnerUser={isForPartnerUser}
            />
            <ViewInboxActionModal
              dialog={viewInboxActionModalDialog}
              inboxActionId={inboxActionId}
              text={text}
              currentEmployeeId={currentEmployeeId}
              refreshList={refreshList}
              companyId={companyId}
            />
            <DetailsModal dialog={detailsModalDialog} inboxActionId={inboxActionId} details={details} />
            <InboxActionItem
              onClick={() => {
                viewInboxActionModalDialog.open();
                onActionClick();
              }}
              text={text}
              onDismissButtonClick={onDismissButtonClick}
              showViewAction={showViewAction}
              showDetailsAction={showDetailsAction}
              onDetailsButtonClick={onDetailsButtonClick}
              {...rest}
            />
          </Box>
        );
      }}
    />
  );
};

export default InboxActionItemContainer;
