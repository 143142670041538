/* tslint:disable */

/** Dummy DateTime, represented as a string */
export type DateTime = any;

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JSON = any;

/** Dummy Date, represented as a string */
export type Date = any;

/** Dummy Time, represented as a string */
export type Time = any;

/** Dummy Currency, represented as a decimal */
export type Currency = any;

export type Decimal = any;

export interface Plan_Rate {
  id?: string | null;
  flatPremium?: string | null;
  gender?: string | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  resource_uri?: string | null;
}

export interface PlanRate {
  id?: string | null;
  flatPremium?: string | null;
  gender?: string | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  resource_uri?: string | null;
}

export interface IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface IQFSession {
  status?: QFSessionStatus | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  completedAt?: DateTime | null;
}

export interface PlanDetails {
  name: string;
  HMOPPO?: string | null;
  planUrl?: string | null;
  carrierId?: number | null;
}

export interface Query {
  dashboard: Dashboard;
  switches: JSON;
  currentZenefitsEmployee?: ZenefitsEmployee | null;
  prerequisiteRedirect?: JSON | null;
  inboxActionPartials?: (InboxActionPartial | null)[] | null /** employeeImpersonations: [EmployeeImpersonation] */;
  zAppActionUrls?:
    | (ZAppActionUrl | null)[]
    | null /** zAppInstallSubscriptions: [ZAppInstallSubscription]pageHelpArticles: [PageHelpArticle] */;
  inboxAction?: InboxAction | null /** spoof_machines(machineCategory: SpoofMachineCategory): [SpoofMachine] */;
  company?: Company | null;
  employee?: AllEmployee | null;
  filterEmployee?: FilterEmployee | null;
  newHire?: NewHire | null;
  employeeEligibility?: EmployeeEligibility | null;
  companySearch?: Company[] | null;
  signature?: Signature | null;
  rolesPermissionsData?: RolesPermissionsData | null;
  PayRunOptions: (PayRunOption | null)[];
  employeeCompensations?: (EmployeeCompensation | null)[] | null;
  changeRequest: ChangeRequest;
  getVariableCompensation?: VariableCompensationData | null;
  getAllPayTypes?: (VariableCompensationPayTypeData | null)[] | null;
  fetchAllVariableComps: (VariableCompensationData | null)[];
  flow?: Flow | null /** Flow & Wizard framework */;
  searchClients?: (SearchClientsResult | null)[] | null;
  inboxTask?: InboxTask | null;
  inboxActionComments?: InboxActionComment[] | null;
  qualifyingEvents?: (QualifyingEvent | null)[] | null;
  filteredCompanies?: (Company | null)[] | null /** Query for inbox task items for partner dashboard */;
  inboxTaskSearch?: InboxTaskSearchResult | null;
  getPDFInfo?: PdfInfo | null;
  fulfillmentTaskEmployees: FulfillmentTaskEmployee[] /** employees to be used for filtering partner dashboard fulfillment tasks */;
  autocompletedCompanies: SuggestedCompany[];
  benefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  benefitsTransaction?: BenefitsTransaction | null;
  benefitsTransactions?: (BenefitsTransaction | null)[] | null;
  restrictionDataForEmployeeAndDependents?: RestrictionData | null;
  planRestrictionData?: PlanRestrictionData | null;
  planRestrictionForEmployee?: (Restriction | null)[] | null;
  planRestrictionForDependents?: (Restriction | null)[] | null;
  employeeBenefitsHsaInfo?: EmployeeHsaInfo | null;
  companyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  bundledLocs?: BundledLocs | null;
  allBundledLocs?: (BundledLocs | null)[] | null;
  benefitsAuditErrors: AuditError[];
  allBenefitsAuditErrors: AuditError[];
  benefitsAuditError: AuditError;
  companyEnrollmentAuditError?: CompanyEnrollmentAuditError | null;
  employeeEnrollmentAuditError?: EmployeeEnrollmentAuditError | null;
  employeeTransactionAuditError?: EmployeeTransactionAuditError | null;
  etaTasks: EtaTasks[];
  partner: Partner;
  brokerPartners: Partner[];
  validateZipCodeCoveredByPartner?: ValidateZipCodeCoveredByPartnerResponse | null;
  partnerUser: PartnerUser;
  fetchPartnerInfo: PartnerInfo;
  allPartnerContacts?: PartnerContact[] | null;
  partnerCompanySettings?: PartnerCompany[] | null;
  partnerCompaniesData: PartnerCompanyMeta;
  validateQleEnrollments: QleEnrollmentsValidationsResult;
  employeeStatus: EmployeeStatusInfo;
  employeeHealthEnrollments?: (EmployeeHealthEnrollment | null)[] | null /** Query for benconnect */;
  employeeBenefitsEnrollments?: (EmployeeBenefitsEnrollment | null)[] | null;
  benefitsLoaCoverageData?: (BenefitsLoaCoverageData | null)[] | null;
  benefitsLoaOngoingLoas?: (BenefitsLoaData | null)[] | null;
  companyBenefitsCost?: (CompanyBenefitsCost | null)[] | null;
  cobraThirdPartyAdministrators?: (CobraThirdPartyAdministrator | null)[] | null;
  cobraClassificationType?: CobraClassification | null;
  companyTags?: (CompanyTag | null)[] | null;
  employeeTags?: (EmployeeTag | null)[] | null;
  waiverRule: (WaiverRule | null)[];
  waiverMetadatas: WaiverMetadata[];
  hasSection125Amendment?: QuerySection125AmendmentResponse | null;
  talent: Talent /** Talent -> Base */;
  talentPermission: TalentPermission;
  questionFlows: QuestionFlow[];
  review?: Review | null /** Talent -> Reviews */;
  reviewTemplates: ReviewTemplate[];
  reviews: Review[];
  revieweeGoals: Goal[] /** Talent -> Goals */;
  goals: Goal[];
  meetingSpaces: MeetingSpace[] /** Talent -> One On Ones */;
  meetingSpace: MeetingSpace;
  meetingsUserPreference?: MeetingsUserPreference | null;
  wellbeingAssessments: WellbeingAssessment[] /** Talent -> Wellbeing */;
  allAssessmentsParticipationSummary: WellbeingAssessmentParticipationSummary[];
  wellbeingLifeEvents: WellbeingLifeEvent[];
  wellbeingLifeEvent: WellbeingLifeEvent;
  wellbeingAssessment: WellbeingAssessment;
  wellbeingArticle?: WellbeingArticle | null;
  wellbeingFeaturedArticles: WellbeingArticle[];
  survey: Survey /** Talent -> Surveys */;
  surveys: Survey[];
  accessibleSurveys: Survey[];
  assignedSurveys: Survey[];
  surveyTemplates: SurveyTemplate[];
  surveyTemplate: SurveyTemplate;
  postAudience?: PostAudience | null /** Social Feed */;
  peopleHubTargetAudience?: TargetAudience | null;
  listSocialFeedComments?: (SocialFeedComment | null)[] | null;
  listPostLikes?: (PostLike | null)[] | null;
  socialFeed?: SocialFeed | null;
  fulfillmentTaskAssignees?: (FilterEmployee | null)[] | null /** Fulfillment Log */;
  fulfillmentTaskViewers?: (FilterEmployee | null)[] | null;
  fulfillmentTaskCarriers?: FulfillmetTaskCarrier[] | null;
  benefitsFulfillmentDetails?: (BenefitsFulfillmentInfo | null)[] | null;
  companyRateVersion?: CompanyRateVersion | null /** Rates Table in Benconnect */;
  companyHealthPlan?: CompanyHealthPlan | null;
  benefitsFormMapperGetDataFields?: DataFields | null;
  benefitsFormMapperStpFormTemplateQuery?: StpFormTemplate | null;
  benefitsFormMapperStpInputFieldsAndOperators?: StpInputFieldsAndOperators | null;
  benefitsFormMapperGetFieldOnPdfForm?: (StpFieldMapping | null)[] | null;
  filterFormTemplates?: (StpFormTemplate | null)[] | null;
  zPayrollCompanySearch?: ZPayrollCompanySearchResult | null /** Query for ZpayrollCompanies */;
  zpCompany?: ZPayrollCompany | null;
  companyImplementationOverviewSearch?: CompanyImplementationOverviewSearchResult | null /** Queries for Implementation Dashboard */;
  companyImplementationOverview?: CompanyImplementationOverview | null;
  allZScoreCompanies?: (ZScoreCompany | null)[] | null /** Queries for ZScore App */;
  allZScoreCategories?: (ZScoreCategory | null)[] | null;
  zScoreCompany?: ZScoreCompany | null;
  allZScoresForCompany?: (ZScoreReduced | null)[] | null;
  paGetIndustries?: IndustryData | null /** People Analytics Appclient app */;
  paGetLocations?: LocationData | null;
  paGetJobFamily?: JobFamilyData | null;
  paGetJobLevel?: JobLevelData | null;
  paGetCompanyConfig?: CompanyConfig | null;
  paGetCompanyTitleLevelMapping?: CompanyJobTitleMapping | null;
  paGetEmployeeTitleLevelMapping?: EmployeeJobTitle | null;
  paGetJobTitleSuggestions?: (JobTitleSuggestion | null)[] | null;
  paSalaryBenchmarking?: EmployeeJobTitleMapping | null;
  paJobLevelSalaryBenchmark?:
    | (JobLevelSalaryMapping | null)[]
    | null /** The paJobLevelSalaryBenchmark will be used for getting data for all job levelswhile paJobBenchmarking for specific job level */;
  paJobBenchmarking?: (JobLevelSalaryMapping | null)[] | null;
  paGetCompanyDataMetrics?: (CompanyDataMetrics | null)[] | null;
  paGetSalaryBenchmarkingBlacklist?: SalaryBenchmarkingBlacklistData | null;
  paGetEmployeeWorkflowInfo?: paWorkflowInfo | null;
  paGetEmployeeChangeRequestInfo?: paWorkflowInfo | null;
  paConsoleGetCompanyConfig?: CompanyConfig | null /** console app */;
  paConsoleGetCompanyTitleLevelMapping?: CompanyJobTitleMapping | null;
  paConsoleGetSalaryInsightsInfo?: EmployeeJobTitleMapping | null;
  paConsoleAppInstall?: PaAppInstall | null;
  paConsoleGetProductFeedbacks?: (Feedback | null)[] | null;
  paConsoleGetSalaryBenchmark?: (JobLevelSalaryMapping | null)[] | null;
  biPremadeReportScheduledInfo?: (PremadeReportScheduledInfo | null)[] | null /** Business Intelligence App */;
  biCustomReportDetails?: (CustomReportData | null)[] | null;
  biCustomReportDetail?: (CustomReportData | null)[] | null;
  biReportDocumentExists?: string | null;
  biPremadeReportTemplates?: (ReportTemplate | null)[] | null;
  trsSampleApi?: trsSampleGraphqlType | null /** Total Reward Statement App */;
  trsGetCategories?: (TrsCategory | null)[] | null;
  trsGetRewardStatements?: (RewardStatement | null)[] | null;
  trsGetRewardStatement?: RewardStatement | null;
  dealsForCompany?: (ProductDeal | null)[] | null /** Queries for zenmarket */;
  dealsForEmployee?: (ProductDeal | null)[] | null;
  getDeals?: (ProductDeal | null)[] | null;
  productCategories?: (ProductCategory | null)[] | null;
  filterParams?: (Filter | null)[] | null;
  currentUserCompany?: (CurrentUserCompany | null)[] | null;
  currentUserEmployeeDetails?: EmployeeDetails | null;
  productCategoryOptions?: JSON | null;
  metaKeysForProduct?: (string | null)[] | null;
  matchingCriteriaChoices?: JSON | null;
  matchingCriteriaForm?: JSON | null;
  getMyDeals?: (MyDeal | null)[] | null;
  isCompanyAdmin?: boolean | null;
  allProductDetailsAndOptions?: JSON | null;
  metaKeysForCategory?: (string | null)[] | null;
  productDetails?: JSON | null;
  companySetupTasks: FlowSection[] /** Queries for company_setupDEPRECATED but don't delete as it's still used by WalkMe */;
  companySetupTasksV3?: CompanySetupFlowTasks | null;
  getOnboardingDocsStatus?: OnboardingDocStatus | null;
  getSubscriptionSeats?: number | null;
  getCompanyOnboardingDatagrid?: number | null;
  companySetupPTOPlan?: CompanySetupPTOPlan | null;
  companySetupEmployeeStats?: CompanySetupEmployeeStats | null;
  companySetupInviteEmailRecipients?: (CompanySetupInviteEmailRecipients | null)[] | null;
  companySetupReminderEmailRecipients?: (CompanySetupReminderEmailRecipients | null)[] | null;
  isEnrolledInPYP?: boolean | null;
  isEnrolledInTimeAndPYP?: boolean | null;
  getPriorPayrollInfo?: PriorPayrollInfo | null;
  getPayrollBankSetupStatus: PayrollBankSetUpStatus;
  companySetupPreviewPaySchedule?: (CompanyPaySchedulePreview | null)[] | null;
  isCompanyOnboardingAccessible?: boolean | null;
  isMarketplaceSetupFlow?: boolean | null /** Start of Marketplace queries */;
  companySetupContributionSettings?: CompanySetupContributionSettings | null;
  companySetupMarketplacePlansV2?: (CompanySetupMarketplacePlanV2 | null)[] | null;
  companySetupBenefitsPreferences?: CompanySetupBenefitsPreferences | null;
  companySetupCarrierSettings?: CompanySetupCarrierSettings | null;
  demoCenterDropdownOptions?: DemoCenterDropdownOptions | null /** Demo Center App */;
  demoConsumerInfo?: DemoConsumerInfo | null;
  productCatalog?: ProductCatalog | null /** Checkout App */;
  checkoutSession: CheckoutSession;
  checkoutFlow?: CheckoutFlow | null;
  zuoraApiEnabled?: ZuoraApiEnabled | null;
  generateGatewayParams?: GenerateGatewayParams | null;
  documentsInfo?: DocumentInfo[] | null /** Documents App */;
  companyAdmins: (CompanyAdmin | null)[];
  docActionHistory: (DocActionHistory | null)[];
  readOnlyAccess?: readOnlyAccess | null /** Read Only Access */;
  getChatStatus?: chatStatus | null /** Support */;
  businessHours?: holidayInfo | null;
  businessHoursSupport?: holidayInfo | null;
  roleAndCategory?: roleAndCategory | null;
  openCases?: (openCases | null)[] | null;
  coveoCredentials?: coveoData | null;
  coveoDeflectionCredentials?: coveoData | null;
  issueCategories?: (issueTopics | null)[] | null;
  contactOptions?: recommendedContactOptions | null;
  finalPage?: finalPageData | null;
  banner?: bannerData | null;
  parentTopics?: (issueTopics | null)[] | null;
  decisionTreePath?: decisionTreePathData | null;
  niceCredentials?: niceCredentials | null;
  allegeusBusinessHours?: alleguesBusinessHours | null;
  allCompanyAdmins?: (EmployeeMessage | null)[] | null /** Company Documents */;
  getTargetableEmployees?: (EmployeeMessage | null)[] | null;
  getTargetedEmployees?: (EmployeeMessage | null)[] | null;
  companyDocuments?: (CompanyDocuments | null)[] | null;
  checkIfDocumentNameAvailable?: boolean | null;
  foldersAndDocuments?: FoldersAndDocumentsQuery | null;
  validateFolderName?: boolean | null;
  listEmployees?: (LoaEmployeeMessage | null)[] | null /** Leave of Absence */;
  getAllLoaPolicies?: LeaveOfAbsencePolicies | null;
  listAllPolicies?: (Policy | null)[] | null;
  loaPolicy?: LoaPolicyResponse | null;
  getLeaveRequest?: LeaveOfAbsenceRequestMessage | null;
  getPtoVacationTypes?: PtoVacationTypes | null;
  getAllLoaRequests?: AllLoaRequestsResponse | null;
  getLoaRequestsForOverviewPage?: LoaRequestsForOverviewPageResponse | null;
  getWorkHours?: DateRangeMessage | null;
  canDeletePolicy?: DeleteLoaPolicyResponse | null;
  isNewPolicyVersion?: IsNewPolicyVersionResponse | null;
  groupTypes: GroupType[] /** Groups */;
  groups: Group[];
  membership?: Group[] | null;
  taPayPeriod?: TaPayPeriod | null /** Time&Attendance App */;
  taPayPeriods?: (TaPayPeriod | null)[] | null;
  taCompanyNotificationSettings?: TaCompanyNotificationSettings | null;
  taPayPeriodEmployees?: (TaPayPeriodEmployee | null)[] | null /** We should rename this resource taTimesheets */;
  taTimesheet?: TaPayPeriodEmployee | null;
  taCompany?: TaCompany | null;
  taEmployee?: TaEmployee | null;
  employeeWithTaEmployeeId?: EmployeeWithTaEmployeeId | null;
  taCompanyWithCompanyId?: TaCompanyWithCompanyId | null;
  taPayPeriodFilter?: (TaPayPeriod | null)[] | null /** TO-DO deprecate this query */;
  taCalculateHoursAndAvailability?: TaCalculateHoursAndAvailability | null;
  adherenceReportFormData?: AdherenceReportFormData | null /** Time Scheduling App Queries */;
  currentSchedulingCompany: SchedulingCompany;
  currentSchedulingEmployee: SchedulingEmployee;
  listSchedulingEmployeeAvailability: SchedulingEmployeeAvailability[];
  listSchedulingEmployees: SchedulingEmployee[];
  listSchedulingPositions: Group[];
  listSchedulingShifts: SchedulingShift[];
  schedulingPermission: SchedulingPermission;
  schedulingOverviewQuery: SchedulingOverview;
  listVacationRequests: VacationRequest[] /** Time Off App Queries */;
  employerPtos: EmployerPto[];
  calculateHoursAndAvailability?: CalculateHoursAndAvailability | null;
  employeePtoAccounts: EmployeePtoAccount[];
  employerPtoAccounts?: (EmployerPtoAccount | null)[] | null;
  balanceHistoryItems?: (BalanceHistoryItem | null)[] | null;
  tenureBonus?: (string | null)[] | null;
  paymentAuthorizationDetails?: PaymentAuthorizationDetails | null /** does the user need to acknowledge a message about contacting their institution */;
  payrollReports?: PayrollReports | null /** Payroll Reports */;
  payrollReportsEmployees: (PayrollReportsEmployee | null)[];
  payrollReportsV2TaxPackageDates?: (TaxPackage | null)[] | null;
  payrollReportsPreviousW2s?: (W2Preview | null)[] | null;
  getPlaidEnvironment: PlaidEnvironment /** Queries for Payroll */;
  getPayrollBankInfo: BankAccountInfo;
  getMappingTemplateInfo: GLMappingTemplateInfo /** General Ledger */;
  getAccountMappingTemplateInfo: GLAccountMappingTemplateInfo;
  getReportDownloadOptions: GLReportDownloadOptions;
  getLaborGroupTypesForCompany: LaborGroupTypesResponse /** Accounting Integration */;
  getSelectedLaborGroupTypesForCompany: LaborGroupTypesResponse;
  getPayTypesForCompany?: ZPCompanyPayTypesResponse | null;
  getLaborGroupDetailsForCompany?: ZPCompanyLaborGroupDetailsResponse | null;
  getAccountingAccountsForCompany?: ZPAccountingAccountResponse | null;
  refreshAccountingAccount?: RefreshAccountingAccounts | null;
  getClassTrackingOptions?: classTrackingOptions | null;
  getAccountMappingData?: ZPAccountingMappingResponse | null;
  getF01kAuditLog?: F01kAuditLog[] | null /** 401k Audit Log */;
  omniSearchSuggestion: OmniSearchSuggestion;
  activationInfo?: ActivationInfo[] | null /** Deployment Dashboard */;
  activationCandidates?: ActivationCandidate[] | null;
  zfApps?: (string | null)[] | null;
  companyHubPermission: CompanyHubPermission;
  companyHubCompanies: CompanyHubCompany[];
  companyHubUserMetaData: CompanyHubUserMetaData;
  multiOrgCustomReportsMetaData: MultiOrgCustomReportMetaData[];
  createMultiOrgReport: MultiOrgReport;
  activeBillingDetails: ActiveBillingDetails /** Billing */;
  delinquentPaymentInfo: DelinquentPaymentInfo;
  datagrid: Datagrid /** Datagrid */;
  datagridSubmissionStatus: DatagridSubmissionStatus /** This query is used when user clicks "Submit" on the editable table page. It returns data needed to show aconfirmation modal. */;
  listAgreements?: GenericAgreement[] | null /** Generic Agreements */;
  resourceCenter: ResourceCenter /** Resource Center */;
  employeeLockboxData: EmployeeLockboxData /** Data deletion */;
  employeesLockboxData: EmployeeLockboxData[];
  allDeletionRequests: DeletionRequestGroup[];
  generateDeletionRequestsCSV: string;
  webhooksDashFilterOptions?: WPFilterOptions | null /** webhooks_proxy */;
  webhookMessages?: (WPElasticSearchResult | null)[] | null;
  getPPBUploadStatusObjects: PriorPayrollUploadDetails[] /** Prior Payroll Balancing (Datagrid) */;
  getPPBUploadDetailsById: GetPriorPayrollUploadById;
  getPPBTaxCalcBgTaskUiSwitchStatus: boolean;
  getPPBTaxCalcTaskStatus?: string | null;
  hasZywaveAgreement?: HasZywaveAgreementResult | null /** Second Party Integration (TODO: remove when zywave is ready) */;
  isProvisioningEnabledForSSOProvider: IsProvisioningEnabledValue;
  getSSOSettings: SSOSettings;
  getTaxLiabilitiesObjects: TaxLiabilitiesDetails[] /** taxliabilities details */;
  isContractor1099FormFilingEditable: boolean /** Contractor 1099 Form Filing */;
  get1099FilingDates?: Get1099FilingDatesDefault | null /** Contractor 1099 Form Filing Console Operation */;
  getOpsTeamEmailList?: OpsTeamEmailList | null;
  getCompanyGroupData: CompanyGroupData /** company hub company grouping */;
  getWorkerDocumentFolders?: WorkerDocumentGetFoldersResponse | null /** worker documentV2 folder view */;
  getWorkerDocumentFiles?: WorkerDocumentGetFilesResponse | null /** worker documentV2 file view */;
  getWorkerDocumentAuditData?: WorkerDocumentGetFilesResponse | null /** worker documentV2 audit view */;
  getAggregatedPlanSummary?: AggregatedPlanSummary | null /** plan shopping */;
  getPlans?: PlanInfo[] | null;
  getCarriers?: CarrierInfo[] | null;
  getSwitchById?: Switches | null /** switches */;
  getSwitchByKey?: Switches | null;
  getSwitches?: Switches[] | null;
  searchSwitchByKey: SearchSwitchByKeyResponse;
  getSwitchPutLogs?: PutLog[] | null;
  getTwoFactorSettings?: TwoFactorSettings | null /** 2FA */;
}

export interface Dashboard {
  id?: string | null;
  user_id?: string | null;
  user?: User | null;
  company?: Company | null;
  isSpoofing?: boolean | null;
  demoSourceType?: string | null;
  isDemoAccount?: boolean | null;
  isTrialPasswordless?: boolean | null;
  isTrialCompany?:
    | boolean
    | null /** true for any trial/demo, including SelfServe and Sales. See`companyTypeIsDemo`, `companyTypeIsTrial` and `isTrialSales` to distinguishA Demo Company uses fake dataA Trial Company (or Free Limited Trial Company) uses real data and is considered a Real company, just not a paying oneDemos and Trials can be used by Sales Assisted teams or directly by customers */;
  isTrialSales?:
    | boolean
    | null /** Demo company seeded for Sales to show this to customers (not used directly by customers)A Demo Company uses fake data */;
  companyTypeIsDemo?:
    | boolean
    | null /** Demo Company. Can be used directly by customers or by sales.A Demo Company uses fake dataSee isTrialSales to identify if the company is meant for direct customer use or Sales Assisted use */;
  companyTypeIsTrial?:
    | boolean
    | null /** It's a Trial Company  (and NOT a Demo)A Trial Company (or Free Limited Trial Company) uses real data and is considered a Real company, just not a paying one */;
  canSeeDemoCenter?: boolean | null;
  isSelfServeTrial?:
    | boolean
    | null /** For Demo or Trial Accounts indicates if it was created for Self Serve purposesFor Accounts created via SFDC there is an explicit checkFor Accounts created via www the logic varies depending on other custom logicThis flag is used for UI personalization, but not for attribution */;
  trialHasFreeLimitedCompany?:
    | boolean
    | null /** For Demo Companies indicates that the Company has an associated Free Limited Trial Company (`companyTypeIsTrial`=true)This flag is always true when companyTypeIsTrial=true */;
  trialAccessToken?:
    | string
    | null /** trialAccessToken was added to identify visitors while logging user events from the frontend.Endpoint to log user events doesn't require login. If we use trial account ID instead ofaccess token, it could lead to brute force attacks and skew experiment results. Access tokenis a UUID and secure compared to auto increment identifiers. */;
  trialPromoCode?: string | null;
  trialToExpireAt?: string | null;
  isMTAUser?: boolean | null;
  isMTAPartnerUser?: boolean | null;
  partnerUserCompanyId?: string | null;
  partnerUserIntegrationHash?: string | null;
  partner?: Partner | null;
  partnerUser?: PartnerUser | null /** We can only fetch partnerUser when NOT in a custom context - there is a permissions issue otherwise */;
  isConsoleUser?: boolean | null;
  employee?: AllEmployee | null;
  switches: JSON;
  emailVerified?: boolean | null;
  features?: JSON | null;
  permission?: JSON | null;
  profile?: JSON | null;
  userIntercomHash?: string | null /** we removed Intercom, but leaving this for backwards compatibility */;
  userBeamerHash?: string | null;
  userFullstoryHash?: string | null;
  userIntegrationHash?:
    | string
    | null /** Moving forward use this for integrations rather than a separate hash per integration */;
  companyDisplayName?: string | null;
  sessionId?: string | null /** hash of the actual sessionId */;
  employees?: (AllEmployee | null)[] | null;
  dashboardTeammates?: (AllEmployee | null)[] | null;
  zAppInstallSubscriptions?: (ZAppInstallSubscription | null)[] | null;
}

export interface User {
  first_name?: string | null;
  last_name?: string | null;
  is_active?: boolean | null;
  id?: string | null;
  objId?: number | null;
  email?: string | null;
}

export interface Company {
  legalAddress2?: string | null;
  overridePayStartWeekDay?: number | null;
  phoneExtension?: string | null;
  stateTaxID?: string | null;
  nextPayPeriodDate?: DateTime | null;
  payFrequency?: string | null;
  payrollPin?: string | null;
  regEmployeeCount?: number | null;
  logoUrl?: string | null;
  payrollHomeDir?: string | null;
  accountantEmail?: string | null;
  county?: string | null;
  payrollProviderUrl?: string | null;
  stateTaxIDState?: string | null;
  syncStatus?: string | null;
  payrollProvider?: string | null;
  isOwnerOnlyCompany?: boolean | null;
  id?: string | null;
  city?: string | null;
  lastGoodSync?: DateTime | null;
  legalCity?: string | null;
  _enrollmentStatus?: string | null;
  stateOfIncorporation?: string | null;
  zip?: string | null;
  benefitsOnly?: boolean | null;
  dayBusinessEstablished?: string | null;
  obfuscatedId?: string | null;
  address2?: string | null;
  overridePayFirstCheckDay?: number | null;
  overrideAnchorPayCheckDate?: DateTime | null;
  payrollPhone?: string | null;
  lastBulkUnRegEmailResend?: DateTime | null;
  businessDescription?: string | null;
  shortCircuitEmployeeCount?: number | null;
  type?: string | null;
  payrollCredentialAccountType?: string | null;
  overridePaySecondCheckDay?: number | null;
  naicsCode?: string | null;
  fax?: string | null;
  payrollPassword?: string | null;
  selectedCarrier?: string | null;
  salesforceIndustry?: string | null;
  overridePayArrears?: number | null;
  legalName?: string | null;
  payrollEmail?: string | null;
  version_id?: number | null;
  deactivationDate?: DateTime | null;
  payrollUsername?: string | null;
  selectedStateCarrier?: Carrier | null;
  state?: string | null;
  monthBusinessEstablished?: string | null;
  address?: string | null;
  employeeCount?: number | null;
  legalAddress?: string | null;
  isReal?: boolean | null;
  syncedPayrollProvider?: string | null;
  groupId?: string | null;
  isActive?: boolean | null;
  payrollSyncType?: string | null;
  legalZip?: string | null;
  name?: string | null;
  phone?: string | null;
  legalState?: string | null;
  logoKey?: string | null;
  syncErrorMessage?: string | null;
  lastSyncRun?: DateTime | null;
  browserQueue?: string | null;
  payrollClientId?: string | null;
  payrollLoginIsOurs?: boolean | null;
  isLargeGroup?: boolean | null;
  sicCode?: string | null;
  cancellationRequest?: CancellationRequest | null;
  cancellationDetails?: CancellationDetails | null;
  overrideAnchorPayDate?: DateTime | null;
  yearBusinessEstablished?: string | null;
  payrollCookies?: string | null;
  ein?: string | null;
  hrProxy?: CompanyHrProxy | null;
  healthProxy?: CompanyHealthProxy | null;
  auxProxy?: CompanyAuxProxy | null;
  payrollProxy?: CompanyPayrollProxy | null /** internationalProxy: CompanyInternationalProxy */;
  employees?:
    | (AllEmployee | null)[]
    | null /** ptoProxy: CompanyPtoProxytaProxy: CompanyTaProxystockoptionProxy: CompanyStockoptionProxy */;
  benefitsEnrollment?: CompanyBenefitsEnrollment | null;
  benefitsContacts?: (BenAdminContact | null)[] | null;
  parentCompany?: Company | null;
  linkedCompanies?: (Company | null)[] | null;
  groupTypes?: GroupType[] | null;
  billingAccount?: BillingAccount | null /** for Billing purposes */;
  showStaffDirectory?: boolean | null;
  contractor1099FormFilings?: Contractor1099FormFiling[] | null;
  getAllContractors1099MiscFilesAsZip?: string | null;
  activeImplementationProjectDetails?: ImplementationProjectDetails | null;
}

export interface Carrier {
  liveQuoteMedical?: boolean | null;
  agentName?: string | null;
  liveQuoteVision?: boolean | null;
  businessInsuranceAgentIdOverride?: string | null;
  liveQuoteEmbargoDays?: number | null;
  isMedicalCarrier?: boolean | null;
  agentId?: string | null;
  id?: string | null;
  agencyId?: string | null;
  appointmentNotes?: string | null;
  isDentalCarrier?: boolean | null;
  businessInsuranceAgentId?: number | null;
  onlineEoiUrl?: string | null;
  isTrust?: boolean | null;
  state?: string | null;
  rxGroup?: string | null;
  isEDISupported?: boolean | null;
  businessInsuranceAgencyId?: string | null;
  supportsAutopay?: boolean | null;
  logoUrl?: string | null;
  isDisabilityCarrier?: boolean | null;
  employerAccessMode?: string | null;
  liveQuoteDental?: boolean | null;
  newHireApprovalProcessingDays?: number | null;
  isVisionCarrier?: boolean | null;
  isLifeCarrier?: boolean | null;
  displayName?: string | null;
  name?: string | null;
  crawlDriver?: string | null;
  appointmentStatus?: string | null;
  rxPCN?: string | null;
  logoKey?: string | null;
  agentModelId?: number | null;
  rxBinNumber?: string | null;
  isBusinessInsuranceCarrier?: boolean | null;
  ein?: string | null;
  autopayInstruction?: string | null;
  carrierID?: number | null;
  copy?: CarrierCopy | null;
  planBundles: (PlanBundle | null)[];
}

export interface CarrierCopy {
  lgEmployeeDeclineWaiver?: string | null;
  achAuthorization?: string | null;
  hmoMedicalDisplayText?: string | null;
  id?: string | null;
  hmoDentalDisplayText?: string | null;
  employeeAgreement?: string | null;
  dentalClaimsAddress?: string | null;
  ltdClaimsAddress?: string | null;
  hmoMedicalUrl?: string | null;
  hmoDentalUrl?: string | null;
  onlyPercentPolicy?: boolean | null;
  stdClaimsAddress?: string | null;
  employeeDeclineWaiver?: string | null;
  lgEmployeeAgreement?: string | null;
  website?: string | null;
  hmoClaimsPhone?: string | null;
  ppoClaimsPhone?: string | null;
  phone?: string | null;
  visionClaimsAddress?: string | null;
  employerLoginUrl?: string | null;
  companyAgreement?: string | null;
  claimsAddress?: string | null;
  disclaimerStatement?: string | null;
  officerStatement?: string | null;
  medicalClaimsAddress?: string | null;
}

export interface PlanBundle {
  isSTDBundled?: boolean | null;
  effectiveEndDate?: string | null;
  isADnDBundled?: boolean | null;
  isDentalBundled?: boolean | null;
  isLifeBundled?: boolean | null;
  isLTDBundled?: boolean | null;
  carrier?: Carrier | null;
  isVisionBundled?: boolean | null;
  isMedicalBundled?: boolean | null;
  id?: string | null;
  effectiveStartDate?: string | null;
  resource_uri?: string | null;
}

export interface CancellationRequest {
  id: string /** Same as Company ID but the ID is required by GraphQL */;
  status: CancellationRequestStatus;
  productsToCancel: string[] /** list of keys indicating the products that we need to cancel. */;
  questionnaireResponse?: JSON | null /** Questionnaire response. Capturing in JSON to push this to SFDCIn the format {productKey: {question1: response1}, payroll: {}, insurance: {}, } */;
  questionnaireExpirationDate: Date;
  cancellationNoticeDate: Date /** Date the customer gave us notice of their intention to cancel */;
  contractEndDate: Date;
  listOfAdmins: string[] /** List of admins that can answer the questionnaire.NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
  cancellationReason: string /** NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
  cancellationReasonDetails?:
    | string
    | null /** NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
}

export interface CancellationDetails {
  cancellationDate?: DateTime | null;
  nextAvailableCancellationDate?: DateTime | null;
}

export interface CompanyHrProxy {
  id?: string | null;
  hrContact?: EmployeeHrContact | null;
  departments?: (Department | null)[] | null;
  locations?: (CompanyLocation | null)[] | null;
  adminName?: string | null;
  adminPhone?: string | null;
  hrContactName?: string | null;
  hrContactEmail?: string | null;
  adminEmail?: string | null;
  permissionedAdmins?: (AllEmployee | null)[] | null;
}

export interface EmployeeHrContact {
  hrContactName?: string | null;
  hrContactPhone?: string | null;
  company?: Company | null;
  hrContactEmail?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface Department {
  id?: string | null;
  resource_uri?: string | null;
  company?: Company | null;
  wfCoCode?: string | null;
  name?: string | null;
  groupId?: string | null;
  isDefault?: boolean | null;
  laborCode?: string | null;
}

export interface CompanyLocation {
  wfCoCode?: string | null;
  officialName?: string | null;
  id?: string | null;
  city?: string | null;
  zip?: string | null;
  isOfficialComplete?: boolean | null;
  company?: Company | null;
  intlAddress?: Address | null;
  state?: string | null;
  officialPhone?: string | null;
  getTimeZone?: string | null;
  naicsCode?: string | null;
  isEeoReportComplete?: boolean | null;
  deleted?: boolean | null;
  street1?: string | null;
  street2?: string | null;
  phone?: string | null;
  officialEmail?: string | null;
  officialTitle?: string | null;
  payrollLocationId?: string | null;
  intlCompanyTax?: string | null;
  name?: string | null;
  country?: string | null;
  countryHumanReadable?: string | null;
  eeoId?: string | null;
  isHeadquarters?: boolean | null;
  eeoFiledLastYear?: boolean | null;
  resource_uri?: string | null;
}

export interface Address {
  city?: string | null;
  fax?: string | null;
  street1?: string | null;
  street2?: string | null;
  phone?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface AllEmployee {
  status?: EmployeeStatus | null /** allStatus returns the same data as status, but status is API-restricted to only managers/admins, while allStatus isavailable to everyone. One of the reasons was so that people could not save `status` modifications if they didn'thave access to it. */;
  allStatus?: EmployeeStatus | null /** status value when you don't have access to the employee as admin/manager etc */;
  preferredName?: string | null;
  preferredOrFirstName?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  creationMethod?: string | null;
  overridePayStartWeekDay?: number | null;
  reportToEmployee?: AllEmployee | null;
  subordinates?: AllEmployee[] | null;
  tShirtSize?: string | null;
  sex?: string | null;
  obfuscatedId?: string | null;
  workPhoneExtension?: string | null;
  deductionsEffectiveStartDate?: DateTime | null /** paySchedule: CompanyPaySchedule */;
  isHighlyCompensated?: boolean | null;
  isSFBayArea?: boolean | null;
  isFicaExempt?: boolean | null;
  location?: CompanyLocation | null;
  id?: string | null;
  employeeNumber?: string | null;
  user_id?: string | null;
  userEmail?: string | null;
  city?: string | null;
  first_name?: string | null;
  middle_name?: string | null;
  underMedicare?: boolean | null;
  zip?: string | null;
  title?: string | null;
  isDisabled?: boolean | null;
  personalPronounsId?: number | null;
  permission?: JSON | null;
  personalPronouns?: string | null;
  company?: Company | null;
  isKeyEmployee?: boolean | null;
  overridePayFirstCheckDay?: number | null;
  overrideAnchorPayCheckDate?: DateTime | null;
  socialSecurityProofUrl?: string | null;
  qeCreated?: boolean | null;
  type?: EmployeeType | null;
  address2?: string | null;
  selectedPlan?: number | null;
  department?: Department | null;
  departments?: DepartmentInfo[] | null;
  changesTimeline?: EmployeeChangesTimeline | null;
  pendingChanges?: PendingChange[] | null /** deprecated - remove after "hr_consolidated_work_groups" at 100% */;
  pendingGroupChanges?:
    | PendingGroupChange[]
    | null /** list of un-applied changes to group memberships for this employee */;
  pendingCompensationChanges?: PendingChangeItem[] | null;
  groups?: (Group | null)[] | null;
  selectedDentalPlan?: number | null;
  state?: string | null;
  overridePayArrears?: number | null;
  visionApprovalStatus?: string | null;
  version_id?: number | null;
  genderIdentity?: string | null;
  phone?: string | null;
  payrollId?: string | null;
  address?: string | null;
  selectedVisionPlan?: number | null;
  dietaryRestrictions?: string | null;
  socialSecurityExpectedDate?: DateTime | null;
  overrideAnchorPayDate?: DateTime | null;
  medicalApprovalStatus?: string | null;
  taReportingMethod?: string | null;
  overridePayFrequency?: string | null;
  paymentDistributionMethod?: string | null;
  dentalApprovalStatus?: string | null;
  dob?: string | null;
  paymentMethod?: string | null;
  photoThumbnailUrl?: string | null;
  marital_status?: string | null;
  email?: string | null;
  overridePaySecondCheckDay?: number | null;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurity?: string | null;
  socialSecurityDecrypted?: string | null;
  ageRange?: string | null;
  w4Signature?: Signature | null;
  workPhone?: string | null;
  homeAddress?: Address | null;
  inDE9C?: boolean | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
  hrProxy?: AllEmployeeHrProxy | null /** syntheic propertiesproxy for HR and core relationships */;
  auxProxy?: AllEmployeeAuxProxy | null /** proxy for health benefits relationshipshealthProxy: AllEmployeeHealthProxyproxy for ancillary benefits relationships */;
  canAdminister?: boolean | null /** is the current user requesting the API an administrator of this employee */;
  canManage?: boolean | null /** is the current user requesting the API a manager of this employee */;
  isManager?: boolean | null;
  hireDate?: string | null /** hire date as a string in the from MM/DD/YYYY */;
  isTeamMember?: boolean | null /** Are they your manager, subordinates or siblings */;
  isRequester?: boolean | null /** the employee object for the user that made this API request */;
  employmentType?: EmploymentType | null;
  employmentTypeIncludingWorkerTypes?: string | null;
  terminationType?: TerminationType | null;
  terminationCategory?: string | null;
  terminationDate?: string | null /** termination date as a string in the from MM/DD/YYYY */;
  currency?: string | null /** for international employees their pay currency */;
  compType?: CompType | null /** compensation type */;
  payRate?: number | null;
  salaryAnnual?: number | null;
  benefitsPlanOptions?:
    | (CompanyHealthPlan | null)[]
    | null /** Deprecated. Use benefitsPlanOptions inside BenefitsEnrollment if possible */;
  benefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  benefitsTransactions?: (BenefitsTransaction | null)[] | null;
  effectiveBenefitsTransactions?: (BenefitsTransaction | null)[] | null;
  nextEffectiveBenefitsTransactions?: (BenefitsTransaction | null)[] | null;
  dependents?: (Dependent | null)[] | null;
  productEligibilityTag?: EmployeeProductEligibilityTag | null;
  benefits?: EmployeeBenefits | null /** Going forward all benefits related queries to EmployeeBenefits andresolve it in benefits_enrollment/benefits_transaction resolvers. */;
  employeeChangesHistory?: (EmployeeChangesHistory | null)[] | null;
  profile?: EmployeeProfile | null;
  personalInfo?: PersonalInfo | null;
  beneficiaries?: (Beneficiary | null)[] | null;
  zAppSubscription?: ZAppInstallSubscription | null;
  changeRequests?: ChangeRequest[] | null;
  isDeidentified?: boolean | null;
  supportId?: string | null;
}
/** deprecated - remove after "hr_consolidated_work_groups" at 100% */
export interface DepartmentInfo {
  group: Group;
  isDefault: boolean;
}

export interface Group {
  id: string;
  name?: string | null;
  description?: string | null;
  groupTypeId?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}

export interface EmployeeChangesTimeline {
  departmentEvents: DepartmentsChangeEvent[] /** deprecated - remove after "hr_consolidated_work_groups" at 100% */;
}

export interface DepartmentsChangeEvent {
  changeRequestGroup: ChangeRequestGroup;
  departments: DepartmentInfo[];
}

export interface ChangeRequestGroup {
  sendManagerEmail?: boolean | null;
  requestCompletionDate?: string | null;
  sendEmployeeEmail?: boolean | null;
  id?: string | null;
  category?: string | null;
  effectiveDate?: string | null;
  applyImmediately?: boolean | null;
  declineReason?: string | null;
  requestApprovalDate?: string | null;
  isRequesterAdmin?: boolean | null;
  type?: string | null;
  status?: string | null;
  isPrimaryRequestGroup?: boolean | null;
  requestedBy?: AllEmployee | null;
  isApproved?: boolean | null;
  reason?: string | null /** approvals: [ApprovalAction] */;
  transfersChangeRequestGroup?: ChangeRequestGroup | null;
  creationDate?: string | null;
  isBackFilled?: boolean | null;
  isReadyToProcess?: boolean | null;
  requests?: (ChangeRequest | null)[] | null;
  requestCreationDate?: string | null;
  resource_uri?: string | null;
}

export interface ChangeRequest {
  isMainRequest?: boolean | null /** employmentTypeChange: EmploymentTypeChange */;
  id?: string | null /** stateTaxChange: StateTaxChange */;
  employee?: AllEmployee | null /** federalTaxChange: FederalTaxChange */;
  isApplied?:
    | boolean
    | null /** payScheduleChange: PayScheduleChangedepartmentChange: DepartmentChangetitleChange: TitleChangegrantAddition: EmployeeOptionGrant */;
  parentRequest?: ChangeRequest | null /** newHireObject: NewHire */;
  salaryChange?: SalaryChange | null /** hireDateChange: HireDateChangedeprecated */;
  groupId?:
    | string
    | null /** taxExemptionChange: TaxExemptionChangelocationChange: LocationChangegrant: GrantAdditionmanagerChange: ManagerChange */;
  group?: ChangeRequestGroup | null;
  resource_uri?:
    | string
    | null /** benefitsChange: TakeoverBenefitsChangeterminationRequest: EmployeeTerminationAction */;
  departmentsChange?: DepartmentsChange | null;
  groupChange?: GroupMembershipChange | null;
  groupChanges: GroupMembershipChange[];
  isGroupNotEmpty: boolean;
  compensationChange?: CompensationChange | null;
  variableCompChange?: VariableCompensationChange | null;
}

export interface SalaryChange {
  salary?: string | null;
  nextEffectivePPEndDate?: string | null;
  effectiveDateWithCurrentPP?: string | null;
  changeRequest?: ChangeRequest | null;
  nextEffectivePPStartDate?: string | null;
  doesEffectiveDateAling?: boolean | null;
  effectivePPStartDate?: string | null;
  payRate?: string | null;
  effectivePPEndDate?: string | null;
  canPushInEffectivePP?: string | null;
  resource_uri?: string | null;
  compType?: string | null;
  id?: string | null;
  requestedBy?: AllEmployee | null;
}

export interface DepartmentsChange {
  id: string;
  changeRequestId: string;
  departments: DepartmentChangeInfo[];
  moveSubordinates?: boolean | null;
}

export interface DepartmentChangeInfo {
  id: string;
  groupId: string;
  isDefault: boolean;
  group: Group;
}

export interface GroupMembershipChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  groupTypeId: string /** The Group Type to which this item applies to */;
  groupType: GroupType /** The Group Type to which this item applies to */;
  groupIds: string[] /** The groups within this type that the employee belongs to */;
  groups: Group[];
  defaultId?: string | null /** Default group within this type for this employee */;
  defaultGroup?: Group | null;
  kind: string;
}

export interface GroupType {
  id: string;
  name: string;
  description?: string | null;
  isSystemType: boolean;
  memberType: string;
  groups: Group[];
  membership?: Group[] | null;
  defaultGroup?: Group | null;
}

export interface CompensationChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  positionRates?: (PositionRateChange | null)[] | null;
}

export interface PositionRateChange {
  groupId: string;
  group: Group;
  rate?: number | null;
}

export interface VariableCompensationChange {
  id?: string | null /** The ChangeRequestItem ID */;
  changeRequestId?: string | null /** The change request to which this item belongs */;
  variableComps?: (VariableCompensationData | null)[] | null;
}

export interface VariableCompensationData {
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: number | null;
  amount?: number | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}
/** deprecated - remove after "hr_consolidated_work_groups" at 100% */
export interface PendingChange {
  id: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail: DepartmentInfo[];
  kind: ChangeRequestItemKind;
}
/** deprecated */
export interface PendingGroupChange {
  id: string;
  changeRequestGroupId: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail?: GroupMembershipChange | null;
  kind: ChangeRequestItemKind;
}

export interface PendingChangeItem {
  id: string;
  changeRequestGroupId: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail?: ChangeDetail | null;
  kind: ChangeRequestItemKind;
}

export interface Signature {
  employee?: AllEmployee | null;
  signatureName?: string | null;
  signatureBlob_id?: number | null;
  signatureDataUrl?: string | null;
  signatureDate?: DateTime | null;
  signatureTitle?: string | null;
  id?: string | null;
}

export interface AllEmployeeHrProxy {
  employments?: (EmployeeEmployment | null)[] | null;
  currentEligibility?: EmployeeEligibility | null /** contractor: ContractorchangeRequests: [ChangeRequest]employeeContact: EmployeeContact */;
  employeeEeo?: EmployeeEeo | null;
  terminationAction?: EmployeeTerminationAction | null;
  emergencyContacts?: EmergencyContacts | null /** stateTaxes: [EmployeeStateTax] */;
  id?: string | null /** federalTaxes: [EmployeeFederalTax]permission: EmployeePermission */;
  isRegistered?: boolean | null;
  canViewCompensation?: boolean | null /** can the current user view compensation info of this employee */;
}

export interface EmployeeEmployment {
  monthlySalaryInUSD?: string | null;
  currency?: string | null;
  overtimeEligibility?: boolean | null;
  hireDate?: string | null;
  compType?: string | null;
  employee?: AllEmployee | null;
  title?: string | null;
  fullTimeEndDate?: Date | null;
  workingDaysPerWeek?: string | null;
  id?: string | null;
  annualSalary?: string | null;
  fixedTermEmployment?: boolean | null;
  terminationDate?: string | null;
  qeCreated?: boolean | null;
  monthlySalary?: string | null;
  terminationType?: string | null;
  version_id?: number | null;
  firstSalaryDate?: string | null;
  fullTimeStartDate?: Date | null;
  annualSalaryInUSD?: string | null;
  isActive?: boolean | null;
  isFlsaExempt?: boolean | null;
  annualSalaryIncludesBonus?: boolean | null;
  country?: string | null;
  payRateInUSD?: string | null;
  payFrequency?: string | null;
  workingHoursPerWeek?: string | null;
  employmentType?: string | null;
  payRate?: string | null;
  terminationCategory?: string | null;
  resource_uri?: string | null;
  hasNonExemptJobDuties?: boolean | null;
}

export interface EmployeeEligibility {
  id: string;
  employee?: AllEmployee | null;
  newHire?: NewHire | null;
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  otherLastNames?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  apartmentNumber?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  dateOfBirth?: Date | null;
  socialSecurityNumber?: string | null;
  ssnImageDataUrl?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  translatorUsed?: boolean | null;
  eligibilitySig?: Signature | null;
  eligibilityType?: string | null;
  documents?: EmployeeEligibilityDocument[] | null;
  isActive?: boolean | null;
  isSealed?: boolean | null;
  status?: string | null;
  eligibilityVerificationFormUrl?: string | null;
  employerEligibilityVerification?: EmployerEligibility | null;
  corrections?: EligibilityCorrection[] | null;
  reverifications?: EligibilityReverification[] | null;
  workExpirationDate?: Date | null;
  documentType?: string | null /** document for lawful PR or alien authorized worker */;
  documentNumber?: string | null;
  documentIssuedCountry?: string | null;
  country?: string | null /** to be compatible with legacy international eligibility */;
  nationality?: string | null;
  nationalID?: string | null;
  passportNumber?: string | null;
  workVisaType?: string | null;
}

export interface NewHire {
  id?: string | null;
  employee?: AllEmployee | null;
  eligibility?: EmployeeEligibility | null;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  status?: string | null;
}

export interface EmployeeEligibilityDocument {
  documentName?: string | null;
  documentType?: string | null;
  eligibility: EmployeeEligibility;
  id: string;
  uploadedFileKey?: string | null;
}

export interface EmployerEligibility {
  id: string;
  employeeFirstEmploymentDay?: DateTime | null;
  employerEligibilitySignatureFirst?: string | null;
  employerEligibilitySignatureLast?: string | null;
  employerEligibilitySignatureTitle?: string | null;
  organizationName?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  employerEligibilitySignature?: string | null;
  employerEligibilitySignatureDate?: DateTime | null;
  employerEligibilitySignatureProvidedDate?: DateTime | null;
  acknowledgement?: boolean | null;
  isComplete?: boolean | null;
  documentListType?: string | null;
  additionalInfo?: string | null;
  documents?: EmployerEligibilityDocument[] | null;
}

export interface EmployerEligibilityDocument {
  id: string;
  rank: number;
  title?: string | null;
  expirationDate?: DateTime | null;
  issuingAuthority?: string | null;
  documentNumber?: string | null;
  associatedDocuments?: EmployerEligibilityAssociatedDocument[] | null;
}

export interface EmployerEligibilityAssociatedDocument {
  id: string;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EligibilityCorrection {
  id: string;
  eligibility?: EmployeeEligibility | null;
  isActive?: boolean | null;
  isSectionOneCorrection?: boolean | null;
  isEmployeeSideComplete?: boolean | null;
  correctionFullName?: string | null;
  correctionMemo?: string | null;
  correctionDate?: DateTime | null;
  correctionMessage?: string | null;
  correctionSignature?: string | null;
  correctionUrlUploadedFileKey?: string | null;
  previousEligibility?: EmployeeEligibility | null;
}

export interface EligibilityReverification {
  id: string;
  eligibility?: EmployeeEligibility | null;
  isActive?: boolean | null;
  newFirstName?: string | null;
  newLastName?: string | null;
  newMiddleInitial?: string | null;
  reHireDate?: DateTime | null;
  document?: EligibilityReverificationDocument | null;
  reverifyDate?: string | null;
  reverifyFullName?: string | null;
  signature?: Signature | null;
  reverifyUrlUploadedFileKey?: string | null;
}

export interface EligibilityReverificationDocument {
  id?: string | null;
  eligibilityReverification?: EligibilityReverification | null;
  title?: string | null;
  documentNumber?: string | null;
  expirationDate?: string | null;
  associatedDocuments?: EligibilityReverificationAssociatedDocument[] | null;
}

export interface EligibilityReverificationAssociatedDocument {
  id: string;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EmployeeEeo {
  employee?: AllEmployee | null;
  isFilledByAdmin?: boolean | null;
  eeoRace?: string | null;
  adminFillDate?: string | null;
  eeoJobCategory?: string | null;
  id?: string | null;
  adminFullName?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeTerminationAction {
  id?: string | null /** reportsNewManager: AllEmployeecoverFullCobra: BooleanunusedPTOAmount: String */;
  status?:
    | string
    | null /** lastMonthOfBenefits: Stringcity: Stringemployee: AllEmployeeremoveFromBenefits: BooleanremoveFromIT: BooleandeactivateEmailIfRemoving: BooleanremoveFromPayroll: Booleanstate: StringcancelAutoPay: BooleanuniqueId: StringterminationReason: StringbenefitsEndDate: DateterminationDate: DateadministerCOBRA: Booleanemail: String */;
}

export interface EmergencyContacts {
  employee?: AllEmployee | null;
  secondaryContactPhone1?: string | null;
  secondaryContactPhone2?: string | null;
  secondaryContactName?: string | null;
  primaryContactName?: string | null;
  primaryContactPhone2?: string | null;
  primaryContactPhone1?: string | null;
  secondaryContactRelationship?: string | null;
  id?: string | null;
  primaryContactRelationship?: string | null;
  resource_uri?: string | null;
}

export interface AllEmployeeAuxProxy {
  id?:
    | string
    | null /** fsa: FsaEmployeeEnrollmentemployee401kEnrollment: Employee401kEnrollmentsignedEmployeeFsaAgreements: [AgreementEmployeeFsa]hra: HraEmployeeEnrollmentunicardCommuter: UnicardCommuterEmployeeEnrollmentbeneficiaries: [Beneficiary]lifeDisabilityEnrollments: [EmployeeLifeDisabilityEnrollment] */;
  hsa?: HsaEmployeeEnrollment | null;
}

export interface HsaEmployeeEnrollment {
  startDate?: string | null;
  employeePayperiodContribution?: string | null;
  authDate?: string | null;
  totalMonthsForEmployeeContribution?: string | null;
  previousYTDContributionRequired?: string | null;
  maximumComputedAnnualHsaContribution?: string | null;
  deductionPerPayPeriod?: string | null;
  enrolledDate?: string | null;
  frontLoadedCompanyContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  effectiveEndDate?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  hsaBankTempPassword?: string | null;
  eStatementsRequired?: boolean | null;
  isBasicInfoComplete?: boolean | null;
  employeeContribution?: string | null;
  statusDisplayName?: string | null;
  contributionPerPayPeriod?: string | null;
  maximumComputedAnnualFamilyHsaContribution?: string | null;
  status?: string | null;
  hsaBankUsername?: string | null;
  countryOfCitizenship?: string | null;
  deductionForNextChange?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  duplicateChecksRequired?: boolean | null;
  agreement?: Document | null;
  isUSCitizen?: boolean | null;
  isContactInfoComplete?: boolean | null;
  previousYTDContribution?: string | null;
  computedStartDate?: string | null;
  isResidentAlien?: boolean | null;
  overrideFrontLoadedCompanyContribution?: string | null;
  upcomingDeduction?: string | null;
  authName?: string | null;
  contribution?: string | null;
  maxMonthlyTotalContributionForNextChange?: string | null;
  currentHsaYearStartDate?: string | null;
  resource_uri?: string | null;
}

export interface Document {
  mimetype?: string | null;
  uploadTime?: DateTime | null;
  employee?: AllEmployee | null;
  reviewState?: string | null;
  embeddableDocId?: string | null;
  reviewComment?: string | null;
  resource_uri?: string | null;
  url?: string | null;
  description?: string | null;
  company?: Company | null;
  reviewTime?: DateTime | null;
  reviewedBy?: User | null;
  isAgreement?: boolean | null;
  date?: string | null;
  documentStatusNotificationTimestamp?: DateTime | null;
  uploadedBy?: User | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface CompanyHealthPlan {
  carrierSpecificData?: string | null;
  invoiceName?: string | null;
  companyHealthCarrier?: CompanyHealthCarrier | null;
  isHraEnabled?: boolean | null;
  id?: string | null;
  companySpecificHMOPPO?: string | null;
  planId?: number | null;
  dentalPediatricProductCode?: string | null;
  companySpecificProductCode?: string | null;
  lineOfCoverage?: string | null;
  plan?: string | null;
  planName?: string | null;
  planDisplayName?: string | null;
  isAvailableForNewHires?: boolean | null;
  summaryPlanDescriptionDocument?: Document | null;
  visionPediatricProductCode?: string | null;
  planNumber?: string | null;
  certificateOfCoverageDocument?: Document | null;
  isDefault?: boolean | null;
  resource_uri?: string | null;
  benefitsPlan?: BenefitsPlan | null;
  policyNumber?: string | null;
  companyHealthRiders?: (CompanyHealthRider | null)[] | null;
  enrolledEmployeeStates?: string | null;
}

export interface CompanyHealthCarrier {
  raf?: string | null;
  openEnrollmentMonth?: string | null;
  isPrimaryCarrier?: boolean | null;
  ptWaitingPeriodChangeEffectiveDate?: string | null;
  skipPackageSizeValidation?: boolean | null;
  dependentMaxAge?: number | null;
  isOpenEnrollmentInProgress?: boolean | null;
  dentalBasePlan?: DentalPlan | null;
  carrier?: Carrier | null;
  openEnrollmentStart?: string | null;
  isOpenEnrollment?: boolean | null;
  onlineAccessUsername?: string | null;
  depCountFixedDeductions?: string | null;
  waitingPeriod?: string | null;
  depFixedContributions?: string | null;
  basePlan?: Plan | null;
  id?: string | null;
  basePlanName?: string | null;
  hasEmployerAccess?: boolean | null;
  waitingPeriodDisplayText?: string | null;
  renewalPacketUrl?: string | null;
  ptWaitingPeriodDisplayText?: string | null;
  employeeContributionSchemes?: string | null;
  ediStatus?: string | null;
  company?: Company | null;
  openEnrollmentEndDate?: string | null;
  shortCircuitOEManualOverrideOn?: boolean | null;
  fixedContributionOverallMax?: string | null;
  isSelfAdministered?: boolean | null;
  borStatus?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  rxGroup?: string | null;
  renewalDate?: string | null;
  status?: string | null;
  planContributionSchemes?: string | null;
  oktaNotes?: string | null;
  planDepTypeContributionSchemes?: string | null;
  ptWaitingPeriod?: string | null;
  onlineAccessPassword?: string | null;
  terminationPolicy?: string | null;
  terminationPolicyDisplayText?: string | null;
  terminationPolicyChangeReason?: string | null;
  contractLength?: number | null;
  autoPayAckSignature?: string | null;
  contributionEmployee?: string | null;
  waitingPeriodChangeEffectiveDate?: string | null;
  employeeFixedDeductions?: string | null;
  waitingPeriodChangeReason?: string | null;
  ptWaitingPeriodChangeReason?: string | null;
  contributionType?: string | null;
  isUnderImplementation?: boolean | null;
  autoPayAckName?: string | null;
  contributionTypeChangeReason?: string | null;
  approvedDate?: string | null;
  commissionID?: string | null;
  approvalLetterUrl?: string | null;
  submittedTimestamp?: DateTime | null;
  contributionDependents?: string | null;
  _openEnrollmentStart?: string | null;
  invoiceUrl?: string | null;
  oktaLink?: string | null;
  rxBinNumber?: string | null;
  rxPCN?: string | null;
  fixedContributionEmployeeMax?: string | null;
  disableDependentCoverageChanges?: boolean | null;
  lineOfCoverage?: string | null;
  doWeAutoPay?: boolean | null;
  hasCustomContributions?: boolean | null;
  eligibleForShortCircuitOpenEnrollment?: string | null;
  openEnrollmentPeriod?: number | null;
  depTypeContributionSchemes?: string | null;
  contributionTypeChangeEffectiveDate?: string | null;
  switchCancellationPolicy?: string | null;
  fixedContributionDependentMax?: string | null;
  groupID?: string | null;
  planFixedDeductions?: string | null;
  resource_uri?: string | null;
  companyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  draftCompanyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  effectiveDate?: string | null;
  effectiveEndDate?: string | null;
}

export interface DentalPlan {
  planUrl?: string | null;
  availableOOS?: boolean | null;
  coInsuranceOrthoOutOfNetwork?: string | null;
  deductibleIndividual?: number | null;
  useSicRaf?: boolean | null;
  group?: string | null;
  scalingRootPlaningOutOfNetwork?: string | null;
  singleTierRate?: boolean | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  freePlan?: boolean | null;
  coInsuranceOrtho?: string | null;
  liveQuoteForRenewal?: boolean | null;
  rateStyle?: string | null;
  needsPCP?: boolean | null;
  orthoCoverage?: boolean | null;
  orthoMaxBenefits?: number | null;
  name?: string | null;
  coInsuranceEndoOutOfNetwork?: string | null;
  coInsuranceOrthoOutOfNetworkChild?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  filling?: string | null;
  sourcePlanId?: number | null;
  genderBandedStyle?: number | null;
  crown?: string | null;
  customPlanCompanyId?: number | null;
  coInsuranceEndo?: string | null;
  hasWaitingPeriods?: boolean | null;
  scalingRootPlaning?: string | null;
  shortCircuitPlanType?: string | null;
  fillingOutOfNetwork?: string | null;
  fundingType?: string | null;
  coInsuranceMajor?: string | null;
  oralExamOutOfNetwork?: string | null;
  stateCarrier?: Carrier | null;
  state?: string | null;
  isNewStyle?: string | null;
  isShortCircuitPlan?: boolean | null;
  orthoMaxAge?: number | null;
  benefitFeeStructure?: string | null;
  coInsuranceBasicOutOfNetwork?: string | null;
  customPlan?: boolean | null;
  rootCanalOutOfNetwork?: string | null;
  displayNote?: string | null;
  deductibleFamily?: number | null;
  carrier?: string | null;
  consoleUrl?: string | null;
  adjustableRates?: boolean | null;
  overrideCarrierDefaultRegion?: boolean | null;
  availableOOSStates?: string | null;
  isVisionBundled?: boolean | null;
  hasNetworkProviders?: boolean | null;
  recommendedRenewalPlan?: DentalPlan | null;
  expiryDate?: string | null;
  oralExam?: string | null;
  deductibleFamilyOutOfNetwork?: number | null;
  coInsurancePerio?: string | null;
  rootCanal?: string | null;
  maxBenefits?: number | null;
  coInsuranceMajorOutOfNetwork?: string | null;
  largeGroup?: boolean | null;
  newGroupExpiryDate?: string | null;
  postACA?: boolean | null;
  coInsuranceBasic?: string | null;
  showName?: string | null;
  coInsurancePeriodOutOfNetwork?: string | null;
  customPlanReason?: string | null;
  maxLives?: number | null;
  isVoluntary?: boolean | null;
  coInsuranceOrthoChild?: string | null;
  id?: string | null;
  productCode?: string | null;
  planRequestLink?: string | null;
  minLives?: number | null;
  liveQuote?: boolean | null;
  coInsurancePreventativeOutOfNetwork?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  progressiveBenefitLevels?: boolean | null;
  benefitPeriod?: string | null;
  compositeFactorSet?: CompositeFactorSet | null;
  deductibleIndividualOutOfNetwork?: number | null;
  displayName?: string | null;
  orthoDeductible?: number | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  crownOutOfNetwork?: string | null;
  useDependentAge?: boolean | null;
  HMOPPO?: string | null;
  inProgress?: boolean | null;
  coInsurancePreventative?: string | null;
  resource_uri?: string | null;
  lineOfCoverage?: string | null;
  planType?: string | null;
  benefitsData?: JSON | null;
}

export interface Network {
  isNationwide?: boolean | null;
  lineOfCoverage?: string | null;
  isNarrowOverride?: boolean | null;
  name?: string | null;
  id?: string | null;
}

export interface CompositeRuleSet {
  compositeRules?: (CompositeRule | null)[] | null;
  resource_uri?: string | null;
  id?: string | null;
  carrier?: Carrier | null;
  name?: string | null;
}

export interface CompositeRule {
  lowerBound?: number | null;
  effectiveDate?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  ratingStyle?: string | null;
  upperBound?: number | null;
  censusStyle?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CompositeFactorSet {
  name?: string | null;
  carrier?: Carrier | null;
  id?: string | null;
  youAndSpouseFactor?: string | null;
  youFactor?: string | null;
  compositeFactors?: (CompositeFactor | null)[] | null;
  familyFactor?: string | null;
  youAndChildFactor?: string | null;
  resource_uri?: string | null;
}

export interface CompositeFactor {
  effectiveDate?: string | null;
  youAndSpouseFactor?: string | null;
  familyFactor?: string | null;
  youFactor?: string | null;
  compositeFactorSet?: CompositeFactorSet | null;
  id?: string | null;
  youAndChildFactor?: string | null;
  resource_uri?: string | null;
}

export interface Plan {
  hasNetworkProviders?: boolean | null;
  rxCoPayBrandRetailCondition?: number | null;
  availableOOS?: boolean | null;
  deductibleIndividual?: number | null;
  urgentCarePreferredNetwork?: string | null;
  oopMaxFamilyOutOfNetwork?: number | null;
  rxCoPayBrandRetailSecondary?: string | null;
  HSA?: boolean | null;
  group?: string | null;
  hospitalInpatientSecondaryPreferredNetwork?: string | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  hospitalOutpatientSecondaryPreferredNetwork?: string | null;
  oopMaxIndividualOutOfNetwork?: number | null;
  carrierRxCode?: string | null;
  coPayPreferredNetwork?: string | null;
  dailyLimits?: string | null;
  coInsurance?: string | null;
  emergencyServiceCondition?: number | null;
  specialistCoPay?: string | null;
  liveQuoteForRenewal?: boolean | null;
  minHRAContribution?: number | null;
  rateStyle?: string | null;
  preventativeCareOutOfNetwork?: string | null;
  HRA?: boolean | null;
  estimatedSevereOOPCost?: number | null;
  urgentCareSecondary?: string | null;
  name?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  notes?: string | null;
  urgentCareSecondaryPreferredNetwork?: string | null;
  hospitalInpatient?: string | null;
  urgentCareCondition?: number | null;
  emergencyService?: string | null;
  carrierCode?: string | null;
  sourcePlanId?: number | null;
  emergencyServicePrimaryPreferredNetwork?: string | null;
  genderBandedStyle?: number | null;
  specialtyPharmacySecondary?: string | null;
  customPlanCompanyId?: number | null;
  shortCircuitPlanType?: string | null;
  minLives?: number | null;
  isMinimumEffectiveCoverage?: boolean | null;
  fundingType?: string | null;
  hospitalOutpatient?: string | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  emergencyServiceConditionPreferredNetwork?: number | null;
  urgentCare?: string | null;
  state?: string | null;
  hospitalOutpatientSecondary?: string | null;
  useEEZipAvailability?: boolean | null;
  outOfNetworkCoInsurance?: string | null;
  rxCoPayNonFormularyRetailSecondary?: string | null;
  rxCoPayGenericRetail?: string | null;
  isShortCircuitPlan?: boolean | null;
  oopMaxIndividualPreferredNetwork?: number | null;
  specialtyPharmacyCondition?: number | null;
  rxCoPayNonFormularyRetailCondition?: number | null;
  preventativeCare?: string | null;
  emergencyServiceSecondaryPreferredNetwork?: string | null;
  emergencyCoPay?: number | null;
  displayNote?: string | null;
  networkSize?: string | null;
  maxDays?: number | null;
  dailyLimitsPreferredNetwork?: string | null;
  deductibleFamily?: number | null;
  carrier?: string | null;
  consoleUrl?: string | null;
  pharmacyCoverage?: boolean | null;
  hospitalInpatientPreferredNetwork?: string | null;
  overrideCarrierDefaultRegion?: boolean | null;
  emergencyServiceSecondary?: string | null;
  customPlan?: boolean | null;
  hospitalOutpatientPreferredNetwork?: string | null;
  availableOOSStates?: string | null;
  isVisionBundled?: boolean | null;
  pharmacyDeductibleOutOfNetwork?: number | null;
  specialistCoPayOutOfNetwork?: string | null;
  rxCoPayBrandRetail?: string | null;
  recommendedRenewalPlan?: Plan | null;
  expiryDate?: string | null;
  deductibleFamilyOutOfNetwork?: number | null;
  pharmacyDeductiblePreferredNetwork?: number | null;
  rxCoPayNonFormularyRetail?: string | null;
  oopMaxIncludeDeductible?: boolean | null;
  rxCoPayGenericRetailCondition?: number | null;
  isMinimumValueCoverage?: boolean | null;
  isDentalBundled?: boolean | null;
  deductibleIndividualPreferredNetwork?: number | null;
  minHSAContribution?: number | null;
  oopMaxFamilyPreferredNetwork?: number | null;
  largeGroup?: boolean | null;
  isVoluntary?: boolean | null;
  newGroupExpiryDate?: string | null;
  coPayOutOfNetwork?: string | null;
  postACA?: boolean | null;
  preventativeCarePreferredNetwork?: string | null;
  emergencyCoInsurance?: string | null;
  showName?: string | null;
  hospitalInpatientConditionPreferredNetwork?: number | null;
  customPlanReason?: string | null;
  hospitalInpatientCondition?: number | null;
  pharmacyDeductible?: number | null;
  maxLives?: number | null;
  hospitalOutpatientConditionPreferredNetwork?: number | null;
  deductibleFamilyPreferredNetwork?: number | null;
  id?: string | null;
  metalTier?: string | null;
  productCode?: string | null;
  planUrl?: string | null;
  bundledOldStyleDentalPlan?: DentalPlan | null;
  specialtyPharmacy?: string | null;
  liveQuote?: boolean | null;
  hospitalInpatientSecondary?: string | null;
  maxHRAContribution?: number | null;
  maxDaysPreferredNetwork?: number | null;
  rxCoPayGenericRetailSecondary?: string | null;
  isOldStyle?: string | null;
  preferredNetworkCoInsurance?: string | null;
  oopMaxIndividual?: number | null;
  afterDeductibles?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  coPay?: string | null;
  applyRaf?: boolean | null;
  needsPCP?: boolean | null;
  rxSupplyDaysRetail?: number | null;
  compositeFactorSet?: CompositeFactorSet | null;
  deductibleIndividualOutOfNetwork?: number | null;
  planRequestLink?: string | null;
  carrierInternalCode?: string | null;
  maxHSAContribution?: number | null;
  urgentCareConditionPreferredNetwork?: number | null;
  displayName?: string | null;
  hospitalOutpatientCondition?: number | null;
  oopMaxFamily?: number | null;
  emergencyServicePrimary?: string | null;
  stateCarrier?: Carrier | null;
  useDependentAge?: boolean | null;
  HMOPPO?: string | null;
  inProgress?: boolean | null;
  specialistCoPayPreferredNetwork?: string | null;
  resource_uri?: string | null;
  lineOfCoverage?: string | null;
  rates?: JSON | null;
  restrictions?: JSON | null;
  benefitsData?: JSON | null;
  planType?:
    | string
    | null /** TODO: remove planType, applicableEnrollees, evidenceOfInsurabilityForm,videoUrl if zgraphql can reslove union type correctly */;
  applicableEnrollees?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  videoUrl?: string | null;
  deductibleReset?: string | null;
}

export interface CompanyHealthEnrollment {
  startDate?: string | null;
  endDate?: string | null;
  authDate?: string | null;
  desiredEffectiveDate?: string | null;
  confirmedGuardianContract?: boolean | null;
  escalationTimestamp?: DateTime | null;
  inStateState?: InStateState | null;
  authWebsite?: string | null;
  renewalPackages?: string | null;
  confirmedPayrollHistory?: boolean | null;
  enrollmentCompleteEmailTimestamp?: DateTime | null;
  isCompositeRated?: boolean | null;
  cobraType?: string | null;
  company?: Company | null;
  id?: string | null;
  authTitle?: string | null;
  lineOfCoverage?: string | null;
  enrollmentType?: string | null;
  progress?: string | null;
  employeeLifeDisabilityEnrollments?: (EmployeeLifeDisabilityEnrollment | null)[] | null;
  participationState?: ParticipationState | null;
  companySicCode?: string | null;
  authSignature?: string | null;
  disableEmployeeWQItems?: boolean | null;
  implementationCompleteDate?: string | null;
  isEnrollmentComplete?: boolean | null;
  authPhone?: string | null;
  enrollmentStatus?: string | null;
  employeeCount?: number | null;
  planMappings?: (PlanMapping | null)[] | null;
  isActive?: boolean | null;
  companyZip?: string | null;
  enrollmentBegunTimestamp?: DateTime | null;
  confirmedWorkersComp?: boolean | null;
  confirmedVspContract?: boolean | null;
  isDocumentRequirementGenerated?: boolean | null;
  documentsReminderTimestamp?: DateTime | null;
  confirmedPreviousCoverage?: boolean | null;
  previousEnrollment?: CompanyHealthEnrollment | null;
  numOfOpenDocuments?: number | null;
  enrollmentDocumentsTimestamp?: DateTime | null;
  isReviewing?: boolean | null;
  censusUrl?: string | null;
  addExistingTimestamp?: DateTime | null;
  authName?: string | null;
  overrideRateEffectiveDate?: string | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  resource_uri?: string | null;
  companyHealthCarrier?: CompanyHealthCarrier | null;
  draftCompanyHealthCarrier?: CompanyHealthCarrier | null;
}

export interface InStateState {
  status?: string | null;
  inStateRuleName?: string | null;
  percentInState?: string | null;
  inStateRule?: InStateRule | null;
  qualifiedInState?: number | null;
  decliningInState?: number | null;
  inStateStates?: string | null;
  enrollingInState?: number | null;
  enrolling?: number | null;
  decliningOutOfState?: number | null;
  isFailing?: boolean | null;
  qualified?: number | null;
  enrollingOutOfState?: number | null;
  resource_uri?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  isByEnrolling?: boolean | null;
  qualifiedOutOfState?: number | null;
  id?: string | null;
  declining?: number | null;
  inStateRuleV2?: string | null;
}

export interface InStateRule {
  softDeleted?: boolean | null;
  maxGroupSize?: number | null;
  minStyle?: string | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  additionalNotes?: string | null;
  id?: string | null;
  minGroupSize?: number | null;
  resource_uri?: string | null;
}

export interface EmployeeLifeDisabilityEnrollment {
  startDate?: Date | null;
  endDate?: Date | null;
  authDate?: string | null;
  id?: string | null;
  authSignature?: string | null;
  employee?: AllEmployee | null;
  employeeStdPlans?: (EmployeeStdPlanNew | null)[] | null;
  effectiveDate?: Date | null;
  dependentLifeDisabilityEnrollments?: (DependentLifeDisabilityEnrollment | null)[] | null;
  authTitle?: string | null;
  enrollmentType?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  progress?: string | null;
  prevEnrollment?: EmployeeLifeDisabilityEnrollment | null;
  status?: string | null;
  isEnrollmentPendingEOI?: boolean | null;
  employeeLifePlans?: (EmployeeLifePlanNew | null)[] | null;
  isEnrollmentComplete?: boolean | null;
  isActive?: boolean | null;
  employeeAddPlans?: (EmployeeAddPlan | null)[] | null;
  enrollmentBegunTimestamp?: DateTime | null;
  employeeLtdPlans?: (EmployeeLtdPlanNew | null)[] | null;
  lineOfCoverage?: string | null;
  authName?: string | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  resource_uri?: string | null;
}

export interface EmployeeStdPlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: StdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeLifeDisabilitySelection {
  employee?: AllEmployee | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface StdPlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  eliminationPeriodAccident?: number | null;
  parentPlan?: StdPlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  stateDisabilityInsurance?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  benefitsDuration?: string | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  ownOccPeriod?: string | null;
  planUrl?: string | null;
  eliminationPeriodIllness?: number | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  preExistingCondition?: string | null;
  isShortCircuitPlan?: boolean | null;
  eliminationPeriod?: string | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  benefitsDurationStructured?: number | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  lineOfCoverage?: string | null;
  rates?: (StdPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (StdPlanRestriction | null)[] | null;
}

export interface StdPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: StdPlanNew | null;
  gender?: string | null;
  ratePerTen?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PlanGuaranteeIssue {
  id?: string | null;
  guaranteeIssue?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  employeeStatus?: string | null;
  valueChangeType?: string | null;
  increment?: string | null;
  enrolleeType?: string | null;
}

export interface PlanBenefitsReduction {
  id?: string | null;
  enrolleeType?: string | null;
  multiplier?: string | null;
  planMaxAmount?: string | null;
  valueChangeType?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface StdPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: StdPlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  rateType?: string | null;
  premiumScheme?: string | null;
  isIncrements?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  incrementalUnits?: string | null;
  earningsMultipliers?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLifeDisabilityEnrollment {
  status?: string | null;
  authSignature?: string | null;
  type?: string | null;
  enrollmentBegunTimestamp?: DateTime | null;
  authDate?: string | null;
  lineOfCoverage?: string | null;
  dependentStdPlans?: (DependentStdPlan | null)[] | null;
  authName?: string | null;
  effectiveDate?: Date | null;
  authTitle?: string | null;
  dependentAddPlans?: (DependentAddPlan | null)[] | null;
  isEnrollmentComplete?: boolean | null;
  resource_uri?: string | null;
  employeeLifeDisabilityEnrollment?: EmployeeLifeDisabilityEnrollment | null;
  dependentLtdPlans?: (DependentLtdPlan | null)[] | null;
  dependent?: Dependent | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  id?: string | null;
  isActive?: boolean | null;
  dependentLifePlans?: (DependentLifePlan | null)[] | null;
}

export interface DependentStdPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: StdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface DependentLifeDisabilitySelection {
  resource_uri?: string | null;
  id?: string | null;
  dependent?: Dependent | null;
}

export interface Dependent {
  medicalCoverageEndDate?: string | null;
  dentalCoverageStartDate?: string | null;
  weight?: string | null;
  dentalEnrollment?: EmployeeHealthEnrollment | null;
  hmoDentalName?: string | null;
  isFullTimeStudent?: boolean | null;
  visionCoverageStartDate?: string | null;
  height?: string | null;
  visionCancelledDate?: string | null;
  proofDate?: string | null;
  dentalCoverageEndDate?: string | null;
  courtOrder?: CourtOrder | null;
  id?: string | null;
  cancelledDate?: string | null;
  city?: string | null;
  hmoDentalProviderNumber?: string | null;
  zip?: string | null;
  hasMedicalCoverage?: boolean | null;
  marriageDate?: string | null;
  enrolledInDental?: boolean | null;
  visionCoverageEndDate?: string | null;
  proofUrl?: string | null;
  dateOfBirth?: string | null;
  state?: string | null;
  medicalEnrollment?: EmployeeHealthEnrollment | null;
  medicalCoverageStartDate?: string | null;
  reason?: string | null;
  dentalCancelledDate?: string | null;
  type?: string | null;
  autoSelectDentalPCP?: boolean | null;
  enrolledInMedical?: boolean | null;
  hasVisionCoverage?: boolean | null;
  status?: string | null;
  enrollInDental?: boolean | null;
  sameEmail?: boolean | null;
  sameAddress?: boolean | null;
  address2?: string | null;
  courtOrderEndDate?: string | null;
  samePCP?: boolean | null;
  sameDentalPCP?: boolean | null;
  autoSelectPCP?: boolean | null;
  hmoDentalExistingPatient?: boolean | null;
  isCourtOrderedDependent?: boolean | null;
  employee?: AllEmployee | null;
  hmoPhysicianProviderPPGNumber?: string | null;
  objId?: number | null;
  address?: string | null;
  enrollInMedical?: boolean | null;
  isDependentDisabled?: boolean | null;
  age?: number | null;
  medicalApprovalStatus?: string | null;
  gender?: string | null;
  enrollInVision?: boolean | null;
  isCourtOrderActive?: boolean | null;
  effectiveDate?: string | null;
  firstName?: string | null;
  dentalApprovalStatus?: string | null;
  dob?: string | null;
  dentalEffectiveDate?: string | null;
  hmoPhysicianName?: string | null;
  hasDentalCoverage?: boolean | null;
  isMarried?: boolean | null;
  visionApprovalStatus?: string | null;
  email?: string | null;
  visionEffectiveDate?: string | null;
  isSmoker?: boolean | null;
  socialSecurity?: string | null;
  socialSecurityDecrypted?: string | null;
  hmoPhysicianProviderPAOrMGNumber?: string | null;
  visionEnrollment?: EmployeeHealthEnrollment | null;
  lastName?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  hasStateException?: boolean | null;
  enrolledInVision?: boolean | null;
  hmoPhysicianProviderNumber?: string | null;
  hasMedicalCoverage_is_set?: boolean | null;
  resource_uri?: string | null;
  courtOrderLinesOfCoverage?: (string | null)[] | null;
}

export interface EmployeeHealthEnrollment {
  startDate?: string | null;
  isSwitchCarrierEnrollment?: boolean | null;
  endDate?: string | null;
  companyVisionEnrollmentCompleteDate?: string | null;
  isInitialEnrollment?: boolean | null;
  medicalCarrierStatus?: string | null;
  coverage_type?: string | null;
  isOpenEnrollment?: boolean | null;
  applicationStatus?: string | null;
  companyEnrollment?: CompanyHealthEnrollment | null;
  isSubmitted?: boolean | null;
  medicalPlan?: Plan | null;
  hasSignedWaiver?: boolean | null;
  companyEffectiveDate?: string | null;
  dentalPlan?: DentalPlan | null;
  isApplicationSubmitted?: boolean | null;
  id?: string | null;
  createdAt?: DateTime | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  isOffCycleEnrollment?: boolean | null;
  isPastEnrollmentDeadline?: boolean | null;
  applicationStatusDate?: string | null;
  addedDependents?: string | null;
  daysUntilDeadline?: string | null;
  companyMedicalEnrollmentCompleteDate?: string | null;
  isCardBlocked?: boolean | null;
  qualifyingEvent?: QualifyingEvent | null;
  isNewHireOEOrSW?: boolean | null;
  enrollmentCompleteDate?: string | null;
  progress?: string | null;
  type?: string | null;
  status?: string | null;
  enrollmentType?: string | null;
  authSignatureId?: number | null;
  version_id?: number | null;
  dependentCoverageChanged?: boolean | null;
  premiumsMap?: string | null;
  submittedDate?: string | null;
  resource_uri?: string | null;
  date?: string | null;
  visionCarrierStatus?: string | null;
  dentalCarrierStatus?: string | null;
  authSignature_id?: number | null;
  removedDependents?: string | null;
  isActive?: boolean | null;
  isEnrolledPlanHraCompatible?: boolean | null;
  oldPlan?: number | null;
  name?: string | null;
  visionPlan?: VisionPlan | null;
  companyDentalEnrollmentCompleteDate?: string | null;
  isEnrollmentOngoing?: boolean | null;
  isRealizationSuccessful?: boolean | null;
  employeeException?: EmployeeException | null;
  title?: string | null;
  previousCoverageExpirationDate?: string | null;
  includedInGroupApp?: boolean | null;
  oldCost?: string | null;
  stateCarrier?: Carrier | null;
  isEnrollmentException?: boolean | null;
  signature?: string | null;
  oldCompanyHealthPlan?: CompanyHealthPlan | null;
  needDocument?: boolean | null;
  disableDependentCoverageChanges?: string | null;
  companyHealthPlan?: CompanyHealthPlan | null;
  defaultPlan?: number | null;
}

export interface QualifyingEvent {
  id?: string | null;
  employee?: AllEmployee | null;
  eventDate?: string | null;
  type?: string | null;
  subtype?: string | null;
  subtypeDisplay?: string | null;
  reason?: string | null;
  documentStatus?: string | null;
  proofURL?: string | null;
  proofType?: string | null;
  knowledgeTime?: DateTime | null;
  documents?: (QualifyingEventDocument | null)[] | null;
  enrollments?: (EmployeeBenefitsEnrollment | null)[] | null;
  proofDocumentTypes?: (string | null)[] | null;
  dependentEnrollments?:
    | (DependentHealthEnrollment | null)[]
    | null /** TODO(Sun): remove dependentEnrollments after fully migrated to EBEs. */;
}

export interface QualifyingEventDocument {
  id?: string | null;
  status?: string | null;
  type?: string | null;
  url?: string | null;
  createdAt?: DateTime | null;
  reviews?: (QualifyingEventDocumentReview | null)[] | null;
}

export interface QualifyingEventDocumentReview {
  id?: string | null;
  document_id?: string | null;
  decision?: string | null;
  comment?: string | null;
  reviewer?: AllEmployee | null;
  carrier?: Carrier | null;
  createdAt?: DateTime | null;
}

export interface EmployeeBenefitsEnrollment {
  id: string /** Identifier which uniquely represents an enrollment object. */;
  employeeId: string /** Employee id */;
  employee: AllEmployee;
  lineOfCoverage: string /** Line of coverage. */;
  effectiveDate: Date /** Effective date of the enrollment. */;
  enrollmentType?: string | null /** Type of the enrollment, one of the granular EmployeeEnrollmentTypes. */;
  status?: string | null /** raw enrollment status -- complete, selected, decline, cancelled */;
  isCardBlocked?: boolean | null /** Is the enrollment currently blocked? */;
  startDate?: Date | null /** Start date of the enrollment */;
  endDate?: Date | null /** Enrollment end date */;
  qualifyingEventId?: string | null /** Id of the associated qualifying event, if applicable. */;
}

export interface DependentHealthEnrollment {
  id?: string | null;
  dependent?: Dependent | null;
  lineOfCoverage?: string | null;
  isEnrollmentComplete?: boolean | null;
  effectiveStartDate?: string | null;
  effectiveDate?: string | null;
  qualifyingEvent?: QualifyingEvent | null;
  enrollmentStatus?: string | null;
  endDate?: string | null;
  status?: string | null;
  type?: string | null;
}

export interface VisionPlan {
  genderBandedStyle?: number | null;
  hasNetworkProviders?: boolean | null;
  frameAllowable?: string | null;
  showName?: string | null;
  availableOOS?: boolean | null;
  customPlanReason?: string | null;
  isVoluntary?: boolean | null;
  customPlanCompanyId?: number | null;
  availableOOSStates?: string | null;
  lensFrequency?: number | null;
  maxLives?: number | null;
  id?: string | null;
  shortCircuitPlanType?: string | null;
  expiryDate?: string | null;
  minLives?: number | null;
  retailDiscountAvailable?: boolean | null;
  fundingType?: string | null;
  productCode?: string | null;
  contactsFrequency?: number | null;
  group?: string | null;
  resource_uri?: string | null;
  stateCarrier?: Carrier | null;
  recommendedRenewalPlan?: VisionPlan | null;
  planUrl?: string | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  lasikCoverage?: boolean | null;
  state?: string | null;
  liveQuote?: boolean | null;
  examFrequency?: number | null;
  frameAllowableSecondary?: string | null;
  consoleUrl?: string | null;
  freePlan?: boolean | null;
  lensAllowable?: string | null;
  isNewStyle?: string | null;
  isShortCircuitPlan?: boolean | null;
  planRequestLink?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  coPay?: string | null;
  singleTierRate?: boolean | null;
  liveQuoteForRenewal?: boolean | null;
  rateStyle?: string | null;
  customPlan?: boolean | null;
  HMOPPO?: string | null;
  largeGroup?: boolean | null;
  compositeFactorSet?: CompositeFactorSet | null;
  contactsAllowableSecondary?: string | null;
  frameFrequency?: number | null;
  displayNote?: string | null;
  newGroupExpiryDate?: string | null;
  displayName?: string | null;
  name?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  useSicRaf?: boolean | null;
  postACA?: boolean | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  contactsAllowableCondition?: number | null;
  lensAllowableCondition?: number | null;
  lensAllowableSecondary?: string | null;
  carrier?: string | null;
  useDependentAge?: boolean | null;
  sourcePlanId?: number | null;
  inProgress?: boolean | null;
  contactsAllowable?: string | null;
  adjustableRates?: boolean | null;
  frameAllowableCondition?: number | null;
  overrideCarrierDefaultRegion?: boolean | null;
  lineOfCoverage?: string | null;
  planType?: string | null;
  benefitsData?: JSON | null;
}

export interface EmployeeException {
  employee?: AllEmployee | null;
  endDate?: string | null;
  effectiveDate?: string | null;
  lineOfCoverage?: string | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CourtOrder {
  employee?: AllEmployee | null;
  visionPlan?: VisionPlan | null;
  id?: string | null;
  proofUrl?: string | null;
  isProcessed?: boolean | null;
  visionEffectiveDate?: string | null;
  noticeDate?: string | null;
  processingDate?: string | null;
  employeeMaxWithholdPercentage?: number | null;
  medicalPlan?: Plan | null;
  medicalEffectiveDate?: string | null;
  signature?: Signature | null;
  dentalEffectiveDate?: string | null;
  orderDate?: string | null;
  dentalPlan?: DentalPlan | null;
  resource_uri?: string | null;
}

export interface DependentAddPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: AddPlan | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface AddPlan {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: AddPlan | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  hasGuaranteeIssue?: boolean | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  isShortCircuitPlan?: boolean | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  oldRateType?: string | null;
  customPlan?: boolean | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  lineOfCoverage?: string | null;
  rates?: (AddPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (AddPlanRestriction | null)[] | null;
}

export interface AddPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: AddPlan | null;
  gender?: string | null;
  ratePerThousand?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface AddPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: AddPlan | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  rateType?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  incrementalUnits?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isIncrements?: boolean | null;
  earningsMultipliers?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLtdPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LtdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LtdPlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: LtdPlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  benefitsDuration?: string | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  ownOccPeriod?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  ownOccPeriodStructured?: string | null;
  benefitsAugmentation?: boolean | null;
  preExistingCondition?: string | null;
  isShortCircuitPlan?: boolean | null;
  eliminationPeriod?: string | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  benefitsDurationStructured?: number | null;
  benefitsDurationChar?: string | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  eliminationPeriodAccidentIllness?: number | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  lineOfCoverage?: string | null;
  rates?: (LtdPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (LtdPlanRestriction | null)[] | null;
}

export interface LtdPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: LtdPlanNew | null;
  gender?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  ratePerHundred?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LtdPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: LtdPlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  rateType?: string | null;
  premiumScheme?: string | null;
  isIncrements?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  incrementalUnits?: string | null;
  earningsMultipliers?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLifePlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LifePlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LifePlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: LifePlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  isShortCircuitPlan?: boolean | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  lineOfCoverage?: string | null;
  rates?: (LifePlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (LifePlanRestriction | null)[] | null;
}

export interface LifePlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: LifePlanNew | null;
  gender?: string | null;
  ratePerThousand?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
  adndRatePerThousand?: string | null;
}

export interface LifePlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: LifePlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  rateType?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  incrementalUnits?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isIncrements?: boolean | null;
  earningsMultipliers?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface EmployeeLifePlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LifePlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeAddPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: AddPlan | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeLtdPlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LtdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ParticipationState {
  isFailing?: boolean | null;
  qualifiedNonNewHires?: number | null;
  qualifiedNewHires?: number | null;
  invalidDeclinedNonNewHires?: number | null;
  participationRuleV2?: string | null;
  id?: string | null;
  invalidDeclinedNewHires?: number | null;
  participationRule?: ParticipationRule | null;
  invalidDeclined?: number | null;
  participationRuleOverride?: ParticipationRuleOverride | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  remaining?: number | null;
  validDeclinedNonNewHires?: number | null;
  percentEnrolled?: string | null;
  status?: string | null;
  participationRuleName?: string | null;
  remainingNewHires?: number | null;
  enrolledNonNewHires?: number | null;
  isUnsure?: boolean | null;
  qualified?: number | null;
  enrolledNewHires?: number | null;
  remainingNonNewHires?: number | null;
  validDeclined?: number | null;
  validDeclinedNewHires?: number | null;
  enrolled?: number | null;
  resource_uri?: string | null;
}

export interface ParticipationRule {
  isStrict?: boolean | null;
  contributoryType?: string | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  minEmployeesCount?: number | null;
  planGroup?: string | null;
  andOrOr?: string | null;
  additionalNotes?: string | null;
  percentage?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  maxEmployees?: number | null;
}

export interface ParticipationRuleOverride {
  isStrict?: boolean | null;
  minPercentage?: string | null;
  softDeleted?: boolean | null;
  reason?: string | null;
  minEmployees?: number | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PlanMapping {
  oldPlanId?: string | null;
  oldPlanName?: string | null;
  oldPlanDisplayName?: string | null;
  newPlanId?: string | null;
  newPlanName?: string | null;
  newPlanDisplayName?: string | null;
}

export interface SupplementalPlan {
  id?: string | null;
  lineOfCoverage?: string | null;
  stateCarrier?: Carrier | null;
  name?: string | null;
  planUrl?: string | null;
  displayName?: string | null;
  videoUrl?: string | null;
  customPlan?: boolean | null;
}

export interface CompanyHealthRider {
  id?: string | null;
  planId?: string | null;
  companyHealthPlan_id?: string | null;
  riderType?: string | null;
}

export interface BenefitsEnrollment {
  id?: string | null;
  employee_id?: string | null;
  benefitsType?: string | null;
  enrollmentType?: string | null;
  effectiveDate?: DateTime | null;
  status?: string | null;
  benefitsEvent?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  validity?: string | null;
  benefitsContext?: BenefitsContext | null;
  companyHealthEnrollment_id?: string | null;
  qualifyingEvent_id?: string | null;
  benefitsPlanOptions?: (CompanyHealthPlan | null)[] | null;
}

export interface BenefitsContext {
  employee?: EmployeeContext | null;
  dependents?: (DependentContext | null)[] | null;
}

export interface EmployeeContext {
  personalInfo?: PersonalInfoContext | null;
  employment?: EmploymentContext | null;
  coverage?: CoverageContext | null;
}

export interface PersonalInfoContext {
  id?: string | null;
  enrolleeId?: string | null;
  email?: string | null;
  type?: string | null;
  status?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  midName?: string | null;
  dateOfBirth?: string | null;
  height?: string | null;
  weight?: string | null;
  gender?: string | null;
  ssn?: string | null;
  maritalStatus?: string | null;
  phoneNumber?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  isDisabled?: boolean | null;
  isSmoker?: boolean | null;
}

export interface EmploymentContext {
  employmentStatus?: string | null;
  jobTitle?: string | null;
  compensationType?: string | null;
  hourlyPay?: string | null;
  hoursWorked?: string | null;
  annualSalary?: string | null;
  employmentType?: string | null;
  hireDate?: string | null;
  fullTimeStartDate?: string | null;
  fullTimeEndDate?: string | null;
  terminationDate?: string | null;
}

export interface CoverageContext {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
  enrollmentTier?: string | null;
  contributionTier?: string | null;
  enrollmentCompleteDate?: string | null;
  status?: string | null;
  rawEnrollmentStatus?: string | null;
  enrollmentType?: string | null;
  isInGroupApp?: boolean | null;
  signatureId?: string | null;
  signatureDate?: string | null;
  carrierMemberId?: string | null;
  applicationStatus?: string | null;
  isNewHire?: boolean | null;
  divisionCode?: string | null;
  isEligibleForBenefits?: boolean | null;
  eligibilityStartDate?: string | null;
  isEligibleForCobra?: boolean | null;
  isCobraEnrollee?: boolean | null;
  isCarrierMapped?: boolean | null;
  cobra?: EmployeeCobraContext | null;
  carrierId?: string | null;
  carrierName?: string | null;
  carrier?: Carrier | null;
  brokerSignatureId?: string | null;
  primaryBenefitsPlan?: PrimaryBenefitsPlanContext | null;
  lndBasicPlan?: DisabilityBenefitsPlanContext | null;
  lndVoluntaryPlan?: DisabilityBenefitsPlanContext | null;
  primaryHealthCarePhysician?: HmoPhysicianContext | null;
  plans?: (DisabilityBenefitsPlanContext | null)[] | null;
  oldPlans?: (PlanContext | null)[] | null;
  defaultPlan?: PlanContext | null;
  waiver?: WaiverContext | null;
  riders?: (RiderContext | null)[] | null;
  qualifyingEvent?: QualifyingEventContext | null;
  cancellationReason?: string | null;
  agreementDocumentId?: string | null;
  lndPlan?: DisabilityBenefitsPlanContext | null;
  isDeclined?: boolean | null;
}

export interface EmployeeCobraContext {
  reason?: string | null;
  type?: string | null;
  coverageStartDate?: string | null;
  coverageEndDate?: string | null;
}

export interface PrimaryBenefitsPlanContext {
  name?: string | null;
  companyHealthPlanId?: string | null;
  policyNumber?: string | null;
  productCode?: string | null;
  effectiveDate?: string | null;
}

export interface DisabilityBenefitsPlanContext {
  planId?: string | null;
  name?: string | null;
  planType?: string | null;
  companyHealthPlanId?: string | null;
  policyNumber?: string | null;
  productCode?: string | null;
  effectiveDate?: string | null;
  amount?: string | null;
  electedAmount?: string | null;
  guaranteeIssue?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  evidenceOfInsurabilityStatus?: string | null;
  isOverGI?: boolean | null;
  isSalaryBased?: boolean | null;
  beneficiaries?: (BeneficiaryContext | null)[] | null;
}

export interface BeneficiaryContext {
  firstName?: string | null /** Person */;
  lastName?: string | null;
  percentage?: string | null;
  relationship?: string | null;
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
  entityName?: string | null /** Entity */;
  entityType?: string | null;
  entityDateEstablished?: string | null;
}

export interface HmoPhysicianContext {
  hmoPhysicianExistingPatient?: boolean | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianName?: string | null;
}

export interface PlanContext {
  name?: string | null;
  companyHealthPlanId?: string | null;
}

export interface WaiverContext {
  hasSignedWaiver?: boolean | null;
  otherCarrier?: string | null;
  reasonForDecliningCoverage?: string | null;
  waiveReasonName?: string | null;
  otherIdNumber?: string | null;
  signatureId?: string | null;
  idCardUrl?: string | null;
}

export interface RiderContext {
  planId?: string | null;
  riderType?: string | null;
}

export interface QualifyingEventContext {
  type?: string | null;
  subtype?: string | null;
  eventDate?: DateTime | null;
  proofURL?: string | null;
  proofType?: string | null;
  reviewer?: QualifyingEventReviewerContext | null;
}

export interface QualifyingEventReviewerContext {
  reviewerId?: string | null;
  reviewer?: string | null;
  reviewerEmail?: string | null;
  reviewerType?: string | null;
  reviewDate?: DateTime | null;
}

export interface DependentContext {
  personalInfo?: PersonalInfoContext | null;
  coverage?: DependentCoverageContext | null;
}

export interface DependentCoverageContext {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
  enrollmentCompleteDate?: string | null;
  status?: string | null;
  type?: string | null;
  declineReason?: string | null;
  primaryHealthCarePhysician?: HmoPhysicianContext | null;
  isEnrolled?: boolean | null;
  waiver?: WaiverContext | null;
  lndBasicPlan?: DisabilityBenefitsPlanContext | null;
  lndVoluntaryPlan?: DisabilityBenefitsPlanContext | null;
  plans?: (DisabilityBenefitsPlanContext | null)[] | null;
}

export interface BenefitsTransaction {
  event?: string | null;
  eventCycle?: string | null;
  maxAnnualContribution?: string | null;
  employeeContribution?: number | null;
  proratingStrategy?: string | null;
  id?: string | null;
  employeePlanCost?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  dependentsPlanCost?: string | null;
  benefitsCost?: BenefitsCost | null;
  employeeRiderCost?: string | null;
  employeeDeduction?: number | null;
  benefitsContext?: BenefitsContext | null;
  payloadContext_id?: string | null;
  deactivationDate?: DateTime | null;
  dependentsContribution?: number | null;
  reason?: string | null;
  creationDate?: DateTime | null;
  dependentsRiderCost?: string | null;
  dependentsDeduction?: number | null;
  benefitsType?: string | null;
  maxAnnualDeduction?: string | null;
  contributionBreakdown?: ContributionBreakdown | null;
  isContributionAsPercentage?: boolean | null;
  isDeductionAsPercentage?: boolean | null;
  logs?: (BenefitsTransactionLog | null)[] | null;
  initiativeRelationships?: (BenefitsTransactionRelationship | null)[] | null;
  passiveRelationships?: (BenefitsTransactionRelationship | null)[] | null;
  fulfillmentInfo?: BenefitsFulfillmentInfo | null;
}

export interface BenefitsCost {
  employeePlanCost?: number | null;
  employeeRidersCost?: number | null;
  employeeVoluntaryPlanCost?: number | null;
  dependentsPlanCost?: number | null;
  dependentsRidersCost?: number | null;
  dependentsVoluntaryPlanCost?: number | null;
  spousePlanCost?: number | null;
  spouseRidersCost?: number | null;
  spouseVoluntaryPlanCost?: number | null;
  childrenPlanCost?: number | null;
  childrenRidersCost?: number | null;
  childrenVoluntaryPlanCost?: number | null;
}

export interface ContributionBreakdown {
  employeeDeduction?: number | null;
  employeeContribution?: number | null;
  dependentsDeduction?: number | null;
  dependentsContribution?: number | null;
  maxAnnualDeduction?: number | null;
  maxAnnualContribution?: number | null;
  isDeductionAsPercentage?: boolean | null;
  isContributionAsPercentage?: boolean | null;
}

export interface BenefitsTransactionLog {
  creationDate?: DateTime | null;
  employee_id?: number | null;
  requestEmployee?: AllEmployee | null;
  requesterCategory?: string | null;
  user_id?: number | null;
  success?: boolean | null;
  benefitsType?: string | null;
  errorMessage?: string | null;
  funcName?: string | null;
  requestData?: string | null;
  time?: DateTime | null;
  commitHash?: string | null;
  fileName?: string | null;
  id?: string | null;
}

export interface BenefitsTransactionRelationship {
  id?: string | null;
  name?: string | null;
  initiativeTransaction?: BenefitsTransaction | null;
  passiveTransaction?: BenefitsTransaction | null;
  contextChange?: string | null;
  contextChangeError?: boolean | null;
  costChange?: string | null;
  costChangeError?: boolean | null;
  contributionChange?: string | null;
  contributionChangeError?: boolean | null;
  effectiveDateChange?: string | null;
}

export interface BenefitsFulfillmentInfo {
  currentStatus: BenefitsFulfillmentCurrentStatus;
  details?: BenefitsFulfillmentInfoDetails | null;
  benConnectInfo?: BenefitsFulfillmentInfo | null;
  benefitsTransactionId: string;
}

export interface BenefitsFulfillmentCurrentStatus {
  statusSummary: string;
  settlementStatus: BenefitsSettlementStatus;
}

export interface BenefitsFulfillmentInfoDetails {
  changeLogTransactions?: BenefitsFulfillmentChangelogTransaction[] | null;
}

export interface BenefitsFulfillmentChangelogTransaction {
  timestamp: DateTime;
  message: string;
  sendChannelName?: string | null;
  status: BenefitsSettlementStatus;
  links?: BenefitsFulfillmentHelpLink[] | null;
}

export interface BenefitsFulfillmentHelpLink {
  identifier: string;
  name: string;
  href: string;
}

export interface EmployeeProductEligibilityTag {
  addEligibilityStartDate?: string | null;
  isEligibleForCommuterBenefits?: boolean | null;
  stdEligibilityStartDate?: string | null;
  lifeEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHSA?: boolean | null;
  isEligibleForPTO?: boolean | null;
  isEligibleForLife?: boolean | null;
  visionEligibilityStartDateOverridden?: boolean | null;
  id?: string | null;
  isEligibleForDental?: boolean | null;
  employee?: AllEmployee | null;
  isEligibleForLTD?: boolean | null;
  isEligibleForVision?: boolean | null;
  dentalEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHRA?: boolean | null;
  isEligibleForSTD?: boolean | null;
  medicalEligibilityStartDateOverridden?: boolean | null;
  isEligibleForMedical?: boolean | null;
  isACAFullTimeEligible?: boolean | null;
  addEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHealth?: boolean | null;
  visionEligibilityStartDate?: string | null;
  isEligibleForTA?: boolean | null;
  lifeEligibilityStartDate?: string | null;
  isEligibleForAdd?: boolean | null;
  ltdEligibilityStartDateOverridden?: boolean | null;
  dentalEligibilityStartDate?: string | null;
  ltdEligibilityStartDate?: string | null;
  isEligibleForFSA?: boolean | null;
  stdEligibilityStartDateOverridden?: boolean | null;
  medicalEligibilityStartDate?: string | null;
  isEligibleForLifeOrAdd?: boolean | null;
  isEligibleForLifeOrDisability?: boolean | null;
  resource_uri?: string | null;
  isEligibleForAccident?: boolean | null;
  isEligibleForCancer?: boolean | null;
  isEligibleForCriticalIllness?: boolean | null;
  isEligibleForHospitalIndemnity?: boolean | null;
}

export interface EmployeeBenefits {
  employeeEnrollmentFlowId?: number | null;
  planCostContribution?: (PlanCostContribution | null)[] | null;
  planAvailability?: (PlanAvailability | null)[] | null;
  draftPlanAvailability?: (DraftPlanAvailability | null)[] | null;
  draftPlanPremiumMDVSUP?: (PlanPremiumMDVSUP | null)[] | null;
  draftPlanPremiumLND?: (PlanPremiumLND | null)[] | null;
  ongoingBenefitsEnrollment?: BenefitsEnrollment | null /** TODO(James): remove the following field */;
  ongoingBenefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  employeeReinstateBenefits?: ReinstateBenefits | null;
  benefitsStatementData?: StatementData | null;
  planBundlingInfo?: PlanBundlingInfo | null;
  employerHSAMonthlyContribution: CompanyHsaMonthlyContributionForEmployee;
}

export interface PlanCostContribution {
  companyHealthPlanId?: string | null;
  youPremium?: number | null;
  youAndChildPremium?: number | null;
  youAndSpousePremium?: number | null;
  familyPremium?: number | null;
  youContribution?: number | null;
  youOnlyContribution?: number | null;
  youAndChildContribution?: number | null;
  youAndSpouseContribution?: number | null;
  familyContribution?: number | null;
  oneFixedCostTotalAmount?: number | null;
}

export interface PlanAvailability {
  chpId?: string | null;
  available?: boolean | null;
  reason?: string | null;
}

export interface DraftPlanAvailability {
  planId?: string | null;
  planName?: string | null;
  ratesUnsupported?: boolean | null;
  perChild?: boolean | null;
  carrierLogoUrl?: string | null;
  allowedEnrollees?: (string | null)[] | null;
}

export interface PlanPremiumMDVSUP {
  success: boolean;
  planId?: string | null;
  totalPremium?: number | null;
  companyPremium?: number | null;
  employeePremium?: number | null;
}

export interface PlanPremiumLND {
  success?: boolean | null;
  employeeId?: string | null;
  enrolleeId?: string | null;
  planId?: string | null;
  totalPremium?: number | null;
  companyPremium?: number | null;
  employeePremium?: number | null;
  gi?: number | null;
  volume?: number | null;
}

export interface ReinstateBenefits {
  lineOfCoverage?: string | null;
  isReinstate?: boolean | null;
  coverageInfo?: CoverageInfo | null;
}

export interface CoverageInfo {
  chpId?: string | null;
  employee?: EmployeeCoverageInfo | null;
  dependents?: (DependentCoverageInfo | null)[] | null;
}

export interface EmployeeCoverageInfo {
  electedAmount?: string | null;
  cost?: string | null;
}

export interface DependentCoverageInfo {
  enrolleeId: string;
  enrolleeType: string;
  electedAmount?: string | null;
  cost?: string | null;
}

export interface StatementData {
  showHsa?: boolean | null;
  carrierStatements?: (CarrierStatement | null)[] | null;
}

export interface CarrierStatement {
  carrierName?: string | null;
  copy?: string | null;
  largeGroupCopy?: string | null;
}

export interface PlanBundlingInfo {
  isCorrect?: boolean | null;
  message?: string | null;
}

export interface CompanyHsaMonthlyContributionForEmployee {
  employerEmployeeContribution: number;
  employerTotalContribution: number;
}

export interface EmployeeChangesHistory {
  resource_uri?: string | null;
  isBackFilled?: boolean | null;
  isRequesterSameAsApprover?: boolean | null;
  isFlsaExemptSystemComputed?: boolean | null;
  reason?: string | null;
  requestedBy?: AllEmployee | null;
  approvedBy?: (AllEmployee | null)[] | null;
  changedBy?: AllEmployee | null;
  hireDate?: Date | null;
  changeDate?: Date | null;
  effectiveDate?: Date | null;
  title?: string | null;
  employmentType?: EmploymentType | null;
  compType?: CompType | null;
  annualSalary?: string | null;
  payRate?: string | null;
  isFlsaExempt?: boolean | null;
  hasNonExemptJobDuties?: boolean | null;
  location?: CompanyLocation | null;
  department?: Department | null;
  workerType?: WorkerType | null;
  manager?: AllEmployee | null;
}

export interface EmployeeProfile {
  sections: EmployeeProfileSection[];
  dropdownItems: EmployeeProfileDropdownItem[];
  topBanners: EmployeeProfileTopBanner[];
  extraInfo?: EmployeeProfileExtraInfo | null;
  colleagueInfo?: EmployeeColleagueInfo | null;
  employmentInfo?: EmploymentInfoDisplay | null;
  equalEmploymentOpportunity?: EqualEmploymentOpportunityDisplay | null;
  employmentHistory?: (EmployeeChangesHistory | null)[] | null;
  taxInfo?: EmployeeProfileTaxInfo | null;
  eligibilities?: (EmployeeEligibility | null)[] | null;
}

export interface EmployeeProfileSection {
  name: string;
}

export interface EmployeeProfileDropdownItem {
  name: string;
}

export interface EmployeeProfileTopBanner {
  title: string;
  message: string;
  status: BannerType;
  buttons?: string[] | null;
  mode: string;
}

export interface EmployeeProfileExtraInfo {
  authorized: boolean;
  isRehire?: boolean | null;
  canEditPhotoUrl?: boolean | null;
  primaryHeadline?: string | null;
  secondaryHeadline?: string | null;
  badgeText?: string | null;
  badgeDescription?: string | null;
  isReadOnlySku?: boolean | null;
  isDeidentified?: boolean | null;
  eligibilityId?: string | null;
}

export interface EmployeeColleagueInfo {
  authorized: boolean;
  primaryHeadline?: string | null;
  showTearsheetLink?: boolean | null;
  showManager?: boolean | null;
}

export interface EmploymentInfoDisplay {
  startDate?: Date | null;
  endDate?: Date | null;
  title?: string | null;
  department?: string | null;
  workLocation?: string | null;
  employmentType?: string | null;
  compensationType?: string | null;
  annualSalary?: string | null;
  annualSalaryInUSD?: string | null;
  hourlyRate?: string | null;
  hourlyRateInUSD?: string | null;
  currency?: string | null;
  extraFields?: (BasicField | null)[] | null;
  compensationByPosition?: (EmployeeCompensation | null)[] | null;
  customGroupTypes: GroupType[];
}

export interface BasicField {
  name?: string | null;
  value?: string | null;
}

export interface EmployeeCompensation {
  id?: string | null;
  compType?: CompType | null /** deprecated */;
  amount?: number | null;
  adHocUnit?: AdHocUnit | null;
  groupId?: string | null;
  group?: Group | null;
  index?: number | null;
  employeeId: string;
}

export interface EqualEmploymentOpportunityDisplay {
  jobCategory?: string | null;
  legalGender?: string | null;
  race?: string | null;
}

export interface EmployeeProfileTaxInfo {
  auFederalTax?: AustraliaFederalTax | null;
  brFederalTax?: BrazilFederalTax | null;
  caFederalTax?: CanadaFederalTax | null;
  caStateTaxes?: (CanadaStateTax | null)[] | null;
  deFederalTax?: GermanyFederalTax | null;
  frFederalTax?: FranceFederalTax | null;
  gbFederalTax?: UnitedKingdomFederalTax | null;
  hkFederalTax?: HongKongFederalTax | null;
  ieFederalTax?: IrelandFederalTax | null;
  inFederalTax?: IndiaFederalTax | null;
  nlFederalTax?: NetherlandsFederalTax | null;
  sgFederalTax?: SingaporeFederalTax | null;
  usFederalTaxes?: (EmployeeFederalTax | null)[] | null;
  usStateTaxes?: (EmployeeStateTax | null)[] | null;
}

export interface AustraliaFederalTax {
  reasonForNoTaxID?: string | null;
  isExempt?: boolean | null;
  hasTaxID?: boolean | null;
  id?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  hasFinancialSupplementDebt?: boolean | null;
  claimSeniorTaxOffset?: boolean | null;
  additionalWithholdingAmount?: number | null;
  additionalWithholding?: boolean | null;
  hasHelpDebt?: boolean | null;
  taxFormUrl?: string | null;
  additionalWithholdingPercent?: string | null;
  isActive?: boolean | null;
  isResident?: boolean | null;
  claimTaxFreeThreshold?: boolean | null;
  taxOffsetAmount?: string | null;
  country?: string | null;
  claimTaxOffset?: boolean | null;
  employeeSignature?: Signature | null;
  seniorTaxOffsetInfo?: string | null;
  withholdingCertificate?: string | null;
  resource_uri?: string | null;
}

export interface BrazilFederalTax {
  workingBookletUF?: string | null;
  workingBookletIssueDate?: string | null;
  employee?: AllEmployee | null;
  hasDependents?: boolean | null;
  workingBookletNumber?: string | null;
  country?: string | null;
  isFirstJob?: boolean | null;
  previousUnionContribution?: string | null;
  workingBookletSeries?: string | null;
  idNumber?: string | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  idNumberIssuingBody?: string | null;
  highestLevelOfEducation?: string | null;
  employeeSignature?: Signature | null;
  hasUnionContribution?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface CanadaFederalTax {
  infirmDependentAmount?: string | null;
  basicPersonalAmount?: string | null;
  isExempt?: boolean | null;
  pensionIncomeAmount?: string | null;
  additionalWithholdings?: string | null;
  disabilityAmount?: string | null;
  id?: string | null;
  fromDependentAmount?: string | null;
  fromSpouseAmount?: string | null;
  employee?: AllEmployee | null;
  childAmount?: string | null;
  mbFamilyTaxBenefit?: string | null;
  seniorSupplementaryAmount?: string | null;
  claimAmountLowerThanIncome?: boolean | null;
  educationAmount?: string | null;
  ageAmount?: string | null;
  eligibleDependentAmount?: string | null;
  alreadyClaimedCredit?: boolean | null;
  taxFormUrl?: string | null;
  isActive?: boolean | null;
  caregiverAmount?: string | null;
  permanentResidence?: string | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isNonResident?: boolean | null;
  spouseAmount?: string | null;
  zoneBasedDeduction?: string | null;
  resource_uri?: string | null;
}

export interface CanadaStateTax {
  disabilityAmount?: string | null;
  infirmDependentAmount?: string | null;
  basicPersonalAmount?: string | null;
  isExempt?: boolean | null;
  pensionIncomeAmount?: string | null;
  additionalWithholdings?: string | null;
  isWorkLocationState?: boolean | null;
  id?: string | null;
  fromDependentAmount?: string | null;
  fromSpouseAmount?: string | null;
  employee?: AllEmployee | null;
  childAmount?: string | null;
  mbFamilyTaxBenefit?: string | null;
  isResidenceState?: boolean | null;
  state?: string | null;
  seniorSupplementaryAmount?: string | null;
  claimAmountLowerThanIncome?: boolean | null;
  educationAmount?: string | null;
  ageAmount?: string | null;
  eligibleDependentAmount?: string | null;
  alreadyClaimedCredit?: boolean | null;
  taxFormUrl?: string | null;
  isActive?: boolean | null;
  caregiverAmount?: string | null;
  permanentResidence?: string | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isNonResident?: boolean | null;
  spouseAmount?: string | null;
  zoneBasedDeduction?: string | null;
  resource_uri?: string | null;
}

export interface GermanyFederalTax {
  exemptionsForChildren?: number | null;
  employee?: AllEmployee | null;
  taxClass?: string | null;
  communityKey?: string | null;
  country?: string | null;
  hasNoChurchAffiliation?: boolean | null;
  taxFormUrl?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  taxOffice?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FranceFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  jobCoefficient?: string | null;
  taxFormUrl?: string | null;
  numberOfDependents?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  cadreStatus?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  legalPosition?: string | null;
}

export interface UnitedKingdomFederalTax {
  taxCode?: string | null;
  country?: string | null;
  isDirector?: boolean | null;
  taxFormUrl?: string | null;
  niCategory?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  employee?: AllEmployee | null;
  employeeStatement?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  unpaidStudentLoan?: boolean | null;
}

export interface HongKongFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface IrelandFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface IndiaFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface NetherlandsFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface SingaporeFederalTax {
  employee?: AllEmployee | null;
  mbmfContribution?: string | null;
  country?: string | null;
  ecfContribution?: string | null;
  taxFormUrl?: string | null;
  sindaContribution?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  race?: string | null;
  cdacContribution?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface EmployeeFederalTax {
  federalFilingStatus?: string | null;
  additionalFederalWitholdings?: string | null;
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  federalWithholdingAllowance?: number | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface EmployeeStateTax {
  INstateAdditionalDependentExemptions?: number | null;
  WVstateOneEarnerWithholdAtLowerRate?: boolean | null;
  stateFilingStatus?: string | null;
  MAstateFullTimeStudent?: boolean | null;
  isExempt?: boolean | null;
  taxState?: string | null;
  MAstatePersonalBlindness?: boolean | null;
  GAstateDependentAllowance?: number | null;
  id?: string | null;
  additionalLocalWithholdings?: string | null;
  GAstatePersonalAllowance?: number | null;
  isWorkState?: boolean | null;
  MAstateSpouseBlindness?: boolean | null;
  NJstateWageChartLetter?: string | null;
  LAstateNumberOfDependents?: number | null;
  taxFormUrl?: string | null;
  employee?: AllEmployee | null;
  VAstateAgeAndBlindnessExemptions?: number | null;
  isActive?: boolean | null;
  INstateAdditionalCountyWithholdings?: string | null;
  stateWithholdingAllowance?: string | null;
  country?: string | null;
  localWithholdingAllowance?: number | null;
  employeeSignature?: Signature | null;
  MOstateSpouseWorks?: boolean | null;
  ILstateAdditionalAllowance?: number | null;
  additionalStateWitholdings?: string | null;
  resource_uri?: string | null;
}

export interface PersonalInfo {
  maritalStatus?: string | null;
  homeCity?: string | null;
  updatedAt?: DateTime | null;
  hireDate?: string | null;
  homeAddress2?: string | null;
  marriageDate?: string | null;
  homeZip?: string | null;
  marriageState?: string | null;
  spouseDPEmployed?: string | null;
  numberOfDependents?: number | null;
  id?: string | null;
  spouseDPEmployerAddress?: string | null;
  numberChildren?: number | null;
  hoursPerWeek?: number | null;
  email?: string | null;
  spouseDPEmployer?: string | null;
  employmentStatus?: string | null;
  haveChildren?: string | null;
  jobTitle?: string | null;
  contactPreference?: string | null;
  homePhone?: string | null;
  salary?: string | null;
  homeState?: string | null;
  firstName?: string | null;
  dob?: string | null;
  gender?: string | null;
  marriageCountry?: string | null;
  socialSecurity?: string | null;
  lastName?: string | null;
  homeAddress?: string | null;
  resource_uri?: string | null;
}

export interface Beneficiary {
  status?: string | null;
  address?: Address | null;
  entityType?: string | null;
  maxBenefits?: string | null;
  relationship?: string | null;
  dateOfBirth?: string | null;
  lastName?: string | null;
  entityName?: string | null;
  isEnrolledInInsurance?: boolean | null;
  phone?: string | null;
  employee?: AllEmployee | null;
  firstName?: string | null;
  socialSecurity?: string | null;
  entityDateEstablished?: string | null;
  otherRelationship?: string | null;
  percentage?: string | null;
  type?: string | null;
  id?: string | null;
  hasSocialSecurity?: boolean | null;
  resource_uri?: string | null;
}

export interface ZAppInstallSubscription {
  id?: string | null;
  appInstall?: ZAppInstall | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  preferences?: JSON | null;
  status?: number | null;
  access?: number | null;
  inheritedStatus?: number | null;
  subscribedAt?: DateTime | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
}

export interface ZAppInstall {
  id?: string | null;
  app?: ZApp | null;
  company?: Company | null;
  preferences?: JSON | null;
  status?: number | null;
  autoSubMode?: string | null;
  installedAt?: DateTime | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
}

export interface ZApp {
  id?: string | null;
  uniqueId?: string | null;
  packageId?: string | null;
  developerId?: number | null;
  title?: string | null;
  shortTitle?: string | null;
  delegate?: string | null;
  preferences?: JSON | null;
  appUrl?: string | null;
  appIconSqUrl?: string | null;
  role?: string | null;
  primaryCategory?: string | null;
  secondaryCategory?: string | null;
  status?: number | null;
  autoSubMode?: string | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
  isPublishedRollout?: boolean | null;
  scopes?: JSON | null;
}

export interface CompanyHealthProxy {
  companyInfo?: CompanyInfo | null /** deductionHeuristics: CompanyDeductionHeuristics */;
  companyCobra?: CompanyCobra | null;
  settings?: CompanySettings | null;
  id?: string | null /** benefitsPreview: BenefitsPreview */;
  employeeContributionTiers?: (EmployeeContributionTier | null)[] | null /** policy: CompanyPolicy */;
  eligibleRenewalsEmployees?: (AllEmployee | null)[] | null;
  benefitsAdmins?: (AllEmployee | null)[] | null;
  partnerUsers?: (AllEmployee | null)[] | null;
}

export interface CompanyInfo {
  entityTypeForTaxPurposes?: string | null;
  billingAddressState?: string | null;
  bankName?: string | null;
  entityType?: string | null;
  establishedYear?: number | null;
  physicalAddressZip?: string | null;
  entityChangedRecently?: boolean | null;
  dbaName?: string | null;
  bankRoutingNumber?: string | null;
  id?: string | null;
  industryType?: string | null;
  numberOfLocations?: number | null;
  fifteenAllowed?: boolean | null;
  fullTimeEmployeeCount?: number | null;
  company?: Company | null;
  isQuarterlyWageTaxAvailable?: boolean | null;
  outOfStateEmployeeCount?: number | null;
  physicalAddressCity?: string | null;
  entityChangedOnPayrollQWTR?: boolean | null;
  website?: string | null;
  bankAccountNumber?: string | null;
  establishedMonth?: number | null;
  businessDescription?: string | null;
  medicareEmployeeCount?: number | null;
  billingAddressStreet2?: string | null;
  billingAddressStreet1?: string | null;
  billingAddressCity?: string | null;
  numUnionMember?: number | null;
  physicalAddressState?: string | null;
  partTimeEmployeeCount?: number | null;
  physicalAddressStreet2?: string | null;
  numPreviousAverageEmployees?: number | null;
  physicalAddressStreet1?: string | null;
  name?: string | null;
  physicalAddressCounty?: string | null;
  billingAddressZip?: string | null;
  isBillingSame?: boolean | null;
  resource_uri?: string | null;
  ein?: string | null;
}

export interface CompanyCobra {
  cobraBankAccountNumber?: string | null;
  previousAdminEmail?: string | null;
  cobraBankAuthDate?: string | null;
  id?: string | null;
  cobraPartTimeCountRawData?: string | null;
  takeoverAdminSignature?: string | null;
  cobraBankRoutingNumber?: string | null;
  company?: Company | null;
  takeoverAdminName?: string | null;
  cobraBankAccountType?: string | null;
  status?: string | null;
  countEmployees?: string | null;
  previousAdminName?: string | null;
  previousHaveParticipants?: boolean | null;
  cobraBankAuthSignature?: string | null;
  cobraFullTimeCount?: number | null;
  cobraClassification?: (CompanyCobra | null)[] | null;
  isBankInfoComplete?: boolean | null;
  cobraBankName?: string | null;
  takeoverDate?: string | null;
  offboardingDate?: string | null;
  coolingOffDays?: number | null;
  cobraPartTimeCount?: number | null;
  cobraBankAuthName?: string | null;
  takeoverAdminTitle?: string | null;
  previousAdministratorType?: string | null;
  takeoverSignDate?: string | null;
  hasActiveCobraEmployees?: boolean | null;
  resource_uri?: string | null /** previousAdministrator: CobraThirdPartyAdministrator */;
  thirdPartyAdministrators?: (CompanyCobraThirdPartyAdministrator | null)[] | null;
}

export interface CompanyCobraThirdPartyAdministrator {
  id?: string | null;
  status?: string | null;
  relationStartTimeStamp?: DateTime | null;
  relationEndTimeStamp?: DateTime | null;
  adminContactName?: string | null;
  adminContactEmail?: string | null;
  adminContactPhoneNumber?: string | null;
  administrator?: CobraThirdPartyAdministrator | null;
}

export interface CobraThirdPartyAdministrator {
  name?: string | null;
  defaultCoolingOffDays?: number | null;
  isCloseOutReportRequired?: boolean | null;
  id?: string | null;
  isTerminationFormRequired?: boolean | null;
  phoneNumber?: string | null;
  type?: string | null;
  email?: string | null;
  isVerified?: boolean | null;
  resource_uri?: string | null;
}

export interface CompanySettings {
  isInAudit?: boolean | null;
  pushContributionsToPayroll?: boolean | null;
  doWeAutoPay?: boolean | null;
  _waitingPeriod?: string | null;
  overriddenCobraType?: string | null;
  id?: string | null;
  firstOfMonthSameEffectiveDate?: string | null;
  overrideProRatingStrategy?: string | null;
  doWeRunPayroll?: boolean | null;
  showStaffDirectory?: boolean | null;
  employeeQuoteContributionsToDisplay?: string | null;
  isAdminDeductionsAuditLive?: boolean | null;
  hasEmployerAccess?: boolean | null;
  domesticPartnerPostTax?: boolean | null;
  hideCompanyContributions?: boolean | null;
  cobraTakeoverDate?: DateTime | null;
  _openEnrollmentStart?: DateTime | null;
  approvedDate?: DateTime | null;
  employeeQuoteContributionsExampleText?: string | null;
  postACA?: boolean | null;
  showCustomEEQuoteContributionsExampleText?: boolean | null;
  cobraType?: string | null;
  openEnrollmentPeriod?: number | null;
  isAdminDeductionsChangesReviewLive?: boolean | null;
  groupID?: string | null;
  reviewQualifyingLifeEventDocument?: boolean | null;
}

export interface EmployeeContributionTier {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  medical?: string | null;
  dental?: string | null;
  vision?: string | null;
}

export interface CompanyAuxProxy {
  hra?: HraCompanyEnrollment | null /** fsa: FsaCompanyEnrollmentcompany401kEnrollment: Company401kEnrollment */;
  id?: string | null /** unicardCommuter: UnicardCommuterCompanyEnrollment */;
  hsa?: HsaCompanyEnrollment | null;
}

export interface HraCompanyEnrollment {
  startDate?: string | null;
  takeOverDate?: string | null;
  authDate?: string | null;
  isCompanyInfoComplete?: boolean | null;
  date?: string | null;
  employeeContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  payFrequencies?: string | null;
  shouldShowInvoices?: string | null;
  title?: string | null;
  securityDepositName?: string | null;
  firstAvailablePlan?: HraCompanyPlan | null;
  company?: Company | null;
  dependentContribution?: string | null;
  provider?: string | null;
  next?: HraCompanyPlan | null;
  planDocument?: Document | null;
  status?: string | null;
  autoEnrollEmployees?: boolean | null;
  securityDepositDate?: string | null;
  signature?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  highEmployeesDone?: boolean | null;
  originalPlanStartDate?: string | null;
  shouldShowHraCard?: boolean | null;
  agreement?: Document | null;
  amfCode?: string | null;
  resource_uri?: string | null;
  securityDepositTitle?: string | null;
  planYearEndDate?: string | null;
  name?: string | null;
  summaryPlanDescription?: Document | null;
  overrideEnrollmentDeadline?: string | null;
  authName?: string | null;
  firstPayrollAfterStart?: string | null;
  complianceDone?: boolean | null;
  current?: HraCompanyPlan | null;
  securityDepositSignature?: string | null;
  arePlanDocumentsAvailable?: boolean | null;
}

export interface HraCompanyPlan {
  status?: string | null;
  startDate?: string | null;
  autoEnrollEmployees?: boolean | null;
  endDate?: string | null;
  isPastEnrollmentDeadline?: boolean | null;
  dependentContribution?: string | null;
  hra?: HraCompanyEnrollment | null;
  employeeContribution?: string | null;
  firstPayrollAfterStart?: string | null;
  overrideEnrollmentDeadline?: string | null;
  agreementSignature?: Signature | null;
  agreement?: Document | null;
  authSignature?: Signature | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface HsaCompanyEnrollment {
  startDate?: string | null;
  authDate?: string | null;
  isCompanyInfoComplete?: boolean | null;
  maximumAnnualEmployeeWithDependentsContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  deadlineCutoffDayForEmployees?: string | null;
  shouldShowInvoices?: string | null;
  effectiveDate?: string | null;
  resource_uri?: string | null;
  isCompanyHealthInsuranceNotCompleted?: boolean | null;
  company?: Company | null;
  employerContributionForEmployee?: string | null;
  employeeMinComputedEffectiveDate?: string | null;
  provider?: string | null;
  status?: string | null;
  maxMonthlyEmployeeWithDependentsContributionForNextChange?: string | null;
  maximumAnnualEmployeeContribution?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  isCompanyHsaWithAlegeus?: boolean | null;
  currentMaxMonthlyEmployeeWithDependentsContribution?: string | null;
  upcomingContributionDate?: string | null;
  optForFrontLoading?: boolean | null;
  agreement?: Document | null;
  computedEffectiveDate?: string | null;
  isBankInfoComplete?: boolean | null;
  currentMaxMonthlyEmployeeContribution?: string | null;
  maxMonthlyEmployeeContributionForNextChange?: string | null;
  authName?: string | null;
  employerContributionForDependents?: string | null;
  firstPreTaxWithholdingDate?: string | null;
}

export interface CompanyPayrollProxy {
  zpCompany?: ZPayrollCompany | null /** smp: SmpcontractorSettings: ContractorSettingsnewPayroll: NewPayroll */;
  paySchedules?:
    | (CompanyPaySchedule | null)[]
    | null /** payroll: PayrollCompanySettingspayrollSwitch: PayrollSwitch */;
  id?: string | null;
}

export interface ZPayrollCompany {
  isCruiseControlOn?: boolean | null;
  signatory?: AllEmployee | null;
  joinWaitlist?: boolean | null;
  onboardingState?: string | null;
  hasPriorPayroll?: boolean | null;
  zpCompanyBenefitsSurvey?: ZPayrollCompanyBenefitsSurvey | null;
  id?: string | null;
  _cruiseControlPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
  signatoryTitle?: string | null;
  suspensionReason?: string | null;
  company?: Company | null;
  blockPeriod?: number | null;
  _creditLimit?: string | null;
  cruiseControlPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
  zpCompanyBankAccount?: ZPayrollCompanyBankAccount | null;
  status?: string | null;
  billingType?: string | null;
  priorPayrollProvider?: string | null;
  zpCompanyImplementation?: ZPayrollCompanyImplementation | null;
  version_id?: number | null;
  isCreditLimitExceeded?: boolean | null;
  zpCompanyJurisdictions?: (ZPayrollCompanyJurisdiction | null)[] | null;
  isActive?: boolean | null;
  cruiseControlPayPeriodSettings_is_set?: boolean | null;
  billingCycleType?: string | null;
  name?: string | null;
  bulkUploadUrl?: string | null;
  zpPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
}

export interface ZPayrollCompanyBenefitsSurvey {
  hasSTD?: boolean | null;
  zpCompany?: ZPayrollCompany | null;
  hasVision?: boolean | null;
  hasStockOption?: boolean | null;
  isInterestedInBOR?: boolean | null;
  hasCommuter?: boolean | null;
  hasWorkersComp?: boolean | null;
  hasFSA?: boolean | null;
  hasDental?: boolean | null;
  hasMedical?: boolean | null;
  hasTA?: boolean | null;
  hasHRA?: boolean | null;
  hasLTD?: boolean | null;
  hasPTO?: boolean | null;
  hasLife?: boolean | null;
  has401k?: boolean | null;
  id?: string | null;
  hasHSA?: boolean | null;
  hasADD?: boolean | null;
}

export interface ZPayrollPayPeriodSettings {
  _payFrequency?: string | null;
  _arrearsDays?: string | null;
  _holidayShift?: string | null;
  _arrearsDaysType?: string | null;
  company?: Company | null;
  isCruiseControlOnChangedBySystem?: boolean | null;
  isCruiseControlOn?: boolean | null;
  companyPaySchedule?: CompanyPaySchedule | null;
  id?: string | null;
  name?: string | null;
}

export interface CompanyPaySchedule {
  company?: Company | null /** smpRuns: [SmpRun] */;
  root?: CompanyPaySchedule | null;
  descendants?: (CompanyPaySchedule | null)[] | null;
  id?: string | null;
  name?: string | null;
  status?: CompanyPayScheduleStatus | null;
  isActive?: boolean | null;
  isMutable?: boolean | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  creationMethod?: PayScheduleCreationMethod | null;
  payFrequency?: PayFrequencyChoices | null;
  displayFrequencyName?: string | null;
  payDayOfMonth?: number | null;
  secondPayDayOfMonth?: number | null;
  payDayOfWeek?: string | null;
  payPeriodEndDayOfMonth?: number | null;
  secondPayPeriodEndDayOfMonth?: number | null;
  payPeriodEndDayPayDayMonthDelta?: number | null;
  secondPayPeriodEndDayPayDayMonthDelta?: number | null;
  holidayShift?: PayScheduleShift | null;
  saturdayShift?: PayScheduleShift | null;
  sundayShift?: PayScheduleShift | null;
  secondSaturdayShift?: PayScheduleShift | null;
  secondSundayShift?: PayScheduleShift | null;
  secondHolidayShift?: PayScheduleShift | null;
  anchorStartDate?: string | null;
  shiftedAnchorCheckDate?: string | null;
  unshiftedAnchorCheckDate?: string | null;
  arrearsDays?: number | null;
  arrearsDayType?: ArrearDayType | null;
  secondArrearsDays?: number | null;
  secondArrearsDayType?: ArrearDayType | null;
  checkDayPolicy?: string | null;
  compType?: CompanyPayScheduleCompType | null;
}

export interface ZPayrollCompanyBankAccount {
  status?: string | null;
  verificationType?: string | null;
  bankAccountNumber?: string | null;
  hasFailed?: boolean | null;
  voidCheckVerified?: string | null;
  bankAccountVerification_id?: number | null;
  company?: Company | null;
  version_id?: number | null;
  voidCheckUrl?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isWaiting?: boolean | null;
  isVerified?: boolean | null;
  id?: string | null;
  bankRoutingNumber?: string | null;
  overrideVerification?: boolean | null;
  voidCheckUploadedOn?: DateTime | null;
  accountHolderName?: string | null;
}

export interface ZPayrollCompanyImplementation {
  blockscoreVerification?: string | null;
  experianVerification?: string | null;
  poaStateVerification?: string | null;
  currentQTEarningTaxVerification?: string | null;
  appCompletedOn?: DateTime | null;
  assignedTo?: User | null;
  employeeSyncDiffsVerification?: string | null;
  closedQTsSubjectWagesVerification?: string | null;
  closedQTsTotalTaxAmountVerification?: string | null;
  companyAddressesVerification?: string | null;
  lastSetupStatusReminderSentOn?: string | null;
  id?: string | null;
  eftpsVerification?: string | null;
  poaFederalVerification?: string | null;
  experianActiveBusinessIndicatorVerification?: string | null;
  employeeDataCompletedOn?: DateTime | null;
  imFieldsUpdatedOn?: DateTime | null;
  experianOFACMatchVerification?: string | null;
  overrideNaicsVerification?: boolean | null;
  experianAddressVerification?: string | null;
  companyVerificationStatus?: string | null;
  status?: string | null;
  appCreatedOn?: DateTime | null;
  blockscoreOFACMatchVerification?: string | null;
  aoiPrincipalsNameVerification?: string | null;
  blockscoreRedFlags?: string | null;
  stateRegistrationVerification?: string | null;
  employeeAndTaxVerificationStatus?: string | null;
  principalPhotoIDVerification?: string | null;
  bankAccountStatementVerification?: string | null;
  bankingVerificationStatus?: string | null;
  employeeReviewDataVerification?: string | null;
  experianCreditRiskClass?: string | null;
  priorPayrollCompletedOn?: DateTime | null;
  aoiCompanyNameVerification?: string | null;
  experianFinancialStabilityRiskClass?: string | null;
  lastPendingStatusReminderSentOn?: Date | null;
  implementationCompletedOn?: DateTime | null;
  zpCompany?: ZPayrollCompany | null;
  pendingTaxLiabilitiesApprovedOn?: DateTime | null;
  priorPayrollVerificationStatus?: string | null;
  experianJudgements?: string | null;
  notes?: string | null;
}

export interface ZPayrollCompanyJurisdiction {
  businessStartDate?: string | null;
  zpCompanyJurisdictionTaxRates?: (ZPayrollCompanyJurisdictionTaxRate | null)[] | null;
  nameControl?: string | null;
  jurisdiction?: string | null;
  federalExemptFrom940?: boolean | null;
  employmentTaxDepositFrequency?: string | null;
  authorizeZenefitsPayTax?: boolean | null;
  unifiedBusinessId?: string | null;
  unemploymentTaxId?: string | null;
  id?: string | null;
  workersCompId?: string | null;
  employmentTaxId?: string | null;
  zpCompanyJurisdictionTaxCategories?: (ZPayrollCompanyJurisdictionTaxCategory | null)[] | null;
  tapPassphrase?: string | null;
  eft?: string | null;
  businessEndDate?: string | null;
  accessCode?: string | null;
  sic?: string | null;
  notFullTimeMedicalBenefitsEmployees?: number | null;
  status?: string | null;
  isReimbursable?: boolean | null;
  deleted?: boolean | null;
  federalFilingType?: string | null;
  mbtType?: string | null;
  isWorkLocationJurisdiction?: boolean | null;
  uiPaymentAccountNumber?: string | null;
  workersCompActCoverage?: boolean | null;
  businessEFileNumber?: string | null;
  zpCompany?: ZPayrollCompany | null;
  legalName?: string | null;
  participationActivationCode?: string | null;
  pinCode?: string | null;
}

export interface ZPayrollCompanyJurisdictionTaxRate {
  filingMetas?: string | null;
  taxMetas?: string | null;
  year?: number | null;
  taxType?: string | null;
  taxPayee?: string | null;
  rate?: string | null;
  workersCompClassCode?: string | null;
  zpCompanyJurisdiction?: ZPayrollCompanyJurisdiction | null;
  id?: string | null;
}

export interface ZPayrollCompanyJurisdictionTaxCategory {
  taxCategory?: string | null;
  zpCompanyJurisdiction?: ZPayrollCompanyJurisdiction | null;
  id?: string | null;
  serviceLevel?: string | null;
}

export interface CompanyBenefitsEnrollment {
  companyId?: string | null;
  previousHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  currentHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  upcomingHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  draftHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  setupFlow?: SetupFlow | null;
  renewalsData?: RenewalsData | null;
}

export interface SetupFlow {
  id?: string | null;
  companyId?: string | null;
  flowId?: string | null;
  status?: number | null;
  contributionSchemeId?: string | null;
  shouldRedirectToBenConnect?: boolean | null;
  flow?: Flow | null;
}

export interface Flow {
  name?: string | null;
  dispatcherArgs?: string | null;
  version_id?: number | null;
  isComplete?: boolean | null;
  sections?: (FlowSection | null)[] | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FlowSection {
  index?: number | null;
  isReady?: boolean | null;
  errors?: (FlowError | null)[] | null;
  name?: string | null;
  tag?: string | null;
  dispatcherArgs?: string | null;
  isEntered?: boolean | null;
  entered?: number | null;
  isComplete?: boolean | null;
  flow?: Flow | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FlowError {
  code?: string | null;
  section?: FlowSection | null;
  field?: string | null;
  mustChangeValue?: string | null;
  reasonCode?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface RenewalsData {
  renewalsFlow?: SetupFlow | null;
  companyRenewalInfo?: CompanyRenewalInfo | null;
  promotionFailed?: boolean | null;
  unsupportedRateTasks?: boolean | null;
  lineOfCoverages?: (string | null)[] | null;
  isRenewalsQaReady?: boolean | null;
  renewalsReviewers?: (RenewalsReviewer | null)[] | null;
  reviewerOptions?: (ReviewerOption | null)[] | null;
}

export interface CompanyRenewalInfo {
  id?: string | null;
  companyId?: string | null;
  flow_id?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  realizationDate?: string | null;
  isPassiveRenewal?: boolean | null;
}

export interface RenewalsReviewer {
  id?: string | null;
  employee?: AllEmployee | null;
  status?: string | null;
  receiveNotifications?: boolean | null;
  statusChanges?: (StatusChange | null)[] | null;
  role?: string | null;
}

export interface StatusChange {
  time?: DateTime | null;
  status?: string | null;
}

export interface ReviewerOption {
  employee?: AllEmployee | null;
  role?: string | null;
}

export interface BenAdminContact {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  contactTypes?: (number | null)[] | null;
}

export interface BillingAccount {
  id: string;
  companyAccountZuoraId: string;
}

export interface Contractor1099FormFiling {
  id: string;
  year: number;
  zpEmployee1099FormFilingId?: number | null;
  contractor1099Id?: number | null;
  fullName?: string | null;
  email?: string | null;
  fileThroughZenefits: boolean;
  zenefitsPayAmount: number;
  externalPayAmount: number;
  reimbursement: number;
  downloadFormUrl?: string | null;
}

export interface ImplementationProjectDetails {
  implementationMethod?: ImplementationProjectMethod | null;
  implementationStatus?: ImplementationProjectStatus | null;
  implementationStage?: ImplementationProjectStage | null;
  managerName?: string | null;
  managerTitle?: string | null;
  managerEmail?: string | null;
  managerPhone?: string | null;
  calendlyLink?: string | null;
}

export interface Partner {
  id?: string | null;
  companyId?: string | null;
  config?: PartnerConfiguration | null;
  name?: string | null;
  partnerUsers: PartnerUser[];
}

export interface PartnerConfiguration {
  allowedEmailDomains: string[] /** example: ['onedigital.com', 'foo.com'] */;
}

export interface PartnerUser {
  id: string /** Id of employee in partner's company */;
  userId: string;
  partnerId: string;
  companyId: string /** partner's companyId */;
  roles: string[] /** Permission.BENEFITS_BROKER_PARTNER_ADMINPermission.BENEFITS_FULFILLMENT_MANAGERPermission.BENEFITS_RENEWALS_MANAGERPermission.BENEFITS_ANALYST */;
  permissions: string[] /** Permission.EDIT_EMPLOYEE_BENEFITS_DATA */;
  status: string /** 'Act': active'Sus': suspended */;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  inUseAsPartnerContact: boolean;
}

export interface ZenefitsEmployee {
  employee?: AllEmployee | null /** contractor: Contractor */;
  user?: User | null;
  id?: string | null /** roles: [Role]permissions: [Permission] */;
  isActive?: boolean | null;
}

export interface InboxActionPartial {
  id?: string | null;
  isForDashboard?: boolean | null;
  requestDate?: string | null;
  status?: string | null;
  titleWithNamesAndVariables?: string | null;
  type?: string | null;
  viewCount?: number | null;
  zAppId?: string | null;
}

export interface ZAppActionUrl {
  id?: string | null;
  title?: JSON | null;
  actionUrl?: JSON | null;
  role?: JSON | null;
}

export interface InboxAction {
  attachments?: (InboxAttachment | null)[] | null;
  hideCannotComplete?: boolean | null;
  variables?: string | null;
  requestCompletionDate?: string | null;
  tag?: string | null;
  id?: string | null;
  descriptionWithNamesAndVariables?: string | null;
  dueDate?: string | null;
  completionDate?: string | null;
  completionNotificationList?: string | null;
  title?: string | null;
  internalNote?: string | null;
  priority?: string | null;
  isForDashboard?: boolean | null;
  zAppId?: string | null;
  type?: string | null;
  isAutomated?: boolean | null;
  status?: string | null;
  description?: string | null;
  deletedReason?: string | null;
  isBlocking?: boolean | null;
  errorMsg?: string | null;
  inboxSubActions?: (InboxSubAction | null)[] | null;
  version_id?: number | null;
  requestDate?: string | null;
  contextObjectId?: string | null;
  disableClientEmails?: boolean | null;
  requester?: User | null;
  viewCount?: number | null;
  cannotCompleteReason?: string | null;
  isUserInputCompleted?: boolean | null;
  forceUnique?: number | null;
  completedBy_id?: number | null;
  completedBy?: AllEmployee | null;
  titleWithNamesAndVariables?: string | null;
  inboxOwners?: (InboxOwner | null)[] | null;
  template?: InboxActionTemplate | null;
  resource_uri?: string | null;
}

export interface InboxAttachment {
  url?: string | null;
  inboxAction?: InboxAction | null;
  resource_uri?: string | null;
  id?: string | null;
  filename?: string | null;
}

export interface InboxSubAction {
  signatureSubActions?: (SignatureSubAction | null)[] | null;
  bankSubActions?: (BankSubAction | null)[] | null;
  description?: string | null;
  inboxAction?: InboxAction | null;
  singleValueSubActions?: (SingleValueSubAction | null)[] | null;
  addressSubActions?: (AddressSubAction | null)[] | null;
  confirmationSubActions?: (ConfirmationSubAction | null)[] | null;
  contentReviewSubActions?: (ContentReviewSubAction | null)[] | null;
  employee_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface SignatureSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  name?: string | null;
  signature?: string | null;
}

export interface BankSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankAccountType?: string | null;
  bankSwiftCode?: string | null;
  bankBranchName?: string | null;
  bankRoutingNumber?: string | null;
  additionalRoutingNumber?: string | null;
  country?: string | null;
  isActive?: boolean | null;
}

export interface SingleValueSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  name?: string | null;
  choices?: (InboxSubActionChoice | null)[] | null;
  value?: string | null;
  type?: string | null;
  null?: (InboxSubActionChoice | null)[] | null;
}

export interface InboxSubActionChoice {
  singleValueSubAction?: SingleValueSubAction | null;
  id?: string | null;
  value?: string | null;
  resource_uri?: string | null;
}

export interface AddressSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  city?: string | null;
  street1?: string | null;
  street2?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface ConfirmationSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  value?: boolean | null;
  ctaInfo?: string | null;
  secondaryCtaText?: string | null;
  redirectUrl?: string | null;
}

export interface ContentReviewSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  decision?: string | null;
  comment?: string | null;
}

export interface InboxOwner {
  inboxAction?: InboxAction | null;
  resource_uri?: string | null;
  id?: string | null;
  owner_id?: number | null;
}

export interface InboxActionTemplate {
  templateDir?: string | null;
  zAppId?: string | null;
  description?: string | null;
  tags?: (InboxActionTag | null)[] | null;
  title?: string | null;
  variables?: string | null;
  templateDescription?: string | null;
  inboxSubActionTemplates?: (InboxSubActionTemplate | null)[] | null;
  isAutomated?: boolean | null;
  priority?: string | null;
  multiActionType?: string | null;
  isForDashboard?: boolean | null;
  isBlocking?: boolean | null;
  disableClientEmails?: boolean | null;
  subActions?: string | null;
  resource_uri?: string | null;
  type?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface InboxActionTag {
  resource_uri?: string | null;
  template?: InboxActionTemplate | null;
  name?: string | null;
  id?: string | null;
}

export interface InboxSubActionTemplate {
  addressSubActionTemplates?: (AddressSubActionTemplate | null)[] | null;
  bankSubActionTemplates?: (BankSubActionTemplate | null)[] | null;
  confirmationSubActionTemplates?: (ConfirmationSubActionTemplate | null)[] | null;
  inboxActionTemplate?: InboxActionTemplate | null;
  singleValueSubActionTemplates?: (SingleValueSubActionTemplate | null)[] | null;
  resource_uri?: string | null;
  signatureSubActionTemplates?: (SignatureSubActionTemplate | null)[] | null;
  id?: string | null;
  description?: string | null;
}

export interface AddressSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface BankSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface ConfirmationSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface SingleValueSubActionTemplate {
  description?: string | null;
  value?: string | null;
  name?: string | null;
  choices?: (InboxSubActionChoiceTemplate | null)[] | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface InboxSubActionChoiceTemplate {
  value?: string | null;
  id?: string | null;
  singleValueSubActionTemplate?: SingleValueSubActionTemplate | null;
  resource_uri?: string | null;
}

export interface SignatureSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface FilterEmployee {
  id?: string | null;
  user_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  email?: string | null;
}

export interface RolesPermissionsData {
  grants?: JSON | null;
  spans?: JSON | null;
}

export interface PayRunOption {
  id?: string | null;
  payrollType?: string | null;
  displayName?: string | null;
  checkDate?: string | null;
  payrollRunState?: string | null;
}

export interface VariableCompensationPayTypeData {
  id?: number | null;
  company_id?: number | null;
  name?: string | null;
  numberOfIndividuals?: number | null;
  type?: string | null;
  amountType?: string | null;
  individualsList?: (EmployeeTypeData | null)[] | null;
}

export interface EmployeeTypeData {
  firstName?: string | null;
  lastName?: string | null;
  designation?: string | null;
  photoUrl?: string | null;
}

export interface SearchClientsResult {
  _type?: string | null;
  _score?: number | null;
  _id?: string | null;
  _source?: SearchClientsSource | null;
  _index?: string | null;
}

export interface SearchClientsSource {
  isReal?: boolean | null;
  id?: number | null;
  enrollmentStatus?: string | null;
  name?: string | null;
}

export interface InboxTask {
  id: string;
  ownerIds?: (string | null)[] | null;
  ownerData?: InboxAssignee[] | null;
  status?: string | null;
  creationDate?: string | null;
  completionDate?: string | null;
  completedById?: string | null;
  completedByName?: string | null;
  benefitsData?: InboxTaskBenefitsData | null;
  coreData?: InboxTaskCoreData | null;
  template?: InboxTaskTemplate | null;
  inboxAction?: FulfillmentInboxAction | null;
  inboxActionComments?: InboxActionComment[] | null;
  attachments?: FulfillmentAttachment[] | null;
  snoozeDueDate?: string | null;
}

export interface InboxAssignee {
  employeeId: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface InboxTaskBenefitsData {
  effectiveDate?: string | null;
  carrierId?: string | null;
  linesOfCoverage?: string[] | null;
  carrierName?: string | null;
  taskType?: string | null;
  previousTaskId?: string | null;
  newTaskId?: string | null;
  bundleId?: string | null;
  benefitsTransactionId?: string | null;
  fulfillmentForms?: FulfillmentFormData[] | null;
}

export interface FulfillmentFormData {
  id: string;
  pdfUrl?: string | null;
  validationErrorCount?: number | null;
  templateName?: string | null;
}

export interface InboxTaskCoreData {
  employeeId?: string | null;
  companyName?: string | null;
  employeeName?: string | null;
  companyId?: string | null;
}

export interface InboxTaskTemplate {
  id: string;
  name?: string | null;
  isBlocking?: boolean | null;
  isForDashboard?: boolean | null;
}

export interface FulfillmentInboxAction {
  id: string;
  templateName: string;
  variables?: InboxTaskVariables | null;
  titleWithNamesAndVariables?: string | null;
  descriptionWithNamesAndVariables?: string | null;
}

export interface InboxTaskVariables {
  benefitsTransaction?: BenefitsTransaction | null;
  benefitsSettlementBundle?: BenefitsSettlementBundle | null;
  benefitsSettlementBundles?: (BenefitsSettlementBundle | null)[] | null;
  renewalGroupLines?: string | null;
  newGroupLines?: string | null;
  locsAffected?: string | null;
  changesByLoc?: JSON | null;
  zippedPDFsLink?: string | null;
  carrierId?: string | null;
  previousTaskId?: string | null;
  newTaskId?: string | null;
  ieSwitchCensusReportUrl?: string | null;
  oeChangeReportUrl?: string | null;
  oeCensusReportUrl?: string | null;
  reasonsForReCreationList?: (string | null)[] | null;
  taskContextJson?: JSON | null;
}

export interface BenefitsSettlementBundle {
  id?: string | null;
  carrier?: Carrier | null;
  employee?: AllEmployee | null;
  company?: Company | null;
  effectiveDate?: Date | null;
  sendChannelName?: string | null;
  action_id?: string | null;
  carrier_id?: string | null;
  employee_id?: number | null;
  event?: string | null;
  dependsOnBundleIds?: string | null;
  status?: string | null;
  current_context?: BundleCurrentContext | null;
  forms?: (FulfillmentForm | null)[] | null;
}

export interface BundleCurrentContext {
  personalInfo?: BenefitsTransaction | null;
  medical?: BenefitsTransaction | null;
  dental?: BenefitsTransaction | null;
  vision?: BenefitsTransaction | null;
  group_term_life_insurance?: BenefitsTransaction | null;
  adnd?: BenefitsTransaction | null;
  vol_ltd_insurance?: BenefitsTransaction | null;
  vol_std_insurance?: BenefitsTransaction | null;
  acc?: BenefitsTransaction | null;
  cancer?: BenefitsTransaction | null;
  ci?: BenefitsTransaction | null;
  hi?: BenefitsTransaction | null;
}

export interface FulfillmentForm {
  id?: string | null;
  pdfUrl?: string | null;
  validationErrorCount?: string | null;
}

export interface InboxActionComment {
  id: string;
  taskId: string;
  employeeId: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeePhotoUrl: string;
  employee: AllEmployee;
  value: string;
  createDateTime: DateTime;
  mentions?: FilterEmployee[] | null;
}

export interface FulfillmentAttachment {
  id: string;
  url: string;
  filename: string;
  uploadedByEmployeeId: string;
}

export interface InboxTaskSearchResult {
  hits?: number | null;
  offset?: number | null;
  size?: number | null;
  data?: InboxTask[] | null;
}

export interface PdfInfo {
  id?: string | null;
  fields?: (PdfElement | null)[] | null;
  error?: string | null;
  success?: boolean | null;
  images?: (string | null)[] | null;
  errorCount?: number | null;
}

export interface PdfElement {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  value?: string | null;
  error?: string | null;
  top?: number | null;
  left?: number | null;
  height?: number | null;
  width?: number | null;
  page_num?: number | null;
  font_size?: number | null;
}

export interface FulfillmentTaskEmployee {
  employeeId: string;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  companyId: string;
  companyName: string;
  email?: string | null /** Employee's email */;
  status?: string | null /** Employee's status -- Act, Ter, Set, Del */;
  score?: number | null;
}

export interface SuggestedCompany {
  companyId: string;
  companyName: string;
  score?: number | null;
}

export interface RestrictionData {
  restrictionForEmployee?: Restriction | null;
  restrictionForDependents?: (Restriction | null)[] | null;
}

export interface Restriction {
  enrolleeId?: string | null;
  guaranteeIssue?: string | null;
  maxAmount?: string | null;
  baseMaxAmount?: string | null;
  computedPremium?: (PremiumSummary | null)[] | null;
  planAmountStyle?: string | null;
  flatAmounts?: string | null;
  incrementalUnits?: string | null;
  rateType?: string | null;
  tieredRateEnrolleeType?: string | null;
}

export interface PremiumSummary {
  amount?: string | null;
  fullAmount?: string | null;
  premium?: string | null;
}
/** TODO: remove when front end change is done */
export interface PlanRestrictionData {
  restrictionsForEmployee?: (Restriction | null)[] | null;
  restrictionForDependents?: (Restriction | null)[] | null;
}

export interface EmployeeHsaInfo {
  maximumAnnualHsaContribution: number;
  previousHsaContribution?: number | null;
  previousHsaContributionRequired: boolean;
  employeeContribution?: number | null;
  maxEmployeeMonthlyContribution: number;
  employerContribution: number;
  frontLoadedCompanyContribution: number;
  effectiveDate: Date;
  isEligibleForFSA: boolean;
  hsaStatus?: string | null;
}

export interface BundledLocs {
  linesOfCoverage?: (string | null)[] | null;
}

export interface AuditError {
  id?: string | null;
  audit?: Audit | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  carrier?: Carrier | null;
  lineOfCoverage?: string | null;
  firstSeen?: DateTime | null;
  lastSeen?: DateTime | null;
  addressedDeadline?: DateTime | null;
  severity?: string | null;
  classification?: string | null;
  extra1?: string | null;
  extra2?: string | null;
}

export interface Audit {
  lastRun?: DateTime | null;
  firstRun?: DateTime | null;
  argsJSON?: string | null;
  auditVersion?: string | null;
  resource_uri?: string | null;
  numErrors?: number | null;
  id?: string | null;
  name?: string | null;
}

export interface CompanyEnrollmentAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  enrollmentId?: string | null;
  effectiveDate?: Date | null;
}

export interface EmployeeEnrollmentAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  isLegacy?: boolean | null;
  enrollmentId?: string | null;
  effectiveDate?: Date | null;
}

export interface EmployeeTransactionAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  transactionId?: string | null;
  effectiveDate?: Date | null;
}

export interface EtaTasks {
  workflowId?: string | null;
  taskName?: string | null;
  group?: string | null;
  isPeriodicTask?: boolean | null;
  started?: DateTime | null;
  when?: DateTime | null;
  effectiveEnd?: DateTime | null;
  queuedAt?: DateTime | null;
  topParentTaskName?: string | null;
  queued?: boolean | null;
  priority?: number | null;
  finished?: DateTime | null;
  swfRunId?: string | null;
  useSwf?: boolean | null;
  signatureId?: number | null;
  swfDomain?: string | null;
  topParentTaskId?: number | null;
  resource_uri?: string | null;
  id?: string | null;
  errorId?: string | null;
  recoverCount?: number | null;
}

export interface ValidateZipCodeCoveredByPartnerResponse {
  partnerId?: string | null;
  partnerName?: string | null;
  zipCode?: string | null;
  isZipCodeCovered?: boolean | null;
}

export interface PartnerInfo {
  partnerName?: string | null;
  partnerEmail?: string | null;
  partnerOrganization?: string | null;
  partnerId?: string | null;
  success?: boolean | null;
  error?: string | null;
}

export interface PartnerContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  productType: string;
  partnerId: string;
  employeeId?: string | null;
}

export interface PartnerCompany {
  id: string;
  partnerId?: string | null;
  companyId: string;
  companyName?: string | null;
  company?: Company | null;
  productType: string;
  config?: PartnerConfiguration | null;
  contacts?: PartnerContact[] | null;
  effectiveStartDate: DateTime;
  effectiveEndDate: DateTime;
}

export interface PartnerCompanyMeta {
  companies?: (PartnerCompanyData | null)[] | null;
  totalCompanies?: number | null;
  totalFilteredCompanies?: number | null;
  totalActiveCompanies?: number | null;
  totalSettingUpCompanies?: number | null;
  totalEnrollingCompanies?: number | null;
  parentCompanies?: Company[] | null;
}

export interface PartnerCompanyData {
  id: string;
  companyId: string;
  company?: Company | null;
  employeesCount?: number | null;
  benefitsSetupComplete?: boolean | null;
  hasIncompleteFlow?: boolean | null;
  renewalDate?: string | null;
  oeEndDate?: string | null;
  oeEffectiveDate?: string | null;
  adminEmployeeId?: number | null;
  adminEmployee?: FilterEmployee | null;
  benefitsImpPercentage?: string | null;
  benefitsRenewalPercentage?: string | null;
  hrImpPercentage?: string | null;
}

export interface QleEnrollmentsValidationsResult {
  validChanges?: (string | null)[] | null;
  invalidChanges?: (string | null)[] | null;
}

export interface EmployeeStatusInfo {
  employeeExists: boolean;
}

export interface BenefitsLoaCoverageData {
  benefitsType?: string | null;
  terminationDate?: Date | null;
  eligibleForCobra?: boolean | null;
  isFuture?: boolean | null;
}

export interface BenefitsLoaData {
  lastDayWorked?: Date | null;
  firstDayBack?: Date | null;
}

export interface CompanyBenefitsCost {
  lineOfCoverage?: string | null;
  numberOfEnrollments?: number | null;
  totalCost?: string | null;
  totalDeduction?: string | null;
  totalContribution?: string | null;
}

export interface CobraClassification {
  documents?: (CobraClassificationDocument | null)[] | null;
  adminName?: string | null;
  effectiveDate?: string | null;
  cobraPartTimeCountRawData?: string | null;
  resource_uri?: string | null;
  adminSignature?: string | null;
  cobraType?: string | null;
  cobraPartTimeCount?: number | null;
  adminTitle?: string | null;
  reason?: string | null;
  cobraFullTimeCount?: number | null;
  countingMethod?: string | null;
  companyCobra?: CompanyCobra | null;
  id?: string | null;
  isVerified?: boolean | null;
  adminSignDate?: string | null;
}

export interface CobraClassificationDocument {
  classification?: CobraClassification | null;
  id?: string | null;
  document?: Document | null;
  resource_uri?: string | null;
}

export interface CompanyTag {
  description?: string | null;
  resource_uri?: string | null;
  ruleExpressionStr?: string | null;
  isNonDeterministicTag?: boolean | null;
  isOverwritableByAdmin?: boolean | null;
  isDisplayableToAdmin?: boolean | null;
  isEligible?: boolean | null;
  eligibilityStartDate?: string | null;
  companyProductTag?: CompanyProductTag | null;
  eligibilityNegatedTag?: CompanyTag | null;
  displayableEmployeeGroups?: JSON | null;
  isEligibilityNegatedTag?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
  employees?: (AllEmployee | null)[] | null;
  addedEmployees?: (AllEmployee | null)[] | null;
  removedEmployees?: (AllEmployee | null)[] | null;
}

export interface CompanyProductTag {
  subcategory?: string | null;
  tags?: (CompanyTag | null)[] | null;
  company?: Company | null;
  mutuallyExclusiveAllowed?: boolean | null;
  productName?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
  contentObject?: ContentObject | null;
}

export interface ContentObject {
  id?: string | null;
  name?: string | null;
}

export interface EmployeeTag {
  employee?: AllEmployee | null;
  companyTag?: CompanyTag | null;
  companyProductTag?: CompanyProductTag | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface WaiverRule {
  id?: string | null;
  validWaiversWithProof?: (string | null)[] | null;
  validWaiversWithoutProof?: (string | null)[] | null;
}

export interface WaiverMetadata {
  category?: string | null;
  applicableDependentTypes?: string | null;
  description?: string | null;
  resource_uri?: string | null;
  softDeleted?: boolean | null;
  name?: string | null;
  requiresCarrierInfo?: boolean | null;
  id?: string | null;
  isDefaultValid?: boolean | null;
}

export interface QuerySection125AmendmentResponse {
  hasAmendment?: boolean | null;
  amendmentUrl?: string | null;
  affectedReportUrl?: string | null;
  isZpay?: boolean | null;
}

export interface Talent {
  accessibleEmployees: AllEmployee[];
}

export interface TalentPermission {
  canCreateReview: boolean /** Reviews */;
  canLaunchAllEmployeesReview: boolean;
  canManageReviewTemplates: boolean;
  canCreateTeamGoal: boolean /** Goals */;
  canCreateCompanyGoal: boolean;
  canCreateDepartmentGoal: boolean;
  canSeeAllDepartmentGoals: boolean;
  canViewContingentWorkersSettings: boolean /** Settings */;
  canViewEmailNotificationSettings: boolean;
  canViewSettingsTab: boolean;
  canViewTemplatesSettings: boolean;
  canCreateSurvey: boolean /** Surveys */;
  canViewDraftSurveys: boolean;
  canViewEngagementAnalytics: boolean;
}

export interface QuestionFlow {
  id: string;
  title: string;
  description?: string | null;
  targetRule: QFTargetRule;
  sections: QFSection[];
  questions: QFQuestion[];
  question?: QFQuestion | null;
  createdBy: AllEmployee;
  createdAt: DateTime;
  sessions: QFSession[];
  clonedFrom?: QuestionFlow | null;
  config?: JSON | null;
}

export interface QFTargetRule {
  scope?: QFTargetRuleScope | null;
  reviewers?: QFTargetRuleReviewers | null;
}

export interface QFTargetRuleScope {
  type?: TargetRuleScopeType | null;
  departments?: string[] | null;
  locations?: string[] | null;
  specificEmployees?: string[] | null;
  additionalEmployees?: string[] | null;
  titles?: string[] | null;
  compTypes?: CompType[] | null;
  employmentTypes?: EmploymentType[] | null;
  workerTypes?: WorkerType[] | null;
  overruling?: TargetRuleScopeOverruling | null;
  or?: TargetRuleScopeOrItems | null;
  and?: TargetRuleScopeAndItems | null;
  excluding?: TargetRuleScopeExcludingItems | null;
}

export interface TargetRuleScopeOverruling {
  employeeIds?: string[] | null;
}
/** OR filters */
export interface TargetRuleScopeOrItems {
  teamIds?: string[] | null;
  locationIds?: string[] | null;
  departmentIds?: string[] | null;
}
/** AND filters */
export interface TargetRuleScopeAndItems {
  departments?: string[] | null;
  locations?: string[] | null;
  titles?: string[] | null;
  compTypes?: CompType[] | null;
  employmentTypes?: EmploymentType[] | null;
  workerTypes?: WorkerType[] | null;
}
/** EXCLUDING filters */
export interface TargetRuleScopeExcludingItems {
  employeeIds?: string[] | null;
  daysFromHireDate?: number | null;
}

export interface QFTargetRuleReviewers {
  predefinedRules?: TargetRuleReviewer[] | null;
  reviewersForAll?: string[] | null;
  specificReviewers?: JSON | null;
  managerSummary?: boolean | null;
  managerRole?: TargetRuleManagerRole | null;
}

export interface QFSection {
  id: string;
  title: string;
  order: number;
  questions: QFQuestion[];
  question?: QFQuestion | null;
  type?: string | null;
  comments: QFComment[];
  responses: QFQuestionResponse[];
}

export interface QFQuestion {
  id: string;
  title: string;
  description?: string | null;
  order: number;
  questionType: QFQuestionType;
  config: JSON;
  createdBy: AllEmployee;
  employee?: AllEmployee | null;
  responses: QFQuestionResponse[];
  sectionId?: string | null;
}

export interface QFRatingResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  rating: number;
  comment?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
  notApplicable?: boolean | null;
}

export interface QFTextResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  text: string;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
  notApplicable?: boolean | null;
}

export interface QFCheckboxResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  value: boolean;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface GenericQFQuestionResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface QFComment {
  id?: string | null;
  questionId?: string | null;
  sectionId?: string | null;
  text?: string | null;
}

export interface QFSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  respondent: AllEmployee;
  subjectEmployee?: AllEmployee | null;
  type?: string | null;
  metadata?: JSON | null;
}

export interface Review {
  id: string;
  questionFlow: QuestionFlow;
  schedules: TalentFlowSchedule[];
  runs: ReviewRun[];
  runsCount: number;
  status: ReviewStatus;
  startDate?: Date | null;
  targetedEmployees?: (AllEmployee | null)[] | null;
  targetableEmployees: AllEmployee[];
  permission: ReviewPermission;
  action: ReviewAction;
  nextCycleStartDate?: Date | null;
  temporaryStates: ReviewTemporaryState[];
  cycles: ReviewCycle[];
  suggestedReviewerSessions: ReviewerSession[];
}

export interface TalentFlowSchedule {
  id: string;
  event: TalentFlowScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration: number;
}

export interface ReviewRun {
  id: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  reviewSessions: ReviewSession[];
  summarySessions: ReviewSession[];
  reviewee: AllEmployee;
  permission: RunPermission;
  action: RunAction;
  startDate?: Date | null;
  endDate?: Date | null;
  status: ReviewRunStatus;
  maxPossibleEndDate?: Date | null;
}

export interface ReviewSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  reviewer: AllEmployee;
  reviewee: AllEmployee;
  type: ReviewSessionType;
  permission: SessionPermission;
  action: SessionAction;
  metadata?: JSON | null;
}

export interface SessionPermission {
  qfSessionId: string;
  canViewResponses: boolean;
  canEditResponses: boolean;
}

export interface SessionAction {
  qfSessionId: string;
  canEditSubmittedResponse: boolean;
}

export interface RunPermission {
  runId: string;
  canViewResults: boolean;
  canUpdateRunSettings: boolean;
  canViewRunGoals: boolean;
}

export interface RunAction {
  runId: string;
  updateRunSettings: boolean;
  removeReviewee: boolean;
}

export interface ReviewPermission {
  reviewId: string;
  canTakeActionOnReview: boolean;
  canSaveReviewAsTemplate: boolean;
  canExtendDueDates: boolean;
  canExportReviewCycleReport: boolean;
}

export interface ReviewAction {
  reviewId: string;
  extendDueDates: boolean;
  reopenReview: boolean;
  addReviewee: boolean;
}

export interface ReviewCycle {
  revieweesCollection: RevieweesCollection;
  reviewersCollection: ReviewersCollection;
  revieweeStatusSummary: RevieweeStatusSummary /** Deprecated, it's copied to metaTODO: Mason, remove the following when completion charts on frontend starts using meta */;
}

export interface RevieweesCollection {
  attributesCollection: RevieweesAttributesCollection;
  runsSelection: RunsSelection;
}

export interface RevieweesAttributesCollection {
  locations: CompanyLocation[];
  departments: Department[];
}

export interface RunsSelection {
  statusSummary: RevieweeStatusSummary;
  count: number;
  runs: ReviewRun[] /** TODO (Mason): Remove argument "first" after release of this ticket: UIINFRA-2097 */;
}

export interface RevieweeStatusSummary {
  review?: RevieweeStatusSummaryStats | null;
  summary?: RevieweeStatusSummaryStats | null;
}

export interface RevieweeStatusSummaryStats {
  complete?: number | null;
  inProgress?: number | null;
  notStarted?: number | null;
}

export interface ReviewersCollection {
  reviewersSelection: ReviewersSelection /** TODO (Mason): Remove argument "first" after release of this ticket: UIINFRA-2097 */;
}

export interface ReviewersSelection {
  count: number;
  reviewersRuns: ReviewerRuns[];
}

export interface ReviewerRuns {
  reviewer: AllEmployee;
  runs: ReviewerRun[];
}

export interface ReviewerRun {
  reviewerSessions: ReviewSession[];
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface ReviewerSession {
  reviewerId: string;
  type: ReviewSessionType;
}

export interface ReviewTemplate {
  id: string;
  companyId?: string | null;
  createdBy: AllEmployee;
  status: ReviewTemplateStatus;
  questionFlow: QuestionFlow;
  permission: ReviewTemplatePermission;
}

export interface ReviewTemplatePermission {
  templateId: string;
  canEditReviewTemplate: boolean;
}

export interface Goal {
  id: string;
  questionFlow: QuestionFlow;
  startDate: Date;
  dueDate: Date;
  owner: AllEmployee;
  createdBy: AllEmployee;
  createdAt: DateTime;
  status: GoalStatus;
  target: GoalTarget;
  targetId: string;
  permission: GoalPermission;
  parentGoal?: Goal | null;
  alignedGoals: Goal[];
}

export interface GoalPermission {
  goalId: string;
  canTakeActionOnGoal: boolean;
}

export interface MeetingSpace {
  id: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  meetings: Meeting[];
  participants: MeetingParticipant[];
  summary: MeetingSpaceSummary;
}

export interface Meeting {
  id: string;
  questionFlow: QuestionFlow;
  createdBy: AllEmployee;
  createdAt: DateTime;
  time: DateTime;
}

export interface MeetingParticipant {
  id: string;
  employee: AllEmployee;
}

export interface MeetingSpaceSummary {
  lastMeetingDate?: Date | null;
  actionsCount: number;
}

export interface MeetingsUserPreference {
  id?: string | null;
  group?: MeetingsUserPreferenceGroupType | null;
  preference?: MeetingsEmailUserPreference | null;
}

export interface MeetingsEmailUserPreference {
  REMINDER: boolean;
  AGENDA_UPDATE: boolean;
}

export interface WellbeingAssessment {
  id: string;
  questionFlow: QuestionFlow;
  runs: WellbeingAssessmentRun[];
  activeRun: WellbeingAssessmentRun;
  lastCompleteRun?: WellbeingAssessmentRun | null;
  status: WellbeingAssessmentStatus;
  filtersTree: WellbeingAssessmentFiltersTree;
  scoreSummary: WellbeingAssessmentScoreSummary[];
  participationSummary: WellbeingAssessmentParticipationSummary[];
}

export interface WellbeingAssessmentRun {
  id: string;
  createdAt: DateTime;
  sessions: WellbeingAssessmentSession[];
  employee: AllEmployee;
  startDate: Date;
  endDate?: Date | null;
  status: WellbeingAssessmentRunStatus;
  recommendedArticles: WellbeingArticle[];
  recommendedArticle?: WellbeingArticle | null;
}

export interface WellbeingAssessmentSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: GenericQFQuestionResponse[];
  comments: QFComment[];
  employee: AllEmployee;
  type: WellbeingAssessmentSessionType;
}

export interface WellbeingArticle {
  id: string;
  summary: string;
  body: string;
  images: WellbeingArticleImages;
  articleUrl: string;
  title: string;
  publishedAt: DateTime;
  categories: string[];
  authors: WellbeingArticleAuthor[];
}

export interface WellbeingArticleImages {
  full: string;
  large?: string | null;
  mediumLarge?: string | null;
  medium?: string | null;
  thumbnail?: string | null;
  card?: string | null;
}

export interface WellbeingArticleAuthor {
  id: string;
  name: string;
  photoUrl: string;
  title?: string | null;
  bio?: string | null;
}

export interface WellbeingAssessmentFiltersTree {
  rootHash: string;
  tree: JSON;
}

export interface WellbeingAssessmentScoreSummary {
  year: number;
  month: number;
  categoryFrequencies: WellbeingAssessmentScoreCategoryFrequency[];
}

export interface WellbeingAssessmentScoreCategoryFrequency {
  category: string;
  count: number;
}

export interface WellbeingAssessmentParticipationSummary {
  year: number;
  month: number;
  participantsCount: number;
}

export interface WellbeingLifeEvent {
  id: string;
  event: WellbeingLifeEventType;
  recommendedArticles: WellbeingArticle[];
  recommendedArticle?: WellbeingArticle | null;
}

export interface Survey {
  id: string;
  questionFlow: QuestionFlow;
  schedules: SurveySchedule[];
  status: SurveyStatus;
  startDate?: Date | null;
  permission: SurveyPermission;
  action: SurveyAction;
  metadata: SurveyMetadata;
  targetedEmployees: AllEmployee[];
  targetableEmployees: AllEmployee[];
  questionsResults: SurveyQuestionsResults;
  engagementScore: SurveyScorePoint;
  companyEngagementScore: SurveyScorePoint;
  companyQuestionsResults: SurveyQuestionsResults;
  companyMetadata: SurveyMetadata;
  questionsComments: SurveyQuestionComments[];
  commentsWeightedWords: SurveyCommentsWeightedWords[];
}

export interface SurveySchedule {
  id: string;
  event: SurveyScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration: number;
}

export interface SurveyPermission {
  surveyId: string;
  canTakeActionOnSurvey: boolean;
  canViewEngagementAnalytics: boolean;
  canViewComments: boolean;
}

export interface SurveyAction {
  surveyId: string;
  canShareSurveyResults: boolean;
}

export interface SurveyMetadata {
  id: string;
  isCompanywide: boolean;
  respondentsProgress: SurveyRespondentsProgress;
  participantsFilters: SurveyParticipantsFilters;
}

export interface SurveyRespondentsProgress {
  total: number;
  completed: number;
}

export interface SurveyParticipantsFilters {
  departments: Department[];
  locations: Location[];
  managers: AllEmployee[];
  genders: string[];
  tenureLevels: string[];
  nullableOptions: string[];
}

export interface Location {
  id: string;
  name?: string | null;
}

export interface SurveyQuestionsResults {
  surveyId: string;
  isCompanywide: boolean;
  questionResults: SurveyQuestionResult[];
  segmentedQuestionResults: SurveySegmentedQuestionResult[];
}

export interface SurveyQuestionResult {
  questionId: string;
  sectionId?: string | null;
  scores: SurveyScorePoint[];
}

export interface SurveyScorePoint {
  value?: number | null;
  count: number;
}

export interface SurveySegmentedQuestionResult {
  questionId: string;
  sectionId?: string | null;
  segmentId?: string | null;
  score: SurveyScorePoint;
}

export interface SurveyQuestionComments {
  question: QFQuestion;
  count: number;
  comments: string[];
}

export interface SurveyCommentsWeightedWords {
  question: QFQuestion;
  words: SurveyCommentsWeightedWord[];
}

export interface SurveyCommentsWeightedWord {
  word: string;
  weight: number;
}

export interface SurveyTemplate {
  id: string;
  questionFlow: QuestionFlow;
}

export interface PostAudience {
  entireCompany?: boolean | null;
  myTeam?: boolean | null;
  locations?: (CompanyLocation | null)[] | null;
  departments?: (Department | null)[] | null;
}

export interface TargetAudience {
  targetedAudience?: (AllEmployee | null)[] | null;
  targetableAudienceCount?: number | null;
}

export interface SocialFeedComment {
  id: string;
  postId: string;
  content: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  permission: SocialCommentPermission;
  key?: string | null;
  taggedEmployees?: (AllEmployee | null)[] | null;
}

export interface SocialCommentPermission {
  canDelete: boolean;
}

export interface PostLike {
  likedBy: AllEmployee;
  likedAt: DateTime;
}

export interface SocialFeed {
  permission: SocialFeedPermission;
  settings?: SocialFeedSettings | null;
  posts?: (SocialFeedPost | null)[] | null;
  taggableEmployees: AllEmployee[];
}

export interface SocialFeedPermission {
  canCreatePost: boolean;
  canViewFeed: boolean;
  canUpdateSocialFeedSettings: boolean;
}

export interface SocialFeedSettings {
  id?: string | null;
  isCompanyWideVisible?: boolean | null;
  announcementsEnabled?: boolean | null;
  birthdayPostsEnabled?: boolean | null;
}

export interface SocialFeedPost {
  id: string;
  description: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  commentsCount: number;
  likesCount: number;
  likedByMe: boolean;
  permission: SocialPostPermission;
  key?: string | null;
  taggedEmployees?: (AllEmployee | null)[] | null;
  postType?: string | null;
}

export interface SocialPostPermission {
  canEdit: boolean;
  canDelete: boolean;
}

export interface FulfillmetTaskCarrier {
  id: string;
  name: string;
}

export interface CompanyRateVersion {
  id?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  genderBandedStyle?: string | null;
  lineOfCoverage?: string | null;
  quoteReason?: string | null;
  rateStyle?: string | null;
  rates?: AllRates | null;
}

export interface AllRates {
  companyRates?: (JSON | null)[] | null;
  planRates?: (JSON | null)[] | null;
}

export interface DataFields {
  id?: number | null;
  fields?: (DataField | null)[] | null;
  error?: string | null;
  success?: boolean | null;
}

export interface DataField {
  id?: number | null;
  fieldName?: string | null;
  description?: string | null;
  expression?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  updatedDate?: string | null;
}

export interface StpFormTemplate {
  name?: string | null;
  description?: string | null;
  isLive?: boolean | null;
  created_by?: string | null;
  modified_by?: string | null;
  created_date?: DateTime | null;
  modified_date?: DateTime | null;
  templateUrl?: string | null;
  category?: string | null;
  FieldMappings?: (StpFieldMapping | null)[] | null;
  groups?: (StpFieldGroup | null)[] | null;
  id?: string | null;
  success?: boolean | null;
  error?: string | null;
  isDeleted?: boolean | null;
  state?: string | null;
  message?: string | null;
}

export interface StpFieldMapping {
  expression?: string | null;
  fieldName?: string | null;
  formId?: string | null;
  created?: DateTime | null;
  modified?: DateTime | null;
  isValid?: boolean | null;
  id?: string | null;
  groupId?: string | null;
  fieldValidationType?: string | null;
  positionOfError?: string | null;
}

export interface StpFieldGroup {
  id?: string | null;
  name?: string | null;
  operationType?: string | null;
  operationInteger?: string | null;
}

export interface StpInputFieldsAndOperators {
  fields?: (string | null)[] | null;
  operators?: (string | null)[] | null;
  keys?: (string | null)[] | null;
}

export interface ZPayrollCompanySearchResult {
  count: number;
  totalCount: number;
  data?: (ZPayrollCompany | null)[] | null;
}

export interface CompanyImplementationOverviewSearchResult {
  count: number;
  totalCount: number;
  data?: (CompanyImplementationOverview | null)[] | null;
}

export interface CompanyImplementationOverview {
  id: string;
  company: Company;
  assignedTo?: User | null;
  overallOnboardingState: OverallOnboardingState;
  _payrollOnboardingState?: PayrollOnboardingState | null;
  payrollOnboardingStartDate?: DateTime | null;
  payrollOnboardingCompletedDate?: DateTime | null;
  payrollTimeline?: OnboardingProductImplementationTimeline | null;
  _benefitsOnboardingState?: BenefitsOnboardingState | null;
  benefitsOnboardingStartDate?: DateTime | null;
  benefitsOnboardingCompletedDate?: DateTime | null;
  benefitsTimeline?: OnboardingProductImplementationTimeline | null;
}

export interface OnboardingProductImplementationTimeline {
  startDate?: string | null;
  endDate?: string | null;
  durationDays?: string | null;
  events?: (OnboardingProductImplementationTimelineEvent | null)[] | null;
}

export interface OnboardingProductImplementationTimelineEvent {
  state?: string | null;
  eventDate?: string | null;
}

export interface ZScoreCompany {
  id: string;
  companyId?: number | null;
  ein?: string | null;
  dunsNumber?: string | null;
  salesForceAccountId?: string | null;
  status?: ZScoreCompanyStatus | null;
  type?: string | null;
  legalName?: string | null;
  legalAddress?: string | null;
  legalCity?: string | null;
  legalState?: string | null;
  legalZip?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  naicsCode?: string | null;
  sicCode?: string | null;
  createdOn?: DateTime | null;
  activatedOn?: DateTime | null;
  deactivatedOn?: DateTime | null;
  latestZScore?: ZScore | null;
  isUsingZPAY?: boolean | null;
  blockPeriod?: number | null;
}

export interface ZScore {
  id: string;
  zScoreCompany?: ZScoreCompany | null;
  score?: number | null;
  commercialStressScore?: number | null;
  financialStressScore?: number | null;
  noOfEEScore?: number | null;
  timeOnPayrollScore?: number | null;
  billingFailureScore?: number | null;
  payrollFailureScore?: number | null;
  payrollQualificationLevel?: ZScorePayrollQualificationLevel | null;
  blockScoreOfacResult?: ZScoreBlockScoreOfacResult | null;
  riskLevel?: ZScoreRiskLevel | null;
  companyEvents?: (ZScoreCompanyEvent | null)[] | null;
  createdOn?: DateTime | null;
  lastRefreshedOn?: DateTime | null;
}

export interface ZScoreCompanyEvent {
  id?: string | null;
  category?: ZScoreCategory | null;
  value?: string | null;
  createdOn?: DateTime | null;
  isActive?: boolean | null;
  deactivatedOn?: DateTime | null;
  deactivatedBy?: number | null;
  details?: JSON | null;
}

export interface ZScoreCategory {
  name?: ZScoreCategoryName | null;
  description?: string | null;
  verboseName?: string | null;
  updateFrequencyUnit?: ZScoreUpdateFrequencyUnit | null;
  updateFrequencyNumber?: number | null;
  newCustomerValue?: number | null;
  existingCustomerValue?: number | null;
  existingZPAYCustomerValue?: number | null;
  rangeConfigurations?: (ZScoreRangeConfiguration | null)[] | null;
  failureConfigurations?: (ZScoreFailureConfiguration | null)[] | null;
}

export interface ZScoreRangeConfiguration {
  id?: string | null;
  startRange?: number | null;
  endRange?: number | null;
  zenefitsValue?: number | null;
}

export interface ZScoreFailureConfiguration {
  id?: string | null;
  value?: number | null;
  operator?: string | null;
  zenefitsValue?: number | null;
}

export interface ZScoreReduced {
  score?: number | null;
  createdOn?: DateTime | null;
}

export interface IndustryData {
  industry?: (Industry | null)[] | null;
}

export interface Industry {
  id: string;
  name: string;
  subIndustry?: (SubIndustry | null)[] | null;
}

export interface SubIndustry {
  id: string;
  name: string;
}

export interface LocationData {
  location?: (Location | null)[] | null;
  city?: (City | null)[] | null;
  state?: (StateInfo | null)[] | null;
  msa?: (MsaInfo | null)[] | null;
}

export interface City {
  id: string;
  name?: string | null;
  stateId?: string | null;
  csaId?: string | null;
  msaId?: string | null;
}

export interface StateInfo {
  id: string;
  name?: string | null;
}

export interface MsaInfo {
  id: string;
  name?: string | null;
  csaId?: string | null;
  stateId?: string | null;
}

export interface JobFamilyData {
  jobFamily?: (JobFamily | null)[] | null;
}

export interface JobFamily {
  id: string;
  name: string;
  jobSubFamily?: (JobSubfamily | null)[] | null;
}

export interface JobSubfamily {
  id: string;
  name: string;
  jobTitle?: (JobTitle | null)[] | null;
}

export interface JobTitle {
  id: string;
  name: string;
}

export interface JobLevelData {
  jobLevel?: (JobLevel | null)[] | null;
}

export interface JobLevel {
  id: string;
  name: string;
  description?: string | null;
}

export interface CompanyConfig {
  subIndustry?: number | null;
  location?: (Location | null)[] | null;
  minPercentile?: number | null;
  maxPercentile?: number | null;
  isConfigured?: boolean | null;
  isLocked?: boolean | null;
  appStatus?: ZAppInstall | null;
  employeeSubscriptionStatus?: ZAppInstallSubscription | null;
}

export interface CompanyJobTitleMapping {
  companyTitleList?: (CompanyJobTitle | null)[] | null;
}

export interface CompanyJobTitle {
  rawTitle: string;
  jobFamily?: JobFamily | null;
  jobSubFamily?: JobSubfamily | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  employeeCount?: number | null;
}

export interface EmployeeJobTitle {
  employee?: Employee | null;
  jobFamily?: JobFamily | null;
  jobSubFamily?: JobSubfamily | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  city?: City | null;
  rollupRule?: RollupRule | null;
  minPercentileSalary?: number | null;
  maxPercentileSalary?: number | null;
  salaryBenchmarkingId?: number | null;
  medianSalary?: number | null;
  locationName?: string | null;
}

export interface Employee {
  id: string;
  employeeNumber: string;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  managerName?: string | null;
  salary?: number | null;
  gender?: string | null;
  department?: string | null;
  title?: string | null;
}

export interface RollupRule {
  priority?: number | null;
  locationType?: number | null;
  industryType?: number | null;
  jobFamilyType?: number | null;
  jobLevelType?: number | null;
}

export interface JobTitleSuggestion {
  jobTitle?: JobTitle | null;
  jobFamily?: JobFamily | null;
  jobSubfamily?: JobSubfamily | null;
  description?: string | null;
  keywords?: (string | null)[] | null;
}

export interface EmployeeJobTitleMapping {
  employeeJobTitleList?: (EmployeeJobTitle | null)[] | null;
  unmappedEmployeeCount?: number | null;
  totalEmployee?: number | null;
  lastUpdatedDate?: string | null;
}

export interface JobLevelSalaryMapping {
  jobLevel?: JobLevel | null;
  salary5thPercentile?: number | null;
  salary25thPercentile?: number | null;
  salary50thPercentile?: number | null;
  salary75thPercentile?: number | null;
  salary95thPercentile?: number | null;
  minPercentileSalary?: number | null;
  maxPercentileSalary?: number | null;
  rollupRule?: RollupRule | null;
}

export interface CompanyDataMetrics {
  jobFamily?: CompanyMarketMetrics | null;
  jobLevel?: CompanyMarketMetrics | null;
  salaryLocation?: (CompanyMarketMetrics | null)[] | null;
}

export interface CompanyMarketMetrics {
  company?: (DataMetrics | null)[] | null;
  market?: (DataMetrics | null)[] | null;
  name?: string | null;
}

export interface DataMetrics {
  y?: number | null;
  name?: string | null;
}

export interface SalaryBenchmarkingBlacklistData {
  salaryBenchmarkingBlacklist?: (SalaryBenchmarkingBlacklistElement | null)[] | null;
}

export interface SalaryBenchmarkingBlacklistElement {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  isActive?: boolean | null;
  modifiedBy?: number | null;
}

export interface paWorkflowInfo {
  employee?: Employee | null;
  city?: City | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  salaryInsights?: (JobLevelSalaryMapping | null)[] | null;
}

export interface PaAppInstall {
  appStatus?: ZAppInstall | null;
  employeeSubscription?: (EmployeeSubscription | null)[] | null;
}

export interface EmployeeSubscription {
  id?: number | null;
  employeeId?: number | null;
  employeeName?: string | null;
  status?: number | null;
}

export interface Feedback {
  companyId?: number | null;
  companyName?: string | null;
  rating?: string | null;
  comment?: string | null;
}

export interface PremadeReportScheduledInfo {
  data?: ReportScheduleInfo | null;
}

export interface ReportScheduleInfo {
  fileFormat?: string | null;
  changedDate?: string | null;
  premadeReportId?: string | null;
  adminEmployeeId?: number | null;
  notifyEmployeeIds?: (number | null)[] | null;
  recurringStrategy?: JSON | null;
}

export interface CustomReportData {
  customReport?: CustomReportInfo | null;
  reportDocument?: ReportDocumentInfo | null;
  reportSchedule?: ReportScheduleInfo | null;
}

export interface CustomReportInfo {
  containsSensitiveInfo?: boolean | null;
  createdBy?: number | null;
  createdByName?: string | null;
  description?: string | null;
  fieldFiltersJson?: string | null;
  fields?: string | null;
  fileFormat?: string | null;
  filtersJson?: string | null;
  headersJson?: string | null;
  id?: number | null;
  includeEmployeeDependentsAsRows?: boolean | null;
  includeInternationalEmployees?: boolean | null;
  includeTerminatedEmployees?: boolean | null;
  isMta?: boolean | null;
  isPointInTime?: boolean | null;
  isPremade?: boolean | null;
  name?: string | null;
  premade_report_id?: string | null;
  runCount?: number | null;
  status?: string | null;
}

export interface ReportDocumentInfo {
  name?: string | null;
  url?: string | null;
  generatedTime?: DateTime | null;
  generatedBy_id?: number | null;
  date?: string | null;
  timeZone?: string | null;
  id?: string | null;
  description?: string | null;
  status?: string | null;
}

export interface ReportTemplate {
  fields?: (string | null)[] | null;
  displayName?: string | null;
  reportName?: string | null;
}

export interface trsSampleGraphqlType {
  name?: string | null;
}

export interface TrsCategory {
  id: string;
  name?: string | null;
  isSelected?: boolean | null;
  categoryType?: string | null;
  description?: string | null;
  path?: string | null;
  fields?: (TrsField | null)[] | null;
}

export interface TrsField {
  id: string;
  name?: string | null;
  isSelected?: boolean | null;
  displayName?: string | null;
  value?: string | null;
}

export interface RewardStatement {
  id: string;
  title?: string | null;
  employeeIds?: (number | null)[] | null;
  createdBy?: string | null;
  generatedDate?: string | null;
  status?: string | null;
  modifiedDate?: string | null;
  createdDate?: string | null;
  nameDisplayPreference?: string | null;
  displayLogo?: boolean | null;
  description?: string | null;
  statementMessage?: string | null;
  disclaimerNotice?: string | null;
  startDate?: string | null;
  additionalNote?: string | null;
  categories?: (TrsCategory | null)[] | null;
}

export interface ProductDeal {
  id: string;
  company_id: string;
  caption?: string | null;
  description?: string | null;
  createdOn: Date;
  expiresOn: Date;
  dealLink?: string | null;
  price?: number | null;
  billingFrequency?: string | null;
  businessGroup?: string | null;
  productStatus?: string | null;
  company_name?: string | null;
  logoUrl?: string | null;
}

export interface ProductCategory {
  value?: string | null;
  label?: string | null;
}

export interface Filter {
  placeholder?: string | null;
  choices?: (FilterElement | null)[] | null;
}

export interface FilterElement {
  value?: string | null;
  label?: string | null;
}

export interface CurrentUserCompany {
  company_id: string;
  company_name?: string | null;
}

export interface EmployeeDetails {
  employee_id: string;
  first_name?: string | null;
  last_name?: string | null;
  entityDict?: JSON | null;
}

export interface MyDeal {
  id: string;
  company_id: string;
  caption?: string | null;
  description?: string | null;
  createdOn: Date;
  expiresOn: Date;
  dealLink?: string | null;
  price?: number | null;
  billingFrequency?: string | null;
  businessGroup?: string | null;
  productStatus?: string | null;
  view_count?: number | null;
}

export interface CompanySetupFlowTasks {
  id: string;
  tasks: CompanySetupFlowTask[];
}

export interface CompanySetupFlowTask {
  name: string;
  sections: CompanySetupFlowTaskSection[];
  eta: number;
  tag: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
}

export interface CompanySetupFlowTaskSection {
  id: string;
  tag: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
  page: string;
  name: string;
  emberRoute?: string | null;
}

export interface OnboardingDocStatus {
  numRequiredDocs: number;
  numUploadedDocs: number;
}

export interface CompanySetupPTOPlan {
  type?: string | null;
  timeOffDaysPerYear?: number | null;
  sickLeaveDaysPerYear?: number | null;
}

export interface CompanySetupEmployeeStats {
  Active?: number | null;
  Completed?: number | null;
}

export interface CompanySetupInviteEmailRecipients {
  id: string;
  first_name: string;
  last_name: string;
  email?: string | null;
}

export interface CompanySetupReminderEmailRecipients {
  id: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  lastReminderEmailTimestamp?: number | null;
}

export interface PriorPayrollInfo {
  hasPriorPayroll: boolean;
  priorPayrollProvider?: string | null;
}

export interface PayrollBankSetUpStatus {
  zPBankAccountCreated?: boolean | null;
  checkUploadedOrPlaidVerified?: boolean | null;
  isPlaidVerification?: boolean | null;
}

export interface CompanyPaySchedulePreview {
  start?: Date | null;
  end?: Date | null;
  payDate?: Date | null;
  isHoliday?: boolean | null;
}

export interface CompanySetupContributionSettings {
  id: string;
  offerContribution?: string | null;
  contributionPriority?: string | null;
  workerContribution?: string | null;
  dependentContribution?: string | null;
  policySettings?: (PolicySettings | null)[] | null;
}

export interface PolicySettings {
  lineOfCoverage?: string | null;
  contributionType?: string | null;
  workerContribution?: number | null;
  dependentContribution?: number | null;
  basePlanPremium?: number | null;
  benchmarks?: JSON | null;
  costEstimates?: JSON | null;
}

export interface CompanySetupMarketplacePlanV2 {
  id: string;
  coverageType?: string | null;
  planDetails?: MarketplacePlan | null;
  youPremium: number;
  youAndSpousePremium: number;
  youAndChildPremium: number;
  familyPremium: number;
}

export interface MarketplaceMedicalPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  HMOPPO?: string | null;
  HSA?: boolean | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface MarketplaceCarrier {
  id?: string | null;
  displayName?: string | null;
  logoUrl?: string | null;
  copy?: MarketplaceCarrierCopy | null;
}

export interface MarketplaceCarrierCopy {
  id?: string | null;
  hmoMedicalUrl?: string | null;
}

export interface MarketplaceDentalPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  HMOPPO?: string | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface MarketplaceVisionPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface CompanySetupBenefitsPreferences {
  id: string;
  coverageStartDates?: (Date | null)[] | null;
}

export interface CompanySetupCarrierSettings {
  id: string;
  coverageStartDate?: Date | null;
  leadTimeDeadline?: Date | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  participation?: JSON | null;
  openEnrollmentPeriod?: OpenEnrollmentPeriod | null;
  authName?: string | null;
  authTitle?: string | null;
  authSignature?: GroupApplicationSignature | null;
}

export interface OpenEnrollmentPeriod {
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface GroupApplicationSignature {
  dataUrl?: string | null;
  date?: Date | null;
}

export interface DemoCenterDropdownOptions {
  tours: walkmeTour[];
}

export interface walkmeTour {
  name?: string | null;
  isOpened?: boolean | null;
  iconName?: string | null;
  tourItems?: (walkmeTourItem | null)[] | null;
}

export interface walkmeTourItem {
  tourName?: string | null;
  tourId?: string | null;
}

export interface DemoConsumerInfo {
  demoConsumerEmail?: string | null;
}

export interface ProductCatalog {
  offer?: CheckoutOptions | null;
  saasAgreement: SaasAgreement;
}

export interface CheckoutOptions {
  creationType?: string | null;
  version: number;
  contractOptions: ContractOption[];
  implementationProductMatrix?: ImplementationProductMatrix | null;
  packageProducts: OfferPackageProduct[];
  optionalProducts: OfferOptionalProduct[];
}

export interface ContractOption {
  contractType: CheckoutContractType;
  billingPeriod: CheckoutBillingPeriod;
  totalContractLength?: number | null;
  isExisting: boolean;
}

export interface ImplementationProductMatrix {
  SELF_SERVE_IMP?: ProductImplementationFeeMap | null;
}

export interface ProductImplementationFeeMap {
  V3_TIER_ONE?: number | null;
  V3_TIER_TWO?: number | null;
  V3_TIER_THREE?: number | null;
  PYP_ADDON?: number | null;
  BEN_ADMIN_ADDON?: number | null;
}

export interface OfferPackageProduct {
  productName: CheckoutPackageProductSku;
  productDisplayName?: string | null;
  annualBasePrice?: string | null;
  monthlyBasePrice?: string | null;
  annualPaidMonthlyBasePrice?:
    | string
    | null /** In the current billing version we don't have a monthly base price. We have in past versions */;
  annualDiscountPercent?: ProductDiscount | null;
  monthlyDiscountPercent?: ProductDiscount | null;
  annualPepmPrice?: string | null /** annual per employee per month price */;
  monthlyPepmPrice?: string | null /** monthly per employee per month price */;
  annualPaidMonthlyPepmPrice?:
    | string
    | null /** annual contract, monthly billing period per employee per month price */;
  annualCwUsage?:
    | string
    | null /** annual contingent worker usage. Annual plans can have monthly contingent worker charges */;
  monthlyCwUsage?: string | null /** monthly contingent worker usage. */;
  annualPCWpmPrice?: string | null /** annual per contingent worker per month price */;
  monthlyPCWpmPrice?: string | null /** monthly per contingent worker per month price */;
  isBaseProduct?:
    | boolean
    | null /** products can be base (essentials/growth/zen) or optional (payroll/ben admin/advisory). This flag is always true */;
}

export interface ProductDiscount {
  V3_TIER_ONE?: number | null;
  V3_TIER_TWO?: number | null;
  V3_TIER_THREE?: number | null;
}

export interface OfferOptionalProduct {
  monthlyDiscountIfExistingOrNew?: string | null;
  annualDiscountIfExistingOrNew?: string | null;
  monthlyDiscountPercent?: ProductDiscount | null;
  annualDiscountPercent?: ProductDiscount | null;
  monthlyPepmPrice?: string | null;
  annualPepmPrice?: string | null;
  annualPaidMonthlyPepmPrice?: string | null;
  productName: CheckoutOptionalProductSku;
  productDisplayName?: string | null;
  isBaseProduct?:
    | boolean
    | null /** products can be base (essentials/growth/zen) or optional (payroll/ben admin/advisory). This flag is always false */;
}

export interface SaasAgreement {
  path: string;
}

export interface CheckoutSession {
  userId?: string | null;
  isTrialAccountExpired?: boolean | null;
  inSession?: boolean | null;
  isAdmin?: boolean | null;
  isDemoCompany?: boolean | null;
  hasFreeLimitedTrialCompany?: boolean | null;
  isFreeLimitedTrialCompany?: boolean | null;
  trialAccountId?: string | null;
  trialCompanyInfo?: TrialCompanyInfo | null;
}

export interface TrialCompanyInfo {
  companyZip?: string | null;
  isTrialAccountConversion?: boolean | null;
  companyId: string;
  firstName?: string | null;
  companyName?: string | null;
  employeeCount?: number | null;
  lastName?: string | null;
  tos?: boolean | null;
  companyPhone?: string | null;
  email?: string | null;
}

export interface CheckoutFlow {
  bundle?: CheckoutBundle | null;
}

export interface CheckoutBundle {
  id: string;
  offerType?: CheckoutContractType | null /** length of the contract the customer wants to purchase (monthly or annual) */;
  billingPeriod?: CheckoutBillingPeriod | null /** frequency of the payments the customer wants to make (monthly or annual) */;
  numberOfEmployees?: number | null;
  numberOfContingentWorkers?: number | null;
  companyId?: string | null;
  trialAccountId?: string | null;
  isActive?: boolean | null;
  baseProduct?: BundleBaseProduct | null;
  optionalProducts: BundleOptionalProduct[];
  implementationProduct?: BundleImplementationProduct | null;
}

export interface BundleBaseProduct {
  productName: string;
}

export interface BundleOptionalProduct {
  productName: string;
}

export interface BundleImplementationProduct {
  productName: string;
}

export interface ZuoraApiEnabled {
  status?: string | null;
}

export interface GenerateGatewayParams {
  style?: string | null;
  success?: boolean | null;
  url?: string | null;
  submitEnabled?: boolean | null;
  tenantId?: string | null;
  locale?: string | null;
  token?: string | null;
  key?: string | null;
  signature?: string | null;
  id?: string | null;
  paymentGateway?: string | null;
  param_supportedTypes?: string | null;
}

export interface DocumentInfo {
  document_meta?: DocumentMeta | null;
  doc_action_status?: DocumentActionStatus | null;
  uploaded_files?: (UploadedFile | null)[] | null;
}

export interface DocumentMeta {
  id: string;
  companyId?: number | null;
  section?: string | null;
  name?: string | null;
  description?: string | null;
  isOnboardingDoc?: boolean | null;
}

export interface DocumentActionStatus {
  doc_meta_id?: string | null;
  status?: string | null;
  requested_by_fullname?: string | null;
  requested_at?: Date | null;
  uploaded_by_fullname?: string | null;
  uploaded_at?: Date | null;
  deleted_by_fullname?: string | null;
  deleted_at?: Date | null;
}

export interface UploadedFile {
  id?: string | null;
  document_file_group_id?: number | null;
  fileName?: string | null;
  path?: string | null;
  document_file_note?: string | null;
}

export interface CompanyAdmin {
  employeeId?: string | null;
  fullName?: string | null;
}

export interface DocActionHistory {
  action?: string | null;
  userName?: string | null;
  actionTimestamp?: Date | null;
  uploadedFile?: UploadedFile | null;
}

export interface readOnlyAccess {
  canEdit?: boolean | null;
}

export interface chatStatus {
  status?: string | null;
  iframe?: string | null;
  liveagentInit?: string | null;
  button?: string | null;
  caseId?: string | null;
  id?: string | null;
}

export interface holidayInfo {
  isBusinessToday?: boolean | null;
  isBusinessHours?: boolean | null;
  nextBusinessDay?: string | null;
  holidayDate?: string | null;
}

export interface roleAndCategory {
  isAdmin?: boolean | null;
  category?: string | null;
  isBroker?: boolean | null;
}

export interface openCases {
  createdTimestamp?: string | null;
  description?: string | null;
  id?: string | null;
  status?: string | null;
  subject?: string | null;
  salesforceId?: string | null;
}

export interface coveoData {
  accessToken?: string | null;
  organizationId?: string | null;
  url?: string | null;
}

export interface issueTopics {
  topicName?: string | null;
  topicDescription?: string | null;
  topicId?: string | null;
  isLeaf?: boolean | null;
}

export interface recommendedContactOptions {
  available?: (string | null)[] | null;
  recommended?: string | null;
  phoneref?: string | null;
  hr?: adminDetailsData | null;
  broker?: adminDetailsData | null;
}

export interface adminDetailsData {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface finalPageData {
  coordinatorType?: string | null;
  path?: string | null;
  employees?: (EmployeesData | null)[] | null;
  customSupportFields?: (CustomFieldData | null)[] | null;
}

export interface EmployeesData {
  name?: string | null;
  id?: string | null;
}

export interface CustomFieldData {
  text?: string | null;
  helpText?: string | null;
  dataType?: string | null;
  metadata?: JSON | null;
}

export interface bannerData {
  show?: boolean | null;
  type?: string | null;
  text?: string | null;
}

export interface decisionTreePathData {
  path?: string | null;
}

export interface niceCredentials {
  endpoint?: string | null;
  poc?: string | null;
  bu?: string | null;
}

export interface alleguesBusinessHours {
  isBusinessHours?: boolean | null;
}

export interface EmployeeMessage {
  employeeId?: string | null;
  fullName?: string | null;
}

export interface CompanyDocuments {
  id?: number | null;
  name?: string | null;
  ruleMetadata?: JSON | null;
  ruleMetadataText?: string | null;
  uploadedFile?: DocumentUploadedFile | null;
  folder_id?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  createdOn?: Date | null;
  createdBy?: string | null;
  createdById?: number | null;
  lastModifiedOn?: Date | null;
  lastModifiedBy?: string | null;
}

export interface DocumentUploadedFile {
  id?: string | null;
  key?: string | null;
  fileName?: string | null;
  path?: string | null;
  size?: number | null;
  uploadedBy?: string | null;
  uploadedAt?: string | null;
}

export interface FoldersAndDocumentsQuery {
  status?: number | null;
  folders?: (Folders | null)[] | null;
}

export interface Folders {
  id?: string | null;
  name?: string | null;
  canViewFolder?: boolean | null;
  canEditFolder?: boolean | null;
  canDeleteFolder?: boolean | null;
  canCreatorDeleteFolder?: boolean | null;
  loggedInEmployeeId?: number | null;
  createdById?: number | null;
  folderPermissions?: (FolderPermissions | null)[] | null;
  documents?: (CompanyDocuments | null)[] | null;
}

export interface FolderPermissions {
  worker?: string | null;
  workerId?: string | null;
  workerPhotoUrl?: string | null;
  permissions?: (FolderPermissionType | null)[] | null;
}

export interface LoaEmployeeMessage {
  employeeId?: string | null;
  fullName?: string | null;
  photoUrl?: string | null;
}

export interface LeaveOfAbsencePolicies {
  policies?: (LoaBasicMessage | null)[] | null;
}

export interface LoaBasicMessage {
  originalPolicyId?: string | null;
  policyId?: number | null;
  name?: string | null;
  reasons?: (LoaPolicyReasonMessage | null)[] | null;
  requiredDocuments?: (LoaRequiredDocumentMessage | null)[] | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  ptoAccrual?: string | null;
  numberOfRequests?: number | null;
}

export interface LoaPolicyReasonMessage {
  id?: number | null;
  loaPolicy_id?: number | null;
  name?: string | null;
  isDeleted?: boolean | null;
}

export interface LoaRequiredDocumentMessage {
  id?: number | null;
  loaPolicy_id?: number | null;
  name?: string | null;
  containsPHI?: boolean | null;
  isDeleted?: boolean | null;
}

export interface Policy {
  policyName?: string | null;
  policyType?: string | null;
  policyId?: string | null;
  isComplete?: boolean | null;
}

export interface LoaPolicyResponse {
  id?: string | null;
  originalPolicyId?: string | null;
  name?: string | null;
  companyId?: string | null;
  reasons?: (LoaReason | null)[] | null;
  ptoAccrual?: string | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  requiredDocuments?: (LoaRequiredDocument | null)[] | null;
  isStaging?: boolean | null;
  isActive?: boolean | null;
  setupState?: string | null;
  createdBy?: number | null;
  lastModifiedBy?: number | null;
  responseStatus?: boolean | null;
  responseMessage?: string | null;
}

export interface LoaReason {
  id?: string | null;
  name?: string | null;
}

export interface LoaRequiredDocument {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
}

export interface LeaveOfAbsenceRequestMessage {
  id?: string | null;
  originalLoaRequestId?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  associatedEmployee?: AllEmployee | null;
  leaveType?: string | null;
  policyId?: number | null;
  policyName?: string | null;
  reasonId?: number | null;
  loaReasonName?: string | null;
  status?: string | null;
  associatedLoaPolicy?: LoaBasicMessage | null;
  additionalNotes?: string | null;
  estimatedDates?: (DateRangeMessage | null)[] | null;
  actualDates?: (DateRangeMessage | null)[] | null;
  documentsUploaded?: (LoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (LoaVacationTypeMessage | null)[] | null;
  requiredDocuments?: (LoaRequiredDocumentMessage | null)[] | null;
  lastDayWorked?: Date | null;
  firstDayBackInOffice?: Date | null;
  paymentOption?: string | null;
  createdBy?: number | null;
  modifiedBy?: number | null;
  responseStatus?: number | null;
  responseMessage?: string | null;
  loaPermissions?: EmployeeLoaPermissions | null;
}

export interface DateRangeMessage {
  loaRequestDateId?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  leaveStatus?: string | null;
  responseStatus?: number | null;
}

export interface LoaDocumentUploaded {
  id?: string | null;
  uploadedFileId?: number | null;
  uploadedFileName?: string | null;
  uploadedFileUrl?: string | null;
  requiredDocumentId?: number | null;
  requiredDocumentType?: string | null;
}

export interface LoaVacationTypeMessage {
  loaPtoReasonId?: number | null;
  vacationTypeId?: number | null;
  name?: string | null;
  ptoId?: number | null;
}

export interface EmployeeLoaPermissions {
  employeeId?: number | null;
  canEditPHI?: boolean | null;
  canApproveLoa?: boolean | null;
  canSetupLoa?: boolean | null;
}

export interface PtoVacationTypes {
  vacationTypes?: (LoaVacationTypeMessage | null)[] | null;
}

export interface AllLoaRequestsResponse {
  status?: boolean | null;
  leaveRequests?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  loaPermissions?: EmployeeLoaPermissions | null;
}

export interface LoaRequestsForOverviewPageResponse {
  status?: boolean | null;
  noPolicy?: boolean | null;
  recentlySubmittedLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  upcomingLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  activeLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  loaPermissions?: EmployeeLoaPermissions | null;
}

export interface DeleteLoaPolicyResponse {
  loaPolicyId?: string | null;
  loaPolicyName?: string | null;
  canDeletePolicy?: boolean | null;
  responseMessage?: string | null;
  responseStatus?: string | null;
}

export interface IsNewPolicyVersionResponse {
  isNewVersion?: boolean | null;
  responseMessage?: string | null;
}

export interface TaPayPeriod {
  getStartOfFifthWeek?: string | null;
  getStartOfFourthWeek?: string | null;
  endDate?: string | null;
  taCompany?: TaCompany | null;
  startDate?: string | null;
  hasThirdWeek?: boolean | null;
  taPayPeriodEmployees?: (TaPayPeriodEmployee | null)[] | null;
  hasFifthWeek?: boolean | null;
  payPeriodLengthInWeeks?: string | null;
  getStartOfSecondWeek?: string | null;
  id?: string | null;
  pushAttempts?: number | null;
  hasFourthWeek?: boolean | null;
  _tzinfo?: string | null;
  lastReminderEvent?: string | null;
  company_id?: number | null;
  pushedAt?: DateTime | null;
  deleted?: boolean | null;
  state?: string | null;
  isApproved?: boolean | null;
  isInApprovalPhase?: boolean | null;
  getStartOfThirdWeek?: string | null;
  payType?: string | null;
  ptoProcessed?: boolean | null;
  hasSecondWeek?: boolean | null;
  estPushDate?: string | null;
  payPeriodBatchUrl?: string | null;
  runs?: (TaPayPeriodRun | null)[] | null;
  locked?: boolean | null;
  pushAt?: DateTime | null;
  submitEnd?: string | null;
  processed?: boolean | null;
  resource_uri?: string | null;
  laborFieldsEnabled?: boolean | null;
}

export interface TaCompany {
  unpaidMealBreakMinutes?: number | null;
  cancelledAt?: DateTime | null;
  isSyncSupported?: boolean | null;
  isCompanyOnZenefitsPayroll?: boolean | null;
  isUsingEligablePayroll?: boolean | null;
  contractVersion?: string | null;
  id?: string | null;
  isMissingPayFrequency?: boolean | null;
  hasPaidLunch?: boolean | null;
  billingPlan?: string | null;
  hasProjectCodes?: boolean | null;
  averageSyncDuration?: string | null;
  employeeStates?: string | null;
  paidLunchMinutes?: number | null;
  company_id?: number | null;
  isComplete?: boolean | null;
  secondMealBreakPenaltyHours?: string | null;
  state?: string | null;
  authorizingPersonName?: string | null;
  daysAfterApproval?: string | null;
  hasPto?: boolean | null;
  authorizingPersonSignature?: string | null;
  approvalHours?: number | null;
  providerSync?: boolean | null;
  projectCodes?: (TaProjectCode | null)[] | null;
  roundToMinutes?: number | null;
  canSync?: string | null;
  hasPaidHolidays?: boolean | null;
  eligibleEmployeeCount?: number | null;
  reportingMethod?: string | null;
  hasAcceptedOvertimePolicy?: boolean | null;
  hasMealBreaks?: boolean | null;
  enrolledAt?: DateTime | null;
  firstMealBreakPenaltyHours?: string | null;
  resource_uri?: string | null;
  laborFields?: (TaGroupType | null)[] | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
}

export interface TaProjectCode {
  code?: string | null;
  description?: string | null;
  taCompany?: TaCompany | null;
  state?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface TaGroupType {
  description?: string | null;
  enabled?: boolean | null;
  id?: string | null;
  name?: string | null;
  groups?: Group[] | null;
}

export interface TaPayPeriodEmployee {
  durationNoticesCount?: number | null;
  totalPtoSickHours?: string | null;
  totalPenaltyHours?: string | null;
  approvedAt?: DateTime | null;
  totalOvertimeHours?: string | null;
  totalPtoPersonalHours?: string | null;
  isReportingTkOrEt?: boolean | null;
  isSelfReportingHourly?: boolean | null;
  isSubmitted?: boolean | null;
  totalRegularHours?: string | null;
  employeeTimeApproved?: (TaTimeApprovedDay | null)[] | null;
  _totalReportedHours?: string | null;
  _totalOvertimeHours?: string | null;
  id?: string | null;
  _totalRegularHours?: string | null;
  isReporting?: boolean | null;
  employee_id?: number | null;
  employee?: AllEmployee | null;
  taEmployee?: TaEmployee | null;
  durationIssuesCount?: number | null;
  isSelfReportingInOut?: boolean | null;
  employeeTimezone?: string | null;
  state?: string | null;
  _totalDoubleOvertimeHours?: string | null;
  totalReportedHours?: string | null;
  payPeriod_id?: number | null;
  payPeriod?: TaPayPeriod | null;
  totalPtoVacationHours?: string | null;
  totalPtoHours?: string | null;
  approvedBy?: AllEmployee | null;
  isApproved?: boolean | null;
  deleted?: boolean | null;
  hasForgotClockOut?: boolean | null;
  approvedBy_id?: number | null;
  getStartOfSecondWeekDate?: string | null;
  isDirty?: boolean | null;
  _totalPtoHours?: string | null;
  totalDoubleOvertimeHours?: string | null;
  totalHolidayHours?: string | null;
  _totalHolidayHours?: string | null;
  resource_uri?: string | null;
  taPtoRequests?: JSON | null;
}

export interface TaTimeApprovedDay {
  calculateWorkedHoursForDay?: string | null;
  numberOfOvertimeHours?: string | null;
  missingAction?: string | null;
  approvedPtoForDay?: string | null;
  approvedPtoVacationHours?: string | null;
  numberOfPenaltyHours?: string | null;
  id?: string | null;
  employee_id?: number | null;
  approvedPtoSickHours?: string | null;
  hasIssueToday?: boolean | null;
  taEmployee?: TaEmployee | null;
  previousNumberOfHours?: string | null;
  employeeTimezone?: string | null;
  state?: string | null;
  calculateHoursForDay?: string | null;
  cardinalPayPeriodWeekNumber?: string | null;
  getDayOfWeekAsNumber?: string | null;
  numberOfHours?: string | null;
  timeDurations?: (TaEmployeeTimeDuration | null)[] | null;
  date?: string | null;
  approvedPtoPersonalHours?: string | null;
  resource_uri?: string | null;
  locked?: boolean | null;
  numberOfDoubleOvertimeHours?: string | null;
  numberOfHolidayHours?: string | null;
  submitted?: boolean | null;
  _numberOfHolidayHours?: string | null;
  payPeriodEmployee?: TaPayPeriodEmployee | null;
  lastModified?: DateTime | null;
  modifiedBy_id?: number | null;
  numberOfTotalHours?: string | null;
  timeReported?: (TaEmployeeTimeReported | null)[] | null;
  hasPtoToday?: boolean | null;
  isApproved?: boolean | null;
  approvedBy?: number | null;
  approvedAt?: string | null;
}

export interface TaEmployee {
  isReporting?: boolean | null;
  lastTimeReported?: string | null;
  employee_id?: number | null;
  taCompany?: TaCompany | null;
  reportingMethod?: string | null;
  lastTimeReported_is_set?: boolean | null;
  approvedBy_id?: number | null;
  lastProjectCode_id?: number | null;
  allMealBreakSettingsDefault?: boolean | null;
  taStatus?: string | null;
  overrideReportingMethod?: string | null;
  reportingMethodBeforeTermination?: string | null;
  enrolledAt?: DateTime | null;
  enrolled?: boolean | null;
  employee?: AllEmployee | null;
  resource_uri?: string | null;
  id?: string | null;
  laborFields?: (TaGroupType | null)[] | null;
  latestDuration?: TaEmployeeTimeDuration | null;
  taEmployeePto?: TaEmployeePto | null;
  isApprover?: boolean | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
  unpaidMealBreakMinutes?: number | null;
  secondMealBreakPenaltyHours?: string | null;
  firstMealBreakPenaltyHours?: string | null;
  hasMealBreaks?: boolean | null;
}

export interface TaEmployeeTimeDuration {
  notice?: string | null;
  employee_id?: number | null;
  photoUrlOut?: string | null;
  state?: string | null;
  matchingDuration?: TaEmployeeTimeDuration | null;
  resource_uri?: string | null;
  taEmployee?: TaEmployee | null;
  isNextDay?: boolean | null;
  hours?: string | null;
  photoUrlIn?: string | null;
  projectCode?: TaProjectCode | null;
  endTime?: Time | null;
  timeApproved?: TaTimeApprovedDay | null;
  startTime?: Time | null;
  activity?: string | null;
  modifiedBy_id?: number | null;
  id?: string | null;
  validStatus?: string | null;
  notes?: (TaEmployeeTimeDurationNote | null)[] | null;
  laborFields?: (number | null)[] | null;
  laborFieldsDetails?: (TaEmployeeTimeDurationGroup | null)[] | null;
  accuracyIn?: string | null;
  accuracyOut?: string | null;
  latitudeIn?: string | null;
  latitudeOut?: string | null;
  longitudeIn?: string | null;
  longitudeOut?: string | null;
  missedMealBreaksCount?: number | null;
  modifiedBy?: AllEmployee | null;
  lastModified?: DateTime | null;
  missingMandatoryFields?: boolean | null;
  hasMissingNoteFlag?: boolean | null;
}

export interface TaEmployeeTimeDurationNote {
  id?: string | null;
  content?: string | null;
  author_id?: number | null;
  createdAt?: Time | null;
  isRestricted?: boolean | null;
}

export interface TaEmployeeTimeDurationGroup {
  id?: string | null;
  name?: string | null;
  groupTypeId?: number | null;
  groupTypeName?: string | null;
}

export interface TaEmployeePto {
  companyPto_id?: number | null;
  TaEmployerPto?: TaEmployerPto | null;
}

export interface TaEmployerPto {
  vacationTypes?: (number | null)[] | null;
  vacationTypeDetails?: (TaVacationType | null)[] | null;
  name?: string | null;
  status?: string | null;
}

export interface TaVacationType {
  countsAs?: string | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  name?: string | null;
  order?: number | null;
  pto_id?: number | null;
  status?: string | null;
  id?: string | null;
}

export interface TaEmployeeTimeReported {
  employee_id?: number | null;
  lastModified?: DateTime | null;
  taEmployee?: TaEmployee | null;
  id?: string | null;
  employeeTimezone?: string | null;
  isOverride?: boolean | null;
  timeAction?: string | null;
  timeApproved?: TaTimeApprovedDay | null;
  time?: DateTime | null;
  timeZone?: string | null;
  modifiedBy_id?: number | null;
  facePicUrl?: string | null;
  resource_uri?: string | null;
}

export interface TaPayPeriodRun {
  totalHours?: string | null;
  payPeriod?: TaPayPeriod | null;
  endedAt?: DateTime | null;
  holidayHours?: string | null;
  errorDetails?: string | null;
  syncDuration?: string | null;
  overtimeHours?: string | null;
  formattedErrorDetails?: string | null;
  id?: string | null;
  downloadUrl?: string | null;
  state?: string | null;
  reportPath?: string | null;
  ptoHours?: string | null;
  doubleOvertimeHours?: string | null;
  totalEmployees?: string | null;
  regularHours?: string | null;
  createdAt?: DateTime | null;
  resource_uri?: string | null;
}

export interface TaCompanyNotificationSettings {
  notificationEnabled?: boolean | null;
  notificationFrequency?: string | null;
  notificationFrequencyDisplayString?: string | null;
  pushTime?: string | null;
}

export interface EmployeeWithTaEmployeeId {
  employee_id?: string | null;
}

export interface TaCompanyWithCompanyId {
  id?: string | null;
}

export interface TaCalculateHoursAndAvailability {
  hours?: number | null;
  startAvailable?: number | null;
  endAvailable?: number | null;
  startYearLeft?: number | null;
}

export interface AdherenceReportFormData {
  departments?: (AdherenceReportDepartment | null)[] | null;
  locations?: (AdherenceReportLocation | null)[] | null;
}

export interface AdherenceReportDepartment {
  id?: string | null;
  department?: string | null;
}

export interface AdherenceReportLocation {
  id?: string | null;
  location?: string | null;
}

export interface SchedulingCompany {
  id: string;
  company?: Company | null;
  startDayOfWeek: number;
  flags: number[];
  state: string;
}

export interface SchedulingEmployee {
  id: string;
  enrolled: boolean;
  firstName: string;
  lastName: string;
  photoUrl?: string | null;
  roles: string[];
  positions: string[];
  shifts: SchedulingShift[];
  vacationRequests?: (SchedulingVacationRequest | null)[] | null;
  employee?: AllEmployee | null;
}

export interface SchedulingShift {
  id?: string | null;
  shiftId?: string | null;
  position?: string | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  isPublished?: boolean | null;
  schedulingEmployee?: SchedulingEmployee | null;
  warningIds?: (string | null)[] | null;
  groups?: (SchedulingShiftGroup | null)[] | null;
  status?: string | null;
  shiftType?: number | null;
  seriesData?: SchedulingShiftSeries | null;
  createdAt?: Date | null;
}

export interface SchedulingShiftGroup {
  groupId?: string | null;
  groupTypeId?: string | null;
  id?: string | null;
  shiftId?: string | null;
}

export interface SchedulingShiftSeries {
  id?: number | null;
  shiftId?: number | null;
  frequency?: number | null;
  onDays?: (number | null)[] | null;
  rangeEndDate?: Date | null;
}

export interface SchedulingVacationRequest {
  date?: Date | null;
  isFullDay?: boolean | null;
  status?: string | null;
  hours?: number | null;
  minutes?: number | null;
}

export interface SchedulingEmployeeAvailability {
  id?: string | null;
  objId?: string | null;
  schedulingEmployee?: SchedulingEmployee | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  recurringAvailability?: boolean | null;
  originalObjId?: string | null;
  availabilityEndDate?: Date | null;
  onDays?: (number | null)[] | null;
  frequency?: number | null;
  notes?: string | null;
  createdAt?: Date | null;
}

export interface SchedulingPermission {
  canAddPositions: boolean;
  isSchedulingAdmin: boolean;
}

export interface SchedulingOverview {
  schedulingCompany: SchedulingCompany;
  schedulingEmployees: SchedulingEmployee[];
}

export interface VacationRequest {
  status?: string | null;
  startDate?: string | null;
  employee?: AllEmployee | null;
  endDate?: string | null;
  isPostedOrPast?: boolean | null;
  isHalfDay?: boolean | null;
  reason?: string | null;
  isPartialDay?: boolean | null;
  hours?: string | null;
  denyReason?: string | null;
  creator?: AllEmployee | null;
  isManagerRecorded?: boolean | null;
  createdDate?: string | null;
  vacationType?: VacationType | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface VacationType {
  status?: string | null;
  pto?: EmployerPto | null;
  countsAs: string;
  name: string;
  order?: number | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  id: string;
  resource_uri?: string | null;
}

export interface EmployerPto {
  blockEmailTrigger?: boolean | null;
  quickChangePolicies?: boolean | null;
  isTenureProrated?: boolean | null;
  accrualPeriod?: string | null;
  companyAnchorStartDate?: DateTime | null;
  knowsEmployeePayFrequency?: string | null;
  isVacationMigrated?: boolean | null;
  switchEmployeesToPto?: EmployerPto | null;
  oldYtdSetupUrl?: string | null;
  negativeBalancePolicy?: string | null;
  ptoUploadTimestamp?: DateTime | null;
  accounts?: (EmployerPtoAccount | null)[] | null;
  existingDataUrl?: string | null;
  payInAdvance?: boolean | null;
  defaultPersonalHours?: string | null;
  importEffectiveDate?: string | null;
  needsAnchorDate?: string | null;
  id?: string | null;
  defaultWorkdayHours?: string | null;
  dataParsingStatus?: string | null;
  importYtdData?: string | null;
  company?: Company | null;
  hasChosenEmployees?: boolean | null;
  defaultHourlyAccrualRate?: string | null;
  isExistingDataParsed?: boolean | null;
  isSickLeaveProrated?: boolean | null;
  isSecondaryComplete?: boolean | null;
  timeAttendancePolicy?: string | null;
  defaultWorkdays?: number | null;
  status?: string | null;
  setupCanHourlyReaccrue?: boolean | null;
  isImportSkipped?: boolean | null;
  hasLegacySickLeaveFloors?: boolean | null;
  negativeBalanceThreshold?: string | null;
  isAdvancedComplete?: boolean | null;
  defaultSickHours?: string | null;
  isVacationTypesComplete?: boolean | null;
  newEmployeePtos?: (EmployeePto | null)[] | null;
  defaultAccrualRate?: string | null;
  vacationCap?: string | null;
  setupProgress?: string | null;
  toTrackTime?: string | null;
  holidays?: (CompanyHolidayCalendar | null)[] | null;
  changeCalendarWarningYear?: number | null;
  allowPersonalLeave?: string | null;
  name?: string | null;
  anchorStartDate?: string | null;
  allowSickLeave?: string | null;
  isSickPersonalMigrated?: boolean | null;
  employeePtos?: (EmployeePto | null)[] | null;
  vacationTypes?: (VacationType | null)[] | null;
  employeePayFrequencies?: JSON | null;
  carryoverThreshold?: string | null;
  carryoverPolicy?: string | null;
  isCompanyTimeAttendanceComplete?: boolean | null;
  isCompanyZPComplete?: boolean | null;
  resource_uri?: string | null;
}

export interface EmployerPtoAccount {
  previousIsYearHireDateBased?: string | null;
  isTenureProrated?: boolean | null;
  accrualPeriod?: string | null;
  hasTenureLevels?: boolean | null;
  isHourlyAccrued?: boolean | null;
  yearStartDate?: string | null;
  id?: string | null;
  isYearHireDateBased?: boolean | null;
  maximumNegativeBalance?: string | null;
  previousAccrualPeriod?: string | null;
  waitingPeriodDays?: number | null;
  companyPto?: EmployerPto | null;
  isSecondaryComplete?: boolean | null;
  isAnnuallyReset?: boolean | null;
  type?: string | null;
  minimumIncrementHours?: string | null;
  isTrackedUnlimitedAllowed?: boolean | null;
  anchorStartDate?: string | null;
  isTracked?: boolean | null;
  previousYearStartDate?: string | null;
  tenureLevels?: (PtoTenureLevel | null)[] | null;
  defaultAccrualRate?: string | null;
  rolloverCap?: string | null;
  setupProgress?: string | null;
  accountTemplate?: TemplateEmployerPtoAccount | null;
  isPaidInAdvance?: boolean | null;
  hourlyAccrualPeriod?: string | null;
  name?: string | null;
  previousIsTrackedAndAvailable?: string | null;
  resetAccrual?: boolean | null;
  isHireDateProrated?: boolean | null;
  balanceCap?: string | null;
  accrualCap?: string | null;
  usageCap?: string | null;
  isAvailable?: boolean | null;
  accrueWaitingPeriod?: boolean | null;
  resource_uri?: string | null;
  hourlyAccrualRateHoursWorkedDivisor?: number | null;
  defaultAccrualRateDaysPerYearOrNA?: string | null;
  hourlyAccrualHours?: number | null;
}

export interface PtoTenureLevel {
  extraHourlyAccrual?: string | null;
  pto?: EmployerPto | null;
  account?: EmployerPtoAccount | null;
  years?: number | null;
  extraAccrual?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  extraAccrualDays?: string | null;
  extraHourlyAccrualHours?: string | null;
}

export interface TemplateEmployerPtoAccount {
  isTrackedUnlimitedAllowed?: boolean | null;
  isTracked?: boolean | null;
  accrualPeriod?: string | null;
  templateCode?: string | null;
  defaultAccrualRate?: string | null;
  yearStartDate?: string | null;
  isAvailable?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  name?: string | null;
}

export interface EmployeePto {
  workdayHours?: string | null;
  isVacationMigrated?: boolean | null;
  employee?: AllEmployee | null;
  isSickPersonalMigrated?: boolean | null;
  newCompanyPto?: EmployerPto | null;
  policyChangeTrigger?: string | null;
  isPtoManager?: boolean | null;
  isDataImported?: boolean | null;
  employmentTypeChangeDate?: string | null;
  newPersonalLeaveYTD?: string | null;
  companyPto?: EmployerPto | null;
  accounts?: (EmployeePtoAccount | null)[] | null;
  canHourlyReaccrue?: boolean | null;
  updateEffectiveHireDate?: boolean | null;
  newSickLeaveYTD?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  newWorkdayHours?: string | null;
  effectiveHireDateWithEmploymentStatusChange?: Date | null;
  actualWorkdayHours?: string | null;
}

export interface EmployeePtoAccount {
  isAvailable?: boolean | null;
  isTracked?: boolean | null;
  isTrackedAndAvailable?: boolean | null;
  isLimited?: boolean | null;
  isUnlimited?: boolean | null;
  isStagingAccount?: boolean | null;
  accruedUntil?: Date | null;
  useable?: string | null;
  useableOrNA?: string | null;
  pto?: EmployeePto | null;
  resource_uri?: string | null;
  lastCheckedRollover?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  tenureAccrualBonus?: string | null;
  hourlyAccrualEligibleDate?: string | null;
  hoursUsedYTD?: string | null;
  scheduledHours?: string | null;
  balance?: string | null;
  balanceOrNA?: string | null;
  accrualRate?: string | null;
  accrualFrequency?: string | null;
  isPaidInAdvance?: boolean | null;
  type?: string | null;
  id?: string | null;
  startFromHireDate?: boolean | null;
  liability?: number | null;
  isHourlyAccrued?: boolean | null;
  accrualRateDaysPerYearOrNA?: string | null;
  tenureAccrualBonusDaysPerYearOrNA?: string | null;
  hourlyAccrualHours?: number | null;
  hourlyAccrualRateHoursWorkedDivisor?: number | null;
  tenureBonusHourlyAccrualHours?: number | null;
  defaultWorkdayHours?: number | null;
}

export interface CompanyHolidayCalendar {
  startDate?: string | null;
  endDate?: string | null;
  name?: string | null;
  companyPto?: EmployerPto | null;
  type?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface CalculateHoursAndAvailability {
  hours?: number | null;
  startAvailable?: number | null;
  endAvailable?: number | null;
  startYearLeft?: number | null;
}

export interface BalanceHistoryItem {
  id?: string | null;
  employeeId?: number | null;
  amount?: number | null;
  balance?: number | null;
  type?: string | null;
  description?: string | null;
  postTime?: DateTime | null;
  isPosted?: boolean | null;
  scheduledDate?: Date | null;
  date?: Date | null;
  isScheduled?: boolean | null;
  reason?: string | null;
  status?: string | null;
  vacationRequest?: VacationRequest | null;
  year?: number | null;
  accountType?: string | null;
  periodStartDate?: Date | null;
  periodEndDate?: Date | null;
  liability?: number | null;
  hours?: number | null;
  blameName?: string | null;
  accountName?: string | null;
  vacationStartDate?: Date | null;
  vacationEndDate?: Date | null;
  vacationStartDateAsMoment?: Date | null;
  vacationEndDateAsMoment?: Date | null;
  ptoId?: number | null;
}

export interface PaymentAuthorizationDetails {
  authorizationRequired?: PaymentAuthorizationType | null;
}

export interface PayrollReports {
  payrollRegister?: PayrollRegisterReport | null;
}

export interface PayrollRegisterReport {
  id: string;
  summary?: PayrollRegisterReportSummary | null;
  amounts?: PayrollRegisterReportAmounts | null;
  payruns?: (PayrollRegisterReportPayrun | null)[] | null;
}

export interface PayrollRegisterReportSummary {
  id: string;
  netAmount?: Currency | null;
  EETaxes?: Currency | null;
  Deductions?: Currency | null;
  TotalPay?: Currency | null;
  ERTaxes?: Currency | null;
  ERContributions?: Currency | null;
  TotalCost?: Currency | null;
}

export interface PayrollRegisterReportAmounts {
  totalCost?: Currency | null;
  EmployerContribution?: Currency | null;
  EmployerTaxes?: Currency | null;
  GrossPay?: Currency | null;
  TotalDeductions?: Currency | null;
  TaxesWithheld?: Currency | null;
  TotalHours?: Currency | null;
  NetAmount?: Currency | null;
}

export interface PayrollRegisterReportPayrun {
  amounts?: PayrollRegisterReportAmounts | null;
  departments?: (PayrollRegisterReportDepartment | null)[] | null;
}

export interface PayrollRegisterReportDepartment {
  amounts?: PayrollRegisterReportAmounts | null;
  paychecks?: (PayrollRegisterReportPayChecks | null)[] | null;
}

export interface PayrollRegisterReportPayChecks {
  checkDate?: Date | null;
  amounts?: PayrollRegisterReportAmounts | null;
  paymentMethod?: string | null;
  employeeName?: string | null;
  location?: string | null;
}

export interface PayrollReportsEmployee {
  id?: string | null;
  includeInRuns?: boolean | null;
  employee?: AllEmployee | null;
}

export interface TaxPackage {
  startDate?: string | null;
  endDate?: string | null;
}

export interface W2Preview {
  name?: string | null;
  id?: string | null;
}

export interface PlaidEnvironment {
  plaid_env: string;
  plaid_key: string;
}

export interface BankAccountInfo {
  bankName: string;
  accountNumber: string;
}

export interface GLMappingTemplateInfo {
  companyId?: string | null;
  availableColumns?: (GLMappingColumn | null)[] | null;
  orderedActiveColumns?: (GLMappingColumn | null)[] | null;
}

export interface GLMappingColumn {
  columnName?: string | null;
  columnCode?: string | null;
}

export interface GLAccountMappingTemplateInfo {
  companyId?: string | null;
  accountMappingInfo?: AccountMappingInfo | null;
  orderedActiveColumns?: (string | null)[] | null;
  earnings?: (Categories | null)[] | null;
  deductions?: (Categories | null)[] | null;
  contributions?: (Categories | null)[] | null;
  taxes?: (Categories | null)[] | null;
  isMappingCompleted: boolean;
}

export interface AccountMappingInfo {
  earnings: (CategoryMapping | null)[];
  deductions: (CategoryMapping | null)[];
  contributions: (CategoryMapping | null)[];
  taxes: (CategoryMapping | null)[];
}

export interface CategoryMapping {
  categoryName?: string | null;
  accountingAccount?: AccountInfo | null;
  includedFields?: (GLMappingColumn | null)[] | null;
}
/** schema generated using server commit 96287800e99b90f117113c19e09308201d6a73bf */
export interface AccountInfo {
  id?: number | null;
  accountNumber?: string | null;
  subAccountNumber?: string | null;
  accountType?: string | null;
}

export interface Categories {
  categoryName?: string | null;
  displayName?: string | null;
}

export interface GLReportDownloadOptions {
  reportTypes?: (OptionsType | null)[] | null;
  reportFormats?: (OptionsType | null)[] | null;
  payRuns?: (OptionsType | null)[] | null;
  fileTypes?: (OptionsType | null)[] | null;
}

export interface OptionsType {
  value?: string | null;
  label?: string | null;
}

export interface LaborGroupTypesResponse {
  companyId: string;
  hasLaborGroupTypesSelected: boolean;
  laborGroupTypes?: (LaborGroupType | null)[] | null;
  status?: string | null;
}

export interface LaborGroupType {
  id: number;
  name?: string | null;
  isSystemType: boolean;
}

export interface ZPCompanyPayTypesResponse {
  companyId: string;
  payTypes?: (ZPCompanyPayType | null)[] | null;
  status?: string | null;
}

export interface ZPCompanyPayType {
  id?: string | null;
  name?: string | null;
  payTypeCategory?: string | null;
  garnishmentCategory?: string | null;
}

export interface ZPCompanyLaborGroupDetailsResponse {
  companyId: string;
  laborGroupDetails?: (LaborGroupDetail | null)[] | null;
  status?: string | null;
}

export interface LaborGroupDetail {
  id?: string | null;
  name?: string | null;
  laborCode?: string | null;
  groupTypeId?: number | null;
  isActive?: boolean | null;
}

export interface ZPAccountingAccountResponse {
  companyId: string;
  accountingAccounts?: (ZPAccountingAccount | null)[] | null;
  status?: string | null;
}

export interface ZPAccountingAccount {
  id?: string | null;
  name?: string | null;
  accountID?: string | null;
  accountName?: string | null;
  accountNumber?: string | null;
  accountType?: string | null;
  description?: string | null;
  subAccountNumber?: string | null;
  isReferenceBankAccount?: boolean | null;
}

export interface RefreshAccountingAccounts {
  status?: string | null;
}

export interface classTrackingOptions {
  classTrackingOptions?: (ClassTrackingOption | null)[] | null;
}

export interface ClassTrackingOption {
  id?: string | null;
  className?: string | null;
}

export interface ZPAccountingMappingResponse {
  mappings?: (MappingResponse | null)[] | null;
  has_missing_mappings: boolean;
}

export interface MappingResponse {
  payCategoryType?: string | null;
  payTypes?: (PayTypeResponse | null)[] | null;
  departmentIds?: (string | null)[] | null;
  locationIds?: (string | null)[] | null;
  positionIds?: (string | null)[] | null;
  projectIds?: (string | null)[] | null;
  customLaborGroupIds?: (string | null)[] | null;
  accountMappingGroup_id?: string | null;
  debitAccount_id?: string | null;
  creditAccount_id?: string | null;
  classTracking_id?: string | null;
  state?: string | null;
}

export interface PayTypeResponse {
  payTypeId?: string | null;
  garnishmentCategory?: string | null;
}

export interface F01kAuditLog {
  companyId: number;
  dataFileUrl?: string | null;
  fileType: string;
  workFlow: string;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  checkDate?: DateTime | null;
  payRunNames: string[];
  createTimeStamp: DateTime;
  createdBy: string;
  status: string;
  fileExtension: string;
  message?: JSON | null;
}

export interface OmniSearchSuggestion {
  employees: OmniSearchEmployees;
  actions: OmniSearchActions;
  help: OmniSearchHelps;
}

export interface OmniSearchEmployees {
  items: OmniSearchEmployeeItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchEmployeeItem {
  id?: string | null;
  company?: string | null;
  companyId?: string | null;
  email?: string | null;
  employmentType?: EmployeeType | null;
  enrollmentStatus?: string | null;
  first_name?: string | null;
  isReal?: boolean | null;
  last_name?: string | null;
  name?: string | null;
  phone?: string | null;
  preferredName?: string | null;
  preferred_name?: string | null;
  startDate?: string | null;
  status?: EmployeeStatus | null;
}

export interface OmniSearchActions {
  items: OmniSearchActionItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchActionItem {
  id?: string | null;
  link?: string | null;
  title?: string | null;
  zAppId?: string | null;
}

export interface OmniSearchHelps {
  items: OmniSearchHelpItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchHelpItem {
  link?: string | null;
  title?: string | null;
  category?: string | null;
  highlight?: string | null;
}

export interface ActivationInfo {
  app?: string | null;
  sha?: string | null;
  id?: string | null;
  user?: string | null;
  timeStamp?: string | null;
  originalBranch?: string | null;
  originalCreator?: string | null;
  originalTimeStamp?: string | null;
  pullRequest?: string | null;
}

export interface ActivationCandidate {
  sha: string;
  date: string;
  app?: string | null;
  title?: string | null;
}

export interface CompanyHubPermission {
  canRunReports: boolean;
}

export interface CompanyHubCompany {
  companyId: string;
  employeeId: number;
  name: string;
  title?: string | null;
  logoUrl?: string | null;
  totalWorkerCount?: string | null;
  tasksCount?: string | null;
  isPrimaryAccount: boolean;
  isAdmin?: boolean | null;
  adminRoles: AdminRole[];
  location?: string | null;
  manager?: string | null;
  companyGroup?: CompanyGroup | null;
}

export interface AdminRole {
  role: string;
}

export interface CompanyGroup {
  id: string /** group id */;
  name: string;
}

export interface CompanyHubUserMetaData {
  adminView: boolean;
}

export interface MultiOrgCustomReportMetaData {
  id?: string | null;
  userEmail?: string | null;
  name?: string | null;
  family?: string | null;
  schedule?: string | null;
  includedCustomReportIds?: (number | null)[] | null;
  includedCompanies?: (BasicCompanyInfo | null)[] | null;
  companiesCount?: number | null;
  state?: ReportState | null;
}

export interface BasicCompanyInfo {
  companyName?: string | null;
  logoUrl?: string | null;
}

export interface ReportState {
  runnable?: boolean | null;
  downloadable?: boolean | null;
}

export interface MultiOrgReport {
  queryResultSet: JSON;
}
/** TODO: expose the rest of fields when needed. */
export interface ActiveBillingDetails {
  version: number;
  system_version: number;
  renewalDate?: DateTime | null;
  numberOfDaysBeforeRenewal?: number | null;
  v2_plan_details?: BillingPlanDetails | null;
  planDetails?: BillingPlanDetails | null;
  optionalProducts?: (BillingPlanDetails | null)[] | null;
  isRenewingContract?: boolean | null;
}

export interface BillingPlanDetails {
  displayName?: string | null;
  name?: string | null;
  contractLength?: number | null;
  numSeats?: number | null;
}

export interface DelinquentPaymentInfo {
  mainAdminEmail?: string | null;
  paymentFailureReason?: string | null;
  possibleSolutions?: string | null;
}

export interface Datagrid {
  id: string;
  category: string /** File category used for uploading file */;
  columnConfiguration: ColumnConfiguration[] /** A list of supported columns */;
  columnMappings: DatagridColumnMapping[] /** An array of column mappings and value mappings */;
  importedRows: ImportedRow[] /** Data from imported file */;
  previousUrl?: string | null /** a previous url for current user */;
  uploadedFileKey?: string | null /** secure file key for the uploaded file */;
  rows: DatagridRow[] /** data for editable table and review */;
  status: DatagridStatus;
  scenario: DatagridScenario;
  isUploadedFileTemplate: boolean;
  templateUrl: string;
  copyMeta: JSON;
  returnUrl: string;
  isSubmitted: boolean;
  features: JSON;
}

export interface ColumnConfiguration {
  id: string;
  label: string;
  type: DatagridColumnType;
  values: ColumnConfigValue[] /** Predefined values for a columnIf columnIds are present, only return results for these columns */;
  category: DatagridColumnCategory;
  width?: number | null;
  subCategory?: string | null;
  columnMappingPlaceholder?: string | null;
  valuePlaceholder?: string | null;
  description?: string | null;
  fixed: boolean;
  validations: DatagridColumnValidation[];
  readOnly: boolean;
}

export interface ColumnConfigValue {
  value?: string | null;
  label: string;
}

export interface DatagridColumnCategory {
  key: string;
  label: string;
}

export interface DatagridColumnValidation {
  key: string;
  type: string;
  message: string;
  meta?: JSON | null;
}

export interface DatagridColumnMapping {
  systemColumnId: string;
  importedColumnName: string;
  valueMappings: DatagridValueMapping[];
}

export interface DatagridValueMapping {
  systemValue?: string | null;
  importedValues: string[];
}

export interface ImportedRow {
  data: ImportedCell[];
}

export interface ImportedCell {
  importedColumnName: string;
  importedValue: string /** If a cell is empty in the imported file, the imported value will be an empty string */;
}

export interface DatagridRow {
  id: string;
  datagridId: string;
  data: JSON;
  contentId?:
    | string
    | null /** contentId is an optional field on each row that could hold the primary key of the record we are keeping on the rowE.g. in company onboarding, contentId will be the employeeId. This will be useful for fetching records using employeeIDs in O(1) instead of going through all the rows and compare employeeIds. */;
  errors?: JSON | null /** errors */;
  updatedAt?: DateTime | null;
  createdAt?: DateTime | null;
}

export interface DatagridSubmissionStatus {
  title: string;
  body: string;
  primaryOption: DatagridSubmissionStatusOption;
  secondaryOption?: DatagridSubmissionStatusOption | null;
  key: string /** used to map to correct component on the UI */;
  metadata?: JSON | null /** metadata to pass in that is usecase specific */;
}

export interface DatagridSubmissionStatusOption {
  text: string;
  action: DatagridSubmissionStatusOptionAction;
  link?: string | null /** If link exists, redirect user after action is done */;
}

export interface GenericAgreement {
  id?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  userId?: number | null;
  agreement?: string | null;
  version?: string | null;
  signTime?: string | null;
  verboseName?: string | null;
  agreementUrl?: string | null;
}

export interface ResourceCenter {
  resourceCenterOffers: ResourceCenterOffer[];
  resourceCenterOfferCategories: ResourceCenterOfferCategory[];
}

export interface ResourceCenterOffer {
  id: string;
  category: ResourceCenterOfferCategory;
  vendor: ResourceCenterVendor;
  isClaimed: boolean;
  isActive: boolean;
  description: string;
  offerTagline: string /** e.g. 20% off introductory fee */;
}

export interface ResourceCenterOfferCategory {
  id: string;
  title: string;
}

export interface ResourceCenterVendor {
  id: string;
  name: string /** e.g. Cleo */;
  description: string /** e.g. Cleo is a family support system that employers offer to working parents -from preconception, through pregnancy, and parenting of kids to age five. */;
  logoUrl: string /** URI to a low-res web optimized image in jpeg/png designed to fit a 180x100 rectangle */;
}

export interface EmployeeLockboxData {
  employeeId: string;
  firstName?: string | null /** The following attributes are the union of all the usecases fields. */;
  lastName?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  fullName?: string | null;
  middleName?: string | null;
  preferredName?: string | null;
  dob?: Date | null;
  socialSecurity?: string | null;
  socialSecurityEnc?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  email?: string | null;
  workEmail?: string | null;
  phone?: string | null;
  photoUrl?: string | null;
  address?: string | null;
}

export interface DeletionRequestGroup {
  id: string;
  userId: string;
  status: string;
  requestedAt: Date;
  verifiedAt: Date;
  deleteAt: Date;
  salesforceCase?: string | null;
  note?: string | null;
  deletionRequests: DeletionRequest[];
}

export interface DeletionRequest {
  id: string;
  employee: AllEmployee;
  company: Company;
}

export interface WPFilterOptions {
  companyId?: (string | null)[] | null;
  zappId?: (string | null)[] | null;
  eventType?: (string | null)[] | null;
  deliveryStatus?: (string | null)[] | null;
}

export interface WPElasticSearchResult {
  id: string;
  score?: number | null;
  index?: string | null;
  type?: string | null;
  source: WebhookMessage;
}

export interface WebhookMessage {
  error?: string | null;
  subscriptionType?: string | null;
  deliveredAt?: string | null;
  zeventId?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  zappId?: string | null;
  zappTitle?: string | null;
  eventType?: string | null;
  messageId?: string | null;
  payload?: string | null;
  createdAt?: string | null;
  deliveryStatus?: string | null;
  deliveryStatusCode?: number | null;
}

export interface PriorPayrollUploadDetails {
  id: string;
  fileName: string;
  fileUrl: string;
  uploadedBy: string;
  uploadDate: Date;
  lastAction: string;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  isYTD?: boolean | null;
  useLiveReader?: boolean | null;
  zPriorPayrollDatagrids: ZPriorPayrollDatagrid[];
}

export interface ZPriorPayrollDatagrid {
  id: string;
  checkDate: Date;
  datagridId: string;
  zPayrollRunId?: string | null;
  lastModifiedBy: string;
  lastModifiedAt: DateTime;
  lastAction?: string | null;
  lastActionStatus?: string | null;
  validations: JSON[];
  status: string;
  datagridStatus: string;
  zPayrollRunStatus: string;
}
/** used in datagrid view in PPB design */
export interface GetPriorPayrollUploadById {
  found: boolean;
  details?: PriorPayrollUploadDetails | null;
}

export interface HasZywaveAgreementResult {
  hasAgreement?: boolean | null;
}

export interface IsProvisioningEnabledValue {
  isEnabled?: boolean | null;
}

export interface SSOSettings {
  isEnabled?: boolean | null;
  metadataXML?: string | null;
  isSetupInProgress?: boolean | null;
  zappId?: string | null;
  zappTitle?: string | null;
  clientId?: string | null;
}

export interface TaxLiabilitiesDetails {
  quarterName: string;
  taxDetails: TaxDetails[];
}

export interface TaxDetails {
  id: string;
  ledgerIds: string[];
  taxName: string;
  taxType: string;
  checkDate: string;
  responsibility: string;
  taxFrequency?: string | null;
  totalSubjectWages?: string | null;
  totalTaxAmount?: string | null;
  zenefitsWillDeposit?: string | null;
}

export interface Get1099FilingDatesDefault {
  openDate: Date;
  closeDate: Date;
}

export interface OpsTeamEmailList {
  emailList: string[];
}

export interface CompanyGroupData {
  companyGroups: (CompanyGroup | null)[];
  companiesWithoutGroup: (CompanyRawData | null)[];
}

export interface CompanyRawData {
  id: string;
  name: string;
}

export interface WorkerDocumentGetFoldersResponse {
  status?: number | null;
  zenefitsGeneratedFolders?: (WorkerDocumentFolder | null)[] | null;
  adminCreatedfolders?: (WorkerDocumentFolder | null)[] | null;
}

export interface WorkerDocumentFolder {
  folderId?: number | null;
  tabId?: number | null;
  subType?: string | null;
  tabName?: string | null;
  documentCount?: number | null;
  isAdminCreated?: boolean | null;
  viewType?: string | null;
  companyId?: number | null;
  createdBy?: number | null;
  createdByName?: string | null;
  createdOn?: Date | null;
  lastModifiedBy?: number | null;
  lastModifiedByName?: string | null;
  lastModifiedOn?: Date | null;
  ruleMetadata?: JSON | null;
  sharedWithGroupId?: number | null;
  specificEmployeesGroupId?: number | null;
}

export interface WorkerDocumentGetFilesResponse {
  status?: number | null;
  documentData?: JSON | null;
}

export interface AggregatedPlanSummary {
  lineOfCoverage?: string | null;
  averageAge?: string | null;
  numberOfPlans?: number | null;
  averagePremium?: number | null;
  minPremium?: number | null;
  maxPremium?: number | null;
}

export interface PlanInfo {
  id: string;
  lineOfCoverage: string;
  carrierId?: number | null;
  planProperties?: PlanProperties | null;
  premiums?: PlanPremiumInfo[] | null;
}

export interface MedicalPlanDetails extends PlanDetails {
  id: string;
  name: string;
  lineOfCoverage?: string | null;
  carrierId?: number | null;
  HMOPPO?: string | null;
  planUrl?: string | null;
  deductibleIndividual?: string | null;
  deductibleFamily?: string | null;
  oopMaxIndividual?: string | null;
  oopMaxFamily?: string | null;
  coPay?: string | null;
  rxCoPayBrandRetail?: string | null;
  rxCoPayGenericRetail?: string | null;
  rxCoPayNonFormularyRetail?: string | null;
  hospitalOutpatient?: string | null;
  urgentCare?: string | null;
  emergencyService?: string | null;
  hospitalInpatient?: string | null;
  maxDays?: string | null;
  specialistCoPay?: string | null;
  coInsurance?: string | null;
  outOfNetworkCoInsurance?: string | null;
  oopMaxIncludeDeductible?: string | null;
  HSA?: boolean | null;
  HRA?: boolean | null;
}

export interface DentalPlanDetails extends PlanDetails {
  id: string;
  name: string;
  lineOfCoverage?: string | null;
  HMOPPO?: string | null;
  carrierId?: number | null;
  planUrl?: string | null;
  deductibleFamily?: string | null;
  deductibleIndividual?: string | null;
  maxBenefits?: string | null;
  benefitFeeStructure?: string | null;
  coInsuanceBasic?: string | null;
  coInsurancePreventative?: string | null;
  coInsuranceEndo?: string | null;
  coInsurancePerio?: string | null;
  coInsuranceMajor?: string | null;
  orthoCoverage?: string | null;
  orthoDeductible?: string | null;
  coInsuranceOrtho?: string | null;
  coInsuranceOrthoChild?: string | null;
  orthoMaxAge?: string | null;
  orthoMaxBenefits?: string | null;
  participationRules?: PlanParticipationRule[] | null;
}

export interface PlanParticipationRule {
  minContribution?: number | null;
  maxContribution?: number | null;
  minParticipation?: number | null;
  maxParticipation?: number | null;
  requiredParticipation?: number | null;
  rateFactor?: number | null;
  contributionFactor?: number | null;
  participationFactor?: number | null;
}

export interface VisionPlanDetails extends PlanDetails {
  id: string;
  name: string;
  carrierId?: number | null;
  lineOfCoverage?: string | null;
  HMOPPO?: string | null;
  planUrl?: string | null;
  examFrequency?: string | null;
  frameAllowable?: string | null;
  contactsFrequency?: string | null;
  coPay?: string | null;
  lensFrequency?: string | null;
  lensAllowable?: string | null;
  frameFrequency?: string | null;
  contactsAllowable?: string | null;
  lasikCoverage?: string | null;
  participationRules?: PlanParticipationRule[] | null;
}

export interface PlanPremiumInfo {
  id: string;
  planId: number;
  lineOfCoverage: string;
  employeeCost: number;
  dependentsCost?: number | null;
}

export interface CarrierInfo {
  id: string;
  displayName: string;
  logoUrl?: string | null;
}

export interface Switches {
  id?: string | null;
  key?: string | null;
  version?: number | null;
  state?: number | null;
  ownerId?: number | null;
  percent?: number | null;
  sampling?: number | null;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
  updatedAt?: DateTime | null;
  createdAt?: DateTime | null;
}

export interface SearchSwitchByKeyResponse {
  resultCount?: number | null;
  results?: Switches[] | null;
}

export interface PutLog {
  id?: string | null;
  switchId?: number | null;
  userId?: number | null;
  payload?: JSON | null;
  createdAt?: DateTime | null;
}

export interface TwoFactorSettings {
  settingType?: string | null;
}

export interface Mutation {
  taMutationEmployeeTimeDuration?: TaEmployeeTimeDuration | null /** Time and Attendence mutations */;
  deleteTaTimeDuration?: boolean | null;
  taApproveTimeApprovedDay?: ApproveTimeApprovedDayResponse | null;
  taCompanyNotificationSettingsSaveMutation?: TaCompanyNotificationSettingsSave | null;
  taEmployeeTimeDurationNoteMutation?: TaEmployeeTimeDurationNote | null;
  taVacationRequestMutation?: TaVacationDataDetails | null;
  taPayPeriodEmployeeMutation?: TaPayPeriodEmployee | null;
  taCompanyMutation?: TaCompany | null;
  taEmployeeMutation?: TaEmployee | null;
  recalculateContribution?: BenefitsTransaction | null /** BenConnect mutations */;
  recalculateCost?: BenefitsTransaction | null;
  enrollEmployee?: BenefitsTransaction | null;
  declineEmployee?: BenefitsTransaction | null;
  editEmployeeEligibility?: EditEmployeeEligibilityResponse | null;
  syncEmployeeEnrollmentFlow?: Flow | null;
  initEmployeeEnrollmentFlow?: Flow | null;
  updateDependent?: Dependent | null;
  deleteDependent?: DeleteDependentResponse | null;
  updateBeneficiary?: Beneficiary | null;
  updateAddress?: Address | null;
  updateEmployee?: AllEmployee | null;
  updatePersonalInfo?: PersonalInfo | null;
  deactivateBeneficiary?: boolean | null;
  startEmployeeHsaSetUp?: boolean | null;
  resetEmployeeHsa?: boolean | null;
  hsaEnrollEmployeeWithoutUpdatingState?: boolean | null;
  hsaEnrollEmployee?: boolean | null;
  employeeSignEnrollment?: boolean | null;
  createDependentRecord?: Dependent | null;
  createBeneficiaryRecord?: Beneficiary | null;
  addDependentsToCoverage?: BenefitsTransaction | null;
  removeDependentsFromCoverage?: BenefitsTransaction | null;
  handleEmployeeLoa?: (string | null)[] | null;
  cancelCoverage?: BenefitsTransaction | null;
  terminateBenefit?: BenefitsTransaction | null;
  planCorrection?: BenefitsTransaction | null;
  changeTransactionEffectiveDate?: BenefitsTransaction | null;
  approveEvidenceOfInsurability?: (BenefitsTransaction | null)[] | null;
  setCompanyContext?: boolean | null;
  setupPolicyNumber?: boolean | null;
  updatePlanFields?: CompanyHealthPlanEditResult | null;
  deactivatePaymentBanner?: PaymentAuthorizationDetails | null;
  changeContractLength?: CompanyHealthEnrollmentEditResponse | null;
  resetSection125?: ResetSection125Response | null;
  uploadSection125Amendment?: UploadSection125AmendmentResponse | null;
  changeWaitingPeriod?: CompanyHealthEnrollmentEditResponse | null;
  changeTerminationPolicy?: CompanyHealthEnrollmentEditResponse | null;
  runBenefitsAuditTask?: EtaTasks[] | null;
  syncDraftEnrollmentMetadata?: boolean | null;
  revokeRenewalsReviewer?: boolean | null;
  inviteRenewalsReviewer?: boolean | null;
  cancelRenewalsReview?: boolean | null;
  updateRenewalsReviewerStatus?: boolean | null;
  reportIssuesWithRenewals?: boolean | null;
  addReviewers?: boolean | null;
  updateRenewalsReviewerNotifications?: boolean | null;
  lnDAddDependent?: (BenefitsTransaction | null)[] | null /** LnD plan operation resolver */;
  lnDRemoveDependent?: (BenefitsTransaction | null)[] | null;
  lnDAddPlan?: (BenefitsTransaction | null)[] | null;
  lnDDeletePlan?: (BenefitsTransaction | null)[] | null;
  lnDSwitchPlan?: (BenefitsTransaction | null)[] | null;
  changeCompanyCobraThirdPartyAdministrator?: CompanyCobraThirdPartyAdministratorChangeResult | null /** cobra Mutations */;
  changeCompanyCobraClassification?: CompanyCobraClassificationChangeResult | null;
  sendDataToMarketo?: MarketoApiResponse | null /** demo-center app Mutations */;
  createFreeLimitedTrialCompany?: CreateFLTCResponse | null;
  toggleTrialAccountCompanies?: ToggleTrialCompanyResponse | null;
  logUserEventToOptimizely?: boolean | null;
  selfServeCheckoutSavePlanSelectorStep?: SaveCheckoutBundleResponse | null /** checkout app mutations */;
  selfServeCheckoutSavePlanOptionsStep?: SaveCheckoutBundleResponse | null;
  selfServeCheckoutSaveReviewStep?: SaveCheckoutBundleResponse | null;
  selfServeCheckoutSubmit: SubmitCheckoutResponse;
  paymentGatewayCallback?: PaymentGatewayCallbackResponse | null;
  updateQuestionFlow?: QuestionFlow | null /** Talent -> Base */;
  createOrUpdateQFQuestion?: QFSection | null /** Deprecated. newCreateOrUpdateQFQuestion to be used in the meantime */;
  newCreateOrUpdateQFQuestion?: QFQuestion | null;
  deleteQFQuestion?: QFSection | null /** Deprecated. newDeleteQFQuestion to be used in the meantime */;
  newDeleteQFQuestion?: boolean | null;
  submitSessionResponses?: boolean | null;
  submitSessionComments?: boolean | null;
  submitQuestionUniqueResponse?: QFQuestion | null;
  generateReviewRunResponsesPdf?: string | null /** Talent -> Reviews */;
  generateReviewCycleReport?: string | null;
  createOrUpdateReview: Review;
  launchReview: Review;
  endReview?: Review | null;
  editReviewNameAndDescription: boolean;
  createReviewFromTemplate?: Review | null;
  updateReviewRunSettings?: ReviewRun | null;
  createOrUpdateReviewTemplate?: ReviewTemplate | null;
  deleteReviewTemplate?: boolean | null;
  extendReviewDueDates?: Review | null;
  deleteMeeting?: MeetingSpace | null;
  deleteReview?: boolean | null;
  reopenReview?: Review | null;
  editReviewResponses?: boolean | null;
  deactivateReviewRun: boolean;
  addRevieweeToReview: ReviewRun;
  createOrUpdateTalentGoal?: Goal | null /** Talent -> Goals */;
  deleteGoal?: boolean | null;
  createMeetingSpace?: MeetingSpace | null /** Talent -> One on Ones */;
  createOrUpdateMeeting?: MeetingSpace | null;
  moveMeetingItem?: QFQuestion | null;
  moveMeetingItems?: boolean | null;
  createOrUpdateMeetingsUserPreference?: MeetingsUserPreference | null;
  logWellbeingEventData?: boolean | null /** Talent -> Wellbeing */;
  createOrUpdateSurvey: Survey /** Talent -> Surveys */;
  launchSurvey: Survey;
  cancelSurvey: Survey;
  deleteSurvey: boolean;
  shareSurveyResults: Survey;
  editSurveyDetails: Survey;
  generateSurveyReport: string;
  addUpdateVariableCompensation?: boolean | null /** variable comp mutations */;
  createOrUpdatePayType?: boolean | null;
  addVariableCompensations: boolean;
  deletePayType?: boolean | null;
  paSetupStart?: boolean | null /** people_analytics mutation startsclient app */;
  paSetupFinish?: boolean | null;
  paCreateCompanyConfig?: CompanyConfig | null;
  paUpdateCompanyConfig?: CompanyConfig | null;
  paUpdateCompanyJobTitleMapping?: CompanyJobTitle | null;
  paUpdateEmployeeJobTitleMapping?: EmployeeJobTitle | null;
  paCreateFeedback?: Feedback | null;
  paUpdateSalaryBenchmarkBlackList?: SalaryBenchmarkingBlacklistElement | null;
  paConsoleCompanyInfo?: CompanyConfig | null /** console app */;
  paConsoleUpdateCompanyJobTitleMapping?: CompanyJobTitle | null;
  paConsoleUpdateIndustryJob?: UpdateName | null;
  trsCreateRewardStatement?: RewardStatement | null /** total reward statement mutation starts */;
  trsUpdateRewardStatement?: RewardStatement | null;
  updatePDFInfo?: PdfInfo | null;
  biDeleteCustomReport?: boolean | null /** Business Intelligence mutation starts */;
  biRunReport?: RunReportInfo | null;
  biPreviewReport?: JSON | null;
  closeInboxTasks?: InboxTasksCloseResult | null /** partner dashboard mutations */;
  assignInboxTasks?: InboxActionAssigneesResult | null;
  snoozeInboxTasks?: InboxActionSnoozeResult | null;
  unSnoozeInboxTasks?: InboxActionUnSnoozeResult | null;
  createInboxActionComment?: boolean | null;
  addFulfillmentAttachments?: boolean | null;
  removeFulfillmentAttachment?: boolean | null;
  sendFullStpEmail?: SendFullStpEmailResult | null;
  declineEnrollment?: boolean | null /** start enrollment mutations */;
  submitEnrollmentSelection?: boolean | null;
  employeeSetPhysicianDentist?: boolean | null;
  employeeSetBeneficiary?: boolean | null;
  employeeSubmitEnrollmentWaiver?: boolean | null;
  createEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  changeEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  cancelEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  addQleDocument?: QualifyingEvent | null /** start QLE mutations */;
  removeQleDocument?: QualifyingEvent | null;
  addQleDocumentReview?: GenericQleChangeResult | null;
  cancelQle?: GenericQleChangeResult | null;
  changeQleDate?: GenericQleChangeResult | null;
  createQualifyingEvent: CreateQleResult;
  qleRemoveDependents?: (QleRemoveDependentsResult | null)[] | null;
  qleCancelCoverage?: (GenericQleChangeResult | null)[] | null;
  updateReviewQualifyingLifeEventDocument?: GenericQleChangeResult | null;
  addPartnerUser: PartnerUser /** start partner user mutations */;
  editPartnerUser: PartnerUser;
  changePartnerUserStatus: PartnerUser;
  createPartnerContact?: PartnerContact | null /** start partner contact mutations */;
  reassignPartnerCompanyContacts?: PartnerCompany[] | null;
  reassignPartnerDefaultContact?: Partner | null;
  updatePartnerContactDetails?: PartnerContact | null;
  replacePartnerContact?: JSON | null;
  createPartnerCompany: CreateCompanyResult;
  linkPartnerCompanies?: LinkCompaniesResult | null;
  enablePartnerCompanyBenefits?: JSON | null;
  savePriorPayrollInfo?: PriorPayrollModalResponse | null /** Mutations for company_setup */;
  updateCompanyLegalInfoV2?: Company | null;
  addCompanyLocations?: (CompanyLocation | null)[] | null;
  setupCompanyPaySchedule?: CompanyPaySchedule | null /** Should ideally move this out later. */;
  createOrUpdateEmployees?: (AllEmployee | null)[] | null;
  sendEmployeeInviteEmails?: boolean | null;
  sendEmployeeReminderEmailsV2?: (CompanySetupReminderEmailRecipients | null)[] | null;
  updateEmployeesAdditionalInfo?: (AllEmployee | null)[] | null;
  markFlowSectionEntered?: FlowSection | null /** Should ideally move this out later. */;
  updateFlowSection?: FlowSection | null;
  skipPTOSetup?: boolean | null;
  updatePTOPlan?: boolean | null;
  unlockDashboardForCompany?: boolean | null;
  saveContributionPreferencesV3?: CompanySetupContributionSettings | null /** Start of Marketplace mutations */;
  saveContributionSettings?: CompanySetupContributionSettings | null;
  saveCarrierSettingsV2?: CompanySetupCarrierSettings | null;
  createProduct?: data | null /** zenmarket mutations */;
  addProductCategories?: boolean | null;
  addMetaDataToProduct?: boolean | null;
  addMatchingCriteria?: boolean | null;
  createOrEditDeal?: data | null;
  editInterestStatus?: string | null;
  editProductStatus?: string | null;
  updateUploadDocInfo?: UploadStatus | null;
  requestDocuments?: boolean | null /** Document Collection */;
  cancelDocumentRequest?: boolean | null;
  completeDocumentRequest?: boolean | null;
  deleteDocInfo?: boolean | null;
  updateDocumentFileNote?: boolean | null;
  createCompanyDocMeta?: CompanyDocMetaCreateResponse | null;
  handleCompanyDocMetaAction?: CompanyDocMetaCreateResponse | null;
  benefitsFormMapperUpdateOrInsertDataField?: DataFields | null;
  benefitsFormMapperStpFormTemplateMutation?: StpFormTemplate | null;
  benefitsFormMapperStpFetchMasterMappings?: (StpFieldMapping | null)[] | null;
  benefitsFormMapperStpReloadExistingMappings?: (StpFieldMapping | null)[] | null;
  createFormTemplate?: StpFormTemplate | null;
  deleteFormTemplate?: boolean | null;
  fetchDefaultValuesForUniqueBaseFieldsInExpressionList?: StpBaseFieldMappingOutput | null;
  generateTestPdf?: GenerateTestPdfOutput | null;
  saveLatestTemplate?: StpFormTemplate | null;
  createSupportCase?: supportCase | null;
  createOrUpdateGroupType?: GroupType | null;
  createGroup?: Group | null;
  updateGroup?: Group | null;
  partialUpdateGroup?: Group | null;
  deleteGroup?: boolean | null;
  assignGroups: GroupMembership;
  createDepartmentGroup?: DepartmentGroup | null;
  updateDepartmentGroup?: DepartmentGroup | null;
  deleteDepartmentGroup?: boolean | null;
  createLocationGroup?: LocationGroup | null;
  updateLocationGroup?: LocationGroup | null;
  deleteLocationGroup?: boolean | null;
  createGroupsDatagrid: Datagrid;
  createProjectsDatagrid: Datagrid /** TODO gnjoo: REMOVE when z-frontend changes makes use of createGroupsDatagrid */;
  createOrUpdateDepartmentsChange: DepartmentsChange /** Change Request through custom api and zmessage */;
  createOrUpdateGroupMembershipChange: GroupMembershipChange /** Upsert a change request item to alter the membership of an employee within groups of a given group type */;
  createOrUpdateCompensationChange: CompensationChange /** Upsert a change request item to alter the compensation of an employee */;
  createOrUpdateVariableCompChange: VariableCompensationChange /** Upsert a change request item to alter the variable compensation of an employee */;
  deleteChangeRequestItem: boolean;
  cancelDeletionRequestGroup: boolean;
  createAndProcessEmployeeDetailsChange: ChangeRequestGroup;
  confirmTermination: ChangeRequestGroup;
  createOrUpdateSchedulingShift?: boolean | null /** Time Scheduling App Mutations */;
  clearSchedulingShifts?: boolean | null;
  deleteSchedulingShifts?: boolean | null;
  publishSchedulingShifts?: boolean | null;
  copySchedule?: (SchedulingShift | null)[] | null;
  revertScheduleToLastPublishedState?: boolean | null;
  generateAdherenceReportXlsx?: string | null;
  enrollSchedulingCompany?: SchedulingCompany | null;
  updateSchedulingCompany?: SchedulingCompany | null;
  enrollSchedulingEmployees: boolean /** Scheduling Console Mutations */;
  unenrollSchedulingEmployees: boolean;
  updateSchedulingCompanySettings: boolean;
  createOrUpdateSchedulingEmployeeAvailability?: SchedulingEmployeeAvailability | null;
  deleteSchedulingEmployeeAvailability?: SchedulingEmployeeAvailability | null;
  approveVacationRequest?: boolean | null /** Time Off Mutations */;
  createOrUpdateVacationRequest?: boolean | null;
  deleteVacationRequest?: boolean | null;
  denyVacationRequest?: boolean | null;
  updateEmployeePtoAccountDetails: EmployeePtoAccount[];
  updateEmployeePtoPolicy?: EmployeePtoAccount[] | null;
  bulkUpdateZAppInstallSubscriptionsStatus?: boolean | null;
  updateZAppInstallForceDisable?: boolean | null;
  savePlaidAccount?: SavePlaidAccountResponse | null /** Payroll mutations */;
  createBankAccountVerification?: BankAccountVerification | null;
  addZPCompanyBankAccount?: boolean | null;
  setVoidCheckUrl?: boolean | null;
  setPayrollSignatory?: boolean | null;
  import401kData?: Import401kData | null;
  updateContractor1099FormFiling?: Contractor1099FormFiling | null;
  setFiling1099FormThroughZenefitsForAllContractors: boolean;
  setOpenClose1099FilingDate?: SetOpenClose1099FilingDateResponse | null /** Contractor 1099 Form Filing Console Operation */;
  setAdminsToOptInEmailListEmailAddress?: SetAdminsToOptInEmailListEmailAddressResponse | null;
  setContractorsWithMissingInfoEmailListEmailAddress?: SetContractorsWithMissingInfoEmailListEmailAddressResponse | null;
  setAdminFilingConfirmationEmailListEmailAddress?: SetAdminFilingConfirmationEmailListEmailAddressResponse | null;
  generateVendorCSVFileAndSendEmail?: GenerateVendorCSVFileAndSendEmailResponse | null;
  updateMappingTemplate?: UpdateOrCreateResponse | null;
  updateAccountMappingTemplate?: UpdateOrCreateResponse | null;
  updateSelectedLaborGroupTypesForCompany?: updateLaborGroupTypesResponse | null /** Accounting Integration */;
  addOrEditMappingGroup?: AddOrEditMappingResponse | null;
  publishMappings?: PublishMappingsResponse | null;
  deleteMappings?: DeleteMappingsResponse | null;
  createFolder?: FolderActionResponse | null /** Company Documents mutations */;
  editFolder?: FolderActionResponse | null;
  deleteFolder?: FolderActionResponse | null;
  createNewDocument?: NewDocumentResponse | null;
  deleteDocument?: DeleteDocumentResponse | null;
  editDocument?: NewDocumentResponse | null;
  deleteDocumentV2?: DeleteDocumentV2Response | null /** Document V2 mutations */;
  createWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null /** Create Worker Document Folder mutation */;
  editWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null;
  deleteWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null;
  uploadWorkerDocument?: UploadDocumentActionResponse | null /** Upload Worker Document mutation */;
  setupLoaPolicy?: PolicyActionResponse | null /** Leave of Absence mutations */;
  loaRequiredDocumentActions?: LoaRequiredDocumentResponse | null;
  policyActions?: PolicyActionResponse | null;
  deleteLeaveRequest?: DeleteLeaveRequestResponse | null;
  recordLeaveRequest?: LeaveOfAbsenceRequestMessage | null;
  updateUploadedLoaDocument?: UploadedDocumentResponseMessage | null;
  incrementInboxActionViews: InboxAction;
  completeInboxAction: InboxAction;
  completeAddressSubAction: AddressSubAction;
  completeBankSubAction: BankSubAction;
  completeConfirmationSubAction: ConfirmationSubAction;
  completeSingleValueSubAction: SingleValueSubAction;
  completeSignatureSubAction: SignatureSubAction;
  completeContentReviewSubAction: ContentReviewSubAction;
  completePrerequisiteRedirectTask?: JSON | null;
  contactAdvisor: ContactAdvisorResponse;
  updateProductionActivation: ActivationInfo /** Deployment dashboard */;
  linkUserAccounts: ManageUserAccountsResponse;
  unlinkUserAccounts: ManageUserAccountsResponse;
  saveMultiOrgReport: SaveReportResponse;
  saveMultiOrgReportSchedule: SaveReportResponse;
  mutateMultiOrgReportState: SaveReportResponse;
  submitProductInterest: SubmitProductInterestResponse;
  getCompanyOptions: (CompanySearchOptions | null)[];
  createDatagrid: Datagrid /** Datagrid properties */;
  datagridFileUploaded: DatagridUploadResult;
  updateDatagridPreviousUrl: Datagrid;
  resetDatagrid: Datagrid;
  saveDatagridColumnMappings: Datagrid /** Datagrid computed properties */;
  generateDatagridRows: Datagrid;
  saveDatagridRows: DatagridRow[] /** Datagrid row actions */;
  deleteDatagridRows: Datagrid /** DatagridId is passed for performance reasons. As the result of this mutation isa datagrid object, if datagridId is not passed, a query needs to be made on thebackend to fetch the datagridId from one of the deleted rows. */;
  submitDatagrid: Datagrid;
  handleCancellationRequest?: CancellationRequest | null /** ##################### cancellation ###################### */;
  updateQuestionnaireResponse: boolean;
  submitQuestionnaireResponse: boolean;
  activateResourceCenterOffer: boolean /** ######################## resource-center ######################### */;
  deactivateResourceCenterOffer: boolean;
  enableEmployeeNotificationsForResourceCenterOffers: boolean;
  disableEmployeeNotificationsForResourceCenterOffers: boolean;
  claimResourceCenterOffer: boolean /** NOTE: consider dropping this, we might do it through the backend as a callback from the partner */;
  resendWebhookMessages?: (ZAppWebhookAuditMessage | null)[] | null /** webhooks_proxy */;
  uploadPPBSpreadsheet: PriorPayrollDatagridUploadStatus /** Prior Payroll Balancing Datagrid */;
  createEmployeeBasicInfo: HiringUpdatesResponse /** hiring flow */;
  updateEmployeeBasicInfo: HiringUpdatesResponse;
  editNewHireEmployment: HiringUpdatesResponse;
  saveNewHireJobDetails: HiringUpdatesResponse;
  updateNewHireEmployment: HiringUpdatesResponse;
  createOrUpdateEmployeeEligibilityPart1: boolean;
  updateEmployeeEligibilityPart2: boolean;
  reviewAndSignEmployeeEligibility: boolean;
  updateEmployeeEligibilityDocuments: boolean;
  createCorrectionForInprogressEligibility: boolean;
  completeEligibilitySection1Correction: boolean;
  syncI9EligibilityOffline?: EmployeeEligibility | null;
  updateEmployerEligibilityDocuments: boolean;
  updateEmployerEligibilityCertification: EmployerEligibility;
  acknowledgeEmployerEligibility: I9MutationStatus;
  acknowledgeEligibilityCorrection: I9MutationStatus;
  createOrUpdateEligibilityReverification: EligibilityReverification;
  certifyEligibilityReverification: I9MutationStatus;
  createInitRun: CreateInitRunResult;
  approveInitRun: ApproveInitRunResult;
  closeZPPDatagrid: CloseZPPDatagridResult;
  runYtdAmountsCalculator: YtdAmountsCalculatorResult;
  runTaxCalculator: TaxCalculatorResult;
  overwriteZPREData: OverwriteZPREDataResult;
  addZywaveAgreement?: boolean | null /** Second Party Integration (TODO: remove when zywave is ready) */;
  payPriorTaxLiabilities: TaxLiabilitiesPaidResponse;
  createCompanyGroup: MutationSuccess /** company hub company group mutation */;
  addCompaniesToGroup: MutationSuccess;
  mergeCompanyGroups: MutationSuccess;
  newHireAdditionalInfo: HiringUpdatesResponse;
  initializeDirectoryUpdate: DirectoryUpdate /** Directory Update */;
  configurePayrollSettings: HiringUpdatesResponse;
  newHireNotification: HiringUpdatesResponse;
  syncNewHireEmployerFlow: HiringUpdatesResponse;
  setEmployeeGenericData: HiringUpdatesResponse;
  updateNewHireCustomFieldValues: HiringUpdatesResponse;
  createOrUpdatePost?: SocialFeedPost | null /** Communication Hub -> Social Feed */;
  deletePost?: boolean | null;
  createSocialFeedComment?: SocialFeedComment | null;
  deleteSocialFeedComment?: boolean | null;
  togglePostLike?: PostLike | null;
  createOrUpdateFeedSettings?: SocialFeedSettings | null;
  createBulkDocumentDownloadRequest?: createBulkDocumentDownloadResponse | null /** bulk documentv2 download request */;
  createCorrectionForCompleteEligibility?: CorrectionForCompleteEligibilityResponse | null /** I9 correction in section1 for eligibility in complete status */;
  agreeToWashingtonDisclosure?: boolean | null /** Generic Agreements app */;
  agreeToTOU?: boolean | null;
  sendNewOfferLetter?: sendNewOfferLetterResponse | null /** Send new offer letter */;
  cancelBackgroundCheck?: CancelBackgroundCheckResponse | null /** Cancel background check */;
  employeeProfileActionPutWorkerOnLoa: string;
  employeeProfileActionDeleteProfile: SimpleResponse;
  employeeProfileActionAccessAccount: SimpleResponse;
  employeeProfileActionDeletePhoto: SimpleResponse;
  employeeProfileActionUpdatePhoto: SimpleResponse;
  employeeProfileActionRevokeOffer: SimpleResponse;
  employeeProfileActionCancelTermination: SimpleResponse;
  editIsProvisioningEnabledForSSOProvider: IsProvisioningEnabledValue;
  editSSOSettings: EditSSOSettingsResponse;
  deleteSwitchByKey?: DeleteSwitchByKeyResponse | null /** switches */;
  rollbackSwitchToLogEntry: RollbackToLogEntryResponse;
  createOrUpdateSwitchByKey?: CreateOrUpdateSwitchByKeyResponse | null;
  editTwoFactorSettings?: TwoFactorSettings | null /** 2FA */;
}

export interface ApproveTimeApprovedDayResponse {
  status?: string | null;
}

export interface TaCompanyNotificationSettingsSave {
  status?: string | null;
}

export interface TaVacationDataDetails {
  status?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  availableAsOf?: string | null;
  approvedDate?: string | null;
  asOfDate?: string | null;
  isPostedOrPast?: boolean | null;
  isHalfDay?: string | null;
  id?: string | null;
  isPartialDay?: boolean | null;
  hours?: string | null;
  asOfHours?: string | null;
  reason?: string | null;
  employee_id?: number | null;
  isManagerRecorded?: boolean | null;
  createdDate?: string | null;
  vacationType_id?: number | null;
  creator_id?: number | null;
  denyReason?: string | null;
  resource_uri?: number | null;
}

export interface EditEmployeeEligibilityResponse {
  success: boolean;
  message?: string | null;
}

export interface DeleteDependentResponse {
  success: boolean;
  previousEnrolledBenefitsTypes: string[];
  enrolledBenefitsTypes: string[];
}

export interface CompanyHealthPlanEditResult {
  success?: boolean | null;
  reason?: string | null;
}

export interface CompanyHealthEnrollmentEditResponse {
  success?: boolean | null;
  error?: string | null;
  enrollment?: CompanyHealthEnrollment | null;
}

export interface ResetSection125Response {
  status?: string | null;
  message?: string | null;
}

export interface UploadSection125AmendmentResponse {
  status?: string | null;
  message?: string | null;
}

export interface CompanyCobraThirdPartyAdministratorChangeResult {
  status?: string | null;
  administratorName?: string | null;
}

export interface CompanyCobraClassificationChangeResult {
  status?: string | null;
}

export interface MarketoApiResponse {
  success?: boolean | null;
}

export interface CreateFLTCResponse {
  success?: boolean | null;
  redirectUrl?: string | null;
}

export interface ToggleTrialCompanyResponse {
  success?: boolean | null;
  redirectUrl?: string | null;
}
/** Mutation Responses */
export interface SaveCheckoutBundleResponse {
  bundle?: CheckoutBundle | null;
}

export interface SubmitCheckoutResponse {
  success: boolean;
  subscriptionId?: string | null;
  djangoRedirect?: boolean | null;
  redirectUrl?: string | null;
  errorMessage?: string | null;
}

export interface PaymentGatewayCallbackResponse {
  city?: string | null;
  zuoraId?: string | null;
  address1?: string | null;
  maskedNumber?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  type?: string | null;
  holderName?: string | null;
  bankName?: string | null /** ach fields */;
  routingNumber?: string | null;
  accountType?: string | null;
  expirationMonth?: number | null /** credit card fields */;
  expirationYear?: number | null;
  cardType?: string | null;
}

export interface UpdateName {
  type?: string | null;
  id?: number | null;
  name?: string | null;
}

export interface RunReportInfo {
  status?: string | null;
  newDoc_id?: number | null;
}

export interface InboxTasksCloseResult {
  success: boolean;
  error?: string | null;
  data?: InboxTaskCloseResult[] | null;
}

export interface InboxTaskCloseResult {
  success: boolean;
  taskId: string;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionAssigneesResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionAssignee | null)[] | null;
}

export interface InboxActionAssignee {
  success: boolean;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionSnoozeResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionSnoozeData | null)[] | null;
}

export interface InboxActionSnoozeData {
  success: boolean;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionUnSnoozeResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionSnoozeData | null)[] | null;
}

export interface SendFullStpEmailResult {
  success: boolean;
  error?: string | null;
  physicalEmailId?: string | null;
}

export interface EmployeeBenefitsEnrollmentEditResult {
  success: boolean;
  error?: string | null;
  enrollment?: EmployeeBenefitsEnrollment | null;
}

export interface GenericQleChangeResult {
  success: boolean;
  error?: string | null;
}

export interface CreateQleResult {
  success: boolean;
  error?: string | null;
  qleId?: string | null;
  validLocs?: (string | null)[] | null;
  invalidLocs?: (string | null)[] | null;
}

export interface QleRemoveDependentsResult {
  success: boolean;
  transactionId?: string | null;
}

export interface CreateCompanyResult {
  companyId?: string | null;
  errors?: string | null;
}

export interface LinkCompaniesResult {
  isSuccess?: boolean | null;
  fromCompanyHasParent?: boolean | null;
  toCompaniesAsParents?: string[] | null;
}

export interface PriorPayrollModalResponse {
  response: string;
}

export interface data {
  product_id: string;
}

export interface UploadStatus {
  status?: string | null;
}

export interface CompanyDocMetaCreateResponse {
  status?: string | null;
  docMetaId?: string | null;
}

export interface StpBaseFieldMappingOutput {
  baseFieldMappings?: (StpBaseFieldMapping | null)[] | null;
  error?: string | null;
  success?: boolean | null;
}

export interface StpBaseFieldMapping {
  baseFieldName?: string | null;
  value?: string | null;
  fieldType?: string | null;
}

export interface GenerateTestPdfOutput {
  fileUrl?: string | null;
  error?: string | null;
  success?: boolean | null;
}

export interface supportCase {
  caseId?: string | null;
  initialPosition?: number | null;
}

export interface GroupMembership {
  memberId: string;
  groupIds: string[];
}

export interface DepartmentGroup {
  id: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}

export interface LocationGroup {
  id: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  isHeadquarters?: boolean | null;
  phone?: string | null;
  businessLegalName?: string | null;
  locationId: string;
}

export interface SavePlaidAccountResponse {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  identity?: PlaidIdentityInfo | null;
}

export interface PlaidIdentityInfo {
  haveNamesMatched?: boolean | null;
  companyNames?: (string | null)[] | null;
  plaidNames?: (string | null)[] | null;
}

export interface BankAccountVerification {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
}

export interface Import401kData {
  data?: JSON | null;
  success: boolean;
}

export interface SetOpenClose1099FilingDateResponse {
  isSuccess: boolean;
}

export interface SetAdminsToOptInEmailListEmailAddressResponse {
  response: string;
}

export interface SetContractorsWithMissingInfoEmailListEmailAddressResponse {
  response: string;
}

export interface SetAdminFilingConfirmationEmailListEmailAddressResponse {
  response: string;
}

export interface GenerateVendorCSVFileAndSendEmailResponse {
  response: string;
}

export interface UpdateOrCreateResponse {
  status?: string | null;
}

export interface updateLaborGroupTypesResponse {
  status?: string | null;
  error_msg?: string | null;
}

export interface AddOrEditMappingResponse {
  status: string;
  error_msg?: string | null;
}

export interface PublishMappingsResponse {
  status?: string | null;
}

export interface DeleteMappingsResponse {
  status?: string | null;
}

export interface FolderActionResponse {
  status?: number | null;
  folderId?: string | null;
  folderName?: string | null;
}

export interface NewDocumentResponse {
  status?: number | null;
  newDocumentId?: number | null;
  newDocumentName?: string | null;
  folderId?: number | null;
  folderName?: string | null;
}

export interface DeleteDocumentResponse {
  status?: number | null;
}

export interface DeleteDocumentV2Response {
  status?: number | null;
}

export interface WorkerDocumentFolderActionResponse {
  status?: number | null;
  folderId?: number | null;
  folderName?: string | null;
}

export interface UploadDocumentActionResponse {
  status?: number | null;
  documentId?: number | null;
  documentName?: string | null;
}

export interface PolicyActionResponse {
  responseStatus?: boolean | null;
  policyId?: string | null;
  originalPolicyId?: string | null;
  newVersion?: boolean | null;
  responseMessage?: string | null;
}

export interface LoaRequiredDocumentResponse {
  responseStatus?: boolean | null;
  requiredDocumentId?: string | null;
}

export interface DeleteLeaveRequestResponse {
  responseStatus?: boolean | null;
  leaveRequestId?: string | null;
}

export interface UploadedDocumentResponseMessage {
  id?: number | null;
  status?: number | null;
}

export interface ContactAdvisorResponse {
  success?: boolean | null;
}

export interface ManageUserAccountsResponse {
  status?: boolean | null;
  reason?: string | null;
  redirectUrl?: string | null;
}

export interface SaveReportResponse {
  newlyCreatedReportId?: number | null;
  status: boolean;
  reason?: string | null;
}

export interface SubmitProductInterestResponse {
  success?: boolean | null;
}

export interface CompanySearchOptions {
  id?: string | null;
  isReal?: boolean | null;
  name?: string | null;
  enrollmentStatus?: string | null;
}

export interface DatagridUploadResult {
  datagrid?: Datagrid | null;
  errorCode?: number | null;
}

export interface ZAppWebhookAuditMessage {
  subscriptionType?: string | null;
  deliveryStatusCode?: number | null;
  deliveryStatus?: string | null;
  deliveredAt?: string | null;
  zappId?: string | null;
  error?: string | null;
  payload?: string | null;
  createdAt?: string | null;
  messageId?: string | null;
}
/** used for upload prior payroll balancing spreadsheet mutation response */
export interface PriorPayrollDatagridUploadStatus {
  success: boolean;
  zPriorPayrollUploadObjId?: string | null;
  zPriorPayrollDatagridId?: string | null;
  errorMsg?: string | null;
  dataExistsDetails?: DataExistsDetails | null;
}

export interface DataExistsDetails {
  zPriorPayrollDatagrids?: ZPriorPayrollDatagrid[] | null;
  draftPayrunsWithoutDatagrid?: string[] | null;
}

export interface HiringUpdatesResponse {
  status?: boolean | null;
  employeeId?: number | null;
  newHireId?: number | null;
}

export interface I9MutationStatus {
  statusCode?: number | null;
  message?: string | null;
}

export interface CreateInitRunResult {
  success: boolean;
  zPayrollRunId?: string | null;
  errors?: string[] | null;
}

export interface ApproveInitRunResult {
  success: boolean;
  errors?: string[] | null;
}

export interface CloseZPPDatagridResult {
  success: boolean;
  errors: string;
}

export interface YtdAmountsCalculatorResult {
  success: boolean;
  errors?: string[] | null;
}

export interface TaxCalculatorResult {
  success: boolean;
  errors?: string[] | null;
}

export interface OverwriteZPREDataResult {
  success: boolean;
  errors?: string[] | null;
}

export interface TaxLiabilitiesPaidResponse {
  status: string;
}
/** a generic type to state an action is successful or not */
export interface MutationSuccess {
  success: boolean;
}

export interface DirectoryUpdate {
  url: string;
}

export interface createBulkDocumentDownloadResponse {
  status?: number | null;
  url?: string | null;
}

export interface CorrectionForCompleteEligibilityResponse {
  status?: number | null;
}

export interface sendNewOfferLetterResponse {
  status?: number | null;
  newHireId?: number | null;
}

export interface CancelBackgroundCheckResponse {
  status?: number | null;
}

export interface SimpleResponse {
  success?: boolean | null;
  exception?: string | null;
}

export interface EditSSOSettingsResponse {
  success?: boolean | null;
  errors?: (string | null)[] | null;
  isEnabled?: boolean | null;
  metadataXML?: string | null;
}

export interface DeleteSwitchByKeyResponse {
  success: boolean;
  switch: Switches;
}

export interface RollbackToLogEntryResponse {
  success: boolean;
  switch: Switches;
}

export interface CreateOrUpdateSwitchByKeyResponse {
  success: boolean;
  switch: Switches;
  putLogId: number;
}

export interface AgreementSection125 {
  agreementType?: string | null;
  user?: User | null;
  effectiveDate?: string | null;
  company?: Company | null;
  isActive?: boolean | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  validThru?: string | null;
  id?: string | null;
  document?: Document | null;
  resource_uri?: string | null;
}

export interface AgreementSection125Amendment {
  user?: User | null;
  uploadedDate?: string | null;
  company?: Company | null;
  isActive?: boolean | null;
  id?: string | null;
  document?: Document | null;
  affectedReportDocument?: Document | null;
}

export interface AllEmployeePtoProxy {
  pto?: EmployeePto | null;
  id?: string | null;
}

export interface AllEmployeeTaProxy {
  id?: string | null;
}

export interface AssociatedDocument {
  description?: string | null;
  resource_uri?: string | null;
  fileName?: string | null /** ipAssignment: IpAssignment */;
  uploadUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface AuditCard {
  audit?: Audit | null;
  taskIds?: string | null;
  company?: Company | null;
  lineOfCoverage?: string | null;
  cardType?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface BaddataValidate {
  resource_uri?: string | null;
}

export interface BenefitsTransactionHelpFlow {
  id?: string | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  benefitsType?: string | null;
  originalContext?: BenefitsContext | null;
  originalCost?: BenefitsCost | null;
  originalContribution?: ContributionBreakdown | null;
  newCost?: BenefitsCost | null;
  newContribution?: ContributionBreakdown | null;
  triageData?: string | null;
  contextChange?: BenefitsContext | null;
  costChange?: BenefitsCost | null;
  contributionChange?: ContributionBreakdown | null;
  flow?: Flow | null;
  jiraTicket?: string | null;
  isApproved?: boolean | null;
  isCompleted?: boolean | null;
  createdAt?: DateTime | null;
  createdBy?: User | null;
  approvedBy?: User | null;
  completedAt?: DateTime | null;
}

export interface Bill {
  billDate?: string | null;
  invoicePdf?: string | null;
  company_id?: number | null;
  componentsReady?: boolean | null;
  amount?: string | null;
  ach_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface BillAdjustment {
  product?: Product | null;
  notes?: string | null;
  company_id?: number | null;
  amount?: string | null;
  isActive?: boolean | null;
  date?: string | null;
  type?: string | null;
  id?: string | null;
  bill?: Bill | null;
  resource_uri?: string | null;
}

export interface Product {
  name?: string | null;
  app_unique_id?: string | null;
  pricingModel?: string | null;
  resource_uri?: string | null;
  billingVariablesCallbackMethod?: string | null;
  id?: string | null;
  billingScheme?: string | null;
}

export interface BillComponents {
  numOfMonths?: number | null;
  billDate?: string | null;
  product?: Product | null;
  company_id?: number | null;
  modelVariables?: string | null;
  amount?: string | null;
  ruleParams?: string | null;
  id?: string | null;
  bill?: Bill | null;
  resource_uri?: string | null;
}

export interface BillingPromotion {
  discountNumMonths?: number | null;
  product?: Product | null;
  discountPercent?: string | null;
  maxRedemptions?: number | null;
  couponName?: string | null;
  campaignName?: string | null;
  redeemByDate?: string | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface BulkUpload {
  status?: string | null;
  errors?: string | null;
  duplicates?: string | null;
  changes?: string | null;
  updateOn?: DateTime | null;
  company?: Company | null;
  id?: string | null;
  content?: string | null;
  warning?: string | null;
  message?: string | null;
  type?: string | null;
  fileUrl?: string | null;
  resource_uri?: string | null;
}

export interface CarrierWaiver {
  isValid?: boolean | null;
  waiver?: WaiverMetadata | null;
  carrierYearMeta?: CarrierYearMetadata | null;
  resource_uri?: string | null;
  id?: string | null;
  groupSize?: string | null;
}

export interface CarrierYearMetadata {
  memberStatusOffsetDaysFromOESubmission?: number | null;
  underwritingDocUrl?: string | null;
  dentalDefaultRegion?: string | null;
  ageRedetermination?: string | null;
  cobraBillingStrategy?: string | null;
  useNewHireAgeAsOfGroupApproval?: boolean | null;
  useEEZipForDependent?: boolean | null;
  medicalSicCodeDisallowList?: string | null;
  allowRetroactiveTermination?: boolean | null;
  packageSizes?: (PackageSize | null)[] | null;
  newhireBillingStrategy?: string | null;
  retroactiveTerminationDeadline?: string | null;
  maxSmallGroupThreshold?: number | null;
  allowOtherCarriers?: boolean | null;
  dependentMaxAge?: number | null;
  largeGroupEligibilityCheck?: string | null;
  supportsFirstPaymentAch?: boolean | null;
  customMedicalPlanDependentCountMaxAge?: number | null;
  year?: number | null;
  leadTime?: number | null;
  shortCircuitDentalRegion?: number | null;
  retroactiveTerminationDeadlineMember?: string | null;
  salaryChangeRenewal?: string | null;
  requiredParticipationFlexible?: boolean | null;
  isPPOHMOPlanRequired?: boolean | null;
  dentalSicCodeDisallowList?: string | null;
  oePlanInstallationTime?: number | null;
  addSpouseEffectiveDate?: string | null;
  studentDependentAllowed?: boolean | null;
  oeLeadTimeMember?: number | null;
  addDependentLossOfCoverageEffectiveDate?: string | null;
  contributionRules?: (ContributionRule | null)[] | null;
  transferLargeGroupThreshold?: number | null;
  isAgeChangeEffectiveAtRenewal?: boolean | null;
  middleOfMonthEnrollment?: boolean | null;
  isDependentAgeEffectiveAtRenewal?: boolean | null;
  ownershipProofResponsibility?: string | null;
  carrier?: Carrier | null;
  ngeRequiresProofOwnership?: boolean | null;
  resource_uri?: string | null;
  allowRetroactiveTerminationGroup?: boolean | null;
  shortCircuitVisionRegion?: number | null;
  allowsOnlyAdjacentMetalTiers?: boolean | null;
  ngeRequiresProofBusiness?: boolean | null;
  shortCircuitMedicalRegion?: number | null;
  useNewHireAgeAsOfGroupApprovalForDep?: boolean | null;
  renewalPacketAvailabilityLeadTime?: number | null;
  addChildEffectiveDate?: string | null;
  isAddresChangeEffectiveAtRenewal?: boolean | null;
  visionDefaultRegion?: string | null;
  memberTerminationBillingStrategy?: string | null;
  medicalDefaultRegion?: string | null;
  requiresContract?: string | null;
  isPediatricDentalCostEmbedded?: boolean | null;
  verifiedFields?: string | null;
  visionSicCodeDisallowList?: string | null;
  spousePartnerAllowed?: boolean | null;
  subscriberLossOfCoverageEffectiveDate?: string | null;
  roundingRules?: (RoundingRule | null)[] | null;
  customVisionPlanDependentCountMaxAge?: number | null;
  supportsOwnerOnlyCompanies?: boolean | null;
  employerAccessRequestMethod?: string | null;
  oeLeadTime?: number | null;
  networkCompatibilityRules?: (NetworkCompatibilityRule | null)[] | null;
  newHirePreEffectiveDateSubmissionDays?: number | null;
  minPTDaysToWaiveWaitingPeriod?: number | null;
  ngeWeeksPayrollRequired?: number | null;
  waivers?: (CarrierWaiver | null)[] | null;
  id?: string | null;
  participationRules?: (ParticipationRule | null)[] | null;
  firstOfMonthSameEffectiveDate?: boolean | null;
  earningsRedetermination?: string | null;
  marriageBillingStrategy?: string | null;
  ngeRequiresPeo?: boolean | null;
  cancelCoverageEffectiveDate?: string | null;
  inStateRules?: (InStateRule | null)[] | null;
  quotedByEmployersZip?: boolean | null;
  employerAccessRequestTiming?: string | null;
  consideredInStateList?: string | null;
  maxMidGroupThreshold?: number | null;
  customDentalPlanDependentCountMaxAge?: number | null;
  autoPlanDropWhenNoEnrollees?: boolean | null;
  additionalNotes?: string | null;
  newbornBillingStrategy?: string | null;
  isWaitingPeriodTrackedByCarrier?: boolean | null;
  ngeRequiresQwtrIfLessThanXEmployees?: number | null;
  dependentAgeOutPolicy?: string | null;
  ngeRequiresFbn?: boolean | null;
  minHoursWorked?: number | null;
  allowPediatricDentalOptOut?: boolean | null;
  defaultWaitingPeriod?: string | null;
}

export interface PackageSize {
  numberOfPlans?: number | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  maxEmployees?: number | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ContributionRule {
  minAmount?: number | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  maxEmployees?: number | null;
  minPercentage?: string | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface RoundingRule {
  softDeleted?: boolean | null;
  roundingLocation?: string | null;
  lineOfCoverage?: string | null;
  roundingType?: string | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface NetworkCompatibilityRule {
  lineOfCoverage?: string | null;
  softDeleted?: boolean | null;
  planType?: string | null;
  additionalNotes?: string | null;
  numberOfNetworks?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface Category {
  mainCategory?: string | null;
  subCategory?: string | null;
}

export interface CompanyPtoProxy {
  id?: string | null;
}

export interface CompanySetupMarketplacePlan {
  id: string;
  displayName: string;
  carrierLogoUrl?: string | null;
  coverageType?: string | null;
  youPremium: number;
  youAndSpousePremium: number;
  youAndChildPremium: number;
  familyPremium: number;
}

export interface CompanyTaProxy {
  id?: string | null;
}

export interface CompanyTwofactorSettings {
  id?: string | null;
  resource_uri?: string | null;
  company?: Company | null;
  settingType?: string | null;
}

export interface Csa {
  id: string;
  name?: string | null;
}

export interface CustomerBillingHistory {
  billDate?: string | null;
  invoicePdf?: string | null;
  company_id?: number | null;
  componentsReady?: boolean | null;
  amount?: string | null;
  ach_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CustomZenefitsEmployee {
  status?: string | null;
  firstName?: string | null;
  title?: string | null;
  lastName?: string | null;
  id?: string | null;
  isContractor?: boolean | null;
  department?: string | null;
  email?: string | null;
  zenefitsEmployees?: JSON | null;
}
/** TODO: this type is used as the return type placeholder for all data grid related mutations,once we figure out what data to return for all mutations, this should be removed */
export interface DatagridMutationResponse {
  success?: boolean | null;
}

export interface EmployeeBadRecords {
  resolved?: boolean | null;
  employee?: AllEmployee | null;
  verbose_key?: string | null;
  reason?: string | null;
  key?: string | null;
  pushType?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeLifeEventQualification {
  needSpouseInfo?: boolean | null;
  needDomesticPartnerInfo?: boolean | null;
  needRelevantDependentInfo?: boolean | null;
  action?: number | null;
  needChildInfo?: boolean | null;
  needAddressInfo?: boolean | null;
  needEmployeeInfo?: boolean | null;
}

export interface EmployeeOrRequestIdMessage {
  employeeId?: string | null;
  requestId?: string | null;
}

export interface EmployeeTaxFields {
  MAresidenceStatePersonalBlindness?: boolean | null;
  INstateAdditionalDependentExemptions?: number | null;
  WVstateOneEarnerWithholdAtLowerRate?: boolean | null;
  MAstateFullTimeStudent?: boolean | null;
  MAstatePersonalBlindness?: boolean | null;
  MOresidenceStateSpouseWorks?: boolean | null;
  id?: string | null;
  employee?: AllEmployee | null;
  GAresidenceStateDependentAllowance?: number | null;
  MAresidenceStateFullTimeStudent?: boolean | null;
  GAstatePersonalAllowance?: number | null;
  GAresidenceStatePersonalAllowance?: number | null;
  VAresidenceStateAgeAndBlindnessExemptions?: number | null;
  NJresidenceStateWageChartLetter?: string | null;
  MAstateSpouseBlindness?: boolean | null;
  NJstateWageChartLetter?: string | null;
  MAresidenceStateSpouseBlindness?: boolean | null;
  LAstateNumberOfDependents?: number | null;
  INresidenceStateAdditionalCountyWithholdings?: string | null;
  WVresidenceStateOneEarnerWithholdAtLowerRate?: boolean | null;
  VAstateAgeAndBlindnessExemptions?: number | null;
  ILresidenceStateAdditionalAllowance?: number | null;
  INstateAdditionalCountyWithholdings?: string | null;
  GAstateDependentAllowance?: number | null;
  LAresidenceStateNumberOfDependents?: number | null;
  INresidenceStateAdditionalDependentExemptions?: number | null;
  MOstateSpouseWorks?: boolean | null;
  ILstateAdditionalAllowance?: number | null;
  resource_uri?: string | null;
}

export interface EmployeeTestContext {
  personalInfo?: PersonalInfoContext | null;
}

export interface Folder {
  id: string;
  name?: string | null;
  companyId?: number | null;
  isDeleted?: boolean | null;
  canViewGroupId?: number | null;
  canEditGroupId?: number | null;
  canDeleteGroupId?: number | null;
}

export interface GroupMember {
  id: string;
  employee: AllEmployee;
}

export interface HelpArticleInfo {
  link?: string | null;
  resource_uri?: string | null;
  pageHelpArticle?: PageHelpArticle | null;
  id?: string | null;
  title?: string | null;
}

export interface PageHelpArticle {
  title?: string | null;
  tags?: string | null;
  searchKeyword?: string | null;
  pageRoute?: string | null;
  helpArticleInfo?: (HelpArticleInfo | null)[] | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface HraEmployeeEnrollment {
  status?: string | null;
  startDate?: string | null;
  employee?: AllEmployee | null;
  enrolledInMedicare?: string | null;
  bankAccountNumber?: string | null;
  resource_uri?: string | null;
  next?: HraEmployeePlan | null;
  autoEnrolled?: boolean | null;
  bankAccountType?: string | null;
  name?: string | null;
  summaryPlanDescriptionUrl?: string | null;
  originalPlanStartDate?: string | null;
  overrideEnrollmentDeadline?: string | null;
  signature?: string | null;
  date?: string | null;
  medicareID?: string | null;
  bankRoutingNumber?: string | null;
  current?: HraEmployeePlan | null;
  agreement?: Document | null;
  id?: string | null;
  isHighlyCompensatedEmployee?: boolean | null;
}

export interface HraEmployeePlan {
  status?: string | null;
  startDate?: string | null;
  overrideEnrollmentDeadline?: string | null;
  isPastEnrollmentDeadline?: boolean | null;
  autoEnrolled?: boolean | null;
  enrollmentDeadline?: string | null;
  hra?: HraEmployeeEnrollment | null;
  companyPlan?: HraCompanyPlan | null;
  agreementSignature?: Signature | null;
  agreement?: Document | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface InboxProductIntro {
  seenDate?: string | null;
  resource_uri?: string | null;
  employeeId?: number | null;
  id?: string | null;
}

export interface InboxSnoozeLog {
  snoozeDate?: string | null;
  inboxAction?: InboxAction | null;
  snoozedFor?: number | null;
  reason?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface MatchingCriteria {
  product_id: string;
  matchingCriteria?: string | null;
  target?: string | null;
  condition?: string | null;
  lowerRange?: number | null;
  upperRange?: number | null;
  strictMatchOrNot?: boolean | null;
}

export interface MobileEmployeePtoAccount {
  isStagingAccount?: boolean | null;
  accruedUntil?: Date | null;
  useable?: string | null;
  pto?: EmployeePto | null;
  startFromHireDate?: boolean | null;
  lastCheckedRollover?: string | null;
  accrualFrequency?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  tenureAccrualBonus?: string | null;
  hourlyAccrualEligibleDate?: string | null;
  hoursUsedYTD?: string | null;
  scheduledHours?: string | null;
  accrualRate?: string | null;
  balance?: Decimal | null;
  type?: string | null;
  id?: string | null;
  requestsAllowedSince?: string | null;
  resource_uri?: string | null;
}

export interface MobileInboxSubAction {
  null?: (ConfirmationSubAction | null)[] | null;
  description?: string | null;
  inboxAction?: InboxAction | null;
  employee_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface MobileVacationType {
  status?: string | null;
  pto?: EmployerPto | null;
  countsAs?: string | null;
  name?: string | null;
  id?: string | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  isAvailable?: string | null;
  order?: number | null;
  resource_uri?: string | null;
}

export interface parentTopicsData {
  parentTopicList?: (issueTopics | null)[] | null;
}

export interface Permission {
  codename?: string | null;
  productName?: string | null;
  description?: string | null;
  id?: string | null;
}

export interface ProductCategories {
  categories?: (ProductCategory | null)[] | null;
}

export interface PromotionRedemption {
  promotion?: BillingPromotion | null;
  resource_uri?: string | null;
  company_id?: number | null;
  id?: string | null;
}

export interface QualifyingEventWaiver {
  migratedFrom?: string | null;
  otherReason?: string | null;
  otherIDNumber?: string | null;
  idCardUrl?: string | null;
  datetime?: DateTime | null;
  lineOfCoverage?: string | null;
  reason?: string | null;
  waiveReason?: WaiverMetadata | null;
  signature?: string | null;
  qualifyingEvent?: QualifyingEvent | null;
  otherCarrier?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface Role {
  rght?: number | null;
  name?: string | null;
  parent?: Role | null;
  level?: number | null;
  lft?: number | null;
  tree_id?: number | null;
  id?: string | null;
  isActive?: boolean | null;
  permissions?: (Permission | null)[] | null;
}

export interface SalaryBenchmarkAggr {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  displayFlag?: boolean | null;
  salaryAvg?: number | null;
  salary25thPerc?: number | null;
  salary50thPerc?: number | null;
  salary75thPerc?: number | null;
  salaryStdDev?: number | null;
  noOfDpBelow25?: number | null;
  noOfDpBtw2550?: number | null;
  noOfDpBtw5075?: number | null;
  noOfDpAbove75?: number | null;
}

export interface ScrapedAudit {
  lastRun?: DateTime | null;
  firstRun?: DateTime | null;
  argsJSON?: string | null;
  auditVersion?: string | null;
  resource_uri?: string | null;
  numErrors?: number | null;
  id?: string | null;
  name?: string | null;
}

export interface SetupOrEditPolicyResponse {
  responseStatus?: boolean | null;
  loaPolicy?: LoaPolicyResponse | null;
}

export interface ShiftDetailsFormData {
  schedulingEmployees?: (SchedulingEmployee | null)[] | null;
  positions?: (Group | null)[] | null;
}

export interface Smp {
  isCompanyLocationsComplete?: boolean | null;
  isPayrollReports?: boolean | null;
  isNativeDeductionsReport?: boolean | null;
  syncType?: string | null;
  isNativeNewHireReport?: boolean | null;
  isNativeModReport?: boolean | null;
  isBasicCompanyInfoComplete?: boolean | null;
  id?: string | null;
  isAddEmployeesComplete?: boolean | null;
  isFileSync?: boolean | null;
  isNativeTerminationReport?: boolean | null;
  company?: Company | null;
  blockPeriod?: number | null;
  status?: string | null;
  isBulkEmailComplete?: boolean | null;
  isNative?: boolean | null;
  bulkUpload?: BulkUpload | null;
  isActive?: boolean | null;
  badModCount?: number | null;
  isBulkValidationComplete?: boolean | null;
  isNativeTaReport?: boolean | null;
  hasStarted?: boolean | null;
  resource_uri?: string | null;
}

export interface SmpRun {
  deductionCount?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  terminationCount?: number | null;
  fullReportUrl?: string | null;
  eeTerminationModSummary?: string | null;
  taCount?: number | null;
  eeDeductionsReportCreateOn?: DateTime | null;
  id?: string | null;
  terminatedEmployeeModificationCount?: number | null;
  modificationCount?: number | null;
  eeNewHireSummary?: string | null;
  newHireCount?: number | null;
  previousRunDay?: string | null;
  newHireReportCreateOn?: DateTime | null;
  eeModReportUrl?: string | null;
  terminatedEmployeeIds?: string | null;
  fullReportCreateOn?: DateTime | null;
  missingPayrollIdEmployeeIds?: string | null;
  eeModReportCreateOn?: DateTime | null;
  taReportUrl?: string | null;
  eeTerminationSummary?: string | null;
  eeNonPushModReportUrl?: string | null;
  newHireReportUrl?: string | null;
  currentRunDay?: string | null;
  isOpen?: boolean | null;
  eeDeductionsSummary?: string | null;
  eeTerminationReportCreateOn?: DateTime | null;
  companyPaySchedule?: CompanyPaySchedule | null;
  eeDeductionsReportUrl?: string | null;
  taReportCreateOn?: DateTime | null;
  checkDate?: string | null;
  eeModSummary?: string | null;
  nonPushableEmployeeModificationDetails?: string | null;
  eeTerminationReportUrl?: string | null;
  resource_uri?: string | null;
}

export interface SubmitPaymentMethodInfoResponse {
  success?: boolean | null;
}

export interface TagsProductMeta {
  isProductName?: boolean | null;
  subcategories?: (TagsProductMeta | null)[] | null;
  adminDisplayName?: string | null;
  name?: string | null;
  adminDisplayContext?: string | null;
  contentObjectDetails?: string | null;
  permission?: Permission | null;
  isDisplayableToAdmin?: boolean | null;
  lineOfCoverage?: string | null;
  consoleDisplayName?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface TargetEmployeeResponse {
  id?: number | null;
  preferredOrFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
}

export interface TasksClassdivisioncodesbusinessstep {
  company?: Company | null /** task: WfStep */;
  isActionable?: boolean | null;
  isApplicable?: boolean | null;
  acknowledgeAction?: boolean | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  resource_uri?: string | null;
  id?: string | null;
  answerQuestionOne?: string | null;
}

export interface TasksGenericborauditbusinessstep {
  company?: Company | null /** task: WfTask */;
  isActionable?: boolean | null;
  businessStepType?: string | null;
  lineOfCoverage?: string | null;
  isApplicable?: boolean | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface TasksQualifyingeventbusinessstep {
  category?: string | null;
  isActionable?: boolean | null /** task: WfStep */;
  businessStepType?: string | null;
  qualifyingEvent?: QualifyingEvent | null;
  isApplicable?: boolean | null;
  id?: string | null;
  linesOfCoverage?: JSON | null;
  resource_uri?: string | null;
}

export interface UnlinkUserAccounts {
  employeeId: number;
}

export interface UploadedDocument {
  is_deleted?: boolean | null;
  name?: string | null;
  tags?: (UploadedDocumentTag | null)[] | null;
  file_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface UploadedDocumentTag {
  resource_uri?: string | null;
  id?: string | null;
  name?: string | null;
}

export interface UserAccountIdsToMerge {
  existingUid?: string | null;
  uid?: string | null;
}

export interface ZenefitsPageSuggestion {
  category?: string | null;
  url?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  title?: string | null;
}

export interface ZPayrollAccountingAccount {
  accountingOrganization?: ZPayrollAccountingOrganization | null;
  description?: string | null;
  accountName?: string | null;
  isReferenceBankAccount?: boolean | null;
  accountType?: string | null;
  id?: string | null;
  accountID?: string | null;
}

export interface ZPayrollAccountingOrganization {
  accessToken?: string | null;
  automaticExportOn?: boolean | null;
  zpCompany?: ZPayrollCompany | null;
  accountingProvider?: string | null;
  accessTokenSecret?: string | null;
  oauthSessionHandle?: string | null;
  accountingOrganizationId?: string | null;
  id?: string | null;
}

export interface ZPayrollAccountingExportStatus {
  accountingOrganization?: ZPayrollAccountingOrganization | null;
  lastExportTime?: Time | null;
  journalEntryId?: string | null;
  isExported?: boolean | null;
  isErrorEmailSent?: boolean | null;
  id?: string | null;
}

export interface ZPayrollCompanyContributionType {
  category?: string | null;
  isPercentage?: boolean | null;
  name?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  annualMax?: string | null;
  value?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isCreatedForDNP?: boolean | null;
  version_id?: number | null;
  contribution?: string | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyDeductionType {
  category?: string | null;
  isPercentage?: boolean | null;
  name?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  annualMax?: string | null;
  value?: string | null;
  deduction?: string | null;
  zpCompany?: ZPayrollCompany | null;
  version_id?: number | null;
  isCreatedForDNP?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyDocument {
  category?: string | null;
  isJurisdictionDocument?: boolean | null;
  shouldShowZPDocument?: string | null;
  description?: string | null;
  signatureName?: string | null;
  title?: string | null;
  needsNotary?: string | null;
  version_id?: number | null;
  isComplete?: boolean | null;
  notarizedFormUrl?: string | null;
  notarizedDate?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isNotarized?: boolean | null;
  isSigned?: boolean | null;
  requireSignature?: string | null;
  signatureDate?: string | null;
  signature?: string | null;
  id?: string | null;
}

export interface ZPayrollCompanyEarningType {
  category?: string | null;
  ratePerUnit?: string | null;
  name?: string | null;
  unitName?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  isRatePerUnit?: boolean | null;
  regularEarningMultiplier?: string | null;
  zpCompany?: ZPayrollCompany | null;
  amount?: string | null;
  isSupplemental?: boolean | null;
  accountingCode?: string | null;
  isMultipleOfRegularEarning?: boolean | null;
  accrueTimeOff?: boolean | null;
  isCreatedForDNP?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyOtherExpenseType {
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  id?: string | null;
  expenseName?: string | null;
  zpCompany?: ZPayrollCompany | null;
}

export interface ZPayrollCompanyPriorPayrollDocument {
  url?: string | null;
  uploadedOn?: DateTime | null;
  documentName?: string | null;
  id?: string | null;
  zpCompany?: ZPayrollCompany | null;
}

export interface PartnerCompanyFilter {
  companyNameFilter?: string | null;
  sicCode?: string | null;
  state?: string | null;
  parentCompanyId?: string | null;
}

export interface SendToFilter {
  filterName?: string | null;
  filterValues?: (string | null)[] | null;
}

export interface InputDateRangeMessage {
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  employeeId?: number | null;
}

export interface PolicyDeleteRequest {
  policyNameToDelete?: string | null;
  policyId?: string | null;
}

export interface LoaPolicyRequest {
  id?: string | null;
  originalPolicyId?: string | null;
  companyId?: string | null;
  name?: string | null;
  reasons?: (InputLoaReason | null)[] | null;
  ptoAccrual?: string | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  requiredDocuments?: (InputLoaRequiredDocument | null)[] | null;
  isStaging?: boolean | null;
  isActive?: boolean | null;
  setupState?: string | null;
}

export interface InputLoaReason {
  id?: string | null;
  name?: string | null;
}

export interface InputLoaRequiredDocument {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
}

export interface EditEmployeeEligibilityRequest {
  employeeId: string;
  lineOfCoverage: string;
  effectiveDate: string;
  isEligible: boolean;
}

export interface UpdateDependentRequest {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  type?: string | null;
  dateOfBirth?: string | null;
  socialSecurity?: string | null;
  email?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  sameAddress?: boolean | null;
  sameEmail?: boolean | null;
  isSmoker?: boolean | null;
  isDependentDisabled?: boolean | null;
  isFullTimeStudent?: boolean | null;
  autoSelectPCP?: boolean | null;
  samePCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  autoSelectDentalPCP?: boolean | null;
  sameDentalPCP?: boolean | null;
  hmoDentalName?: string | null;
  hmoDentalProviderNumber?: string | null;
  hmoDentalExistingPatient?: boolean | null;
}

export interface DeleteDependentRequest {
  id: string;
  isDryRun?: boolean | null;
}

export interface UpdateBeneficiaryRequest {
  id: string;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  entityName?: string | null;
  entityType?: string | null;
  dateOfBirth?: string | null;
  phone?: string | null;
  entityDateEstablished?: string | null;
  hasSocialSecurity?: boolean | null;
  socialSecurity?: string | null;
  isEnrolledInInsurance?: boolean | null;
  percentage?: string | null;
}

export interface UpdateAddressRequest {
  id: string;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface UpdateEmployeeRequest {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  dob?: string | null;
  phone?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  socialSecurity?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurityExpectedDate?: string | null;
}

export interface UpdatePersonalInfoRequest {
  id: string;
  jobTitle?: string | null;
  salary?: string | null;
  maritalStatus?: string | null;
  marriageDate?: string | null;
  marriageState?: string | null;
  marriageCountry?: string | null;
  contactPreference?: string | null;
}
/** Message structure used to represent plan amount change. */
export interface AmountApprovalEntry {
  enrolleeId: string;
  enrolleeType: string;
  amount: number;
}

export interface MedicalSbcPlanDesignRequest {
  deductibleIndividualPreferredNetwork?: number | null;
  deductibleIndividual?: number | null;
  deductibleIndividualOutOfNetwork?: number | null;
  oopMaxIndividualPreferredNetwork?: number | null;
  deductibleFamily?: number | null;
  deductibleFamilyOutOfNetwork?: number | null;
  oopMaxIndividual?: number | null;
  oopMaxIndividualOutOfNetwork?: number | null;
  oopMaxFamilyPreferredNetwork?: number | null;
  oopMaxFamily?: number | null;
  oopMaxFamilyOutOfNetwork?: number | null;
  pharmacyDeductiblePreferredNetwork?: number | null;
  pharmacyDeductible?: number | null;
  pharmacyDeductibleOutOfNetwork?: number | null;
  deductibleReset?: string | null;
  oopMaxIncludeDeductible?: boolean | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
}

export interface DentalSbcPlanDesignRequest {
  deductibleIndividual?: number | null;
  deductibleIndividualOutOfNetwork?: number | null;
  deductibleFamily?: number | null;
  deductibleFamilyOutOfNetwork?: number | null;
  maxBenefits?: number | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
}

export interface VisionSbcPlanDesignRequest {
  coPay?: string | null;
  examFrequency?: number | null;
  lensFrequency?: number | null;
  lensAllowable?: string | null;
  lensAllowableCondition?: number | null;
  lensAllowableSecondary?: string | null;
  frameFrequency?: number | null;
  frameAllowable?: string | null;
  frameAllowableCondition?: number | null;
  frameAllowableSecondary?: string | null;
  contactsFrequency?: number | null;
  contactsAllowable?: string | null;
  contactsAllowableCondition?: number | null;
  lasikCoverage?: boolean | null;
  retailDiscountAvailable?: boolean | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
}

export interface RemoveEnrolleeEntry {
  enrolleeId: string;
  enrolleeType: string;
}

export interface ProspectInfo {
  formData: FormData;
  contextData: ContextData;
  formMetaData: FormMetaData;
  syncData: SyncData;
}

export interface FormData {
  email: string;
}

export interface ContextData {
  referrer: string;
  location: LocationInfo;
}

export interface LocationInfo {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  origin: string;
  path: string;
  port: string;
  search: string;
  protocol: string;
}

export interface FormMetaData {
  formId: string;
  formType: string;
  formStep: number;
  formIsFinalStep: boolean;
}

export interface SyncData {
  instantSubmit: boolean;
  handlerName: string;
  syncTo: MarketoListId;
}

export interface MarketoListId {
  marketoListId: number;
}

export interface UpdateCheckoutBundleRequest {
  bundleId: string;
  offerType?: string | null;
  billingPeriod?: string | null;
  numberOfEmployees?: number | null;
  numberOfContingentWorkers?: number | null;
  baseProduct?: string | null;
  optionalProducts?: (string | null)[] | null;
  implementationProduct?: string | null;
}

export interface AdditionalBundleParams {
  estimatedMonthlyTotal?: number | null /** price related data */;
  monthlySavings?: number | null;
  estimatedAnnualTotal?: number | null;
  currentBrokerName?: string | null /** benefits related data */;
  currentBrokerId?: string | null;
  companyZip?: string | null;
  hasPriorBenefits?: string | null;
  implementationFee?: number | null /** implementation related data */;
}

export interface UpdatePaymentMethodInfo {
  zuoraId?: string | null;
  holderName?: string | null;
  maskedNumber?: string | null;
  last4?: string | null;
  type?: string | null;
  isActive?: boolean | null;
  expirationMonth?: number | null /** credit card fields */;
  expirationYear?: number | null;
  cardType?: string | null;
  bankName?: string | null /** ach fields */;
  routingNumber?: string | null;
  accountType?: string | null;
}

export interface CustomerInfo {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyName?: string | null;
  companyZip?: string | null;
  companyPhone?: string | null;
}

export interface QFCommentInput {
  questionId?: string | null;
  sectionId?: string | null;
  text: string;
}

export interface TalentFlowScheduleInput {
  event: TalentFlowScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration?: number | null;
}

export interface ReviewerSessionInput {
  reviewerId: string;
  type: ReviewSessionType;
}

export interface WellbeingAnalyticsEventDataInput {
  contentType: WellbeingAnalyticsContentType;
  contentId?: string | null;
  metadata: JSON;
}

export interface SurveyScheduleInput {
  duration: number;
}

export interface VariableCompensationInput {
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: number | null;
  amount?: number | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}

export interface SalaryBenchmarkingBlacklistInput {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  isActive?: boolean | null;
}

export interface ReportFilter {
  effectiveDate?: string | null;
  payrollFilter?: PayrollFilter | null;
}

export interface PayrollFilter {
  startDate?: string | null;
  endDate?: string | null;
  runId?: string | null;
}

export interface CustomReportMetaDataInput {
  name?: string | null;
  fields?: (string | null)[] | null;
  headers?: (ReportHeader | null)[] | null;
  fieldFilters?: (FieldFilter | null)[] | null;
  isPointInTime?: boolean | null;
  includeTerminatedEmployees?: boolean | null;
  includeEmployeeDependentsAsRows?: boolean | null;
}

export interface ReportHeader {
  headerId?: string | null;
  headerName?: string | null;
}

export interface FieldFilter {
  field?: string | null;
  fieldValue?: FieldFilterValue | null;
}

export interface FieldFilterValue {
  type?: number | null;
  value?: (string | null)[] | null;
}

export interface FulfillmentAttachmentInput {
  url: string;
  filename: string;
}

export interface FullStpEmailAttachmentInput {
  url: string;
  filename: string;
}

export interface SetPhysicianDentistInput {
  autoSelectPCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  dependents?: (DependentPhysicianDentistInput | null)[] | null;
}

export interface DependentPhysicianDentistInput {
  id?: string | null;
  samePCP?: boolean | null;
  autoSelectPCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
}

export interface SetBeneficiaryInput {
  beneficiaries?: (BeneficiaryInput | null)[] | null;
}

export interface BeneficiaryInput {
  id?: string | null;
  percentage?: string | null;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  entityName?: string | null;
  relationship?: string | null;
  ssn?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: string | null;
  entityType?: string | null;
  entityDateEstablished?: string | null;
}

export interface SubmitEnrollmentWaiverInput {
  benefitsType: string;
  effectiveDate: string;
  employee?: WaiverContextInput | null;
  dependents?: (WaiverContextInput | null)[] | null;
}

export interface WaiverContextInput {
  id?: string | null /** only applicable for dependents */;
  otherCarrier?: string | null;
  reasonForDecliningCoverage?: string | null;
  waiveReasonName?: string | null;
  otherIdNumber?: string | null;
  idCardUrl?: string | null;
}
/** Message structure used to represent the creation of an enrollment for a specific line. */
export interface CreateEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
  enrollmentType: string;
  endDate: Date;
}
/** Message structure used to represent the enrollment date change for a single line. */
export interface ChangeEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
  newEffectiveDate?: Date | null;
  newEndDate?: Date | null;
}
/** Message structure used to represent the enrollment cancellation for a specific line. */
export interface CancelEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
}

export interface AddQleDocumentReviewRequest {
  decision: string;
  comment?: string | null;
}

export interface CreateQleRequest {
  type?: string | null;
  subType: string;
  eventDate: string;
  validationOnly?: boolean | null;
  effectiveDate?: string | null;
  linesOfCoverages?: (string | null)[] | null;
}

export interface QleRemoveDependentsRequest {
  authSignature: string;
  authName: string;
  qualifyingEventId: string;
  removedDependentsData: RemoveDependentData[];
}

export interface RemoveDependentData {
  lineOfCoverage: string;
  dependents: string[];
}

export interface QleCancelCoverageRequest {
  authSignature: string;
  authName: string;
  cancelledLinesData: CancelCoverageData[];
  reason: string;
}

export interface CancelCoverageData {
  lineOfCoverage: string;
  effectiveDate: string;
}

export interface CompanyWorkLocation {
  id?: string | null;
  name: string;
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  country?: string | null;
  zip: string;
}

export interface CompanySetupBasicEmployeeInput {
  id?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  location_id?: string | null;
}

export interface CompanySetupAdditionalInfoEmployeeInput {
  id: string;
  hireDate?: Date | null;
  employmentType?: EmploymentType | null;
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
}

export interface FlowSectionUpdate {
  isEntered?: boolean | null;
  isComplete?: boolean | null;
}

export interface OpenEnrollmentPeriodInput {
  startDate: Date;
  endDate: Date;
}

export interface GroupApplicationSignatureInput {
  dataUrl: string;
  date: Date;
  valid?: boolean | null;
}

export interface StpFormTemplateInput {
  name?: string | null;
  description?: string | null;
  isLive?: boolean | null;
  templateUrl?: string | null;
  category?: string | null;
  FieldMappings?: (StpFieldMappingInput | null)[] | null;
  id?: string | null;
  groups?: (StpFieldGroupInput | null)[] | null;
}

export interface StpFieldMappingInput {
  expression?: string | null;
  fieldName?: string | null;
  id?: string | null;
  fieldValidationType?: string | null;
}

export interface StpFieldGroupInput {
  fields?: (string | null)[] | null;
  id?: string | null;
  name?: string | null;
  operationType?: string | null;
  operationInteger?: string | null;
}

export interface StpBaseFieldMappingInput {
  baseFieldName?: string | null;
  value?: string | null;
  fieldType?: string | null;
}

export interface DepartmentChangeInput {
  id?: string | null;
  groupId: string;
  isDefault: boolean;
}

export interface PositionRateChangeInput {
  groupId: string;
  rate?: number | null;
}

export interface EmployeeDetailsAddressChangeInput {
  createWorkLocation: boolean;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  country: string;
  postalCode?: string | null;
}

export interface TerminationDetailsInput {
  employeeId: string /** Basic InfoFields match EmployeeTerminationAction model */;
  employmentId: string;
  status: string;
  terminationType: string;
  terminationDate: string;
  terminationCategory: string;
  terminatedOn?: Date | null;
  eligibleForRehire?: boolean | null;
  cancelAutoPay?: boolean | null;
  reportsNewManagerId?: string | null;
  unusedPTOHours?: Decimal | null;
  unusedPTOAmount?: Decimal | null;
  removeFromBenefits?: boolean | null /** Benefits related fields */;
  askedBenefitsTerminationQuestion?: boolean | null;
  deactivateEmailIfRemoving?: boolean | null;
  administerCOBRA?: boolean | null;
  coverFullCobra?: boolean | null;
  cobraMonths?: number | null;
  removeFromIT?: boolean | null;
  removeFromPayroll?: boolean | null /** Payroll related fields */;
  isPushedToPayroll?: boolean | null;
  issueFinalPaycheck?: boolean | null;
  payrollTerminationReasonId?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  email?: string | null;
  socialSecurity?: string | null;
  dob?: Date | null;
  reason?: string | null;
  uniqueId?: string | null;
  fromConsole?: boolean | null /** Source of termination action */;
  isCanceledInEditing?: boolean | null;
  isRequestedInEditing?: boolean | null;
}

export interface BenefitsDetailsInput {
  requestedById?: string | null;
  borTerminateMedical?: boolean | null;
  borTerminateDental?: boolean | null;
  borTerminateVision?: boolean | null;
  borTerminateLife?: boolean | null;
  borTerminateSTD?: boolean | null;
  borTerminateLTD?: boolean | null;
  borTerminateADD?: boolean | null;
  borBenefitsMedicalPlanType?: string | null;
  borBenefitsMedicalEffectiveDate?: Date | null;
  borBenefitsMedicalPlanId?: string | null;
  borBenefitsMedicalCost?: Decimal | null;
  borBenefitsDentalPlanType?: string | null;
  borBenefitsDentalEffectiveDate?: Date | null;
  borBenefitsDentalPlanId?: string | null;
  borBenefitsDentalCost?: Decimal | null;
  borBenefitsVisionPlanType?: string | null;
  borBenefitsVisionEffectiveDate?: Date | null;
  borBenefitsVisionPlanId?: string | null;
  borBenefitsVisionCost?: Decimal | null;
}

export interface CobraTPADetailsInput {
  selectedCobraTPAId?: string | null;
  companyCobraId?: string | null;
}

export interface SchedulingShiftGroupInput {
  groupId?: string | null;
  groupTypeId?: string | null;
  id?: string | null;
  shiftId?: string | null;
}

export interface SchedulingShiftSeriesInput {
  id?: number | null;
  shiftId?: number | null;
  frequency?: number | null;
  onDays?: (number | null)[] | null;
  rangeEndDate?: Date | null;
  originalStartDate?: Date | null;
}

export interface PlaidMetaData {
  link_session_id?: string | null;
  institution?: Institution | null;
  account?: PlaidAccountDetail | null /** Plaid api returns a  "selected account" and list of accounts */;
  accounts?: (PlaidAccountDetail | null)[] | null;
  public_token?: string | null;
  account_id?: string | null;
}

export interface Institution {
  name?: string | null;
  institution_id?: string | null;
}

export interface PlaidAccountDetail {
  id?: string | null;
  name?: string | null;
  mask?: number | null;
  type?: string | null;
  subtype?: string | null;
}

export interface BankDetails {
  accountHolderName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  verificationType: string;
}

export interface UpdateAccountInfo {
  payType?: string | null;
  categoryName?: string | null;
  accountingAccount?: GetAccountInfo | null;
  includedFields?: (MappingColumn | null)[] | null;
}

export interface GetAccountInfo {
  id?: number | null;
  accountNumber?: string | null;
  subAccountNumber?: string | null;
  accountType?: string | null;
}

export interface MappingColumn {
  columnName?: string | null;
  columnCode?: string | null;
}

export interface PayTypeRequest {
  payTypeId?: string | null;
  garnishmentCategory?: string | null;
}

export interface FolderPermission {
  worker?: string | null;
  workerId?: string | null;
  permissions?: (FolderPermissionType | null)[] | null;
}

export interface LoaRequiredDocumentRequest {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
  loaPolicy_id?: string | null;
  isDeleted?: boolean | null;
}

export interface PolicyActionRequest {
  policyNameToDelete?: string | null;
  policyId?: string | null;
  policyName?: string | null;
  policyType?: string | null;
  newPolicyName?: string | null;
}

export interface InputLoaDocumentUploaded {
  id?: string | null;
  uploadedFileId?: number | null;
  uploadedFileName?: string | null;
  requiredDocumentId?: number | null;
  requiredDocumentType?: string | null;
}

export interface AddressSubActionInput {
  city?: string | null;
  street1?: string | null;
  street2?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface BankSubActionInput {
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankAccountType?: string | null;
  bankSwiftCode?: string | null;
  bankBranchName?: string | null;
  bankRoutingNumber?: string | null;
  additionalRoutingNumber?: string | null;
  country?: string | null;
  isActive?: boolean | null;
}

export interface SignatureSubActionInput {
  name?: string | null;
  signature?: string | null;
}

export interface ContentReviewSubActionInput {
  decision?: string | null;
  comment?: string | null;
}

export interface DatagridColumnMappingInput {
  systemColumnId: string;
  importedColumnName: string;
  valueMappings: DatagridValueMappingInput[];
}

export interface DatagridValueMappingInput {
  systemValue?: string | null;
  importedValues: string[];
}

export interface DatagridRowInput {
  id?: string | null;
  data: JSON;
}

export interface EmployeeEligibilityPart1Input {
  id?: string | null;
  newHireId: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  otherLastNames?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  streetAddress: string;
  apartmentNumber: string;
  city: string;
  state: string;
  zipCode: string;
  dateOfBirth: Date;
  socialSecurityNumber?: string | null;
  isWaitingForSSNInfo: boolean;
}

export interface EmployeeEligibilityPart2Input {
  id: string;
  newHireId: string;
  eligibilityType: string;
  workExpirationDate?: Date | null;
  documentType?: string | null;
  documentNumber?: string | null;
  documentIssuedCountry?: string | null;
  translatorUsed: boolean;
}

export interface EmployeeEligibilityReviewInput {
  id: string;
  signatureDataUrl: string;
}

export interface EmployeeEligibilityDocumentsInput {
  id: string;
  documents: EmployeeEligibilityDocumentInput[];
}

export interface EmployeeEligibilityDocumentInput {
  documentType: string;
  documentName: string;
  uploadedFileKey: string;
}

export interface EligibilityCorrectionCreationInput {
  eligibilityId: string;
  changeReason: string;
  isSectionOneCorrection: boolean;
}

export interface EmployerEligibilityDocumentsInput {
  employerEligibilityId: string;
  documentListType: string;
  documents: EmployerEligibilityDocumentInput[];
  additionalInfo?: string | null;
}

export interface EmployerEligibilityDocumentInput {
  id?: string | null;
  rank: number;
  title: string;
  documentNumber: string;
  expirationDate?: DateTime | null;
  issuingAuthority: string;
  associatedDocuments?: EmployerEligibilityAssociatedDocumentInput[] | null;
}

export interface EmployerEligibilityAssociatedDocumentInput {
  id?: string | null;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EmployerEligibilityCertificationInput {
  id: string;
  employeeFirstEmploymentDay: DateTime;
  employerEligibilitySignatureFirst: string;
  employerEligibilitySignatureLast: string;
  employerEligibilitySignatureTitle: string;
  organizationName: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zipCode: string;
}

export interface EmployerEligibilityAcknowledgementInput {
  id: string;
  employerSignatureDataUrl: string;
  employerSignatureProvidedDate: DateTime;
}

export interface EligibilityCorrectionAcknowledgementInput {
  id: string;
  eligibilityId: string;
  employerSignatureDataUrl: string;
  correctionMemo?: string | null;
  correctionFullName: string;
}

export interface EligibilityReverificationInput {
  id?: string | null;
  eligibilityId: string;
  newFirstName?: string | null;
  newLastName?: string | null;
  newMiddleInitial?: string | null;
  reHireDate?: DateTime | null;
  reverifyFullName: string;
  document?: EligibilityReverificationDocumentInput | null;
}

export interface EligibilityReverificationDocumentInput {
  id?: string | null;
  title: string;
  documentNumber: string;
  expirationDate?: DateTime | null;
  associatedDocuments?: EligibilityReverificationAssociatedDocumentInput[] | null;
}

export interface EligibilityReverificationAssociatedDocumentInput {
  id?: string | null;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EligibilityReverificationCertificationInput {
  id: string;
  eligibilityId: string;
  signatureDataUrl: string;
  signatureProvidedDate: DateTime;
}

export interface TaxLiability {
  quarterName: string;
  taxDetails: TaxDetail[];
}

export interface TaxDetail {
  id: string;
  ledgerIds: string[];
  taxName: string;
  taxType: string;
  responsibility: string;
  taxFrequency?: string | null;
  totalSubjectWages?: string | null;
  totalTaxAmount?: string | null;
  zenefitsWillDeposit?: string | null;
}

export interface NewHireCustomFieldValueDetails {
  customFieldValueId: string;
  customFieldValue?: string | null;
}

export interface FilterOptions {
  filterName: string;
  checked: boolean;
}

export interface InputLeaveOfAbsenceRequestMessage {
  id?: string | null;
  companyId?: number | null;
  employeeId?: number | null;
  leaveType?: string | null;
  policyId?: number | null;
  reasonId?: number | null;
  status?: string | null;
  additionalNotes?: string | null;
  estimatedDates?: (InputDateRangeMessage | null)[] | null;
  actualDates?: (InputDateRangeMessage | null)[] | null;
  documentsUploaded?: (InputLoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (InputLoaVacationTypeMessage | null)[] | null;
  paymentOption?: string | null;
  createdBy?: number | null;
  modifiedBy?: number | null;
  responseStatus?: number | null;
  responseMessage?: string | null;
}

export interface InputLoaVacationTypeMessage {
  id?: number | null;
  vacationTypeId?: number | null;
  name?: string | null;
  ptoId?: number | null;
}

export interface InputPolicy {
  policyName?: string | null;
  policyType?: string | null;
  policyId?: string | null;
}
export interface DashboardQueryArgs {
  id?: string | null;
}
export interface SwitchesQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
}
export interface InboxActionQueryArgs {
  id?: string | null;
}
export interface CompanyQueryArgs {
  id?: string | null;
}
export interface EmployeeQueryArgs {
  id?: string | null;
}
export interface FilterEmployeeQueryArgs {
  id: string;
}
export interface NewHireQueryArgs {
  id: string;
}
export interface EmployeeEligibilityQueryArgs {
  id: string;
}
export interface CompanySearchQueryArgs {
  name?: string | null;
}
export interface SignatureQueryArgs {
  id?: string | null;
}
export interface PayRunOptionsQueryArgs {
  companyId?: string | null;
  isConsoleUser?: boolean | null;
}
export interface EmployeeCompensationsQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
}
export interface ChangeRequestQueryArgs {
  id: string;
}
export interface GetVariableCompensationQueryArgs {
  employeeId: string;
  index?: number | null;
}
export interface FetchAllVariableCompsQueryArgs {
  employeeId: string;
}
export interface FlowQueryArgs {
  id: string;
}
export interface SearchClientsQueryArgs {
  text?: string | null;
}
export interface InboxTaskQueryArgs {
  id: string;
}
export interface InboxActionCommentsQueryArgs {
  id: string;
}
export interface QualifyingEventsQueryArgs {
  ids?: string[] | null;
}
export interface FilteredCompaniesQueryArgs {
  ids?: string[] | null;
}
export interface InboxTaskSearchQueryArgs {
  status?: string[] | null /** Open/Closed task status */;
  carriers?: string[] | null;
  groups?: string[] | null;
  employees?: string[] | null;
  events?: string[] | null;
  sort?:
    | string
    | null /** Column header sort, ascending: [Carrier, employee, effectiveDate, company]descending: [-carrier, -employee, -effectiveDate, -company] */;
  snoozed?: string | null;
  effectiveDateMax?: string | null;
  effectiveDateMin?: string | null;
  completionDateMin?: string | null;
  completionDateMax?: string | null;
  creationDateMax?: string | null;
  creationDateMin?: string | null;
  assigneeUserIds?: string[] | null;
  offset?: number | null /** Pagination page value */;
  size?: number | null /** Number of tasks per pagination */;
}
export interface GetPdfInfoQueryArgs {
  id: string;
}
export interface FulfillmentTaskEmployeesQueryArgs {
  name?: string | null /** Search keyword for employee names, return no employee if name is null */;
  company?: string[] | null /** IDs of companies where the search is restricted to */;
  size?: number | null /** The max number of results returned, for default search for SIZE_EMPLOYEE_SEARCH */;
}
export interface AutocompletedCompaniesQueryArgs {
  name?: string | null;
  size?: number | null;
}
export interface BenefitsEnrollmentsQueryArgs {
  ids: string[];
}
export interface BenefitsTransactionQueryArgs {
  id: string;
}
export interface BenefitsTransactionsQueryArgs {
  companyId: string;
  employeeId: string;
  benefitsTypes?: string[] | null;
  sort?: string | null;
}
export interface RestrictionDataForEmployeeAndDependentsQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
}
export interface PlanRestrictionDataQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
}
export interface PlanRestrictionForEmployeeQueryArgs {
  chp_id: string;
  effective_date: string;
}
export interface PlanRestrictionForDependentsQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
  employeeSelectedAmount: string;
}
export interface EmployeeBenefitsHsaInfoQueryArgs {
  numEnrolledDependents?: number | null;
}
export interface CompanyHealthPlansQueryArgs {
  chpIds: (string | null)[];
}
export interface BundledLocsQueryArgs {
  chpId: string;
}
export interface AllBundledLocsQueryArgs {
  chpIds: (string | null)[];
  effectiveDate?: string | null;
}
export interface BenefitsAuditErrorsQueryArgs {
  companyId: string;
  employeeId?: string | null;
  names?: string[] | null;
  severities?: string[] | null;
}
export interface AllBenefitsAuditErrorsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  names?: string[] | null;
  severities?: string[] | null;
}
export interface BenefitsAuditErrorQueryArgs {
  id: string;
}
export interface CompanyEnrollmentAuditErrorQueryArgs {
  id: string;
}
export interface EmployeeEnrollmentAuditErrorQueryArgs {
  id: string;
}
export interface EmployeeTransactionAuditErrorQueryArgs {
  id: string;
}
export interface EtaTasksQueryArgs {
  ids?: string[] | null;
}
export interface PartnerQueryArgs {
  id: string;
}
export interface ValidateZipCodeCoveredByPartnerQueryArgs {
  partnerId: string;
  zipCode: string;
}
export interface PartnerUserQueryArgs {
  id: string;
}
export interface FetchPartnerInfoQueryArgs {
  companyId: string;
}
export interface AllPartnerContactsQueryArgs {
  partnerId?: string | null;
}
export interface PartnerCompanySettingsQueryArgs {
  partnerId?: string | null;
}
export interface PartnerCompaniesDataQueryArgs {
  partnerId?: string | null;
  filter?: PartnerCompanyFilter | null;
  benefitsStatusFilter?: string | null;
  order_by?: string[] | null;
  limit?: number | null;
  offset?: number | null;
}
export interface ValidateQleEnrollmentsQueryArgs {
  qleId: string;
}
export interface EmployeeStatusQueryArgs {
  employeeId: string;
}
export interface EmployeeHealthEnrollmentsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  isActive?: boolean | null;
  includedInGroupApp?: boolean | null;
}
export interface EmployeeBenefitsEnrollmentsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  linesOfCoverage?: string[] | null;
  activeOnly?: boolean | null;
}
export interface BenefitsLoaCoverageDataQueryArgs {
  employeeId: string;
  eventDate: Date;
}
export interface BenefitsLoaOngoingLoasQueryArgs {
  employeeId: string;
}
export interface CompanyBenefitsCostQueryArgs {
  companyId?: string | null;
}
export interface CobraThirdPartyAdministratorsQueryArgs {
  isKnownPartner?: boolean | null;
}
export interface CobraClassificationTypeQueryArgs {
  companyCobra_id?: string | null;
}
export interface CompanyTagsQueryArgs {
  companyId: string;
  product: string;
}
export interface EmployeeTagsQueryArgs {
  employeeId: string;
  product: string;
}
export interface WaiverRuleQueryArgs {
  q_carrierId?: string | null;
  q_companyId: string;
  q_lineOfCoverage: string;
  q_effectiveDate: string;
}
export interface HasSection125AmendmentQueryArgs {
  companyId: string;
}
export interface QuestionFlowsQueryArgs {
  questionFlowId?: string | null;
  appId?: string | null;
}
export interface ReviewQueryArgs {
  reviewId: string;
}
export interface ReviewTemplatesQueryArgs {
  id?: string | null;
  status?: ReviewTemplateStatus | null;
}
export interface ReviewsQueryArgs {
  companyId?: string | null;
  statuses?: ReviewStatus[] | null;
  hasAssignedSummaries?: boolean | null;
  hasSharedFeedback?: boolean | null;
  hasAssignedReviews?: boolean | null;
  specificRevieweeId?: string | null;
  offset?: number | null;
  limit?: number | null;
  ids?: string[] | null;
}
export interface RevieweeGoalsQueryArgs {
  revieweeId: string;
}
export interface GoalsQueryArgs {
  status?: GoalStatus | null;
  targets?: GoalTarget[] | null;
  id?: string | null;
  contextualize?: boolean | null;
}
export interface MeetingSpaceQueryArgs {
  id: string;
}
export interface MeetingsUserPreferenceQueryArgs {
  group: MeetingsUserPreferenceGroupType;
}
export interface AllAssessmentsParticipationSummaryQueryArgs {
  filters: JSON;
}
export interface WellbeingLifeEventQueryArgs {
  id: string;
}
export interface WellbeingAssessmentQueryArgs {
  id: string;
}
export interface WellbeingArticleQueryArgs {
  id: string;
}
export interface WellbeingFeaturedArticlesQueryArgs {
  page: number;
  pageSize: number;
}
export interface SurveyQueryArgs {
  surveyId: string;
}
export interface SurveysQueryArgs {
  statuses?: SurveyStatus[] | null;
}
export interface AccessibleSurveysQueryArgs {
  statuses?: SurveyStatus[] | null;
}
export interface SurveyTemplateQueryArgs {
  templateId: string;
}
export interface PostAudienceQueryArgs {
  postId: string;
}
export interface PeopleHubTargetAudienceQueryArgs {
  locationIds?: (number | null)[] | null;
  departmentIds?: (number | null)[] | null;
  myTeam?: boolean | null;
  entireCompany?: boolean | null;
}
export interface ListSocialFeedCommentsQueryArgs {
  postId: string;
}
export interface ListPostLikesQueryArgs {
  postId: string;
}
export interface SocialFeedQueryArgs {
  pageNumber?: number | null;
  pageSize?: number | null;
}
export interface BenefitsFulfillmentDetailsQueryArgs {
  btIds: (string | null)[];
}
export interface CompanyRateVersionQueryArgs {
  companyHealthPlanId: string;
}
export interface CompanyHealthPlanQueryArgs {
  chpId: string;
  draft?: boolean | null;
}
export interface BenefitsFormMapperGetDataFieldsQueryArgs {
  searchString?: string | null;
}
export interface BenefitsFormMapperStpFormTemplateQueryQueryArgs {
  id?: string | null;
  appType?: string | null;
}
export interface BenefitsFormMapperStpInputFieldsAndOperatorsQueryArgs {
  appType?: string | null;
  id?: string | null;
}
export interface BenefitsFormMapperGetFieldOnPdfFormQueryArgs {
  url?: string | null;
}
export interface FilterFormTemplatesQueryArgs {
  templateNameFilter?: string | null;
  appType?: string | null;
}
export interface ZPayrollCompanySearchQueryArgs {
  onboardingState?: (string | null)[] | null;
  offset: number /** For Pagination */;
  limit: number;
}
export interface ZpCompanyQueryArgs {
  companyId: string;
}
export interface CompanyImplementationOverviewSearchQueryArgs {
  companyName?: string | null /** Filter/search fields */;
  companyId?: string | null;
  assignedToId?: string | null;
  overallOnboardingState?: (string | null)[] | null;
  offset: number /** For Pagination */;
  limit: number;
}
export interface CompanyImplementationOverviewQueryArgs {
  companyId: string;
}
export interface ZScoreCompanyQueryArgs {
  id?: string | null;
}
export interface AllZScoresForCompanyQueryArgs {
  id?: string | null;
}
export interface PaGetJobTitleSuggestionsQueryArgs {
  query?: string | null;
}
export interface PaJobLevelSalaryBenchmarkQueryArgs {
  location?: number | null;
  jobTitle?: number | null;
}
export interface PaJobBenchmarkingQueryArgs {
  location?: number | null;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaGetEmployeeWorkflowInfoQueryArgs {
  newHireId?: number | null;
}
export interface PaGetEmployeeChangeRequestInfoQueryArgs {
  changeRequestId?: number | null;
}
export interface PaConsoleGetCompanyConfigQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetCompanyTitleLevelMappingQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetSalaryInsightsInfoQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleAppInstallQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetSalaryBenchmarkQueryArgs {
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
}
export interface BiPremadeReportScheduledInfoQueryArgs {
  premadeReportIds?: (string | null)[] | null;
}
export interface BiCustomReportDetailQueryArgs {
  customReportId?: number | null;
}
export interface BiReportDocumentExistsQueryArgs {
  reportDocumentId?: number | null;
}
export interface TrsGetRewardStatementQueryArgs {
  rewardStatementId?: string | null;
}
export interface DealsForCompanyQueryArgs {
  company_id?: string | null;
  filter?: JSON | null;
}
export interface DealsForEmployeeQueryArgs {
  employee_id?: string | null;
  filter?: JSON | null;
}
export interface GetDealsQueryArgs {
  filter?: JSON | null;
  offset?: number | null;
  limit?: number | null;
}
export interface MetaKeysForProductQueryArgs {
  product_id?: string | null;
}
export interface AllProductDetailsAndOptionsQueryArgs {
  product_id?: string | null;
}
export interface MetaKeysForCategoryQueryArgs {
  categories?: (JSON | null)[] | null;
}
export interface ProductDetailsQueryArgs {
  product_id?: string | null;
}
export interface CompanySetupPreviewPayScheduleQueryArgs {
  id: string;
}
export interface CompanySetupMarketplacePlansV2QueryArgs {
  lineOfCoverage?: string | null;
}
export interface CheckoutFlowQueryArgs {
  bundleId?: string | null;
}
export interface GenerateGatewayParamsQueryArgs {
  mode?: string | null;
}
export interface DocumentsInfoQueryArgs {
  company_id?: string | null;
  view_type?: string | null;
}
export interface CompanyAdminsQueryArgs {
  company_id?: string | null;
}
export interface DocActionHistoryQueryArgs {
  companyId?: string | null;
  docMetaId: number;
}
export interface GetChatStatusQueryArgs {
  id?: string | null;
}
export interface IssueCategoriesQueryArgs {
  parentTopicId?: string | null;
}
export interface ContactOptionsQueryArgs {
  topicId?: string | null;
}
export interface FinalPageQueryArgs {
  topicId?: string | null;
}
export interface ParentTopicsQueryArgs {
  topicId?: string | null;
}
export interface DecisionTreePathQueryArgs {
  topicId?: string | null;
}
export interface AllCompanyAdminsQueryArgs {
  company_id?: string | null;
}
export interface GetTargetableEmployeesQueryArgs {
  company_id?: string | null;
}
export interface GetTargetedEmployeesQueryArgs {
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface CompanyDocumentsQueryArgs {
  company_id?: string | null;
  view_type?: string | null;
}
export interface CheckIfDocumentNameAvailableQueryArgs {
  documentName?: string | null;
}
export interface FoldersAndDocumentsQueryArgs {
  company_id?: string | null;
}
export interface ValidateFolderNameQueryArgs {
  folderName?: string | null;
}
export interface ListEmployeesQueryArgs {
  companyId?: string | null;
}
export interface GetAllLoaPoliciesQueryArgs {
  companyId?: string | null;
}
export interface ListAllPoliciesQueryArgs {
  companyId?: string | null;
}
export interface LoaPolicyQueryArgs {
  policyId?: string | null;
}
export interface GetLeaveRequestQueryArgs {
  requestId?: string | null;
}
export interface GetPtoVacationTypesQueryArgs {
  employeeId?: string | null;
  requestId?: string | null;
}
export interface GetAllLoaRequestsQueryArgs {
  companyId?: string | null;
}
export interface GetLoaRequestsForOverviewPageQueryArgs {
  companyId?: string | null;
}
export interface GetWorkHoursQueryArgs {
  inputDates?: InputDateRangeMessage | null;
}
export interface CanDeletePolicyQueryArgs {
  deleteRequest?: PolicyDeleteRequest | null;
}
export interface IsNewPolicyVersionQueryArgs {
  loaPolicy?: LoaPolicyRequest | null;
}
export interface GroupTypesQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface GroupsQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
  groupTypeIds?: string[] | null;
}
export interface MembershipQueryArgs {
  memberId: string;
  groupTypeIds?: string[] | null;
}
export interface TaPayPeriodQueryArgs {
  id: string;
}
export interface TaCompanyNotificationSettingsQueryArgs {
  companyId?: string | null;
  notificationType: string;
}
export interface TaPayPeriodEmployeesQueryArgs {
  employee_id?: string | null;
  payPeriod_id?: string | null;
}
export interface TaTimesheetQueryArgs {
  id: string;
}
export interface TaCompanyQueryArgs {
  id: string;
}
export interface TaEmployeeQueryArgs {
  employee_id: string;
}
export interface EmployeeWithTaEmployeeIdQueryArgs {
  id: string;
}
export interface TaCompanyWithCompanyIdQueryArgs {
  company_id: string;
}
export interface TaPayPeriodFilterQueryArgs {
  stateIn?: (string | null)[] | null;
  startDate?: string | null;
}
export interface TaCalculateHoursAndAvailabilityQueryArgs {
  startDate: string;
  endDate: string;
  vacationType: string;
  isHalfDay: string;
  employee: string;
}
export interface ListSchedulingEmployeeAvailabilityQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface ListSchedulingEmployeesQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface ListSchedulingShiftsQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface SchedulingOverviewQueryQueryArgs {
  companyId: number;
}
export interface CalculateHoursAndAvailabilityQueryArgs {
  startDate: string;
  vacationType: string;
  employee: string;
}
export interface EmployeePtoAccountsQueryArgs {
  view: string;
  accountType: string;
  employeeId?: string | null;
}
export interface EmployerPtoAccountsQueryArgs {
  view: string;
}
export interface BalanceHistoryItemsQueryArgs {
  accountTypes: string[];
  employeeIds: string[];
}
export interface TenureBonusQueryArgs {
  employeeId: string;
  accountType: string;
  employerPtoIds: string[];
}
export interface PayrollReportsEmployeesQueryArgs {
  companyId?: string | null;
}
export interface PayrollReportsV2TaxPackageDatesQueryArgs {
  companyId?: string | null;
}
export interface PayrollReportsPreviousW2sQueryArgs {
  companyId?: string | null;
}
export interface GetLaborGroupTypesForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetSelectedLaborGroupTypesForCompanyQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface GetPayTypesForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetLaborGroupDetailsForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetAccountingAccountsForCompanyQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface RefreshAccountingAccountQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface GetClassTrackingOptionsQueryArgs {
  companyId?: string | null;
}
export interface GetAccountMappingDataQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface OmniSearchSuggestionQueryArgs {
  query?: string | null;
  sources: OmniSearchSource[];
}
export interface ActivationInfoQueryArgs {
  app?: string | null;
  limit?: string | null;
}
export interface ActivationCandidatesQueryArgs {
  app: string;
}
export interface CreateMultiOrgReportQueryArgs {
  reportIdOrName?: string | null;
  reportFormat: string;
  updatedFilters?: JSON | null;
  action?: string | null;
  selectedColumns?: (string | null)[] | null;
}
export interface DatagridQueryArgs {
  id: string;
}
export interface DatagridSubmissionStatusQueryArgs {
  datagridId: string;
}
export interface ListAgreementsQueryArgs {
  companyId?: number | null;
  employeeId?: number | null;
}
export interface EmployeeLockboxDataQueryArgs {
  usecase: string;
  employeeId: string;
}
export interface EmployeesLockboxDataQueryArgs {
  usecase: string;
  employeeIds: string[];
}
export interface AllDeletionRequestsQueryArgs {
  startRequestedAt?: Date | null;
  endRequestedAt?: Date | null;
}
export interface GenerateDeletionRequestsCsvQueryArgs {
  startRequestedAt?: Date | null;
  endRequestedAt?: Date | null;
}
export interface WebhookMessagesQueryArgs {
  messageIdList?: (string | null)[] | null;
  zappIdList?: (string | null)[] | null;
  zappTitle?: string | null;
  zeventIdList?: (string | null)[] | null;
  eventType?: string | null;
  subscriptionTypeList?: (string | null)[] | null;
  companyIdList?: (number | null)[] | null;
  employeeIdList?: (number | null)[] | null;
  createdAtFrom?: string | null;
  createdAtTo?: string | null;
  deliveryStatus?: string | null;
  deliveredAtFrom?: string | null;
  deliveredAtTo?: string | null;
  response?: string | null;
  payload?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  limit?: number | null;
  offset?: number | null;
}
export interface GetPpbUploadStatusObjectsQueryArgs {
  companyId: string;
}
export interface GetPpbUploadDetailsByIdQueryArgs {
  id: string;
  companyId?: string | null;
}
export interface GetPpbTaxCalcBgTaskUiSwitchStatusQueryArgs {
  companyId: string;
}
export interface GetPpbTaxCalcTaskStatusQueryArgs {
  zPayrollRunId: string;
}
export interface IsProvisioningEnabledForSsoProviderQueryArgs {
  zappId: string;
}
export interface GetSsoSettingsQueryArgs {
  zappId?: string | null;
}
export interface GetTaxLiabilitiesObjectsQueryArgs {
  companyId: string;
}
export interface IsContractor1099FormFilingEditableQueryArgs {
  year: number;
}
export interface Get1099FilingDatesQueryArgs {
  year: number;
}
export interface GetWorkerDocumentFilesQueryArgs {
  companyId: string;
  employeeId?: string | null;
  folderId?: string | null;
  isAdminCreated: boolean;
}
export interface GetWorkerDocumentAuditDataQueryArgs {
  companyId?: string | null;
  folderId?: string | null;
  employeeIds?: (number | null)[] | null;
  source?: string | null;
  isAdminCreated: boolean;
  fetchDocuments: boolean;
}
export interface GetAggregatedPlanSummaryQueryArgs {
  numEmployees: number;
  zipCode: string;
  ageRange: string;
  lineOfCoverage: string;
}
export interface GetPlansQueryArgs {
  numEmployees: number;
  zipCode: string;
  employeeAge: number;
  lineOfCoverage: string;
}
export interface GetCarriersQueryArgs {
  carrierIds: number[];
}
export interface GetSwitchByIdQueryArgs {
  id: number;
}
export interface GetSwitchByKeyQueryArgs {
  key: string;
}
export interface GetSwitchesQueryArgs {
  ids: number[];
}
export interface SearchSwitchByKeyQueryArgs {
  searchString: string;
  noOfResults?: number | null;
}
export interface GetSwitchPutLogsQueryArgs {
  switchId: number;
}
export interface SwitchesDashboardArgs {
  switches?: string[] | null;
}
export interface EmployeesDashboardArgs {
  first?:
    | number
    | null /** if you want to add any filters here you have to add them inAllEmployeeResource.Meta.filtering in hub/hub_data/register_company/api.py too */;
  offset?: number | null;
  company_id?: number | null;
  email?: string | null;
  status?: EmployeeStatus | null;
  status__in?: (EmployeeStatus | null)[] | null;
  type?: EmployeeType | null;
  type__in?: (EmployeeType | null)[] | null;
  employmentType?: EmploymentType | null;
  employmentType__in?: (EmploymentType | null)[] | null;
  hireDate__lt?: (string | null)[] | null;
  hireDate__gt?: (string | null)[] | null;
  hireDate__lte?: (string | null)[] | null;
  hireDate__gte?: (string | null)[] | null;
  isPastHireDate?: boolean | null /** custom transformed filter for backwards compat (lets not add more) */;
  allStatus?: EmployeeStatus | null;
  allStatus_not?: EmployeeStatus | null;
  allStatus__in?: (EmployeeStatus | null)[] | null;
  status__not?: EmployeeStatus | null /** dead filters, this was used to filter Del but that's already the default */;
  order_by?: string | null /** note similar to above order_by needs to match */;
  reportToEmployee_id?: string | null;
  isMultiOrg?: boolean | null /** set to true if you need employees data from all companies. */;
}
export interface DashboardTeammatesDashboardArgs {
  first?: number | null;
}
export interface ZAppInstallSubscriptionsDashboardArgs {
  appUniqueIds?: string[] | null;
}
export interface EmployeesCompanyArgs {
  type?: string | null;
  employmentTypeIncludingWorkerTypes?: (string | null)[] | null;
}
export interface GroupTypesCompanyArgs {
  groupTypeIds?: string[] | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface Contractor1099FormFilingsCompanyArgs {
  year: number;
}
export interface GetAllContractors1099MiscFilesAsZipCompanyArgs {
  year: number;
}
export interface PermissionedAdminsCompanyHrProxyArgs {
  permission: string;
}
export interface DepartmentsAllEmployeeArgs {
  effectiveDate?: Date | null;
}
export interface PendingChangesAllEmployeeArgs {
  kinds: ChangeRequestItemKind[];
}
export interface PendingGroupChangesAllEmployeeArgs {
  kind: ChangeRequestItemKind;
}
export interface GroupsAllEmployeeArgs {
  groupTypeIds?: string[] | null;
}
export interface BenefitsPlanOptionsAllEmployeeArgs {
  lineOfCoverage: string;
  effectiveDate: string;
  enrollmentType?: string | null;
}
export interface BenefitsEnrollmentsAllEmployeeArgs {
  benefitsType?: string | null;
}
export interface BenefitsTransactionsAllEmployeeArgs {
  benefitsType?: string | null;
}
export interface EffectiveBenefitsTransactionsAllEmployeeArgs {
  benefitsTypes: string[];
  effectiveDate?: string | null;
}
export interface NextEffectiveBenefitsTransactionsAllEmployeeArgs {
  benefitsTypes: string[];
  effectiveDate?: string | null;
}
export interface DependentsAllEmployeeArgs {
  ids?: (string | null)[] | null;
}
export interface BeneficiariesAllEmployeeArgs {
  ids?: (string | null)[] | null;
}
export interface ZAppSubscriptionAllEmployeeArgs {
  zAppUniqueId: string;
}
export interface ChangeRequestsAllEmployeeArgs {
  statuses: ChangeRequestGroupStatus[];
}
export interface GroupChangeChangeRequestArgs {
  kind?: ChangeRequestItemKind | null;
}
export interface GroupChangesChangeRequestArgs {
  kinds: ChangeRequestItemKind[];
}
export interface GroupsGroupTypeArgs {
  companyId?: string | null;
}
export interface MembershipGroupTypeArgs {
  memberId: string;
}
export interface DefaultGroupGroupTypeArgs {
  memberId: string;
  effectiveDate?: Date | null;
}
export interface CorrectionsEmployeeEligibilityArgs {
  isComplete?: boolean | null;
}
export interface ReverificationsEmployeeEligibilityArgs {
  isComplete?: boolean | null;
}
export interface CompanyHealthCarrierCompanyHealthPlanArgs {
  draft?: boolean | null;
}
export interface CompanyHealthPlansCompanyHealthCarrierArgs {
  draft?: boolean | null;
}
export interface CompanyHealthCarrierCompanyHealthEnrollmentArgs {
  draft?: boolean | null;
}
export interface PlanCostContributionEmployeeBenefitsArgs {
  effectiveDate: string;
  dependentIds: (string | null)[];
  chpIds: string[];
}
export interface PlanAvailabilityEmployeeBenefitsArgs {
  effectiveDate: string;
  dependentIds: (string | null)[];
  chpIds: string[];
}
export interface DraftPlanAvailabilityEmployeeBenefitsArgs {
  lineOfCoverage: string;
}
export interface DraftPlanPremiumMdvsupEmployeeBenefitsArgs {
  dependentIds: (string | null)[];
  lineOfCoverage: string;
  planIds: (string | null)[];
}
export interface DraftPlanPremiumLndEmployeeBenefitsArgs {
  enrolleeIds: (string | null)[];
  lineOfCoverage: string;
  planIds: (string | null)[];
}
export interface OngoingBenefitsEnrollmentEmployeeBenefitsArgs {
  lineOfCoverage: string;
  effectiveDate: string;
}
export interface OngoingBenefitsEnrollmentsEmployeeBenefitsArgs {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
}
export interface EmployeeReinstateBenefitsEmployeeBenefitsArgs {
  lineOfCoverage: string;
}
export interface EmployerHsaMonthlyContributionEmployeeBenefitsArgs {
  effectiveDate: string;
}
export interface PaySchedulesCompanyPayrollProxyArgs {
  status?: CompanyPayScheduleStatus | null;
}
export interface SectionsQuestionFlowArgs {
  type?: string | null;
}
export interface SchedulesReviewArgs {
  event?: TalentFlowScheduleEvent | null;
}
export interface RunsReviewArgs {
  runId?: string | null;
  revieweeId?: string | null;
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
  hasAssignedSummaries?: boolean | null;
  hasAssignedReviews?: boolean | null;
  hasSharedFeedback?: boolean | null;
}
export interface RunsCountReviewArgs {
  revieweeId?: string | null;
}
export interface TargetedEmployeesReviewArgs {
  targetScope?: JSON | null;
}
export interface SuggestedReviewerSessionsReviewArgs {
  revieweeId: string;
}
export interface ReviewSessionsReviewRunArgs {
  sessionId?: string | null;
  isAssigned?: boolean | null;
  revieweeId?: string | null;
}
export interface SummarySessionsReviewRunArgs {
  sessionId?: string | null;
  isAssigned?: boolean | null;
  revieweeId?: string | null;
}
export interface RunsSelectionRevieweesCollectionArgs {
  filter?: JSON | null;
}
export interface RunsRunsSelectionArgs {
  runId?: string | null;
  revieweeId?: string | null;
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
}
export interface ReviewersSelectionReviewersCollectionArgs {
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
}
export interface RunsWellbeingAssessmentArgs {
  statuses?: WellbeingAssessmentRunStatus[] | null;
  id?: string | null;
}
export interface ScoreSummaryWellbeingAssessmentArgs {
  filters: JSON;
}
export interface ParticipationSummaryWellbeingAssessmentArgs {
  filters: JSON;
}
export interface SessionsWellbeingAssessmentRunArgs {
  id?: string | null;
  type?: WellbeingAssessmentSessionType | null;
}
export interface RecommendedArticleWellbeingAssessmentRunArgs {
  id: string;
}
export interface RecommendedArticleWellbeingLifeEventArgs {
  id: string;
}
export interface TargetedEmployeesSurveyArgs {
  targetScope?: JSON | null;
}
export interface EngagementScoreSurveyArgs {
  filters?: JSON | null;
}
export interface CompanyEngagementScoreSurveyArgs {
  filters?: JSON | null;
}
export interface QuestionsCommentsSurveyArgs {
  limitPerQuestion?: number | null;
}
export interface CommentsWeightedWordsSurveyArgs {
  limitPerQuestion?: number | null;
}
export interface RespondentsProgressSurveyMetadataArgs {
  filters?: JSON | null;
}
export interface QuestionResultsSurveyQuestionsResultsArgs {
  filters?: JSON | null;
}
export interface SegmentedQuestionResultsSurveyQuestionsResultsArgs {
  segmentKey: SurveySegmentKey;
  filters?: JSON | null;
}
export interface OfferProductCatalogArgs {
  promoCode?: string | null;
}
export interface GroupsTaGroupTypeArgs {
  memberId?: string | null;
}
export interface ValuesColumnConfigurationArgs {
  filteredBy?: ColumnConfigValueFilter | null;
  columnIds?: string[] | null;
}
export interface ResourceCenterOffersResourceCenterArgs {
  limit?: number | null;
  offset?: number | null;
  resourceCenterOfferCategoryId?: string | null;
}
export interface TaMutationEmployeeTimeDurationMutationArgs {
  id?: string | null;
  activity?: string | null;
  endTime?: string | null;
  startTime?: string | null;
  state?: string | null;
  validStatus?: string | null;
  groupIds?: (number | null)[] | null;
  employeeId: number;
  modifiedById?: number | null;
  timeApprovedId?: number | null;
  projectCodeId?: number | null;
  matchingDurationId?: string | null;
  hours?: string | null;
  accuracyIn?: string | null;
  accuracyOut?: string | null;
  geolocationSkippedIn?: boolean | null;
  geolocationSkippedOut?: boolean | null;
  hasMissingNoteFlag?: boolean | null;
  isNextDay?: boolean | null;
  lastModified?: string | null;
  latitudeIn?: string | null;
  latitudeOut?: string | null;
  longitudeIn?: string | null;
  longitudeOut?: string | null;
  missedMealBreaksCount?: string | null;
  notes?: (number | null)[] | null;
  notice?: string | null;
  photoUrlIn?: string | null;
  photoUrlOut?: string | null;
  reportSourceIn?: string | null;
  reportSourceOut?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
}
export interface DeleteTaTimeDurationMutationArgs {
  id?: string | null;
}
export interface TaApproveTimeApprovedDayMutationArgs {
  timeApprovedId: string;
  isApproved: boolean;
  approverId?: string | null;
}
export interface TaCompanyNotificationSettingsSaveMutationMutationArgs {
  companyId?: string | null;
  notificationEnabled: boolean;
  notificationType: string;
  notificationFrequency: string;
}
export interface TaEmployeeTimeDurationNoteMutationMutationArgs {
  id?: number | null;
  content?: string | null;
  authorId: number;
  isRestricted?: boolean | null;
  taEmployeeTimeDurationId: number;
}
export interface TaVacationRequestMutationMutationArgs {
  approvedDate?: string | null;
  asOfDate?: string | null;
  asOfHours?: string | null;
  availableAsOf?: string | null;
  createdDate?: string | null;
  denyReason?: string | null;
  employeeId?: number | null;
  endDate?: string | null;
  hours?: number | null;
  id?: string | null;
  isHalfDay?: boolean | null;
  isManagerRecorded?: boolean | null;
  isPartialDay?: boolean | null;
  isPostedOrPast?: boolean | null;
  reason?: string | null;
  startDate?: string | null;
  status?: string | null;
  vacationTypeId?: number | null;
}
export interface TaPayPeriodEmployeeMutationMutationArgs {
  approvedAt?: DateTime | null;
  approvedBy_id?: number | null;
  durationIssuesCount?: number | null;
  employeeTimeApproved?: (number | null)[] | null;
  employee_id?: number | null;
  id?: number | null;
  isApproved?: boolean | null;
  isSubmitted?: boolean | null;
  payPeriod_id?: number | null;
  totalDoubleOvertimeHours?: string | null;
  totalHolidayHours?: string | null;
  totalOvertimeHours?: string | null;
  totalPtoHours?: string | null;
  totalRegularHours?: string | null;
  totalReportedHours?: string | null;
}
export interface TaCompanyMutationMutationArgs {
  hasMealBreaks?: boolean | null;
  firstMealBreakPenaltyHours?: number | null;
  secondMealBreakPenaltyHours?: number | null;
  unpaidMealBreakMinutes?: number | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  id?: number | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
}
export interface TaEmployeeMutationMutationArgs {
  hasMealBreaks?: boolean | null;
  firstMealBreakPenaltyHours?: number | null;
  secondMealBreakPenaltyHours?: number | null;
  unpaidMealBreakMinutes?: number | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  employee_id?: number | null;
  id?: number | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
  allMealBreakSettingsDefault?: boolean | null;
}
export interface RecalculateContributionMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface RecalculateCostMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface EnrollEmployeeMutationArgs {
  employeeId: string;
  benefitsType: string;
  enrollmentType: string;
  companyHealthPlanId?: string | null;
  volCompanyHealthPlanId?: string | null;
  enrollingDependentIds?: string[] | null;
  effectiveDate: string;
  employeeElectedBasicAmount?: string | null;
  spouseElectedBasicAmount?: string | null;
  childrenElectedBasicAmount?: string | null;
  employeeApprovedBasicAmount?: string | null;
  spouseApprovedBasicAmount?: string | null;
  childrenApprovedBasicAmount?: string | null;
  employeeElectedVoluntaryAmount?: string | null;
  spouseElectedVoluntaryAmount?: string | null;
  childrenElectedVoluntaryAmount?: string | null;
  employeeApprovedVoluntaryAmount?: string | null;
  spouseApprovedVoluntaryAmount?: string | null;
  childrenApprovedVoluntaryAmount?: string | null;
}
export interface DeclineEmployeeMutationArgs {
  employeeId: string;
  lineOfCoverage: string;
  enrollmentType: string;
  effectiveDate: string;
}
export interface EditEmployeeEligibilityMutationArgs {
  request?: EditEmployeeEligibilityRequest | null;
}
export interface SyncEmployeeEnrollmentFlowMutationArgs {
  flowId: string;
}
export interface UpdateDependentMutationArgs {
  request: UpdateDependentRequest;
}
export interface DeleteDependentMutationArgs {
  request: DeleteDependentRequest;
}
export interface UpdateBeneficiaryMutationArgs {
  request: UpdateBeneficiaryRequest;
}
export interface UpdateAddressMutationArgs {
  request: UpdateAddressRequest;
}
export interface UpdateEmployeeMutationArgs {
  request: UpdateEmployeeRequest;
}
export interface UpdatePersonalInfoMutationArgs {
  request: UpdatePersonalInfoRequest;
}
export interface DeactivateBeneficiaryMutationArgs {
  beneficiaryId: string;
}
export interface StartEmployeeHsaSetUpMutationArgs {
  previousContribution?: number | null;
}
export interface HsaEnrollEmployeeWithoutUpdatingStateMutationArgs {
  employeeId: string;
  effectiveDate: string;
  amount: number;
}
export interface HsaEnrollEmployeeMutationArgs {
  employeeId: string;
  effectiveDate: Date;
  amount: number;
}
export interface EmployeeSignEnrollmentMutationArgs {
  employeeId: string;
  enrollmentIds: (string | null)[];
  name: string;
  signature: string;
  agreementContent: string;
}
export interface CreateDependentRecordMutationArgs {
  employeeId: string;
  type: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  ssn?: string | null;
  zip: string;
}
export interface CreateBeneficiaryRecordMutationArgs {
  employeeId: string;
  type: string;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  entityName?: string | null;
  entityType?: string | null;
}
export interface AddDependentsToCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  dependentIds: (string | null)[];
  effectiveDate: string;
}
export interface RemoveDependentsFromCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  dependentIds: (string | null)[];
  effectiveDate: string;
}
export interface HandleEmployeeLoaMutationArgs {
  employeeId: string;
  benefitsTypes: (string | null)[];
  eventDate: Date;
  enrollCobra: boolean;
}
export interface CancelCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface TerminateBenefitMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface PlanCorrectionMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  planId: string;
  companyHealthPlanId: string;
  planName: string;
  carrierId: string;
  carrierName: string;
}
export interface ChangeTransactionEffectiveDateMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  newEffectiveDate: string;
}
export interface ApproveEvidenceOfInsurabilityMutationArgs {
  employeeId: string;
  benefitsType: string;
  planId: string;
  effectiveDate: string;
  amountApprovalEntries: (AmountApprovalEntry | null)[];
}
export interface SetCompanyContextMutationArgs {
  employeeId: string;
}
export interface SetupPolicyNumberMutationArgs {
  companyId: string;
  policyNumber: string;
  companyHealthPlanId: string;
  samePolicyNumberForAllPlan?: boolean | null;
}
export interface UpdatePlanFieldsMutationArgs {
  companyHealthPlanId: string;
  newEoiFormUrl?: string | null;
  newSbcFileUrl?: string | null;
  policyNumber?: string | null;
  samePolicyNumberForAllPlan?: boolean | null;
  newPlanName?: string | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  hsa?: boolean | null;
  availableOOS?: boolean | null;
  availableOOSStates?: string | null;
  medicalSbcPlanDesignRequest?: MedicalSbcPlanDesignRequest | null;
  dentalSbcPlanDesignRequest?: DentalSbcPlanDesignRequest | null;
  visionSbcPlanDesignRequest?: VisionSbcPlanDesignRequest | null;
}
export interface DeactivatePaymentBannerMutationArgs {
  companyId?: string | null;
}
export interface ChangeContractLengthMutationArgs {
  companyEnrollmentId: string;
  contractLength?: number | null;
  effectiveEndDate?: string | null;
}
export interface ResetSection125MutationArgs {
  companyId: string;
}
export interface UploadSection125AmendmentMutationArgs {
  companyId: string;
  amendmentUrl: string;
  documentType: string;
}
export interface ChangeWaitingPeriodMutationArgs {
  companyEnrollmentId: string;
  waitingPeriod: string;
}
export interface ChangeTerminationPolicyMutationArgs {
  companyEnrollmentId: string;
  terminationPolicy: string;
}
export interface RunBenefitsAuditTaskMutationArgs {
  companyId: string;
  employeeId?: string | null;
  linesOfCoverage?: string[] | null;
}
export interface RevokeRenewalsReviewerMutationArgs {
  reviewerId: string;
}
export interface InviteRenewalsReviewerMutationArgs {
  employeeId: string;
  receiveNotifications?: boolean | null;
}
export interface UpdateRenewalsReviewerStatusMutationArgs {
  status: string;
}
export interface ReportIssuesWithRenewalsMutationArgs {
  issueText?: string | null;
  errorTypes?: (string | null)[] | null;
}
export interface AddReviewersMutationArgs {
  employeeIds: string[];
}
export interface UpdateRenewalsReviewerNotificationsMutationArgs {
  reviewerId: string;
  receiveNotifications: boolean;
}
export interface LnDAddDependentMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface LnDRemoveDependentMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  dependentEntries: (RemoveEnrolleeEntry | null)[];
}
export interface LnDAddPlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  newCompanyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface LnDDeletePlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
}
export interface LnDSwitchPlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  newCompanyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface ChangeCompanyCobraThirdPartyAdministratorMutationArgs {
  companyCobraId: string;
  administratorId: string;
}
export interface ChangeCompanyCobraClassificationMutationArgs {
  companyCobraId: string;
  classificationType: string;
}
export interface SendDataToMarketoMutationArgs {
  prospectInfo?: ProspectInfo | null;
}
export interface LogUserEventToOptimizelyMutationArgs {
  trialAccessToken: string;
  event: string;
}
export interface SelfServeCheckoutSavePlanSelectorStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
}
export interface SelfServeCheckoutSavePlanOptionsStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
  bundleParams: AdditionalBundleParams;
}
export interface SelfServeCheckoutSaveReviewStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
}
export interface SelfServeCheckoutSubmitMutationArgs {
  bundleId: string;
  paymentMethod: UpdatePaymentMethodInfo;
  customerInfo?: CustomerInfo | null;
  promoCode?: string | null;
}
export interface PaymentGatewayCallbackMutationArgs {
  refId?: string | null;
}
export interface UpdateQuestionFlowMutationArgs {
  questionFlowId: string;
  title?: string | null;
  description?: string | null;
  targetRule?: JSON | null;
  appId?: string | null;
  config?: JSON | null;
}
export interface CreateOrUpdateQfQuestionMutationArgs {
  questionId?: string | null;
  sectionId: string;
  order: number;
  title: string;
  description?: string | null;
  questionType: QFQuestionType;
  employeeId?: string | null;
  config: JSON;
  appId?: string | null;
}
export interface NewCreateOrUpdateQfQuestionMutationArgs {
  id?: string | null;
  sectionId?: string | null;
  questionFlowId: string;
  order: number;
  title: string;
  description?: string | null;
  questionType: QFQuestionType;
  employeeId?: string | null;
  config: JSON;
  appId?: string | null;
}
export interface DeleteQfQuestionMutationArgs {
  questionId: string;
  sectionId: string;
  appId?: string | null;
}
export interface NewDeleteQfQuestionMutationArgs {
  id: string;
}
export interface SubmitSessionResponsesMutationArgs {
  sessionId: string;
  responses: JSON[] /** A JSON in the format [{id, questionId|sectionId, response}] */;
  status: QFSessionStatus;
  appId?: string | null;
}
export interface SubmitSessionCommentsMutationArgs {
  sessionId: string;
  comments: QFCommentInput[];
  status: QFSessionStatus;
  appId?: string | null;
}
export interface SubmitQuestionUniqueResponseMutationArgs {
  sessionId: string;
  questionId: string;
  response: JSON;
  contextId?: string | null;
  appId?: string | null;
}
export interface GenerateReviewRunResponsesPdfMutationArgs {
  runId: string;
  includeDraftRemarks: boolean;
  isManagerVersion: boolean;
}
export interface GenerateReviewCycleReportMutationArgs {
  reviewId: string;
  cycleStartDate: Date;
  cycleEndDate: Date;
}
export interface CreateOrUpdateReviewMutationArgs {
  id?: string | null;
  title: string;
  startDate?: Date | null;
  schedules?: TalentFlowScheduleInput[] | null;
  description?: string | null;
  targetRule: JSON;
}
export interface LaunchReviewMutationArgs {
  reviewId: string;
}
export interface EndReviewMutationArgs {
  reviewId: string;
}
export interface EditReviewNameAndDescriptionMutationArgs {
  id: string;
  title: string;
  description?: string | null;
}
export interface CreateReviewFromTemplateMutationArgs {
  reviewTemplateId: string;
}
export interface UpdateReviewRunSettingsMutationArgs {
  id: string;
  startDate: Date;
  endDate: Date;
  reviewerIds: string[];
}
export interface CreateOrUpdateReviewTemplateMutationArgs {
  title: string;
  status: ReviewTemplateStatus;
  description?: string | null;
  id?: string | null;
  sourceQuestionFlowId?: string | null;
}
export interface DeleteReviewTemplateMutationArgs {
  id: string;
}
export interface ExtendReviewDueDatesMutationArgs {
  id: string;
  endDate: Date;
}
export interface DeleteMeetingMutationArgs {
  id: string;
}
export interface DeleteReviewMutationArgs {
  id: string;
}
export interface ReopenReviewMutationArgs {
  id: string;
  endDate: Date;
}
export interface EditReviewResponsesMutationArgs {
  sessionId: string;
  responses: JSON[] /** A JSON in the format [{id, questionId|sectionId, response}] */;
}
export interface DeactivateReviewRunMutationArgs {
  id: string;
}
export interface AddRevieweeToReviewMutationArgs {
  reviewId: string;
  revieweeId: string;
  reviewerSessions: ReviewerSessionInput[];
  startDate: Date;
  endDate: Date;
  cycleId?: string | null;
  addToFutureCycles?: boolean | null;
}
export interface CreateOrUpdateTalentGoalMutationArgs {
  id?: string | null;
  title: string;
  description?: string | null;
  startDate: Date;
  dueDate: Date;
  status: GoalStatus;
  measurementConfig: JSON;
  measurementUnit: QFQuestionType;
  target?: GoalTarget | null;
  targetId?: string | null;
  parentGoalId?: string | null;
}
export interface DeleteGoalMutationArgs {
  id: string;
}
export interface CreateMeetingSpaceMutationArgs {
  employeeId: string;
  firstMeetingTime: DateTime;
}
export interface CreateOrUpdateMeetingMutationArgs {
  id?: string | null;
  spaceId: string;
  time: DateTime;
}
export interface MoveMeetingItemMutationArgs {
  qfQuestionId: string;
  qfSectionId: string;
  order: number;
}
export interface MoveMeetingItemsMutationArgs {
  qfQuestionIds: string[];
  qfSectionId: string;
  startOrder: number;
}
export interface CreateOrUpdateMeetingsUserPreferenceMutationArgs {
  id?: string | null;
  group: MeetingsUserPreferenceGroupType;
  preference: JSON;
}
export interface LogWellbeingEventDataMutationArgs {
  eventType: WellbeingAnalyticsEventType;
  eventData: WellbeingAnalyticsEventDataInput;
}
export interface CreateOrUpdateSurveyMutationArgs {
  id?: string | null;
  title: string;
  description?: string | null;
  startDate?: Date | null;
  schedules?: SurveyScheduleInput[] | null;
  templateId?: string | null;
}
export interface LaunchSurveyMutationArgs {
  id: string;
}
export interface CancelSurveyMutationArgs {
  id: string;
}
export interface DeleteSurveyMutationArgs {
  id: string;
}
export interface ShareSurveyResultsMutationArgs {
  id: string;
}
export interface EditSurveyDetailsMutationArgs {
  id: string;
  title: string;
  description?: string | null;
  startDate: Date;
  schedules: SurveyScheduleInput[];
}
export interface GenerateSurveyReportMutationArgs {
  id: string;
}
export interface AddUpdateVariableCompensationMutationArgs {
  employeeId?: string | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: string | null;
  amount?: string | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}
export interface CreateOrUpdatePayTypeMutationArgs {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  amountType?: string | null;
}
export interface AddVariableCompensationsMutationArgs {
  employeeId?: number | null;
  variableComps?: (VariableCompensationInput | null)[] | null;
  effectiveDate?: Date | null;
}
export interface DeletePayTypeMutationArgs {
  payTypeId: number;
}
export interface PaCreateCompanyConfigMutationArgs {
  subIndustry?: number | null;
  location?: (number | null)[] | null;
}
export interface PaUpdateCompanyConfigMutationArgs {
  minPercentile?: number | null;
  maxPercentile?: number | null;
}
export interface PaUpdateCompanyJobTitleMappingMutationArgs {
  rawTitle: string;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaUpdateEmployeeJobTitleMappingMutationArgs {
  employeeId?: number | null;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaCreateFeedbackMutationArgs {
  rating?: string | null;
  comment?: string | null;
}
export interface PaUpdateSalaryBenchmarkBlackListMutationArgs {
  salaryBenchmark?: SalaryBenchmarkingBlacklistInput | null;
}
export interface PaConsoleCompanyInfoMutationArgs {
  companyId?: number | null;
  subIndustry?: number | null;
  minPercentile?: number | null;
  maxPercentile?: number | null;
  isLocked?: boolean | null;
  isConfigured?: boolean | null;
}
export interface PaConsoleUpdateCompanyJobTitleMappingMutationArgs {
  companyId?: number | null;
  rawTitle: string;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaConsoleUpdateIndustryJobMutationArgs {
  type?: string | null;
  id?: number | null;
  name?: string | null;
}
export interface TrsCreateRewardStatementMutationArgs {
  categoryIds?: (string | null)[] | null;
  name?: string | null;
  description?: string | null;
}
export interface TrsUpdateRewardStatementMutationArgs {
  id?: string | null;
  rewardStatement?: JSON | null;
}
export interface UpdatePdfInfoMutationArgs {
  id: string;
  updatedValues?: JSON | null;
}
export interface BiDeleteCustomReportMutationArgs {
  customReportId?: number | null;
}
export interface BiRunReportMutationArgs {
  customReportId?: string | null;
  reportFilter?: ReportFilter | null;
}
export interface BiPreviewReportMutationArgs {
  reportMetaData?: CustomReportMetaDataInput | null;
  reportFilter?: ReportFilter | null;
}
export interface CloseInboxTasksMutationArgs {
  taskIds: string[];
}
export interface AssignInboxTasksMutationArgs {
  ownerUserId: string;
  inboxActionIds: string[];
}
export interface SnoozeInboxTasksMutationArgs {
  inboxActionIds: string[];
  snoozeDueDate: Date;
}
export interface UnSnoozeInboxTasksMutationArgs {
  inboxActionIds: string[];
}
export interface CreateInboxActionCommentMutationArgs {
  taskId: string;
  value: string;
}
export interface AddFulfillmentAttachmentsMutationArgs {
  taskId: string;
  attachments: FulfillmentAttachmentInput[];
}
export interface RemoveFulfillmentAttachmentMutationArgs {
  taskId: string;
  attachmentId: string;
}
export interface SendFullStpEmailMutationArgs {
  taskId: string;
  taskType: string;
  carrierId: string;
  subject: string;
  fromEmail: string;
  toEmails: string;
  attachmentFiles: FullStpEmailAttachmentInput[];
  body: string;
  bundleId: string;
  partnerId?: string | null;
  ccEmails?: string | null;
  companyId?: string | null;
}
export interface DeclineEnrollmentMutationArgs {
  employeeId: string;
  effectiveDate: string;
  benefitsType: string;
  chpId?: string | null;
}
export interface SubmitEnrollmentSelectionMutationArgs {
  employeeId: string;
  effectiveDate: string;
  benefitsType: string;
  chpId: string;
  dependentIds: (string | null)[];
  electedAmount?: number | null;
  dependentElectedAmounts?: (number | null)[] | null;
}
export interface EmployeeSetPhysicianDentistMutationArgs {
  action: EmployeeSetPhysicianDentistActions;
  request: SetPhysicianDentistInput;
}
export interface EmployeeSetBeneficiaryMutationArgs {
  action: EmployeeSetBeneficiaryAction;
  request: SetBeneficiaryInput;
}
export interface EmployeeSubmitEnrollmentWaiverMutationArgs {
  request: SubmitEnrollmentWaiverInput;
}
export interface CreateEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: CreateEmployeeBenefitsEnrollment[];
}
export interface ChangeEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: ChangeEmployeeBenefitsEnrollment[];
}
export interface CancelEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: CancelEmployeeBenefitsEnrollment[];
}
export interface AddQleDocumentMutationArgs {
  qualifyingEventId: string;
  fileType: string;
  fileUrl: string;
}
export interface RemoveQleDocumentMutationArgs {
  qualifyingEventId: string;
}
export interface AddQleDocumentReviewMutationArgs {
  qualifyingEventId: string;
  data: AddQleDocumentReviewRequest;
  isAdmin?: boolean | null;
}
export interface CancelQleMutationArgs {
  qualifyingEventId: string;
}
export interface ChangeQleDateMutationArgs {
  qualifyingEventId: string;
  newEventDate: Date;
}
export interface CreateQualifyingEventMutationArgs {
  request: CreateQleRequest;
}
export interface QleRemoveDependentsMutationArgs {
  data: QleRemoveDependentsRequest;
}
export interface QleCancelCoverageMutationArgs {
  data: QleCancelCoverageRequest;
}
export interface UpdateReviewQualifyingLifeEventDocumentMutationArgs {
  viaZenefits: boolean;
}
export interface AddPartnerUserMutationArgs {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  roles: string[];
  permissions: string[];
}
export interface EditPartnerUserMutationArgs {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
  permissions: string[];
  phone?: string | null;
  phoneExtension?: string | null;
}
export interface ChangePartnerUserStatusMutationArgs {
  id: string;
  status: string;
}
export interface CreatePartnerContactMutationArgs {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  productType: string;
}
export interface ReassignPartnerCompanyContactsMutationArgs {
  contactIds: string[];
  companyIds: string[];
}
export interface ReassignPartnerDefaultContactMutationArgs {
  contactId: string;
}
export interface UpdatePartnerContactDetailsMutationArgs {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  productType?: string | null;
}
export interface ReplacePartnerContactMutationArgs {
  oldContactId: string;
  newContactId: string;
}
export interface CreatePartnerCompanyMutationArgs {
  companyName: string;
  ein: string;
  companyZip: string;
  companyPhoneNumber: string;
  parentCompanyID?: string | null;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
}
export interface LinkPartnerCompaniesMutationArgs {
  fromCompany?: string | null;
  toCompanies?: (string | null)[] | null;
  linkAsParent?: boolean | null;
  copyBenefits?: boolean | null;
  copyContributions?: boolean | null;
  enableBenefits?: number | null;
}
export interface EnablePartnerCompanyBenefitsMutationArgs {
  companyId?: string | null;
  hasBenefits?: boolean | null;
}
export interface SavePriorPayrollInfoMutationArgs {
  hasPriorPayroll: boolean;
  priorPayrollProvider?: string | null;
}
export interface UpdateCompanyLegalInfoV2MutationArgs {
  id: string;
  legalName: string;
  dbaName?: string | null;
  ein: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  legalAddress: string;
  legalAddress2: string;
  legalCity: string;
  legalState: string;
  legalZip: string;
  naicsCode?: string | null;
  type?: string | null;
  phone?: string | null;
}
export interface AddCompanyLocationsMutationArgs {
  locations: CompanyWorkLocation[];
}
export interface SetupCompanyPayScheduleMutationArgs {
  company_id: string;
  id?: string | null;
  payFrequency: PayFrequencyChoices;
  payDayOfMonth?: number | null;
  secondPayDayOfMonth?: number | null;
  unshiftedAnchorCheckDate: Date;
  holidayShift: PayScheduleShift;
  saturdayShift: PayScheduleShift;
  sundayShift: PayScheduleShift;
}
export interface CreateOrUpdateEmployeesMutationArgs {
  employees: CompanySetupBasicEmployeeInput[];
}
export interface SendEmployeeInviteEmailsMutationArgs {
  async?: boolean | null;
}
export interface SendEmployeeReminderEmailsV2MutationArgs {
  employeeIds: number[];
}
export interface UpdateEmployeesAdditionalInfoMutationArgs {
  employees: CompanySetupAdditionalInfoEmployeeInput[];
}
export interface MarkFlowSectionEnteredMutationArgs {
  sectionId: string;
}
export interface UpdateFlowSectionMutationArgs {
  flowSectionId: string;
  flowSectionUpdate: FlowSectionUpdate;
}
export interface UpdatePtoPlanMutationArgs {
  planType: string;
  timeOffDaysPerYear?: number | null;
  sickLeaveDaysPerYear?: number | null;
}
export interface SaveContributionPreferencesV3MutationArgs {
  offerContribution: string;
  contributionPriority: string;
  workerContribution: string;
  dependentContribution: string;
}
export interface SaveContributionSettingsMutationArgs {
  lineOfCoverage: string;
  contributionType: string;
  workerContribution: number;
  dependentContribution: number;
  costEstimates: JSON;
}
export interface SaveCarrierSettingsV2MutationArgs {
  coverageStartDate?: Date | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  openEnrollmentPeriod?: OpenEnrollmentPeriodInput | null;
  authName?: string | null;
  authTitle?: string | null;
  authSignature?: GroupApplicationSignatureInput | null;
}
export interface CreateProductMutationArgs {
  company_id: string;
  caption: string;
  description: string;
  createdOn: string;
  expiresOn: string;
  dealLink: string;
  metadata: string;
  price: number;
  businessGroup: string;
  productStatus: string;
}
export interface AddProductCategoriesMutationArgs {
  data?: (JSON | null)[] | null;
}
export interface AddMetaDataToProductMutationArgs {
  metadata?: JSON | null;
}
export interface AddMatchingCriteriaMutationArgs {
  data?: (JSON | null)[] | null;
}
export interface CreateOrEditDealMutationArgs {
  product_id?: string | null;
  company_id?: string | null;
  caption?: string | null;
  description?: string | null;
  createdOn?: string | null;
  expiresOn?: string | null;
  dealLink?: string | null;
  metadata?: JSON | null;
  price?: number | null;
  billingFrequency?: string | null;
  pricingDetails?: string | null;
  businessGroup?: string | null;
  productStatus?: string | null;
  categories?: (JSON | null)[] | null;
  criterias?: (JSON | null)[] | null;
  images?: (JSON | null)[] | null;
}
export interface EditInterestStatusMutationArgs {
  product_id?: string | null;
  interestLevel?: string | null;
  entity_id?: string | null;
  entity?: string | null;
}
export interface EditProductStatusMutationArgs {
  product_id?: string | null;
  expiresOn?: string | null;
  action?: string | null;
}
export interface UpdateUploadDocInfoMutationArgs {
  company_id?: number | null;
  fileId: number;
  key: string;
  filename: string;
  document_meta_id: string;
  document_file_note?: string | null;
}
export interface RequestDocumentsMutationArgs {
  company_id: number;
  admin_id: string[];
  doc_meta_id: (string | null)[];
}
export interface CancelDocumentRequestMutationArgs {
  company_id: number;
}
export interface CompleteDocumentRequestMutationArgs {
  company_id?: number | null;
  admin_id?: (number | null)[] | null;
}
export interface DeleteDocInfoMutationArgs {
  companyId?: number | null;
  docFileGroupIds: (number | null)[];
}
export interface UpdateDocumentFileNoteMutationArgs {
  document_file_group_id: string;
  document_file_note?: string | null;
}
export interface CreateCompanyDocMetaMutationArgs {
  companyId?: number | null;
  section: string;
  name: string;
  description: string;
}
export interface HandleCompanyDocMetaActionMutationArgs {
  companyId: number;
  docMetaId: string;
  action: string;
  name?: string | null;
  description?: string | null;
}
export interface BenefitsFormMapperUpdateOrInsertDataFieldMutationArgs {
  id?: string | null;
  updatedValues?: JSON | null;
}
export interface BenefitsFormMapperStpFormTemplateMutationMutationArgs {
  formTemplateAndMappingData?: StpFormTemplateInput | null;
  appType?: string | null;
}
export interface BenefitsFormMapperStpFetchMasterMappingsMutationArgs {
  fieldMappings?: (StpFieldMappingInput | null)[] | null;
}
export interface BenefitsFormMapperStpReloadExistingMappingsMutationArgs {
  fieldNames?: (string | null)[] | null;
  formId?: string | null;
}
export interface CreateFormTemplateMutationArgs {
  name: string;
  description?: string | null;
  templateUrl: string;
  appType?: string | null;
  category?: string | null;
  state?: string | null;
}
export interface DeleteFormTemplateMutationArgs {
  id?: string | null;
  appType?: string | null;
}
export interface FetchDefaultValuesForUniqueBaseFieldsInExpressionListMutationArgs {
  expressionList?: (string | null)[] | null;
  appType?: string | null;
}
export interface GenerateTestPdfMutationArgs {
  userInput?: (StpBaseFieldMappingInput | null)[] | null;
  fields?: (StpFieldMappingInput | null)[] | null;
  templateUrl?: string | null;
  appType?: string | null;
}
export interface SaveLatestTemplateMutationArgs {
  templateUrl?: string | null;
}
export interface CreateSupportCaseMutationArgs {
  caseData?: JSON | null;
}
export interface CreateOrUpdateGroupTypeMutationArgs {
  name: string;
  description?: string | null;
  memberType: string;
  isSystemType?: boolean | null;
}
export interface CreateGroupMutationArgs {
  groupTypeId: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface UpdateGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface PartialUpdateGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface DeleteGroupMutationArgs {
  id: string;
}
export interface AssignGroupsMutationArgs {
  memberId?: string | null;
  groupIds?: string[] | null;
  groupTypeId: string;
  memberType: string;
}
export interface CreateDepartmentGroupMutationArgs {
  name: string;
  description?: string | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}
export interface UpdateDepartmentGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}
export interface DeleteDepartmentGroupMutationArgs {
  id?: string | null;
  name?: string | null;
}
export interface CreateLocationGroupMutationArgs {
  name: string;
  laborCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  phone?: string | null;
  businessLegalName?: string | null;
  wfCoCode?: string | null;
}
export interface UpdateLocationGroupMutationArgs {
  id: string;
  name: string;
  laborCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string | null;
  businessLegalName?: string | null;
  wfCoCode?: string | null;
}
export interface DeleteLocationGroupMutationArgs {
  id?: string | null;
}
export interface CreateGroupsDatagridMutationArgs {
  scenario: BulkCreateUpdateGroupScenario;
}
export interface CreateOrUpdateDepartmentsChangeMutationArgs {
  id?: string | null;
  changeRequestId: string;
  departments?: DepartmentChangeInput[] | null;
  moveSubordinates?: boolean | null;
}
export interface CreateOrUpdateGroupMembershipChangeMutationArgs {
  changeRequestId: string;
  groupTypeId: string /** ID of group type we are making a change request for */;
  defaultId?: string | null /** Default group within this type for this employee */;
  groupIds: string[] /** The groups within this type that the employee belongs to */;
}
export interface CreateOrUpdateCompensationChangeMutationArgs {
  changeRequestId: string;
  positionRates?: PositionRateChangeInput[] | null;
}
export interface CreateOrUpdateVariableCompChangeMutationArgs {
  changeRequestId: string;
  variableComps?: (VariableCompensationInput | null)[] | null;
}
export interface DeleteChangeRequestItemMutationArgs {
  id: string;
}
export interface CancelDeletionRequestGroupMutationArgs {
  id: string;
  note: string;
  litigationHold: boolean;
}
export interface CreateAndProcessEmployeeDetailsChangeMutationArgs {
  employeeId: string;
  addressChange?: EmployeeDetailsAddressChangeInput | null;
}
export interface ConfirmTerminationMutationArgs {
  employeeId: string;
  terminationAction: TerminationDetailsInput;
  existingTerminationActionId?: string | null;
  shouldAddBenefitsChange: boolean;
  benefitsChange?: BenefitsDetailsInput | null;
  cobraTPAInfo?: CobraTPADetailsInput | null;
}
export interface CreateOrUpdateSchedulingShiftMutationArgs {
  shiftId?: string | null;
  shiftType?: number | null;
  position?: string | null;
  startDateTime: DateTime;
  endDateTime: DateTime;
  isPublished?: boolean | null;
  schedulingEmployeeId?: string | null;
  groups?: (SchedulingShiftGroupInput | null)[] | null;
  status?: string | null;
  seriesData?: SchedulingShiftSeriesInput | null;
}
export interface ClearSchedulingShiftsMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface DeleteSchedulingShiftsMutationArgs {
  shiftId?: number | null;
  seriesId?: number | null;
  shiftDate?: Date | null;
  editType?: number | null;
}
export interface PublishSchedulingShiftsMutationArgs {
  ids?: string[] | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  seriesData?: SchedulingShiftSeriesInput | null;
}
export interface CopyScheduleMutationArgs {
  fromStartDate: Date;
  fromEndDate: Date;
  toStartDate: Date;
  toEndDate: Date;
}
export interface RevertScheduleToLastPublishedStateMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface GenerateAdherenceReportXlsxMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  departments?: (string | null)[] | null;
  locations?: (string | null)[] | null;
}
export interface UpdateSchedulingCompanyMutationArgs {
  flags?: (number | null)[] | null;
  startDayOfWeek?: string | null;
}
export interface EnrollSchedulingEmployeesMutationArgs {
  employeeIds: number[];
}
export interface UnenrollSchedulingEmployeesMutationArgs {
  employeeIds: number[];
}
export interface UpdateSchedulingCompanySettingsMutationArgs {
  companyId?: number | null;
  state?: string | null;
  startDayOfWeek?: number | null;
  flags?: (number | null)[] | null;
}
export interface CreateOrUpdateSchedulingEmployeeAvailabilityMutationArgs {
  id?: number | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  recurringAvailability?: boolean | null;
  availabilityEndDate?: Date | null;
  onDays: (number | null)[];
  frequency?: number | null;
  notes?: string | null;
  availabilityId?: number | null /** these fields are used to determine if single or future edit */;
  availabilityDate?: Date | null;
  editType?: number | null;
}
export interface DeleteSchedulingEmployeeAvailabilityMutationArgs {
  id?: number | null;
  availabilityId?: number | null;
  availabilityDate?: Date | null;
  editType?: number | null;
}
export interface ApproveVacationRequestMutationArgs {
  id: number;
}
export interface CreateOrUpdateVacationRequestMutationArgs {
  id?: number | null;
  employee_id?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  vacationType_id?: number | null;
  reason?: string | null;
}
export interface DeleteVacationRequestMutationArgs {
  id: number;
  restore?: boolean | null;
}
export interface DenyVacationRequestMutationArgs {
  id: number;
  reason?: string | null;
}
export interface UpdateEmployeePtoAccountDetailsMutationArgs {
  employeeId: number;
  accountType: string;
  newAccrualRate?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  newBalance?: string | null;
  reason?: string | null;
}
export interface UpdateEmployeePtoPolicyMutationArgs {
  employeeId: number;
  newCompanyPto_id: number;
  accountType: string;
}
export interface BulkUpdateZAppInstallSubscriptionsStatusMutationArgs {
  zappInstallSubscriptions: JSON[];
}
export interface UpdateZAppInstallForceDisableMutationArgs {
  zAppUniqueId?: string | null;
  companyId?: number | null;
  forceDisable?: boolean | null;
}
export interface SavePlaidAccountMutationArgs {
  public_token?: string | null;
  metadata?: PlaidMetaData | null;
  verificationType?: string | null;
  verifyIdentity?: boolean | null;
}
export interface CreateBankAccountVerificationMutationArgs {
  bankDetails?: BankDetails | null;
}
export interface AddZpCompanyBankAccountMutationArgs {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountHolderName?: string | null;
}
export interface SetVoidCheckUrlMutationArgs {
  voidCheckUrl: string;
}
export interface SetPayrollSignatoryMutationArgs {
  signatoryId: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  title?: string | null;
}
export interface Import401kDataMutationArgs {
  fileUrl: string;
  template: string;
  format: string;
  startDate?: string | null;
  endDate?: string | null;
}
export interface UpdateContractor1099FormFilingMutationArgs {
  zpEmployee1099FormFilingId?: number | null;
  contractor1099Id?: number | null;
  fileThroughZenefits: boolean;
  externalPayAmount: number;
}
export interface SetFiling1099FormThroughZenefitsForAllContractorsMutationArgs {
  year: number;
  fileThroughZenefits: boolean;
}
export interface SetOpenClose1099FilingDateMutationArgs {
  year: number;
  openDate: Date;
  closeDate: Date;
}
export interface SetAdminsToOptInEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface SetContractorsWithMissingInfoEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface SetAdminFilingConfirmationEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface GenerateVendorCsvFileAndSendEmailMutationArgs {
  email: string;
  year: number;
}
export interface UpdateMappingTemplateMutationArgs {
  orderedActiveColumns: (string | null)[];
  companyId?: string | null;
}
export interface UpdateAccountMappingTemplateMutationArgs {
  updatedAccountType: UpdateAccountInfo;
  companyId?: string | null;
}
export interface UpdateSelectedLaborGroupTypesForCompanyMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  laborGroupTypeIds: (string | null)[];
}
export interface AddOrEditMappingGroupMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroup_id?: string | null;
  payCategoryType: string;
  payTypes: (PayTypeRequest | null)[];
  departmentIds?: (string | null)[] | null;
  locationIds?: (string | null)[] | null;
  positionIds?: (string | null)[] | null;
  projectIds?: (string | null)[] | null;
  customLaborGroupIds?: (string | null)[] | null;
  creditAccountId: string;
  debitAccountId: string;
  classTrackingId?: string | null;
}
export interface PublishMappingsMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroupIds: string[];
}
export interface DeleteMappingsMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroupIds: string[];
}
export interface CreateFolderMutationArgs {
  companyId?: number | null;
  name: string;
  folderPermissionList?: (FolderPermission | null)[] | null;
}
export interface EditFolderMutationArgs {
  id?: number | null;
  name?: string | null;
  folderPermissionList?: (FolderPermission | null)[] | null;
}
export interface DeleteFolderMutationArgs {
  folderId?: number | null;
}
export interface CreateNewDocumentMutationArgs {
  name?: string | null;
  createNewFolder?: boolean | null;
  newFolderName?: string | null;
  uploadedFileKey?: string | null;
  uploadedFileId?: number | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
  folderId?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
}
export interface DeleteDocumentMutationArgs {
  documentId?: number | null;
}
export interface EditDocumentMutationArgs {
  id?: number | null;
  name?: string | null;
  createNewFolder?: boolean | null;
  newFolderName?: string | null;
  uploadedFileKey?: string | null;
  uploadedFileId?: number | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
  folderId?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
}
export interface DeleteDocumentV2MutationArgs {
  documentId?: number | null;
  employeeId?: number | null;
  documentType?: string | null;
  documentName?: string | null;
  documentTab?: string | null;
  documentUrl?: string | null;
  companyId?: number | null;
  hasSignature?: boolean | null;
  newHire?: number | null;
  documentReference?: string | null;
}
export interface CreateWorkerDocumentFolderMutationArgs {
  name?: string | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface EditWorkerDocumentFolderMutationArgs {
  id?: number | null;
  name?: string | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface DeleteWorkerDocumentFolderMutationArgs {
  folderId?: number | null;
}
export interface UploadWorkerDocumentMutationArgs {
  id?: number | null;
  name: string;
  uploadedFileId?: number | null;
  tags?: (string | null)[] | null;
  folderId?: number | null;
  employeeId: number;
  employeeCanAccess?: boolean | null;
  restrictedAdminCanAccess?: boolean | null;
  managerCanAccess?: boolean | null;
}
export interface SetupLoaPolicyMutationArgs {
  loaPolicy?: LoaPolicyRequest | null;
}
export interface LoaRequiredDocumentActionsMutationArgs {
  requiredDocument?: LoaRequiredDocumentRequest | null;
}
export interface PolicyActionsMutationArgs {
  policyAction?: PolicyActionRequest | null;
}
export interface DeleteLeaveRequestMutationArgs {
  requestId: string;
}
export interface RecordLeaveRequestMutationArgs {
  id?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  leaveType?: string | null;
  policyId?: number | null;
  reasonId?: number | null;
  status?: string | null;
  additionalNotes?: string | null;
  estimatedDates?: (InputDateRangeMessage | null)[] | null;
  actualDates?: (InputDateRangeMessage | null)[] | null;
  documentsUploaded?: (InputLoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (number | null)[] | null;
  paymentOption?: string | null;
  lastDayWorked?: Date | null;
  firstDayBackInOffice?: Date | null;
}
export interface UpdateUploadedLoaDocumentMutationArgs {
  requestId?: number | null;
  requiredDocumentId?: number | null;
  fileId?: number | null;
  fileName?: string | null;
  key?: string | null;
  action?: string | null;
}
export interface IncrementInboxActionViewsMutationArgs {
  actionId: string;
}
export interface CompleteInboxActionMutationArgs {
  actionId: string;
  employeeId: string;
  status?: InboxActionStatus | null;
  reason?: string | null;
}
export interface CompleteAddressSubActionMutationArgs {
  id: string;
  employeeId: string;
  address: AddressSubActionInput;
}
export interface CompleteBankSubActionMutationArgs {
  id: string;
  employeeId: string;
  bank: BankSubActionInput;
}
export interface CompleteConfirmationSubActionMutationArgs {
  id: string;
  employeeId: string;
  value?: boolean | null;
}
export interface CompleteSingleValueSubActionMutationArgs {
  id: string;
  employeeId: string;
  value: string;
}
export interface CompleteSignatureSubActionMutationArgs {
  id: string;
  employeeId: string;
  signature: SignatureSubActionInput;
}
export interface CompleteContentReviewSubActionMutationArgs {
  id: string;
  employeeId: string;
  review: ContentReviewSubActionInput;
}
export interface CompletePrerequisiteRedirectTaskMutationArgs {
  type: PrerequisiteType;
  group: string;
}
export interface ContactAdvisorMutationArgs {
  type: ContactAdvisorType;
  message: string;
  phoneNumber?: string | null;
}
export interface UpdateProductionActivationMutationArgs {
  app?: string | null;
  sha?: string | null;
  user?: string | null;
  originalBranch?: string | null;
  originalCreator?: string | null;
  originalTimeStamp?: string | null;
}
export interface LinkUserAccountsMutationArgs {
  toLinkUserEmail?: string | null;
  toLinkUserPassword?: string | null;
  currentUser?: string | null;
}
export interface UnlinkUserAccountsMutationArgs {
  employeeId: number;
}
export interface SaveMultiOrgReportMutationArgs {
  selectedCompanies?: (string | null)[] | null;
  reportIdOrName: string;
  selectedColumns?: (string | null)[] | null;
  selectedFilters?: string | null;
  action: string;
}
export interface SaveMultiOrgReportScheduleMutationArgs {
  customReportId: number;
  runFrequency: string;
  reportExportFormat: string;
  dailyRunFrequency?: string | null;
  weeklyRunFrequency?: string | null;
  weeklyRunReportOnDay?: string | null;
  monthlyRunFrequency?: string | null;
  daysOfWeek?: (number | null)[] | null;
}
export interface MutateMultiOrgReportStateMutationArgs {
  customReportId: number;
  action: string;
  newReportName?: string | null;
}
export interface SubmitProductInterestMutationArgs {
  fullName: string;
  phoneNumber: string;
  productName: string;
}
export interface GetCompanyOptionsMutationArgs {
  searchText?: string | null;
}
export interface CreateDatagridMutationArgs {
  scenario: DatagridScenario;
  companyId?: string | null;
}
export interface DatagridFileUploadedMutationArgs {
  id: string;
  uploadedFileKey: string;
}
export interface UpdateDatagridPreviousUrlMutationArgs {
  id: string;
  previousUrl: string;
}
export interface ResetDatagridMutationArgs {
  id: string;
}
export interface SaveDatagridColumnMappingsMutationArgs {
  datagridId: string;
  columnMappings: DatagridColumnMappingInput[];
}
export interface GenerateDatagridRowsMutationArgs {
  datagridId: string;
}
export interface SaveDatagridRowsMutationArgs {
  datagridId: string;
  rows: DatagridRowInput[];
}
export interface DeleteDatagridRowsMutationArgs {
  datagridId: string;
  ids: string[];
}
export interface SubmitDatagridMutationArgs {
  id: string;
}
export interface HandleCancellationRequestMutationArgs {
  cancellationNoticeDate: Date;
  cancellationReason: string;
  cancellationReasonDetails?: string | null;
  formExpirationDate: Date;
  listOfAdmins: (string | null)[];
  companyId: string;
  contractEndDate: Date;
}
export interface UpdateQuestionnaireResponseMutationArgs {
  id: string;
  questionnaireResponse: JSON;
}
export interface SubmitQuestionnaireResponseMutationArgs {
  id: string;
}
export interface ActivateResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface DeactivateResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface ClaimResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface ResendWebhookMessagesMutationArgs {
  messageIds?: (string | null)[] | null;
}
export interface UploadPpbSpreadsheetMutationArgs {
  companyId?: string | null;
  fileKey: string;
  fileName: string;
  liveReader?: boolean | null;
  isYTD?: boolean | null;
  dataExistsFlow?: boolean | null;
}
export interface CreateEmployeeBasicInfoMutationArgs {
  companyId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  locationId?: string | null;
  departmentId?: string | null;
  reportToEmployeeId?: string | null;
  requester?: number | null;
  reason?: string | null;
  isReadyToProcess?: boolean | null;
  applyImmediately?: boolean | null;
  type?: string | null;
  status?: string | null;
  isPrimaryRequestGroup?: boolean | null;
  isMainRequest?: boolean | null;
  isFromAddExistingFlow?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  atsNewHireId?: string | null;
}
export interface UpdateEmployeeBasicInfoMutationArgs {
  newHireId: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}
export interface EditNewHireEmploymentMutationArgs {
  newHireId: string;
  employeeId: string;
  employmentType: string;
  contractorDetailWorkerType?: string | null;
  isContractor?: boolean | null;
}
export interface SaveNewHireJobDetailsMutationArgs {
  newHireId: string;
  employeeId: string;
  hireDate?: Date | null;
  title?: string | null;
  employmentType?: string | null;
  isContractorButNotCPT?: boolean | null;
  locationId?: string | null;
  departmentId?: string | null;
  reportToEmployeeId?: string | null;
  eeoJobCategory?: string | null;
}
export interface UpdateNewHireEmploymentMutationArgs {
  newHireId: string;
  employeeId: string;
  reportingMethod?: string | null;
  offerLetterId?: number | null;
  offerExpiryDate?: Date | null;
  isDoingOfferLetter?: boolean | null;
  isDoingEmployeeHandbook?: boolean | null;
  isDoingAgreements?: boolean | null;
  commission?: string | null;
  relocationBonus?: string | null;
  bonusAbsolute?: string | null;
  bonusPercent?: string | null;
  duties?: string | null;
  companyPtoId?: number | null;
  stockOptionType?: string | null;
  employeeOptionType?: string | null;
  grantDate?: Date | null;
  percentStockOptions?: string | null;
  numStockOptions?: string | null;
  additionalTerms?: string | null;
  payScheduleId?: string | null;
  hireDate?: Date | null;
  employmentType?: string | null;
  payRate?: string | null;
  compType?: string | null;
  hasNonExemptJobDuties?: boolean | null;
  isFlsaExempt?: boolean | null;
  workingHoursPerWeek?: string | null;
  zeroSalaryReason?: string | null;
  zeroSalaryReasonForOtherType?: string | null;
  currency?: string | null;
  annualSalary?: string | null;
  annualSalaryIncludesBonus?: boolean | null;
  overtimeEligibility?: boolean | null;
  endDate?: Date | null;
  isContractor?: boolean | null;
  workerType?: string | null;
  isOffCycle?: boolean | null;
  fixedAmount?: string | null;
}
export interface CreateOrUpdateEmployeeEligibilityPart1MutationArgs {
  input: EmployeeEligibilityPart1Input;
}
export interface UpdateEmployeeEligibilityPart2MutationArgs {
  input: EmployeeEligibilityPart2Input;
}
export interface ReviewAndSignEmployeeEligibilityMutationArgs {
  input: EmployeeEligibilityReviewInput;
}
export interface UpdateEmployeeEligibilityDocumentsMutationArgs {
  input: EmployeeEligibilityDocumentsInput;
}
export interface CreateCorrectionForInprogressEligibilityMutationArgs {
  input: EligibilityCorrectionCreationInput;
}
export interface CompleteEligibilitySection1CorrectionMutationArgs {
  eligibilityCorrectionId: string;
}
export interface SyncI9EligibilityOfflineMutationArgs {
  eligibilityId: string;
}
export interface UpdateEmployerEligibilityDocumentsMutationArgs {
  input: EmployerEligibilityDocumentsInput;
}
export interface UpdateEmployerEligibilityCertificationMutationArgs {
  input: EmployerEligibilityCertificationInput;
}
export interface AcknowledgeEmployerEligibilityMutationArgs {
  input: EmployerEligibilityAcknowledgementInput;
}
export interface AcknowledgeEligibilityCorrectionMutationArgs {
  input: EligibilityCorrectionAcknowledgementInput;
}
export interface CreateOrUpdateEligibilityReverificationMutationArgs {
  input?: EligibilityReverificationInput | null;
}
export interface CertifyEligibilityReverificationMutationArgs {
  input?: EligibilityReverificationCertificationInput | null;
}
export interface CreateInitRunMutationArgs {
  companyId?: string | null;
  datagridId: string;
  checkDate: Date;
}
export interface ApproveInitRunMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
}
export interface CloseZppDatagridMutationArgs {
  companyId?: string | null;
  zPriorPayrollDatagridId: string;
}
export interface RunYtdAmountsCalculatorMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
  isYTD?: boolean | null;
}
export interface RunTaxCalculatorMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
  liveReader?: boolean | null;
  runAsync?: boolean | null;
}
export interface OverwriteZpreDataMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
}
export interface PayPriorTaxLiabilitiesMutationArgs {
  companyId: string;
  signatureData: string;
  taxLiabilities: TaxLiability[];
}
export interface CreateCompanyGroupMutationArgs {
  name: string;
  memberIds: (string | null)[];
}
export interface AddCompaniesToGroupMutationArgs {
  groupId: string;
  companyIds: (string | null)[];
}
export interface MergeCompanyGroupsMutationArgs {
  groupIds: (string | null)[];
  name: string;
}
export interface NewHireAdditionalInfoMutationArgs {
  newHireId: string;
  employeeId: string;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  isDoingBackgroundCheck?: boolean | null;
  isBackgroundCheckPremium?: boolean | null;
  isBackgroundCheckIncludingMotorVehicleReport?: boolean | null;
}
export interface InitializeDirectoryUpdateMutationArgs {
  columns: (string | null)[];
  employeeIds: (string | null)[];
  effectiveDate?: string | null;
  companyId?: string | null;
}
export interface ConfigurePayrollSettingsMutationArgs {
  newHireId: string;
  employeeId: string;
  wfCustomFileNumber?: string | null;
  wfJobTitleCode?: string | null;
  wfCustomCoCode?: string | null;
  workersCompCode?: string | null;
  wfBusinessUnitCode?: string | null;
  wfLocationCode?: string | null;
  usesWfTimeAndAttendance?: boolean | null;
  wfBadgeNumber?: string | null;
  wfPayClassCode?: string | null;
  isWfSupervisor?: boolean | null;
  wfSupervisorPayrollId?: string | null;
  departmentCode?: string | null;
  pxLocationCode?: string | null;
  pxLaborAssignmentCode?: string | null;
  plCostCenter1?: string | null;
  plCostCenter2?: string | null;
  plCostCenter3?: string | null;
  positionCode?: string | null;
  intuitPayScheduleId?: string | null;
  isDoingOnboarding?: boolean | null;
  isDoingTax?: boolean | null;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
}
export interface NewHireNotificationMutationArgs {
  newHireId: string;
  employeeId: string;
  employeeStatus?: string | null;
  skipEmailingOfferLetter?: boolean | null;
}
export interface SyncNewHireEmployerFlowMutationArgs {
  newHireId: string;
  employeeId: string;
  flowSectionName?: string | null;
}
export interface SetEmployeeGenericDataMutationArgs {
  newHireId: string;
  employeeId: string;
  workEmail?: string | null;
}
export interface UpdateNewHireCustomFieldValuesMutationArgs {
  newHireId: string;
  employeeId: string;
  customFieldValues?: (NewHireCustomFieldValueDetails | null)[] | null;
}
export interface CreateOrUpdatePostMutationArgs {
  id?: string | null;
  locationIds?: (number | null)[] | null;
  departmentIds?: (number | null)[] | null;
  postDescription?: string | null;
  myTeam?: boolean | null;
  entireCompany?: boolean | null;
}
export interface DeletePostMutationArgs {
  id: string;
}
export interface CreateSocialFeedCommentMutationArgs {
  postId: string;
  content: string;
}
export interface DeleteSocialFeedCommentMutationArgs {
  id: string;
  postId: string;
}
export interface TogglePostLikeMutationArgs {
  postId: string;
}
export interface CreateOrUpdateFeedSettingsMutationArgs {
  id?: string | null;
  isCompanyWideVisible?: boolean | null;
  announcementsEnabled?: boolean | null;
  birthdayPostsEnabled?: boolean | null;
}
export interface CreateBulkDocumentDownloadRequestMutationArgs {
  employeeId?: string | null;
  source?: string | null;
  employeeIds?: (number | null)[] | null;
  documentsTabs?: string | null;
  documentsType?: string | null;
}
export interface CreateCorrectionForCompleteEligibilityMutationArgs {
  isSectionOneCorrection: boolean;
  employeeId: string;
  changeReason: string;
}
export interface AgreeToTouMutationArgs {
  employeeId: string;
}
export interface SendNewOfferLetterMutationArgs {
  employeeId: string;
}
export interface CancelBackgroundCheckMutationArgs {
  newHireId: string;
  employeeId: string;
}
export interface EmployeeProfileActionPutWorkerOnLoaMutationArgs {
  targetEmployeeId: string;
  targetCompanyId: string;
}
export interface EmployeeProfileActionDeleteProfileMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionAccessAccountMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionDeletePhotoMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionUpdatePhotoMutationArgs {
  targetEmployeeId: string;
  photoUrl: string;
}
export interface EmployeeProfileActionRevokeOfferMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionCancelTerminationMutationArgs {
  targetEmployeeId: string;
}
export interface EditIsProvisioningEnabledForSsoProviderMutationArgs {
  zappId: string;
  isEnabled: boolean;
}
export interface EditSsoSettingsMutationArgs {
  zappId: string;
  isEnabled: boolean;
  metadataXML: string;
}
export interface DeleteSwitchByKeyMutationArgs {
  key: string;
}
export interface RollbackSwitchToLogEntryMutationArgs {
  putLogId: number;
}
export interface CreateOrUpdateSwitchByKeyMutationArgs {
  key: string;
  version?: number | null;
  state: number;
  percent: number;
  sampling: number;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
}
export interface EditTwoFactorSettingsMutationArgs {
  settingType: string;
}

export enum CancellationRequestStatus {
  initial = 'initial',
  inProgress = 'inProgress',
  completed = 'completed',
}
/** Different statuses of employees */
export enum EmployeeStatus {
  Act = 'Act',
  Req = 'Req',
  Set = 'Set',
  Ter = 'Ter',
  Del = 'Del',
  LOA = 'LOA',
  NA = 'NA',
}
/** Different types of employees */
export enum EmployeeType {
  AD = 'AD',
  RE = 'RE',
  HC = 'HC',
  IN = 'IN',
  SH = 'SH',
  TE = 'TE',
}

export enum ChangeRequestItemKind {
  position = 'position',
  department = 'department',
  location = 'location',
  project = 'project',
  compensation = 'compensation',
}

export enum CompType {
  S = 'S',
  H = 'H',
  A = 'A',
  N = 'N',
}
/** The employee's employment status/type */
export enum EmploymentType {
  FT = 'FT',
  PT = 'PT',
  IN = 'IN',
  TP = 'TP',
  CO = 'CO',
}

export enum TerminationType {
  IN = 'IN',
  VR = 'VR',
  VN = 'VN',
  UN = 'UN',
  NS = 'NS',
}

export enum BenefitsSettlementStatus {
  created = 'created',
  send_fail = 'send_fail',
  send_channel_processing = 'send_channel_processing',
  aborted = 'aborted',
  sent = 'sent',
  completed = 'completed',
}

export enum WorkerType {
  AW = 'AW',
  CW = 'CW',
  VE = 'VE',
  IC = 'IC',
  IN = 'IN',
}

export enum BannerType {
  info = 'info',
  error = 'error',
  success = 'success',
}

export enum AdHocUnit {
  RIDE = 'RIDE',
  FIXED = 'FIXED',
}

export enum ChangeRequestGroupStatus {
  approved = 'approved',
  completed = 'completed',
  declined = 'declined',
  created = 'created',
  approval_emails_sent = 'approval_emails_sent',
}

export enum CompanyPayScheduleStatus {
  REQUESTED = 'REQUESTED',
  ACTIVE = 'ACTIVE',
  UPDATED = 'UPDATED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
}

export enum PayScheduleCreationMethod {
  PYP = 'PYP',
  SMP = 'SMP',
}

export enum PayFrequencyChoices {
  We = 'We',
  BW = 'BW',
  SM = 'SM',
  Mo = 'Mo',
}

export enum PayScheduleShift {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  NONE = 'NONE',
}

export enum ArrearDayType {
  C = 'C',
  B = 'B',
}

export enum CompanyPayScheduleCompType {
  H = 'H',
  S = 'S',
  B = 'B',
}

export enum ImplementationProjectMethod {
  QUICKSTART = 'QUICKSTART',
  GUIDED = 'GUIDED',
}

export enum ImplementationProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  NEW_PROJECT = 'NEW_PROJECT',
  SUCCESSFUL = 'SUCCESSFUL',
  DEFERRED = 'DEFERRED',
  FAILED = 'FAILED',
}

export enum ImplementationProjectStage {
  S1_NEW_PROJECT = 'S1_NEW_PROJECT',
  S2_DOCUMENT_COLLECTION = 'S2_DOCUMENT_COLLECTION',
  S3_CONFIGURATION = 'S3_CONFIGURATION',
  S4_QA = 'S4_QA',
  S5_CLOSED = 'S5_CLOSED',
}

export enum TargetRuleScopeType {
  ALL = 'ALL',
  CREATOR_TEAM = 'CREATOR_TEAM',
  CUSTOM = 'CUSTOM',
  SPECIFIC_EMPLOYEES = 'SPECIFIC_EMPLOYEES',
}

export enum TargetRuleReviewer {
  MANAGER = 'MANAGER',
  PEERS = 'PEERS',
  DIRECT_REPORTS = 'DIRECT_REPORTS',
  SELF = 'SELF',
}

export enum TargetRuleManagerRole {
  SHARED_FEEDBACK = 'SHARED_FEEDBACK',
  PRIVATE_FEEDBACK = 'PRIVATE_FEEDBACK',
  PRIVATE_AND_SHARED_FEEDBACK = 'PRIVATE_AND_SHARED_FEEDBACK',
  NO_FEEDBACK = 'NO_FEEDBACK',
}

export enum QFQuestionType {
  RATING = 'RATING',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  NUMERIC = 'NUMERIC',
  PERCENTAGE = 'PERCENTAGE',
  MONEY = 'MONEY',
  SELECT = 'SELECT',
}

export enum QFSessionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

export enum TalentFlowScheduleEvent {
  HIRE_DATE = 'HIRE_DATE',
  PREV_RUN = 'PREV_RUN',
  ONE_TIME_RUN = 'ONE_TIME_RUN',
}

export enum TalentTimeUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum ReviewSessionType {
  REVIEW = 'REVIEW',
  SUMMARY = 'SUMMARY',
}

export enum ReviewRunStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
  INACTIVE = 'INACTIVE',
}

export enum ReviewStatus {
  DRAFT = 'DRAFT',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum ReviewTemporaryState {
  EXTEND_DUE_DATES = 'EXTEND_DUE_DATES',
  ALLOW_PERIODIC_REVIEW_REOPENING = 'ALLOW_PERIODIC_REVIEW_REOPENING',
}

export enum ReviewTemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum GoalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum GoalTarget {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
  DEPARTMENT = 'DEPARTMENT',
  TEAM = 'TEAM',
}

export enum MeetingsUserPreferenceGroupType {
  EMAIL = 'EMAIL',
}

export enum WellbeingAssessmentRunStatus {
  RUNNING = 'RUNNING',
  PENDING_RESULTS = 'PENDING_RESULTS',
  ENDED = 'ENDED',
}

export enum WellbeingAssessmentSessionType {
  SELF = 'SELF',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum WellbeingAssessmentStatus {
  DRAFT = 'DRAFT',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum WellbeingLifeEventType {
  NewHire = 'NewHire',
  ManagerChange = 'ManagerChange',
  TitleChange = 'TitleChange',
  DepartmentChange = 'DepartmentChange',
  AddressChange = 'AddressChange',
  WorkLocationChange = 'WorkLocationChange',
}

export enum SurveyScheduleEvent {
  ONE_TIME_RUN = 'ONE_TIME_RUN',
}

export enum SurveyStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  CANCELLED = 'CANCELLED',
  ENDED = 'ENDED',
}

export enum SurveySegmentKey {
  DEPARTMENT = 'DEPARTMENT',
  LOCATION = 'LOCATION',
  MANAGER = 'MANAGER',
  GENDER = 'GENDER',
  TENURESHIP = 'TENURESHIP',
}

export enum OverallOnboardingState {
  INDETERMINATE = 'INDETERMINATE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum PayrollOnboardingState {
  SETUP = 'SETUP',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum BenefitsOnboardingState {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum ZScoreCompanyStatus {
  new = 'new',
  active = 'active',
  inactive = 'inactive',
  former = 'former',
}

export enum ZScorePayrollQualificationLevel {
  two_day = 'two_day',
  four_day = 'four_day',
}

export enum ZScoreBlockScoreOfacResult {
  hit = 'hit',
  no_hit = 'no_hit',
  not_found = 'not_found',
  overridden = 'overridden',
}

export enum ZScoreRiskLevel {
  low_risk = 'low_risk',
  medium_risk = 'medium_risk',
  high_risk = 'high_risk',
  very_high_risk = 'very_high_risk',
}
/** List of all ZScore Category Names */
export enum ZScoreCategoryName {
  duns_and_brad_ccs = 'duns_and_brad_ccs',
  duns_and_brad_fss = 'duns_and_brad_fss',
  employee_count = 'employee_count',
  zpay_time_spent = 'zpay_time_spent',
  zpay_nsf_failures = 'zpay_nsf_failures',
  billing_failures = 'billing_failures',
  blockscore_ofac = 'blockscore_ofac',
  two_day_payroll = 'two_day_payroll',
}

export enum ZScoreUpdateFrequencyUnit {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years',
}

export enum CheckoutContractType {
  monthly = 'monthly',
  annual = 'annual',
}

export enum CheckoutBillingPeriod {
  monthly = 'monthly',
  annual = 'annual',
}

export enum CheckoutPackageProductSku {
  V3_TIER_ONE = 'V3_TIER_ONE',
  V3_TIER_TWO = 'V3_TIER_TWO',
  V3_TIER_THREE = 'V3_TIER_THREE',
}

export enum CheckoutOptionalProductSku {
  PYP_ADDON = 'PYP_ADDON',
  ULTIMATE_ADVISOR_ADDON = 'ULTIMATE_ADVISOR_ADDON',
  BEN_ADMIN_ADDON = 'BEN_ADMIN_ADDON',
  BEN_CONNECT_ADDON = 'BEN_CONNECT_ADDON',
}

export enum FolderPermissionType {
  CAN_VIEW = 'CAN_VIEW',
  CAN_EDIT = 'CAN_EDIT',
}

export enum PaymentAuthorizationType {
  ach = 'ach',
  cc = 'cc',
}

export enum OmniSearchSource {
  employees = 'employees',
  actions = 'actions',
  help = 'help',
}

export enum DatagridColumnType {
  addressCity = 'addressCity',
  addressCountry = 'addressCountry',
  addressState = 'addressState',
  addressStreet1 = 'addressStreet1',
  addressStreet2 = 'addressStreet2',
  addressZip = 'addressZip',
  boolean = 'boolean',
  date = 'date',
  decimal = 'decimal',
  email = 'email',
  integer = 'integer',
  money = 'money',
  percentage = 'percentage',
  phone = 'phone',
  string = 'string',
  stringInteger = 'stringInteger',
}

export enum ColumnConfigValueFilter {
  mappedColumns = 'mappedColumns',
  allColumns = 'allColumns',
}

export enum DatagridStatus {
  initial = 'initial',
  mappingColumns = 'mappingColumns',
  mappingValues = 'mappingValues',
  editingData = 'editingData',
  review = 'review',
  processing = 'processing',
  processed = 'processed',
  readOnly = 'readOnly',
}

export enum DatagridScenario {
  bulkUpdatePayTemplate = 'bulkUpdatePayTemplate',
  companySetupAddYourPeople = 'companySetupAddYourPeople',
  directoryUpdate = 'directoryUpdate',
  priorPayrollBalancing = 'priorPayrollBalancing',
  sample = 'sample',
}

export enum DatagridSubmissionStatusOptionAction {
  submitDatagrid = 'submitDatagrid',
  closeModal = 'closeModal',
}

export enum WellbeingAnalyticsEventType {
  UserContentInteraction = 'UserContentInteraction',
}

export enum WellbeingAnalyticsContentType {
  Article = 'Article',
}

export enum EmployeeSetPhysicianDentistActions {
  employeeSetPhysician = 'employeeSetPhysician',
  dependentSetPhysician = 'dependentSetPhysician',
  employeeSetDentist = 'employeeSetDentist',
  dependentSetDentist = 'dependentSetDentist',
}

export enum EmployeeSetBeneficiaryAction {
  employeeBulkSetBeneficiaries = 'employeeBulkSetBeneficiaries',
  employeeSetSingleBeneficiary = 'employeeSetSingleBeneficiary',
}

export enum BulkCreateUpdateGroupScenario {
  bulkCreateUpdatePositions = 'bulkCreateUpdatePositions',
  bulkCreateUpdateProjects = 'bulkCreateUpdateProjects',
  bulkCreateUpdateCustomGroups = 'bulkCreateUpdateCustomGroups',
}

export enum InboxActionStatus {
  created = 'created',
  sent = 'sent',
  completed = 'completed',
  cannot_complete = 'cannot_complete',
  deleted = 'deleted',
  canceled = 'canceled',
  pending_approval = 'pending_approval',
}

export enum PrerequisiteType {
  EE = 'EE',
  CO = 'CO',
}

export enum ContactAdvisorType {
  hr = 'hr',
  payroll = 'payroll',
}

export enum GroupTypeName {
  Project = 'Project',
  Position = 'Position',
}

export enum PolicySetupState {
  GETTING_STARTED = 'GETTING_STARTED',
  POLICY_DETAILS = 'POLICY_DETAILS',
  PAID_TIME_OFF = 'PAID_TIME_OFF',
  PAY = 'PAY',
  BENEFITS = 'BENEFITS',
  REQUIRED_DOCUMENTS = 'REQUIRED_DOCUMENTS',
  SUMMARY = 'SUMMARY',
  COMPLETED = 'COMPLETED',
}

export enum ReviewerRelationship {
  MANAGER = 'MANAGER',
  DIRECT_REPORT = 'DIRECT_REPORT',
  PEER = 'PEER',
  SELF = 'SELF',
}

export type ChangeDetail = GroupMembershipChange | CompensationChange;

export type BenefitsPlan =
  | Plan
  | DentalPlan
  | VisionPlan
  | LifePlanNew
  | AddPlan
  | StdPlanNew
  | LtdPlanNew
  | SupplementalPlan;

/** TODO: remove all types and just expose the genericQFQuestionResponse asQFQuestionResponse and stop injecting questionType on the response. */
export type QFQuestionResponse = QFRatingResponse | QFTextResponse | QFCheckboxResponse | GenericQFQuestionResponse;

export type MarketplacePlan = MarketplaceMedicalPlan | MarketplaceDentalPlan | MarketplaceVisionPlan;

export type PlanProperties = MedicalPlanDetails | DentalPlanDetails | VisionPlanDetails;
