// from https://medium.com/bleeding-edge/enforcing-an-aspect-ratio-on-an-html-element-in-react-and-css-27a13241c3d4

import * as React from 'react';

import { styled } from 'z-frontend-theme';

const OuterWrapper = styled.div<{ ratio: number }>`
  position: relative;
  width: 100%;
  height: 0;
  /**
   * For human readability, the ratio is expressed as
   * width / height, so we need to invert it.
   */
  padding-bottom: ${props => (1 / props.ratio) * 100}%;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

type AspectRatioProps = {
  children?: any;
  /**
   * The width divided by the height. This ratio can be passed in
   * using JavaScript division syntax. So, to get a 16:9 ratio,
   * simply pass `16/9`.
   */
  ratio: number;
};

const AspectRatio = ({ children, ratio }: AspectRatioProps) => (
  <OuterWrapper ratio={ratio}>
    <InnerWrapper>{children}</InnerWrapper>
  </OuterWrapper>
);
export default AspectRatio;
