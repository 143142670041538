import React, { Component } from 'react';
import gql from 'graphql-tag';

import { Query } from 'z-frontend-network';

import { TrialPromoQuery } from '../../gqlTypes';
import TrialPromoBanner from './TrialPromoBanner';

type TrialPromoBannerContainerProps = {};

export default class TrialPromoBannerContainer extends Component<TrialPromoBannerContainerProps> {
  render() {
    return (
      <Query<TrialPromoQuery.Query> query={trialPromoQuery} handleLoading={false} handleError={false} isBackgroundQuery>
        {({ data, loading, error }) => {
          if (error) {
            console.error('GraphQL error in TrialPromoBannerContainer:', error);
            return null;
          }
          if (loading || !data) {
            return null;
          }
          const { dashboard } = data;
          const trialPromoCode = dashboard && dashboard.trialPromoCode;

          return <TrialPromoBanner trialPromoCode={trialPromoCode} />;
        }}
      </Query>
    );
  }
}

const trialPromoQuery = gql`
  query TrialPromoQuery {
    dashboard {
      id
      trialPromoCode
    }
  }
`;
