import React, { Component } from 'react';
import gql from 'graphql-tag';

import { TextBlock } from 'zbase';
import { Banner, BannerFlexContainerProps } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { Query } from 'z-frontend-network';

import { MarketplaceBanner, MarketplaceSetupFlow } from '../../gqlTypes';

const marketplaceSetupFlowQuery = gql`
  query MarketplaceSetupFlow {
    isMarketplaceSetupFlow
  }
`;

const marketplaceBannerQuery = gql`
  query MarketplaceBanner {
    companySetupTasksV3 {
      id
      tasks {
        tag
        isReady
        isComplete
        isEntered
      }
    }
  }
`;

export default class MarketplaceBannerContainer extends Component<BannerFlexContainerProps> {
  render() {
    const { ...containerProps } = this.props;

    return (
      <Query<MarketplaceSetupFlow.Query, MarketplaceSetupFlow.Variables>
        query={marketplaceSetupFlowQuery}
        handleLoading={false}
        handleError={false}
        renderError={() => null}
        isBackgroundQuery
      >
        {({ data: marketplaceSetupFlowData, loading }) => {
          if (loading || !marketplaceSetupFlowData || !marketplaceSetupFlowData.isMarketplaceSetupFlow) {
            return null;
          }

          return (
            <Query<MarketplaceBanner.Query, MarketplaceBanner.Variables>
              query={marketplaceBannerQuery}
              handleLoading={false}
              handleError={false}
              renderError={() => null}
              isBackgroundQuery
            >
              {({ data: marketplaceBannerData, loading }) => {
                if (loading || !marketplaceBannerData || !marketplaceBannerData.companySetupTasksV3) {
                  return null;
                }

                const isMarketplaceSetupFlowInProgress = marketplaceBannerData.companySetupTasksV3.tasks.some(
                  task => task.isEntered,
                );
                const linkText = isMarketplaceSetupFlowInProgress ? 'Continue!' : 'Get started now!';

                return (
                  <Banner type="success" isClosable={false} {...containerProps}>
                    <TextBlock>
                      Great news: easily offer health benefits to your workers. Explore options, identify costs, and
                      choose the right options for your workers, in just a few simple steps.{' '}
                      <Link href="/app/company-setup/#/tasks_overview">{linkText}</Link>
                    </TextBlock>
                  </Banner>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}
