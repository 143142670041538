import React, { Component, RefObject } from 'react';
import gql from 'graphql-tag';
import { graphql, withApollo, ChildProps } from 'react-apollo';
import { flowRight } from 'lodash';

import { color, depth, fontSizes, fontStyles, radius, space, zIndex } from 'z-frontend-theme/utils';
import { styled } from 'z-frontend-theme';
import { Box, Flex, Icon, TextInline } from 'zbase';
import { LiveAgentChat } from 'z-frontend-chat';
import { generateUUID } from 'z-frontend-app-bootstrap';
import { openChatWindow } from 'z-frontend-support-components';
import { withGraphqlProgress, Switches, SwitchContext } from 'z-frontend-network';

import { CreateSupportCase, GetChatStatus } from '../gqlTypes';

interface Props {
  includeSecondaryChatButton?: boolean;
}

type AllProps = ChildProps<Props, GetChatStatus.Query, GetChatStatus.Variables> &
  ChildProps<Props, CreateSupportCase.Mutation, CreateSupportCase.Variables>;

interface State {
  caseCreated: boolean;
  bubble: boolean;
}
const bubbleDisplayDelay = 2000;
const bubbleHideDelay = 12000;

const StyledBox = styled(Box)`
  position: fixed;
  color: ${color('grayscale.black')};
  right: 70px;
  bottom: 10px;
  background-color: ${color('grayscale.white')};
  z-index: ${props => 1 + zIndex('fixed')(props)};
  border-radius: ${radius()};
  ${depth(2)};
  margin: ${space(2)};
  font-style: ${fontStyles('paragraphs.s')};
  font-size: ${fontSizes(1)};
`;

const coordinatorType = 'QuickStart';

class ImplementationChat extends Component<AllProps, State> {
  private childRef: RefObject<any>; // Use a more generic type instead of any

  constructor(props: AllProps) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      caseCreated: false,
      bubble: false,
    };
  }

  onChatClose = () => {
    this.setState({ caseCreated: false });
  };

  getOnChatClickCallback = (switches: Switches) => async () => {
    if (!this.businessHour()) {
      this.childRef.current.initializeChat();
      return;
    }
    if (this.state.caseCreated) return;
    this.setState({ caseCreated: true });
    const result = await this.props.mutate({
      variables: {
        caseData: {
          coordinatorType,
          description: 'QuickStart Implementation Chat',
          subject: 'QuickStart Implementation Chat',
          topicId: null,
          attachments: [],
          uniqueId: generateUUID(),
          requestedContactMethod: 'chat',
          suppliedPhone: null,
        },
      },
    });

    if (result && result.data && result.data.createSupportCase) {
      openChatWindow(result.data.createSupportCase.caseId, coordinatorType);
    } else {
      this.setState({ caseCreated: false });
      // also show error message indicating network error.
      window.alert('Chat request could not be sent. Please retry.');
    }
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('hashchange', this.chatBubble);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.chatBubble);
  }

  chatBubble = () => {
    // as per requirement, only showing the chat bubble when
    // transitioning to company basic info page
    if (this.businessHour() && window.location.hash === '#/tasks/company-basic-info') {
      setTimeout(this.showBubble, bubbleDisplayDelay);
      setTimeout(this.hideBubble, bubbleHideDelay);
    }
  };

  hideBubble = () => {
    this.setState({ bubble: false });
  };

  showBubble = () => {
    this.setState({ bubble: true });
  };

  businessHour = () => {
    const { businessHours } = this.props.data;

    return businessHours ? businessHours.isBusinessHours : false;
  };

  render() {
    const { dashboard, isMarketplaceSetupFlow } = this.props.data;

    if (dashboard.companyTypeIsTrial || isMarketplaceSetupFlow) {
      return null;
    }

    const isBusinessHours = this.businessHour();
    const username = dashboard && dashboard.user ? `${dashboard.user.first_name} ${dashboard.user.last_name}` : '';

    return (
      <SwitchContext.Consumer>
        {switches => (
          <Box>
            {this.state.bubble && (
              <StyledBox w={1 / 5}>
                <Flex justify="flex-end">
                  <Icon iconName="close" onClick={this.hideBubble} />
                </Flex>
                <Flex pl={3} pr={3} pb={3} w={1}>
                  <Box>
                    Welcome {username}! Need help onboarding with <TextInline textKey="brandName" />?
                  </Box>
                </Flex>
              </StyledBox>
            )}
            <LiveAgentChat
              isChatActive={isBusinessHours}
              onChatClick={this.getOnChatClickCallback(switches.switches)}
              onChatClose={this.onChatClose}
              ref={this.childRef}
              includeSecondaryChatButton={this.props.includeSecondaryChatButton}
            />
          </Box>
        )}
      </SwitchContext.Consumer>
    );
  }
}

const getChatStatus = gql`
  query getChatStatus {
    dashboard {
      id
      companyTypeIsTrial
      user {
        first_name
        last_name
        id
      }
    }
    businessHours {
      isBusinessHours
    }
    isMarketplaceSetupFlow
  }
`;

const createSupportCase = gql`
  mutation createSupportCase($caseData: JSON) {
    createSupportCase(caseData: $caseData) {
      caseId
      initialPosition
    }
  }
`;

export default flowRight([
  withApollo,
  graphql<Props, GetChatStatus.Query, GetChatStatus.Variables>(getChatStatus, {
    options: props => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql<Props, CreateSupportCase.Mutation, CreateSupportCase.Variables>(createSupportCase),
  withGraphqlProgress({ renderLoading: () => null }),
])(ImplementationChat);
