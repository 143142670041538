import { useEffect, useState } from 'react';

declare global {
  interface Window {
    Solvvy: any;
    SolvvyApi: {
      openModal: () => void;
      closeModal: () => void;
      on: (event: 'contact_support' | 'view_open_cases' | 'solvvy_closed', callback: () => void) => void;
      hideWidget: () => void;
    };
  }
}

let isSolvvyOpen = false;
export const toggleSolvvy = (e: React.MouseEvent<HTMLElement>) => {
  if (window.SolvvyApi) {
    e.preventDefault();
    if (isSolvvyOpen) {
      window.SolvvyApi.closeModal();
    } else {
      window.SolvvyApi.openModal();
    }
    isSolvvyOpen = !isSolvvyOpen;
  }
};

export enum SOLVVY_SUPPORT_FLOW {
  VIEW_OPEN_CASES = 'view_open_cases',
  CONTACT_SUPPORT = 'contact_support',
}

export const useSolvvy = () => {
  const [isSolvvyOpenPending, setIsSolvvyOpenPending] = useState(false);
  const [pendingSupportFlow, setPendingSupportFlow] = useState<SOLVVY_SUPPORT_FLOW | null>(null);

  useEffect(() => {
    const pollingTimeout = 1000 * 60; // one minute
    const pollingStart = new Date().valueOf();

    const interval = setInterval(() => {
      if (new Date().valueOf() - pollingStart > pollingTimeout || window.SolvvyApi) {
        clearInterval(interval);
      }
      if (window.SolvvyApi) {
        if (isSolvvyOpenPending) {
          window.SolvvyApi.openModal();
          setIsSolvvyOpenPending(false);
        }
        if (pendingSupportFlow) {
          triggerSolvvySupportFlow(pendingSupportFlow);
          setPendingSupportFlow(null);
        }
      }
    }, 200);
    return () => {
      // cleanup
      clearInterval(interval);
    };
  }, [isSolvvyOpenPending, pendingSupportFlow]);

  const triggerSolvvySupportFlow = (flowName: SOLVVY_SUPPORT_FLOW) => {
    if (!window.SolvvyApi) {
      setIsSolvvyOpenPending(true);
      setPendingSupportFlow(flowName);
    } else {
      window.SolvvyApi.openModal();

      const solvvyVersion = window.Solvvy.V5 ? 'v5' : 'v4';
      switch (flowName) {
        case SOLVVY_SUPPORT_FLOW.CONTACT_SUPPORT:
          triggerSupportFlow(null, { solvvyVersion });
          break;
        case SOLVVY_SUPPORT_FLOW.VIEW_OPEN_CASES:
          triggerSupportFlow('openCases', { solvvyVersion });
          break;
      }
    }
  };

  return { isSolvvyOpenPending, triggerSolvvySupportFlow };
};

function triggerSupportFlow(page?: string | null, options?: { solvvyVersion?: 'v4' | 'v5' }) {
  // We lookup height of the container so we can match it
  const solvvyContainer =
    options?.solvvyVersion === 'v5'
      ? document.getElementById('solvvy-v4-container')
      : document.getElementById('solvvy-v5-ui');

  const event = new CustomEvent('triggerSupportFlow', {
    detail: {
      page,
      solvvyVersion: options?.solvvyVersion,
      closeSolvvyWindow: window.SolvvyApi.closeModal,
      containerHeight: solvvyContainer && solvvyContainer.clientHeight,
    },
  });

  const cancelledEvent = window.document.dispatchEvent(event);
  if (!cancelledEvent) {
    // In the case where we didn't catch and act on this event, send the user to support center
    window.location.href = '/app/support-flow/#/home';
    window.SolvvyApi.closeModal();
  }
}
