import React, { FC } from 'react';

import { useSwitches } from 'z-frontend-network';

import FormSelectDeprecated, { FormSelectProps } from '../../select/FormSelectDeprecated';
import {
  ALL_COUNTRIES,
  ZENEFITS_SUPPORTED_COUNTRIES,
  ZENEFITS_SUPPORTED_COUNTRIES_INCLUDING_SANCTIONED,
} from '../countries';

type FormAddressCountryProps = FormSelectProps & {
  /**
   * Use set of countries supported for Zenefits work locations https://github.com/zenefits/z-address/blob/7239d15761ce6816735c418712ba0cbf427971a1/addon/constants/work-location.js#L69
   * @default false
   */
  useZenefitsSupportedCountries?: boolean;
};

const FormAddressCountry: FC<FormAddressCountryProps> = props => {
  const switches = useSwitches();
  const shouldAllowSanctionedCountries = switches?.allow_sanctioned_countries;

  const { useZenefitsSupportedCountries } = props;

  const options = shouldAllowSanctionedCountries
    ? ZENEFITS_SUPPORTED_COUNTRIES_INCLUDING_SANCTIONED
    : useZenefitsSupportedCountries
    ? ZENEFITS_SUPPORTED_COUNTRIES
    : ALL_COUNTRIES;

  // NOTE-DZH: not using FormSelect until its performance issues have been resolved (lots of countries)
  return <FormSelectDeprecated label="Country" options={options} openOnFocus clearable={false} {...props} />;
};

export default FormAddressCountry;
