import React, { useState, FunctionComponent, StatelessComponent } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Box, Flex, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { linkStyle, LinkButton } from 'z-frontend-elements';
import { Modal } from 'z-frontend-overlays';
import { Avatar } from 'z-frontend-composites';
import { sanitizeHtml } from 'z-frontend-app-bootstrap';

import SubActionContainer from './subactions/SubActionContainer';
import { AllEmployee, InboxAction, InboxActionStatus, InboxSubAction } from '../../schema/schemaTypes';
import { SubActionType } from '../types';
import InboxAttachments from './inbox-attachments/InboxAttachments';
import { CannotComplete } from '../gqlTypes';
import CannotCompleteForm, { CannotCompleteFormValues } from './cannot-complete-form/CannotCompleteForm';

// TODO: de-dupe
export const AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';

const inboxActionSubActionTypes = [
  'addressSubActions',
  'bankSubActions',
  'confirmationSubActions',
  'contentReviewSubActions',
  'signatureSubActions',
  'singleValueSubActions',
];

function createMarkup(content: string) {
  return { __html: content };
}

export type InboxActionModalContentProps = {
  inboxAction: InboxAction;
  currentEmployeeId: string;
  companyId?: string;
};

function fullName(employee: AllEmployee) {
  if (!employee) {
    return '';
  }
  return `${employee.preferredOrFirstName} ${employee.last_name}`;
}

const CompletedFooter: StatelessComponent<InboxActionModalContentProps> = ({ inboxAction, currentEmployeeId }) => {
  if (!inboxAction.completionDate) {
    return <TextBlock>This task has been completed</TextBlock>;
  }

  const completedById = get(inboxAction, 'completedBy.id');
  const completedBySelf = !!completedById && completedById === currentEmployeeId;

  let markedAsCopy =
    inboxAction.status === InboxActionStatus.cannot_complete ? 'Marked as "Cannot Complete"' : 'Completed';
  if (inboxAction.completedBy) {
    markedAsCopy = `${markedAsCopy} by`;
  }

  const date = inboxAction.completionDate;
  const formattedDate = date && moment(date).format(AMERICAN_DATE_FORMAT);
  const name = completedBySelf ? 'You' : fullName(inboxAction.completedBy);
  const markedByCopy = name ? `${name} on ${formattedDate}` : `on ${formattedDate}`;

  return (
    <Flex justify="flex-end">
      <Box textAlign="right">
        <TextBlock color="text.light">{markedAsCopy}</TextBlock>
        <TextBlock>{markedByCopy}</TextBlock>
      </Box>
      {inboxAction.completedBy && (
        <Avatar
          firstName={inboxAction.completedBy.preferredOrFirstName}
          lastName={inboxAction.completedBy.last_name}
          photoUrl={inboxAction.completedBy.photoUrl}
          ml={3}
        />
      )}
    </Flex>
  );
};

/**
 * There is HTML from backend that contains <a> tag and we display it directly.
 * This is to give the <a> tags the same style as our Link component.
 * TextInlineWithLink does similar job in another level.
 */
const BoxWithLinkStyle = styled(Box)`
  a {
    ${linkStyle}
  }
`;

const InboxActionModalContent: FunctionComponent<InboxActionModalContentProps> = props => {
  const { inboxAction, currentEmployeeId, ...rest } = props;
  const [showCannotCompleteForm, setShowCannotCompleteForm] = useState(false);
  const [callCannotCompleteMutation] = useMutation<CannotComplete.Mutation, CannotComplete.Variables>(
    cannotCompleteMutation,
  );

  const toggleCannotCompleteForm = () => {
    setShowCannotCompleteForm(!showCannotCompleteForm);
  };

  const onSubmitCannotComplete = async ({ reason }: CannotCompleteFormValues) => {
    await callCannotCompleteMutation({
      variables: {
        reason,
        actionId: inboxAction.id,
        employeeId: currentEmployeeId,
        status: InboxActionStatus.cannot_complete,
      },
    });

    // getEventLogger().log('inbox-action-submit-cannot-complete-action-feedback', {
    //   reason,
    //   inboxActionId: inboxAction.id,
    // });

    toggleCannotCompleteForm();
  };

  const isOnlyOneSubAction = checkIsOnlyOneSubAction(inboxAction);
  const isCompleted = inboxAction.status === InboxActionStatus.completed;
  const isCannotComplete = inboxAction.status === InboxActionStatus.cannot_complete;
  const { showAttachmentsOutOfSubAction, showAttachmentsInSubAction } = getAttachmentPosition(
    isCompleted,
    isOnlyOneSubAction,
  );
  // This flag only determines whether to show the button. It might be showed in this component, or in sub action
  // component if there is only one sub action.
  const showCannotCompleteButton =
    !showCannotCompleteForm && !isCompleted && !isCannotComplete && !inboxAction.hideCannotComplete;
  const isCompletedOrCannotComplete = isCompleted || isCannotComplete;
  const showCompletedFooter = isCompletedOrCannotComplete;

  return (
    <>
      <Modal.Body p={0} fontStyle="paragraphs.m">
        <BoxWithLinkStyle mx={4} my={3}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              sanitizeHtml(sanitizeHtml(inboxAction.descriptionWithNamesAndVariables)),
            )}
          />
        </BoxWithLinkStyle>
        {inboxAction.inboxSubActions.map(subAction => (
          <Modal.BodySection key={subAction.id} p={0}>
            <SubActionContainer
              {...rest}
              currentEmployeeId={currentEmployeeId}
              subAction={subAction}
              isOnlyOneSubAction={isOnlyOneSubAction}
              attachments={showAttachmentsInSubAction && inboxAction.attachments}
              showCannotCompleteButton={isOnlyOneSubAction && showCannotCompleteButton}
              onClickCannotComplete={toggleCannotCompleteForm}
              hideOnlyOneSubActionCTA={showCannotCompleteForm}
              isActionCompletedOrCannotComplete={isCompletedOrCannotComplete}
            />
          </Modal.BodySection>
        ))}
        {showAttachmentsOutOfSubAction && <InboxAttachments attachments={inboxAction.attachments} />}
      </Modal.Body>

      {showCompletedFooter && (
        <Modal.Footer>
          <CompletedFooter {...props} />
        </Modal.Footer>
      )}

      {showCannotCompleteButton && !isOnlyOneSubAction && (
        <Modal.Footer>
          <LinkButton s="medium" onClick={toggleCannotCompleteForm}>
            Cannot complete this request
          </LinkButton>
        </Modal.Footer>
      )}

      {showCannotCompleteForm && (
        <Modal.Footer>
          <CannotCompleteForm onSubmit={onSubmitCannotComplete} onCancel={toggleCannotCompleteForm} />
        </Modal.Footer>
      )}
    </>
  );
};

export const cannotCompleteMutation = gql`
  mutation CannotComplete($actionId: ID!, $employeeId: ID!, $status: InboxActionStatus, $reason: String) {
    completeInboxAction(actionId: $actionId, employeeId: $employeeId, status: $status, reason: $reason) {
      id
      completionDate
      completedBy {
        id
        preferredOrFirstName
        last_name
        photoUrl
      }
      status
    }
  }
`;

function checkIsOnlyOneSubAction(inboxAction: InboxAction) {
  if (inboxAction.inboxSubActions && inboxAction.inboxSubActions.length === 1) {
    const subAction: InboxSubAction = inboxAction.inboxSubActions[0];
    const typedSubActionCount = inboxActionSubActionTypes.reduce((count: number, subActionType: SubActionType) => {
      if (subAction[subActionType]) {
        return count + subAction[subActionType].length;
      } else {
        return count;
      }
    }, 0);

    return typedSubActionCount === 1;
  }

  return false;
}

function getAttachmentPosition(isCompleted: boolean, isOnlyOneSubAction: boolean) {
  let showAttachmentsOutOfSubAction = false;
  let showAttachmentsInSubAction = false;
  if (isCompleted) {
    showAttachmentsOutOfSubAction = true;
  } else {
    showAttachmentsOutOfSubAction = !isOnlyOneSubAction;
    showAttachmentsInSubAction = isOnlyOneSubAction;
  }

  return { showAttachmentsOutOfSubAction, showAttachmentsInSubAction };
}

export default InboxActionModalContent;
