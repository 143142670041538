import gql from 'graphql-tag';

export const redirectRequirementsQuery = gql`
  query RedirectRequirementsQuery {
    dashboard {
      id
      employee {
        id
        type
      }
      isMTAPartnerUser
      emailVerified
      switches
      permission
    }
    prerequisiteRedirect
  }
`;
