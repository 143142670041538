import React from 'react';

import { Banner } from 'z-frontend-composites';
import { useSwitches } from 'z-frontend-network';
import { TextBlock } from 'zbase';

import PromoBannerText from './PromoBannerText';

type TrialPromoBannerProps = { trialPromoCode: string };

const baseInfoText = 'off on our annual plans if you purchase now';
const freePayrollLinkText = 'Get Payroll FREE';
const freePayrollInfoText = 'for 12 months';

const promoCodeMap: { [key: string]: { switch: string; linkText: string; infoText: string } } = {
  STANDARD: { switch: 'self_serve_promo_standard', linkText: 'Get up to 15%', infoText: baseInfoText },
  FREE_PAYROLL_2020_ANNUAL_BASE: {
    switch: 'self_serve_free_payroll_promo_2020_annual_base',
    linkText: freePayrollLinkText,
    infoText: freePayrollInfoText,
  },
  D1_FREE_PAYROLL: {
    switch: 'self_serve_d1_free_payroll',
    linkText: freePayrollLinkText,
    infoText: freePayrollInfoText,
  },
  TWENTY5_OFF_2022: {
    switch: 'self_serve_twenty5_off_2022',
    linkText: 'Get an extra 25% off',
    infoText: 'our lowest rates on any annual package',
  },
};

function TrialPromoBanner(props: TrialPromoBannerProps) {
  const switches = useSwitches();
  const promoInfo = promoCodeMap[props.trialPromoCode];
  const shouldShowPromo = promoInfo && switches && switches[promoInfo.switch];
  if (!shouldShowPromo) {
    return null;
  }
  return (
    <Banner type="success" isClosable={false} mx="auto" mb={4} centerContent>
      <TextBlock fontStyle="paragraphs.l">
        <PromoBannerText linkText={promoInfo.linkText} infoText={promoInfo.infoText} />
      </TextBlock>
    </Banner>
  );
}

export default TrialPromoBanner;
