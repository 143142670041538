import { ParsedZenefitsUrl } from '../client-nav-content/utils';

export type OmniSearchSource = 'employees' | 'help' | 'actions';

export type OmniSearchSources = OmniSearchSource[];

export type EmployeeItem = {
  id?: string;
  preferred_name?: string;
  name?: string;
};

export type TitleAndLink = {
  title?: string;
  link?: string;
};

export type ActionItem = {
  id?: string;
  zAppId?: string;
  title?: string;
  link?: ParsedZenefitsUrl;
};

export type HelpItem = TitleAndLink;

export type OmniSearchQueryResult = {
  dashboard: {
    employee: {
      allStatus: string;
    };
  };
  omniSearchSuggestion: {
    employees: { items: EmployeeItem[] };
    actions: { items: ActionItem[] };
    help: { items: HelpItem[] };
  };
};

export type OmniSearchSelectOptionType = 'employee' | 'action' | 'help' | 'helpCenter';

export type OmniSearchSelectOption = {
  type: OmniSearchSelectOptionType;
  displayName?: string;
  link?: string;
  searchString?: string;
};

export type OmniSearchSelectOptions = OmniSearchSelectOption[];

// TODO: could probably import type in EmberRouteLink.tsx
export type QueryParams = {
  /**
   * the name of the ember route or a url that starts with a '/'
   */
  to: string;
  routeParams?: (string | number)[];
  /**
   * use JSON.stringify to convert object to string
   */
  queryParams?: string;
};

export type UserInfoBusinessCase = {
  firstName: string;
  lastName: string;
  userEmail: string;
  companyName: string;
};

export enum BannerTypes {
  none = '',
  freePayroll = 'freePayroll',
  benefitsShopping = 'benefitsShopping',
}

/**
 * Converted from class ZAppInstallStatus in
 * yourPeople3/hub/services/zenefits_apps/models.py
 */
export enum ZAppInstallStatus {
  deleted = 0,
  disabled = 10,
  initializing = 25,
  notEnrolled = 50,
  enrolling = 100,
  ok = 200,
}

export enum ZAppUniqueIds {
  medicalInsuranceAdmin = '1.com.zenefits.MedicalInsuranceAdmin',
  medicalInsuranceEmployee = '1.com.zenefits.MedicalInsuranceEmployee',
  dentalInsuranceAdmin = '1.com.zenefits.DentalInsuranceAdmin',
  dentalInsuranceEmployee = '1.com.zenefits.DentalInsuranceEmployee',
  visionInsuranceAdmin = '1.com.zenefits.VisionInsuranceAdmin',
  visionInsuranceEmployee = '1.com.zenefits.VisionInsuranceEmployee',
  fsaAdmin = '1.com.zenefits.FsaAdmin',
  fsaEmployee = '1.com.zenefits.FsaEmployee',
  hsaAdmin = '1.com.zenefits.HsaAdmin',
  hsaEmployee = '1.com.zenefits.HsaEmployee',
  commuterBenefitsAdmin = '1.com.zenefits.UnicardCommuterAdmin',
  commuterBenefitsEmployee = '1.com.zenefits.UnicardCommuterEmployee',
  _401kAdmin = '1.com.zenefits.F01kAdmin',
  _401kEmployee = '1.com.zenefits.F01kEmployee',
  clairDev = '288.com.clair.dev',
  clairProd = '288.com.clair',
}
