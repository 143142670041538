import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { get } from 'lodash';

import { ConfirmationModal, DialogsManager } from 'z-frontend-layout';
import { Query } from 'z-frontend-network';
import { getEventLogger, BRAND_NAME } from 'z-frontend-app-bootstrap';

import { AccountUpdateModalQuery } from '../../gqlTypes';
import AccountUpdateModalContent from './AccountUpdateModalContent';
import { DashboardRole } from '../../types';

const sessionStorageKey = 'lite_customers_to_paid_banner_confirmed';

const accountUpdateModalQuery = gql`
  query AccountUpdateModalQuery {
    dashboard {
      id
      isTrialCompany
      permission
      switches
      employee {
        id
        first_name
      }
    }
    activeBillingDetails {
      v2_plan_details {
        name
      }
    }
  }
`;

type AccountUpdateModalProps = {
  role: DashboardRole;
};

class AccountUpdateModal extends React.Component<AccountUpdateModalProps, {}> {
  render() {
    const { role } = this.props;
    if (role !== DashboardRole.admin) {
      return null;
    }

    return (
      <Query<AccountUpdateModalQuery.Query>
        query={accountUpdateModalQuery}
        isBackgroundQuery
        handleLoading={false}
        handleError={false}
      >
        {({ data, error, loading }) => {
          if (error) {
            console.error('GraphQL error in AccountUpdateModal:', error);
            return null;
          }

          if (loading) return null;

          const { showModal, ...modalContentProps } = getDataForAccountUpdateModal(data);

          return (
            <DialogsManager
              dialogsCount={2}
              openByDefault={[showModal, false]}
              render={([accountUpdateModalDialog, thankYouModalDialog]) => {
                const accountUpdateModalProps = {
                  title: 'Important Account Update',
                  content: <AccountUpdateModalContent {...modalContentProps} />,
                  submitActionText: 'Discuss Upgrade',
                  omitCancelButton: true,
                };
                const thankYouModalProps = {
                  title: 'Thank You',
                  content: `Thank you for being a ${BRAND_NAME} customer. We’ll contact you shortly.`,
                  submitActionText: 'OK',
                  omitCancelButton: true,
                };

                return (
                  <>
                    <ConfirmationModal
                      {...accountUpdateModalProps}
                      onCancel={accountUpdateModalDialog.close}
                      onSubmit={() => {
                        window.sessionStorage.setItem(sessionStorageKey, 'true');
                        // We use this event to record the receipt of the information
                        getEventLogger().log('lite_customers_to_paid_acted_on');
                        accountUpdateModalDialog.close();
                        thankYouModalDialog.open();
                      }}
                      isVisible={accountUpdateModalDialog.isVisible}
                      controlEl={accountUpdateModalDialog.controlEl}
                    />
                    <ConfirmationModal
                      {...thankYouModalProps}
                      onCancel={thankYouModalDialog.close}
                      onSubmit={thankYouModalDialog.close}
                      isVisible={thankYouModalDialog.isVisible}
                      controlEl={thankYouModalDialog.controlEl}
                    />
                  </>
                );
              }}
            />
          );
        }}
      </Query>
    );
  }
}

export default AccountUpdateModal;

function getShowModal(data: AccountUpdateModalQuery.Query) {
  const { dashboard, activeBillingDetails } = data;
  const isTrialCompany = dashboard && dashboard.isTrialCompany;
  const isAdmin = dashboard && get(dashboard, 'permission.isAdmin');
  const v2_plan_details = activeBillingDetails && activeBillingDetails.v2_plan_details;
  const isLiteCustomer = !v2_plan_details || v2_plan_details.name === 'STATER_TIER';
  const isLiteCustomerSwitchOn = dashboard && get(dashboard, 'switches.lite_customers_to_paid_banner');
  const confirmedLiteToPaidCustomerBanner = window.sessionStorage.getItem(sessionStorageKey);
  const showModal =
    !isTrialCompany && isAdmin && isLiteCustomer && isLiteCustomerSwitchOn && !confirmedLiteToPaidCustomerBanner;
  return showModal;
}

function getDataForAccountUpdateModal(data: AccountUpdateModalQuery.Query) {
  const { dashboard } = data;
  const adminFirstName = dashboard ? get(dashboard, 'employee.first_name') : 'Admin';
  const deadline = moment([2020, 0, 31]);
  const lastDayOfCampaign = deadline.format('MMMM Do, YYYY');
  const cutAccessDeadline = deadline
    .clone()
    .add(1, 'days')
    .format('MMMM Do, YYYY');
  const deadlineMonth = deadline.format('MMMM');
  const today = moment();
  const daysLeftBeforeDeadline = Math.ceil(deadline.diff(today, 'days', true)) + 1;
  const lastDayOfMonth = today.endOf('month').format('MMMM Do, YYYY');
  const showModal = getShowModal(data);
  return {
    adminFirstName,
    deadlineMonth,
    daysLeftBeforeDeadline,
    cutAccessDeadline,
    lastDayOfCampaign,
    lastDayOfMonth,
    showModal,
  };
}
