import React from 'react';

import { Box, Flex, Icon, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { Button, Link } from 'z-frontend-elements';
import { Avatar } from 'z-frontend-composites';
import { useSwitches } from 'z-frontend-network';

import { CardSize } from './OnboardingImplementationHelpCard';
import ImplementationChat from './ImplementationChat';

export const QuickstartDefaultValues = {
  defaultName: 'Contact Your Implementation Team',
  defaultTitle: 'Onboarding Specialists',
  defaultEmail: 'implementation-support@zenefits.com',
  defaultLink: 'https://zenefits.orelara.com/calendar/team/t/82',
};

const implementationManagersImages = {
  manager1: require('./quickstart-avatar-images/manager1.png'),
  manager2: require('./quickstart-avatar-images/manager2.png'),
  manager3: require('./quickstart-avatar-images/manager3.png'),
  manager4: require('./quickstart-avatar-images/manager4.png'),
};

const StyledAvatar = styled(Avatar)`
  box-shadow: none;
`;

const StyledLink = styled(Link)`
  overflow-wrap: anywhere;
`;

type QuickStartOnboardingHelpProps = {
  size: CardSize;
  isBusinessHours: boolean;
};

export const QuickStartOnboardingHelp: React.FunctionComponent<QuickStartOnboardingHelpProps> = props => {
  const switches = useSwitches();
  const showChatOption = props.isBusinessHours && !switches.disable_implementation_chat;
  const smallSize = props.size === 'small';
  return (
    <Box textAlign={['center', null, 'left']}>
      <Flex
        direction={smallSize ? ['column', 'column', 'row'] : ['column', 'column', 'column', 'row', 'row']}
        justify={['center', 'center', 'left']}
      >
        <Flex justify={['center', 'center', 'left']} direction={smallSize ? ['row', 'row', 'column'] : 'row'}>
          <StyledAvatar photoUrl={implementationManagersImages.manager4} s={smallSize ? 'small' : 'large'} />
          <StyledAvatar
            photoUrl={implementationManagersImages.manager3}
            ml={smallSize ? 0 : -5}
            mt={smallSize ? [0, 0, -3] : 0}
            s={smallSize ? 'small' : 'large'}
          />
          <StyledAvatar
            photoUrl={implementationManagersImages.manager2}
            ml={smallSize ? 0 : -5}
            mt={smallSize ? [0, 0, -3] : 0}
            s={smallSize ? 'small' : 'large'}
          />
          <StyledAvatar
            photoUrl={implementationManagersImages.manager1}
            ml={smallSize ? 0 : -5}
            mt={smallSize ? [0, 0, -3] : 0}
            s={smallSize ? 'small' : 'large'}
          />
        </Flex>
        <Box ml={smallSize ? [2, 2, 1, 2, 2] : [3, 3, 1, 3, 3]}>
          <TextBlock color="grayscale.b">{QuickstartDefaultValues.defaultName}</TextBlock>
          <TextBlock color="grayscale.d" fontStyle="paragraphs.s">
            {QuickstartDefaultValues.defaultTitle}
          </TextBlock>
        </Box>
      </Flex>
      <Flex mt={2} ml={1} mb={1} justify={['center', 'center', 'left']}>
        <Icon
          fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
          color="link.normal"
          pr={2}
          mt="3px"
          iconName="email"
        />
        <StyledLink
          fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
          href={`mailto:${QuickstartDefaultValues.defaultEmail}`}
          color="link.normal"
        >
          {QuickstartDefaultValues.defaultEmail}
        </StyledLink>
      </Flex>

      {props.isBusinessHours && (
        <Flex mt={2} ml={1} mb={2} justify={['center', 'center', 'left']}>
          <Icon
            fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
            color="link.normal"
            pr={2}
            mt="3px"
            iconName="phone"
          />
          <StyledLink
            fontStyle={smallSize ? 'paragraphs.s' : 'paragraphs.m'}
            href={QuickstartDefaultValues.defaultLink}
            color="link.normal"
            target="_blank"
          >
            Schedule a call
          </StyledLink>
        </Flex>
      )}

      {showChatOption ? (
        <ImplementationChat includeSecondaryChatButton />
      ) : (
        <Button.Link mt={2} s="small" href={QuickstartDefaultValues.defaultLink} target="_blank">
          Schedule a Call
        </Button.Link>
      )}
    </Box>
  );
};
