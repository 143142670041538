// links
const buyLink = 'https://www.trinet.com/checkout';

// Other
const maxEmployeeCountForSelfServe = 25;
type ShouldContactSalesOptions = { isSelfServeTrial: boolean };
export const isSalesContact = (registeredEmployeeCount: number, { isSelfServeTrial }: ShouldContactSalesOptions) =>
  !isSelfServeTrial && registeredEmployeeCount > maxEmployeeCountForSelfServe;

export const getBuyLink = () => {
  return buyLink;
};
