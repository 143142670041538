import React from 'react';

import { BannerTypes } from '../../types';
import BenefitsShoppingBanner, { isBenefitsShoppingBannerDismissedLocalStorageKey } from '../BenefitsShoppingBanner';

type TopNavBarBannerProps = {
  onBannerClose: Function;
  bannerType: BannerTypes;
};

const TopNavBarBanner: React.FunctionComponent<TopNavBarBannerProps> = ({ onBannerClose, bannerType }) => {
  switch (bannerType) {
    case BannerTypes.benefitsShopping:
      return (
        <BenefitsShoppingBanner
          onCloseButtonClick={() => {
            window.localStorage.setItem(isBenefitsShoppingBannerDismissedLocalStorageKey, '1');
            onBannerClose();
          }}
        />
      );
    default:
      return null;
  }
};

export default TopNavBarBanner;
