import React, { Component } from 'react';
import gql from 'graphql-tag';

import { Flex, Heading, TextBlock } from 'zbase';
import { Link } from 'z-frontend-elements';
import { Query } from 'z-frontend-network';
import { Avatar, Card } from 'z-frontend-composites';
import { Post } from 'z-frontend-social-components';

import { ListPostsQueryOnDashboard } from '../../gqlTypes';

export const POSTS_PAGE_SIZE = 1;

type Author = {
  id?: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  title?: string;
  status?: string;
};

const authorFragment = gql`
  fragment AuthorDetailsOnDashboard on AllEmployee {
    id
    preferredOrFirstName
    last_name
    photoUrl
    title
    status
  }
`;

export const listPostsQuery = gql`
  query ListPostsQueryOnDashboard($pageNumber: Int, $pageSize: Int) {
    dashboard {
      id
      employee {
        ...AuthorDetailsOnDashboard
      }
    }
    socialFeed(pageNumber: $pageNumber, pageSize: $pageSize) {
      permission {
        canCreatePost
      }
      posts {
        id
        description
        createdBy {
          ...AuthorDetailsOnDashboard
        }
        createdAt
        postType
        taggedEmployees {
          id
          preferredOrFirstName
          last_name
          email
        }
      }
    }
  }
  ${authorFragment}
`;

type FilterEmployee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

type Props = { hasInboxActions?: boolean };

const RedirectToPeopleHub = ({ author }: { author: Author }) => {
  return (
    <Card p={2} mt={3}>
      <Flex align="center">
        <Avatar
          firstName={author.firstName}
          lastName={author.lastName}
          photoUrl={author.photoUrl}
          s="large"
          ml={2}
          mr={3}
        />
        <TextBlock m={2} mt={2} fontStyle="paragraphs.m" color="grayscale.d">
          You’re all caught up on tasks and announcements.
          <Link href="/app/hr-communication-hub/#/overview"> Post an announcement...</Link>
        </TextBlock>
      </Flex>
    </Card>
  );
};

const FeedPost = ({
  lastPost,
  createdBy,
  hasInboxActions,
}: {
  lastPost: ListPostsQueryOnDashboard.Posts;
  createdBy: Author;
  hasInboxActions: boolean;
}): any => {
  const taggedEmployees = lastPost.taggedEmployees.map((ee: ListPostsQueryOnDashboard.TaggedEmployees) => {
    return {
      id: ee.id,
      firstName: ee.preferredOrFirstName,
      lastName: ee.last_name,
      email: ee.email,
    } as FilterEmployee;
  });
  return hasInboxActions ? (
    <>
      <Flex justify="space-between" mb={3} mt={3}>
        <Heading level={6} color="text.light" textAlign={['center', null, 'left']}>
          Feed
        </Heading>
      </Flex>
      <Card p={3}>
        <Post
          description={lastPost.description}
          createdAt={lastPost.createdAt}
          createdBy={createdBy}
          postType={lastPost.postType}
          taggedEmployees={taggedEmployees}
        />
      </Card>
    </>
  ) : (
    <>
      <Flex justify="space-between" mb={3} mt={4}>
        <Heading level={6} color="text.light" textAlign={['center', null, 'left']}>
          Feed
        </Heading>
        <Link href="/app/hr-communication-hub/#/overview" fontStyle="paragraphs.m">
          View All
        </Link>
      </Flex>
      <Card px={3} py={3}>
        <Post
          description={lastPost.description}
          createdAt={lastPost.createdAt}
          createdBy={createdBy}
          postType={lastPost.postType}
          taggedEmployees={taggedEmployees}
        />
      </Card>
    </>
  );
};

class SocialFeedForDashboard extends Component<Props> {
  render() {
    return (
      <Query
        query={listPostsQuery}
        handleLoading={false}
        notifyOnNetworkStatusChange
        variables={{
          pageNumber: 1,
          pageSize: POSTS_PAGE_SIZE,
        }}
      >
        {({ data }) => {
          let lastPost;
          let createdBy;
          const { hasInboxActions } = this.props;
          const queryData = data as ListPostsQueryOnDashboard.Query;
          const employee = queryData?.dashboard?.employee;
          if (queryData && !queryData.socialFeed) {
            return null;
          }
          const { socialFeed } = queryData;
          const socialFeedViewPermission = socialFeed && socialFeed.permission.canCreatePost;

          if (!socialFeedViewPermission) {
            return null;
          }

          if (socialFeed.posts && socialFeed.posts.length === 0 && !hasInboxActions && employee) {
            const author = {
              firstName: employee.preferredOrFirstName,
              lastName: employee.last_name,
              photoUrl: employee.photoUrl,
            };
            return <RedirectToPeopleHub author={author} />;
          }

          if (socialFeed.posts && socialFeed.posts.length > 0) {
            lastPost = socialFeed.posts[0];
            createdBy = {
              firstName: lastPost.createdBy.preferredOrFirstName,
              lastName: lastPost.createdBy.last_name,
              photoUrl: lastPost.createdBy.photoUrl,
              id: lastPost.createdBy.id,
              title: lastPost.createdBy.title,
              status: lastPost.createdBy.status,
            };
          }

          return lastPost ? (
            <FeedPost lastPost={lastPost} createdBy={createdBy} hasInboxActions={hasInboxActions} />
          ) : null;
        }}
      </Query>
    );
  }
}

export default SocialFeedForDashboard;
