import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useMutation } from 'react-apollo';

import { Box } from 'zbase';
import { Form, FormSignature, FormTextInput, SignatureValue } from 'z-frontend-forms';
import { Modal } from 'z-frontend-overlays';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';

import TextInlineWithLink from './TextInlineWithLink';
import SubActionFormFooter from './SubActionFormFooter';
import { SubAction } from './SubAction';
import { SignatureSubAction as SignatureSubActionType, SignatureSubActionInput } from '../../../schema/schemaTypes';
import { CompleteSignatureSubActionMutation } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import { getCtaButtonPosition } from './utils';
import InboxAttachments from '../inbox-attachments/InboxAttachments';

type SignatureSubActionProps = SignatureSubActionType & SharedSubActionProps;

// TODO: de-dupe
export const AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';

type SignatureSubActionForm = {
  name?: string;
  signature?: SignatureValue;
};

const SignatureSubAction: FunctionComponent<SignatureSubActionProps> = ({
  attachments,
  isCompleted: isSubActionCompleted,
  descriptionWithNamesAndVariables,
  ctaText,
  currentEmployeeId,
  id,
  name,
  signature,
  completionDate,
  isOnlyOneSubAction,
  showCannotCompleteButton,
  onClickCannotComplete,
  hideOnlyOneSubActionCTA,
  isActionCompletedOrCannotComplete,
}) => {
  const [completeSubAction] = useMutation<
    CompleteSignatureSubActionMutation.Mutation,
    CompleteSignatureSubActionMutation.Variables
  >(completeSignatureSubActionMutation);

  const {
    showWithoutModalFooter: showFormFooterWithoutModalFooter,
    showWithModalFooter: showFormFooterWithModalFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  return (
    <Form<SignatureSubActionForm>
      onSubmit={async values => {
        const { signature, name } = values;
        const signatureInput: SignatureSubActionInput = {
          name,
          signature: signature && signature.dataUrl,
        };
        await completeSubAction({
          variables: {
            id,
            signature: signatureInput,
            employeeId: currentEmployeeId,
          },
        });
        getEventLogger().log('inbox-action-save-sub-action', {
          // don't log fields like title which could contain sensitive info
          subActionId: id,
          subActionType: 'signature',
        });
      }}
      initialValues={{
        name,
        signature: {
          date: completionDate && moment(completionDate, AMERICAN_DATE_FORMAT).toDate(),
          dataUrl: signature || '',
        },
      }}
      validationSchema={{
        name: Form.Yup.string().required('Please enter your name'),
        signature: FormSignature.getValidationSchema('Signature'),
      }}
    >
      <SubAction isCompleted={isSubActionCompleted}>
        <Box w={1}>
          <Box mb={3} mt={1}>
            <TextInlineWithLink dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }} />
          </Box>

          <FormSignature name="signature" disabled={isSubActionCompleted} label="Signature" />
          <FormTextInput name="name" disabled={isSubActionCompleted} label="Full Name" />
          {showFormFooterWithoutModalFooter && <SubActionFormFooter ctaText={ctaText} />}
        </Box>
      </SubAction>

      <InboxAttachments attachments={attachments} />

      {showFormFooterWithModalFooter && (
        <Modal.Footer borderTop>
          <SubActionFormFooter
            ctaText={ctaText}
            showCannotCompleteButton={showCannotCompleteButton}
            onClickCannotComplete={onClickCannotComplete}
          />
        </Modal.Footer>
      )}
    </Form>
  );
};

const completeSignatureSubActionMutation = gql`
  mutation CompleteSignatureSubActionMutation($id: ID!, $employeeId: ID!, $signature: SignatureSubActionInput!) {
    completeSignatureSubAction(id: $id, employeeId: $employeeId, signature: $signature) {
      id
      isCompleted
      completionDate
      completedBy_id
    }
  }
`;

export default SignatureSubAction;
