import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { Banner } from 'z-frontend-composites';
import { LinkButton } from 'z-frontend-elements';

import { Mutation, Query } from 'z-frontend-network';
import { BRAND_NAME } from 'z-frontend-app-bootstrap';

import { PaymentMethodAuthRequiredQuery } from '../gqlTypes';

const PAYMENT_BANNER_MESSAGES = {
  cc: `Please remember to authorize "${BRAND_NAME} / Pay Your People" with your credit card company to avoid payment failure.`,
  ach: `Please authorize ${BRAND_NAME} (ACH Company Name: YOURPEOPLE INC // ACH ID 2460645293) to debit your account.`,
};

const deactivatePaymentBanner = gql`
  mutation deactivatePaymentBanner {
    deactivatePaymentBanner {
      authorizationRequired
    }
  }
`;

const paymentMethodAuthRequiredQuery = gql`
  query PaymentMethodAuthRequiredQuery {
    paymentAuthorizationDetails {
      authorizationRequired
    }
  }
`;

const PaymentBanner: FunctionComponent = () => {
  return (
    <Query<PaymentMethodAuthRequiredQuery.Query, PaymentMethodAuthRequiredQuery.Variables>
      query={paymentMethodAuthRequiredQuery}
      renderError={() => <></>}
      handleLoading={false}
    >
      {({ data }) => {
        if (!(data && data.paymentAuthorizationDetails && data.paymentAuthorizationDetails.authorizationRequired)) {
          return null;
        }

        const { authorizationRequired: paymentBanner } = data.paymentAuthorizationDetails;

        return (
          <Mutation mutation={deactivatePaymentBanner}>
            {doDeactivatePaymentBanner => (
              <Banner showIcon={false} mb={4} type="info">
                {PAYMENT_BANNER_MESSAGES[paymentBanner]}
                <LinkButton
                  ml={1}
                  onClick={() =>
                    doDeactivatePaymentBanner({
                      variables: {},
                      refetchQueries: [
                        {
                          query: paymentMethodAuthRequiredQuery,
                        },
                      ],
                    })
                  }
                >
                  Got&nbsp;it
                </LinkButton>
              </Banner>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default PaymentBanner;
