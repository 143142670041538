import React, { useState } from 'react';

import { Button, Link } from 'z-frontend-elements';
import { Checkbox } from 'z-frontend-forms';
import { DialogProps, Modal } from 'z-frontend-overlays';
import { Box, Flex, TextBlock } from 'zbase';

import { TRINET_CONNECTORS_TERMS_URL } from './constants';

interface TermsOfUseConfirmationModalProps {
  onConfirm: Function;
  dialog: DialogProps;
  onCancel: Function;
}

const TermsOfUseConfirmationModal: React.FunctionComponent<TermsOfUseConfirmationModalProps> = props => {
  const [isCheckedTermsOfUse, setIsCheckedTermsOfUse] = useState(false);

  const modalProps = {
    title: 'Terms and Conditions',
    onSubmit: () => {
      props.onConfirm();
      props.dialog.close();
    },
    onCancel: () => {
      props.onCancel();
      props.dialog.close();
    },
    isVisible: props.dialog.isVisible,
  };

  return (
    <Box>
      <Modal {...modalProps}>
        <Modal.Body>
          <Box flex="1">
            <TextBlock>
              You can add additional apps to your account through our Integration center. Please acknowledge that you
              have read and agree to the terms & conditions of TriNet Integration Center before proceeding.
            </TextBlock>
          </Box>
          <Flex flex="1 0" mt={5}>
            <Checkbox
              checked={isCheckedTermsOfUse}
              label="I have read and agree to "
              onChange={() => {
                if (isCheckedTermsOfUse) {
                  setIsCheckedTermsOfUse(false);
                } else {
                  setIsCheckedTermsOfUse(true);
                }
              }}
            />{' '}
            <TextBlock>
              <Link href={TRINET_CONNECTORS_TERMS_URL}> TriNet Integration Center terms and conditions.</Link>
            </TextBlock>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Flex justify="flex-end" flex="1 0">
            <Button mr={2} mode="normal" onClick={() => props.onCancel()}>
              Cancel
            </Button>
            <Button disabled={!isCheckedTermsOfUse} mode="primary" mr={2} onClick={() => props.onConfirm()}>
              Agree & Proceed
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default TermsOfUseConfirmationModal;
