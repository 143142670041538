import { useEffect, useState } from 'react';

import { useHasFeatures } from 'z-frontend-app-bootstrap/src/createIntlProvider';

import { CONTRACTOR_PAYMENTS } from './constants';

type QueryParams = { [key: string]: string };

export type ParsedZenefitsUrl =
  | string
  | {
      route: string;
      params: { queryParams: QueryParams } | null;
    };

export const parseZenefitsUrl = (url: string): ParsedZenefitsUrl => {
  const getQueryParams = (a: string[]) => {
    const b: QueryParams = {};
    for (let i = 0; i < a.length; i += 1) {
      const p = a[i].split('=');
      if (p.length === 2) {
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
      }
    }
    return b;
  };

  const matches = url.match(/^zenefits:\/\/client\/(.*)/);
  if (!matches) {
    return url;
  }

  const queryParams = matches[1].indexOf('?') !== -1 ? getQueryParams(matches[1].split('?')[1].split('&')) : {};

  return {
    route: matches[1].replace(/\//g, '.').split('?')[0],
    params: Object.keys(queryParams).length ? { queryParams } : null,
  };
};

export function useCheckContractorLiteCompany() {
  const includesContractorLiteFeature = useHasFeatures([CONTRACTOR_PAYMENTS], true);
  return includesContractorLiteFeature;
}

export function useBrandInfo() {
  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    const getBrandValues = async () => {
      // const skus = await getSubscriptionSkus();
      setBrandName('New Brand Name');
    };
    getBrandValues();
  }, []);

  return {
    brandName,
  };
}
