import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import { Box } from 'zbase';
import { Form, FormSimpleSelect, FormTextInput } from 'z-frontend-forms';
import { Modal } from 'z-frontend-overlays';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';

import TextInlineWithLink from './TextInlineWithLink';
import SubActionFormFooter from './SubActionFormFooter';
import { SubAction } from './SubAction';
import { BankSubAction as BankSubActionType, BankSubActionInput } from '../../../schema/schemaTypes';
import { CompleteBankSubAction } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import { getCtaButtonPosition } from './utils';
import InboxAttachments from '../inbox-attachments/InboxAttachments';

type AddressSubActionProps = BankSubActionType & SharedSubActionProps;

type BankAccountType = 'C' | 'S';

type BankTypeSelectionOption = { label: string; id: BankAccountType };

const bankTypeSelectOptions: BankTypeSelectionOption[] = [
  { label: 'Checking', id: 'C' },
  { label: 'Savings', id: 'S' },
];

type BankForm = {
  bankRoutingNumber?: string;
  bankAccountNumber?: string;
  bankAccountType?: BankTypeSelectionOption;
};

const BankSubAction: FunctionComponent<AddressSubActionProps> = props => {
  const {
    id,
    attachments,
    currentEmployeeId,
    isCompleted: isSubActionCompleted,
    ctaText,
    descriptionWithNamesAndVariables,
    bankRoutingNumber,
    bankAccountNumber,
    bankAccountType,
    isOnlyOneSubAction,
    showCannotCompleteButton,
    onClickCannotComplete,
    hideOnlyOneSubActionCTA,
    isActionCompletedOrCannotComplete,
  } = props;

  const [completeSubAction] = useMutation<CompleteBankSubAction.Mutation, CompleteBankSubAction.Variables>(
    completeBankSubActionMutation,
  );

  const {
    showWithoutModalFooter: showFormFooterWithoutModalFooter,
    showWithModalFooter: showFormFooterWithModalFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  return (
    <Form<BankForm>
      initialValues={{
        bankRoutingNumber,
        bankAccountNumber,
        bankAccountType: bankAccountType && bankTypeSelectOptions.find(option => option.id === bankAccountType),
      }}
      onSubmit={async values => {
        const { bankRoutingNumber, bankAccountNumber, bankAccountType } = values;
        const bankInput: BankSubActionInput = {
          bankRoutingNumber,
          bankAccountNumber,
          bankAccountType: bankAccountType && bankAccountType.id,
        };
        await completeSubAction({
          variables: {
            id,
            bank: bankInput,
            employeeId: currentEmployeeId,
          },
        });
        getEventLogger().log('inbox-action-save-sub-action', {
          // don't log fields like title which could contain sensitive info
          subActionId: id,
          subActionType: 'bank',
        });
      }}
      validationSchema={{
        bankRoutingNumber: Form.Yup.string().required('Please enter a bank routing number'),
        bankAccountNumber: Form.Yup.string().required('Please enter a bank account number'),
        bankAccountType: Form.Yup.string().required('Please enter a bank account type'),
      }}
    >
      <SubAction isCompleted={isSubActionCompleted}>
        <Box w={1}>
          <Box mb={3} mt={1}>
            <TextInlineWithLink dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }} />
          </Box>

          <FormTextInput disabled={isSubActionCompleted} label="Routing number" name="bankRoutingNumber" />
          <FormTextInput disabled={isSubActionCompleted} label="Account number" name="bankAccountNumber" />
          <FormSimpleSelect<BankTypeSelectionOption>
            name="bankAccountType"
            label="Account type"
            getOptionText={o => o.label}
            disabled={isSubActionCompleted}
          >
            {({ SelectOption }) =>
              bankTypeSelectOptions.map(option => <SelectOption key={option.id} option={option} />)
            }
          </FormSimpleSelect>
          {showFormFooterWithoutModalFooter && <SubActionFormFooter ctaText={ctaText} />}
        </Box>
      </SubAction>

      <InboxAttachments attachments={attachments} />

      {showFormFooterWithModalFooter && (
        <Modal.Footer borderTop>
          <SubActionFormFooter
            ctaText={ctaText}
            showCannotCompleteButton={showCannotCompleteButton}
            onClickCannotComplete={onClickCannotComplete}
          />
        </Modal.Footer>
      )}
    </Form>
  );
};

const completeBankSubActionMutation = gql`
  mutation CompleteBankSubAction($id: ID!, $employeeId: ID!, $bank: BankSubActionInput!) {
    completeBankSubAction(id: $id, employeeId: $employeeId, bank: $bank) {
      id
      isCompleted
      completionDate
      completedBy_id
      descriptionWithNamesAndVariables
      ctaText
    }
  }
`;

export default BankSubAction;
