import React, { StatelessComponent } from 'react';

import { Flex, Icon } from 'zbase';

export const SubAction: StatelessComponent<{ isCompleted: boolean }> = props => {
  return (
    <Flex align="flex-start" mx={4} my={3}>
      <Flex align="center" justify="center">
        <Icon
          minWidth={20}
          iconName={props.isCompleted ? 'check-circle' : 'chevron-right'}
          aria-label={props.isCompleted && 'Completed'}
          s="large"
          color={props.isCompleted ? 'affirmation.a' : null}
          mr={3}
        />
      </Flex>
      {props.children}
    </Flex>
  );
};
