import React, { useEffect, useRef, useState } from 'react';

import { styled } from 'z-frontend-theme';
import { TextBlock } from 'zbase';
import { color } from 'z-frontend-theme/utils';

import { PromoDetails } from './promo-utils';

const Ribbon = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  width: 68%;
  height: 68%;
  overflow: hidden;
  & .label {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    transform: rotate(45deg);
  }
  path {
    fill: ${color('affirmation.c')};
  }
`;

type AppTilePromoBadgeProps = {
  promoDetails: PromoDetails;
};

const AppTilePromoBadge: React.FunctionComponent<AppTilePromoBadgeProps> = ({
  promoDetails,
}: AppTilePromoBadgeProps) => {
  const ribbonRef = useRef(null);
  const [showFullMessage, setShowFullMessage] = useState(false);
  // TODO: handle resize effectively, maybe also improve HideFor/RenderFor
  useEffect(() => {
    setShowFullMessage(ribbonRef.current?.offsetWidth > 75);
  }, [ribbonRef]);
  return !promoDetails.shortMessage ? null : (
    <div>
      {/* check if fullMessageLine2 is empty, only show textBlock for fullMessageLine1 */}
      {!promoDetails.fullMessageLine2 ? (
        <Ribbon ref={ribbonRef}>
          <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88 60L28 0L88 0Z" />
          </svg>
          <div className="label">
            <TextBlock textTransform="uppercase" textAlign="center" fontStyle="paragraphs.m" color="affirmation.a">
              {showFullMessage ? promoDetails.fullMessageLine1 : promoDetails.shortMessage}
            </TextBlock>
          </div>
        </Ribbon>
      ) : (
        <Ribbon ref={ribbonRef}>
          <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88 88L0 0H88V88Z" />
          </svg>
          <div className="label">
            <TextBlock textTransform="uppercase" textAlign="center" fontStyle="paragraphs.m" color="affirmation.a">
              {showFullMessage ? promoDetails.fullMessageLine1 : promoDetails.shortMessage}
            </TextBlock>
            {showFullMessage && (
              <TextBlock textAlign="center" ellipsis fontStyle="paragraphs.m" color="affirmation.a">
                {promoDetails.fullMessageLine2}
              </TextBlock>
            )}
          </div>
        </Ribbon>
      )}
    </div>
  );
};

export default AppTilePromoBadge;
