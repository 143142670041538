import React, { Component } from 'react';

import { Query } from 'z-frontend-network';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { TextBlock } from 'zbase';

import { AppsQuery } from '../../gqlTypes';
import { DashboardRole } from '../../types';
import AppList from './AppList';
import { appsQuery } from '../../initialQueries';
import SectionHeading from '../SectionHeading';

type AppListContainerProps = {
  role: DashboardRole; // Whether the user is viewing admin or employee app tiles
  isIOM: boolean;
};

export default class AppListContainer extends Component<AppListContainerProps> {
  render() {
    const { role, isIOM } = this.props;
    return (
      <ErrorBoundary
        FallbackComponent={() => (
          <>
            <SectionHeading>Apps</SectionHeading>
            <TextBlock mt={3}>Sorry, we were unable to load available apps.</TextBlock>
          </>
        )}
      >
        <Query<AppsQuery.Query, AppsQuery.Variables> query={appsQuery} handleLoading={false}>
          {({ data, loading }) => <AppList role={role} data={data} loading={loading} isIOM={isIOM} />}
        </Query>
      </ErrorBoundary>
    );
  }
}
