import React, { Component, Suspense } from 'react';
import { getIn } from 'formik';
// @ts-ignore
import { conformToMask } from 'react-text-mask';

import Field from '../Field';
import FormFieldWrapper, { FormFieldProps } from '../FormFieldWrapper';
import PhoneInput, { nationalPhoneMask, PhoneInputProps } from '../../phone-input/PhoneInput';
import { getAriaInputProps } from '../formAccessibility';

const InternationalPhoneInput = React.lazy(() => import('../../phone-input/InternationalPhoneInput'));

type FormPhoneInputProps = PhoneInputProps &
  FormFieldProps & {
    /**
     * Input accepts international phone numbers. Adds a country selector alongside with input
     * @default false
     */
    international?: boolean;
    /**
     * Use to toggle the component from edit to display but keep exact spacing, eg in an EditableTable.
     * @default false
     */
    displayOnly?: boolean;
  };

class FormPhoneInput extends Component<FormPhoneInputProps> {
  static toUSFormat = (value: string) => {
    return conformToMask(value, nationalPhoneMask, { guide: false }).conformedValue;
  };

  /**
   * Convert a masked phone number to digits only.
   * @param maskedPhone - masked phone string, e.g. value from FormPhoneInput.
   */
  static toDigits = (maskedPhone: string): string => {
    if (!maskedPhone) return '';

    return maskedPhone.split('').reduce((acc: string, char: string) => {
      if (/\d/.test(char)) {
        return `${acc}${char}`;
      }
      return acc;
    }, '');
  };

  render() {
    const {
      name,
      label,
      containerProps,
      optional,
      limitRerender,
      dependencies,
      'aria-label': ariaLabel,
      format,
      international,
      displayOnly,
      ...rest
    } = this.props;
    return (
      <Field name={name} limitRerender={limitRerender} dependencies={dependencies}>
        {({ field, form, setFieldValueAndTouched }) => {
          const error: any = getIn(form.touched, name) && getIn(form.errors, name);
          return (
            <FormFieldWrapper
              name={name}
              label={label}
              error={error}
              format={format}
              containerProps={containerProps}
              optional={optional}
              isDisplayMode={displayOnly}
            >
              {international ? (
                // Fallback to normal input as we lazy load full featured international input
                <Suspense
                  fallback={
                    <PhoneInput
                      id={name}
                      {...field}
                      {...rest}
                      hasError={Boolean(error)}
                      {...getAriaInputProps(name, error, ariaLabel)}
                      mb={0}
                      allowInternational
                    />
                  }
                >
                  <InternationalPhoneInput
                    id={name}
                    {...field}
                    {...rest}
                    hasError={Boolean(error)}
                    {...getAriaInputProps(name, error, ariaLabel)}
                    mb={0}
                    onChange={(value: string) => {
                      setFieldValueAndTouched(name, value);
                      this.props.onChange && this.props.onChange(value);
                    }}
                  />
                </Suspense>
              ) : (
                <PhoneInput
                  id={name}
                  {...field}
                  {...rest}
                  hasError={Boolean(error)}
                  {...getAriaInputProps(name, error, ariaLabel)}
                  mb={0}
                  onChange={(value: string) => {
                    form.setFieldValue(name, value);
                    this.props.onChange && this.props.onChange(value);
                  }}
                />
              )}
            </FormFieldWrapper>
          );
        }}
      </Field>
    );
  }
}

export default FormPhoneInput;
