import React, { Component } from 'react';
import { getIn, FieldProps } from 'formik';

import Field from '../Field';
import { Input, InputProps } from '../../../index';
import FormFieldWrapper, { FormFieldProps } from '../FormFieldWrapper';
import { getAriaInputProps } from '../formAccessibility';
import InputDisplay from '../../input/InputDisplay';

export type FormTextInputProps = InputProps &
  FormFieldProps & {
    /**
     * Use to toggle the component from edit to display but keep exact spacing, eg in an EditableTable.
     * @default false
     */
    displayOnly?: boolean;
    /**
     * To be passed to the input's type attribute like <input type="email"/>
     * @default undefined
     */
    inputType?: string;
  };

class FormTextInput extends Component<FormTextInputProps> {
  render() {
    const {
      name,
      label,
      containerProps,
      optional,
      format,
      limitRerender,
      dependencies,
      'aria-label': ariaLabel,
      helpText,
      displayOnly,
      inputType,
      additionalText,
      prefix,
      suffix,
      disableError,
      ...rest
    } = this.props;

    return (
      <Field name={name} limitRerender={limitRerender} dependencies={dependencies}>
        {({ field, form }: FieldProps) => {
          const error: any = getIn(form.touched, name) && getIn(form.errors, name);
          return (
            <FormFieldWrapper
              name={name}
              label={label}
              helpText={helpText}
              error={error}
              format={format}
              containerProps={containerProps}
              optional={optional}
              dependencies={dependencies}
              additionalText={additionalText}
              isDisplayMode={displayOnly}
              disableError={disableError}
            >
              {displayOnly ? (
                <InputDisplay prefix={prefix} suffix={suffix} value={field.value || rest.value} />
              ) : (
                <Input
                  id={name}
                  {...field}
                  {...rest}
                  type={inputType}
                  hasError={Boolean(error)}
                  {...getAriaInputProps(name, error, ariaLabel)}
                  mb={0}
                  prefix={prefix}
                  suffix={suffix}
                />
              )}
            </FormFieldWrapper>
          );
        }}
      </Field>
    );
  }
}

export default FormTextInput;
