import React, { Component } from 'react';

import { Box, Flex, TextBlock } from 'zbase';
import { Avatar } from 'z-frontend-composites';
import { Hide, Render } from 'z-frontend-theme';
import { Skeleton } from 'z-frontend-elements';

import AspectRatio from '../AspectRatio';

type CompanyLogoProps = {
  companyName: string;
  logoUrl: string;
  loading?: boolean;
};

const avatarProps = {
  s: 'xxxlarge' as 'xxxlarge',
  alt: '',
  isSquare: true,
  imageFit: 'contain' as 'contain',
  bg: 'grayscale.white' as 'grayscale.white',
  p: 2,
};

export default class CompanyLogo extends Component<CompanyLogoProps> {
  render() {
    const { loading, companyName, logoUrl } = this.props;

    return (
      <Flex direction={['row', null, 'column']} align="center" mb={[3, null, 0]} data-testid="CompanyLogo">
        <Hide forBreakpoints={[true, true]}>
          <Box w={1} mb={3}>
            <AspectRatio ratio={1}>
              {loading ? (
                <Skeleton width={1} height={1} mb={3} />
              ) : (
                <Avatar w={1} mb={3} photoUrl={logoUrl} {...avatarProps} />
              )}
            </AspectRatio>
          </Box>
        </Hide>
        <Render forBreakpoints={[true, true]}>
          {loading ? (
            <Skeleton width={75} height={75} mr={3} />
          ) : (
            <Avatar w={75} mr={3} photoUrl={logoUrl} {...avatarProps} />
          )}
        </Render>
        <TextBlock wordBreak="break-word" fontStyle={['headings.l', 'headings.l', 'headings.m']} textAlign="center">
          {loading ? <Skeleton width={120} /> : companyName}
        </TextBlock>
      </Flex>
    );
  }
}
