import { produce } from 'immer';
import moment from 'moment';
import React, { Component } from 'react';
import { MutationFunction } from 'react-apollo';

import { withErrorBoundary, BRAND_NAME } from 'z-frontend-app-bootstrap';
import { NotificationContextProps, NotificationManagerDeprecated } from 'z-frontend-elements';
import { Mutation } from 'z-frontend-network';
import { ConfirmationModal, DialogProps } from 'z-frontend-overlays';

import { InboxActionStatus } from '../../../schema/schemaTypes';
import { InboxActionPartialQuery } from '../../gqlTypes';
import { inboxActionPartialQuery } from '../InboxActionContainer';
import { cannotCompleteMutation } from '../InboxActionModalContent';

type DismissInboxActionModalProps = {
  dialog: DialogProps;
  inboxActionId: string;
  currentEmployeeId: string;
  refreshList?: () => Promise<any>;
  isForPartnerUser?: boolean;
};

type State = {
  isDismissInProgress: boolean;
};

class DismissInboxActionModal extends Component<DismissInboxActionModalProps, State> {
  constructor(props: DismissInboxActionModalProps) {
    super(props);
    this.state = {
      isDismissInProgress: false,
    };
  }

  handleSubmit = async (mutateFunc: MutationFunction, notificationProps: NotificationContextProps) => {
    this.setState({ isDismissInProgress: true });
    const result = await mutateFunc({
      variables: {
        reason: 'Task Archived',
        actionId: this.props.inboxActionId,
        employeeId: this.props.currentEmployeeId,
        status: InboxActionStatus.cannot_complete,
      },
      optimisticResponse: {
        completeInboxAction: {
          __typename: 'InboxAction',
          completionDate: moment().format('MMM DD h:mm a'),
          id: 0,
          completedBy: {
            __typename: 'AllEmployee',
            id: this.props.currentEmployeeId,
            preferredOrFirstName: 'test',
            last_name: 'test',
            photoUrl: 'test',
          },
        },
      },
    });
    if (result && result.data && result.data.completeInboxAction) {
      notificationProps.openNotification('The task has been archived!');
      if (this.props.refreshList) {
        this.props.refreshList();
      }
    } else {
      notificationProps.openNotification('Task could not be archived. Please try again.');
    }
    this.props.dialog.close();
  };

  render() {
    const { dialog, isForPartnerUser } = this.props;
    const modalContent = isForPartnerUser
      ? 'This will remove the item from your to-do list along with any other users assigned to it. It can still be accessed from the Inbox found on the main company dashboard.'
      : `We'll remove this from your to-do list. You can always access this in your ${BRAND_NAME} Inbox.`;
    const dismissModalProps = {
      isVisible: dialog.isVisible,
      title: 'Archive Task',
      content: modalContent,
      submitActionText: 'Archive',
      onCancel: dialog.close,
      controlEl: dialog.controlEl,
      isSubmitting: this.state.isDismissInProgress,
    };

    return (
      <Mutation
        mutation={cannotCompleteMutation}
        update={cache => {
          const { inboxActionPartials, dashboard } = cache.readQuery<InboxActionPartialQuery.Query>({
            query: inboxActionPartialQuery,
          });

          const newInboxPartials = produce(inboxActionPartials, (draft: any[]) => {
            return draft.filter(
              (partial: InboxActionPartialQuery.InboxActionPartials) => partial.id !== this.props.inboxActionId,
            );
          });
          this.setState({ isDismissInProgress: false });

          cache.writeQuery<InboxActionPartialQuery.Query>({
            query: inboxActionPartialQuery,
            data: { dashboard, inboxActionPartials: newInboxPartials },
          });
        }}
      >
        {onSubmit => (
          <NotificationManagerDeprecated>
            {notificationProps => (
              <ConfirmationModal
                onSubmit={() => {
                  this.handleSubmit(onSubmit, notificationProps);
                }}
                {...dismissModalProps}
              />
            )}
          </NotificationManagerDeprecated>
        )}
      </Mutation>
    );
  }
}

export default withErrorBoundary<DismissInboxActionModalProps>()(DismissInboxActionModal);
