import React, { Component } from 'react';

import { InboxSubAction } from '../../../schema/schemaTypes';
import AddressSubAction from './AddressSubAction';
import ConfirmationSubAction from './ConfirmationSubAction';
import ContentReviewSubAction from './ContentReviewSubAction';
import SingleValueSubAction from './SingleValueSubAction';
import SignatureSubAction from './SignatureSubAction';
import BankSubAction from './BankSubAction';
import { SharedSubActionProps } from '../../types';

type SubActionContainerProps = {
  subAction: InboxSubAction;
} & SharedSubActionProps;

class SubActionContainer extends Component<SubActionContainerProps> {
  render() {
    const { subAction, ...rest } = this.props;

    return (
      <>
        {subAction.description && <p>{subAction.description}</p>}
        {subAction.addressSubActions.map(action => (
          <AddressSubAction key={action.id} {...action} {...rest} />
        ))}
        {subAction.bankSubActions.map(action => (
          <BankSubAction key={action.id} {...action} {...rest} />
        ))}
        {subAction.signatureSubActions.map(action => (
          <SignatureSubAction key={action.id} {...action} {...rest} />
        ))}
        {subAction.confirmationSubActions.map(action => (
          <ConfirmationSubAction key={action.id} {...action} {...rest} />
        ))}
        {subAction.singleValueSubActions.map(action => (
          <SingleValueSubAction key={action.id} {...action} {...rest} />
        ))}
        {(subAction.contentReviewSubActions || []).map(action => (
          <ContentReviewSubAction key={action.id} {...action} {...rest} />
        ))}
      </>
    );
  }
}

export default SubActionContainer;
