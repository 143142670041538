import React from 'react';

import PeopleHubAnnouncementPost from './announcement-post/AnnouncementPost';
import BirthdayPost from './birthday-post/BirthdayPost';

const moment = require('moment');
const BIRTHDAY_IMAGE_URL = require('./images/birthday-image.png');

const BIRTHDAY_POST = 'birthday_post';

export type Author = {
  id?: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  title?: string;
  status?: string;
};

interface TaggedEmployee {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

type Permission = {
  canEdit: boolean;
  canDelete: boolean;
};

type PostProps = {
  id?: string;
  createdAt?: string;
  createdBy?: Author;
  description?: string;
  postType?: string;
  permission?: Permission;
  taggedEmployees?: TaggedEmployee[];
};

const Post: React.FunctionComponent<PostProps> = props => {
  if (props.postType === BIRTHDAY_POST) {
    return (
      <BirthdayPost
        description={props.description}
        taggedEmployees={props.taggedEmployees}
        postTitle={`${moment(props.createdAt).format('MMMM Do')} Birthdays 🎉`}
        photoUrl={BIRTHDAY_IMAGE_URL}
      />
    );
  }

  return (
    <PeopleHubAnnouncementPost
      description={props.description}
      createdAt={props.createdAt}
      createdBy={props.createdBy}
      postId={props.id}
      permission={props.permission}
      employees={props.taggedEmployees}
    />
  );
};

export default Post;
