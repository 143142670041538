import gql from 'graphql-tag';

export const paywallDelinquentInfoQuey = gql`
  query getDelinquentInfo {
    delinquentPaymentInfo {
      mainAdminEmail
      paymentFailureReason
      possibleSolutions
    }
  }
`;
