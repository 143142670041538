import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import { Box } from 'zbase';
import { Form, FormSimpleSelect, FormTextInput } from 'z-frontend-forms';
import { Modal } from 'z-frontend-overlays';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';

import TextInlineWithLink from './TextInlineWithLink';
import SubActionFormFooter from './SubActionFormFooter';
import { SubAction } from './SubAction';
import {
  ContentReviewSubAction as ContentReviewSubActionType,
  ContentReviewSubActionInput,
} from '../../../schema/schemaTypes';
import { CompleteContentReviewSubAction } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import { getCtaButtonPosition } from './utils';
import InboxAttachments from '../inbox-attachments/InboxAttachments';

type ContentReviewSubAction = ContentReviewSubActionType & SharedSubActionProps;

type ContentReviewIdType = 'R' | 'A';
type ContentReviewSelectionOption = { label: string; id: ContentReviewIdType };

const contentReviewSelectOptions: ContentReviewSelectionOption[] = [
  // { label: 'Select', id: null },
  { label: 'Ask for Another', id: 'R' },
  { label: 'Approve', id: 'A' },
];

type ContentReviewForm = {
  decision?: string;
  comment?: string;
};

const ContentReviewSubAction: FunctionComponent<ContentReviewSubAction> = props => {
  const {
    id,
    attachments,
    currentEmployeeId,
    isCompleted: isSubActionCompleted,
    ctaText,
    descriptionWithNamesAndVariables,
    decision,
    comment,
    isOnlyOneSubAction,
    showCannotCompleteButton,
    onClickCannotComplete,
    hideOnlyOneSubActionCTA,
    isActionCompletedOrCannotComplete,
  } = props;

  const [completeSubAction] = useMutation<
    CompleteContentReviewSubAction.Mutation,
    CompleteContentReviewSubAction.Variables
  >(completeContentReviewSubActionMutation);

  const {
    showWithoutModalFooter: showFormFooterWithoutModalFooter,
    showWithModalFooter: showFormFooterWithModalFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  return (
    <Form<ContentReviewForm>
      initialValues={{
        decision: decision || '',
        comment: comment || '',
      }}
      onSubmit={async values => {
        const { decision, comment } = values;
        const reviewInput: ContentReviewSubActionInput = {
          decision,
          comment,
        };
        await completeSubAction({
          variables: {
            id,
            review: reviewInput,
            employeeId: currentEmployeeId,
          },
        });
        getEventLogger().log('inbox-action-save-sub-action', {
          // don't log fields like title which could contain sensitive info
          subActionId: id,
          subActionType: 'contentReview',
        });
      }}
      validationSchema={{
        decision: Form.Yup.string()
          .nullable()
          .required('Please select a decision'),
        comment: Form.Yup.string()
          .nullable()
          .when('decision', {
            is: 'R',
            then: Form.Yup.string().required('Please enter the reason why you did not approve the document'),
          }),
      }}
    >
      {props => (
        <>
          <SubAction isCompleted={isSubActionCompleted}>
            <Box w={1}>
              <Box mb={3} mt={1}>
                <TextInlineWithLink
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }}
                />
              </Box>

              <FormSimpleSelect<string>
                name="decision"
                label="Review Decision"
                data-testid="js-glue-content-review-decision"
                disabled={isSubActionCompleted}
              >
                {({ SelectOption }) =>
                  contentReviewSelectOptions.map(option => (
                    <SelectOption key={option.id} option={option.id}>
                      {option.label}
                    </SelectOption>
                  ))
                }
              </FormSimpleSelect>
              {props.values.decision === 'R' && <FormTextInput name="comment" label="Reason for Not Approving" />}
              {showFormFooterWithoutModalFooter && <SubActionFormFooter ctaText={ctaText} />}
            </Box>
          </SubAction>

          <InboxAttachments attachments={attachments} />

          {showFormFooterWithModalFooter && (
            <Modal.Footer borderTop>
              <SubActionFormFooter
                ctaText={ctaText}
                showCannotCompleteButton={showCannotCompleteButton}
                onClickCannotComplete={onClickCannotComplete}
              />
            </Modal.Footer>
          )}
        </>
      )}
    </Form>
  );
};

const completeContentReviewSubActionMutation = gql`
  mutation CompleteContentReviewSubAction($id: ID!, $employeeId: ID!, $review: ContentReviewSubActionInput!) {
    completeContentReviewSubAction(id: $id, employeeId: $employeeId, review: $review) {
      id
      isCompleted
      completionDate
      completedBy_id
      descriptionWithNamesAndVariables
      ctaText
      decision
      comment
    }
  }
`;

export default ContentReviewSubAction;
