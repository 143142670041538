import React, { Component } from 'react';

import { Box, BoxProps, Heading, TextBlock } from 'zbase';
import { Link } from 'z-frontend-elements';

type CompanyContactProps = {
  name: string;
  email: string;
} & BoxProps;

export default class CompanyContact extends Component<CompanyContactProps> {
  render() {
    const { name, email, ...rest } = this.props;
    return (
      <Box {...rest} textAlign={['center', null, 'left']} data-testid="CompanyContact">
        <Heading level={6} color="text.light" mb={2}>
          Company Contact
        </Heading>
        <TextBlock wordBreak="break-word" color="text.dark">
          {name}
        </TextBlock>
        <TextBlock wordBreak="break-word">
          <Link href={`mailto:${email}`}>{email}</Link>
        </TextBlock>
      </Box>
    );
  }
}
