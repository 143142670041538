import React, { useContext, useEffect, useRef, useState, FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { styled } from 'z-frontend-theme';
import { BoxProps } from 'zbase';

import Card from '../card/Card';
import { AccordionContext } from './Accordion';

const AccordionContentContainer = styled.div<{ height: string }>`
  overflow: hidden;
  height: 0;
  &.expand-enter-active {
    height: ${props => props.height};
    transition: height 0.3s ease-in;
  }
  &.expand-enter-done {
    height: auto;
  }
  &.expand-exit {
    height: ${props => props.height};
  }
  &.expand-exit-active {
    height: 0;
    transition: height 0.2s ease-out;
  }
`;

const AccordionContent: FunctionComponent<BoxProps> = props => {
  const { isExpanded } = useContext(AccordionContext);
  const { children, ...rest } = props;
  const contentEl = useRef(null);
  const [contentMaxHeight, setContentMaxHeight] = useState<string>();

  useEffect(() => {
    if (isExpanded) {
      setContentMaxHeight(`${contentEl.current.scrollHeight}px`);
    }
  }, [isExpanded]);

  return (
    <CSSTransition in={isExpanded} timeout={{ enter: 300, exit: 200 }} classNames="expand">
      <AccordionContentContainer ref={contentEl} data-testid="Accordion-Content" height={contentMaxHeight}>
        <Card.Row {...rest}>{children}</Card.Row>
      </AccordionContentContainer>
    </CSSTransition>
  );
};

export default AccordionContent;
