import React, { StatelessComponent } from 'react';

import { Form, FormTextarea } from 'z-frontend-forms';

export type CannotCompleteFormValues = {
  reason?: string;
};

type CannotCompleteFormProps = {
  onSubmit: (values: CannotCompleteFormValues) => void;
  onCancel: () => void;
};

const CannotCompleteForm: StatelessComponent<CannotCompleteFormProps> = ({ onSubmit, onCancel }) => {
  return (
    <Form<CannotCompleteFormValues>
      onSubmit={onSubmit}
      initialValues={{ reason: '' }}
      validationSchema={{
        reason: Form.Yup.string().required('Please explain why you are not able to complete this request'),
      }}
    >
      <FormTextarea name="reason" label="Why can't you complete this request?" />
      <Form.Footer primaryText="Submit feedback" cancelOnClick={onCancel} />
    </Form>
  );
};

export default CannotCompleteForm;
