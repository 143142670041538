import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { useQueryWithProgress, SwitchChecker } from 'z-frontend-network';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { Box, Flex, Heading, NumberText, TextBlock, TextInline } from 'zbase';
import { Button, ProgressBar } from 'z-frontend-elements';

import { CompanySetupTasksV3query } from './../../gqlTypes';

const companyTasksV3query = gql`
  query CompanySetupTasksV3query {
    companySetupTasksV3 {
      id
      taskGroups {
        tasks {
          isComplete
        }
      }
    }
  }
`;

const CompanySetupNotice: FunctionComponent = () => {
  const { Loading, QueryError, data } = useQueryWithProgress<CompanySetupTasksV3query.Query>(companyTasksV3query, {
    showInlineLoading: false,
  });
  if (Loading || QueryError || !data.companySetupTasksV3) {
    return <></>;
  }
  const companySetupTasks = data.companySetupTasksV3.taskGroups.flatMap(taskGroup => taskGroup.tasks);
  const totalTasks = companySetupTasks.length;
  const numTasksCompleted = companySetupTasks.filter(t => t.isComplete).length;

  return (
    <>
      <Flex justify="space-between" mb={2} mt={[0, null, 4]}>
        <Heading level={6} color="text.light" textAlign={['center', null, 'left']} textTransform="uppercase">
          Set up <TextInline textKey="brandName" />
        </Heading>
      </Flex>
      <Flex p={3} bg="grayscale.white" justify="space-between">
        <Box w={4 / 5}>
          <TextBlock>
            {numTasksCompleted ? (
              <Flex justify="space-between">
                <TextInline bold>Your Setup Progress</TextInline>
                <TextInline>
                  <NumberText value={numTasksCompleted / totalTasks} style="percent" /> complete
                </TextInline>
              </Flex>
            ) : (
              "We'll keep track of your progress here once you start."
            )}
          </TextBlock>
          <ProgressBar w={1} value={numTasksCompleted} max={totalTasks} />
        </Box>
        <Box>
          <Button
            s="medium"
            mode="primary"
            onClick={() => (window.location.href = '/app/company-setup/#/tasks_overview')}
          >
            Resume Setup
          </Button>
        </Box>
      </Flex>
    </>
  );
};

const CompanySetupNoticeContainer: FunctionComponent = () => {
  return (
    <ErrorBoundary FallbackComponent={() => null}>
      <SwitchChecker switch="onboarding_redesign_enabled">
        <CompanySetupNotice />
      </SwitchChecker>
    </ErrorBoundary>
  );
};

export default CompanySetupNoticeContainer;
