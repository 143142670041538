import React, { useState, FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import { DialogProps, Modal } from 'z-frontend-overlays';
import {
  Form,
  FormCheckbox,
  FormFieldFormat,
  FormPhoneInput,
  FormRadio,
  FormRadioGroup,
  FormTextarea,
} from 'z-frontend-forms';
import { TextBlock } from 'zbase';
import { BRAND_NAME } from 'z-frontend-app-bootstrap';

import { ContactAdvisorType } from '../../../schema/schemaTypes';
import { ContactAdvisor } from '../../gqlTypes';

type AdvisorValues = {
  contactType: ContactAdvisorType | '';
  message: string;
  prefersPhone: boolean;
  phoneNumber: string;
};

const promptMap = {
  hr:
    "Call us at 888-402-1963 to speak with an HR Advisor immediately or complete the form below and we'll get back to you as soon as possible.",
  payroll:
    "Call us at 888-402-0649 to speak with a Payroll Advisor immediately or complete the form below and we'll get back to you as soon as possible.",
};

const commonFormat = { format: 'form-row-top-label' as FormFieldFormat };

const AdvisorModalBody: FunctionComponent<{
  sent: boolean;
  formValues: AdvisorValues;
  dialog: DialogProps;
  onClose: () => void;
}> = props => {
  const { onClose } = props;
  const { contactType, prefersPhone } = props.formValues;

  if (props.sent) {
    return (
      <>
        <Modal.Body>
          <TextBlock fontStyle="headings.l" mb={3} data-testid="AdvisorModalSuccess">
            {contactType === 'hr' ? 'HR' : 'Payroll'} advice is on the way.
          </TextBlock>
          <TextBlock mb={3}>
            We've assigned your question to one of our {contactType === 'hr' ? 'HR specialists' : 'Payroll advisors'}.
          </TextBlock>
          <TextBlock>They'll be in touch within one business day.</TextBlock>
        </Modal.Body>
        <Modal.Footer buttons={[{ text: 'Okay', type: 'submit', onClick: onClose }]} omitCancelButton />
      </>
    );
  }

  if (contactType) {
    return (
      <>
        <Modal.Body pb={0}>
          <TextBlock mb={3}>{promptMap[contactType]}</TextBlock>
          <FormTextarea
            label="Ask your question and we'll email you back."
            name="message"
            placeholder="E.g., Are the overtime laws in my state different from federal overtime laws?"
            rows={3}
            mb={3}
            {...commonFormat}
          />
          <FormCheckbox
            label="Request an answer by phone."
            name="prefersPhone"
            containerProps={{ mb: prefersPhone ? 2 : 3 }}
          />
          {prefersPhone && (
            <FormPhoneInput label="What's the best number to reach you?" name="phoneNumber" {...commonFormat} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* using Form.Footer will show errors when needed */}
          <Form.Footer primaryText="Send" cancelShown={false} />
        </Modal.Footer>
      </>
    );
  } else {
    return (
      <Modal.Body>
        <FormRadioGroup name="contactType" label="Do you need help with HR or Payroll?" {...commonFormat}>
          <FormRadio value="hr" label="HR" />
          <FormRadio value="payroll" label="Payroll" />
        </FormRadioGroup>
      </Modal.Body>
    );
  }
};

function getTitle(contactType: ContactAdvisorType): string {
  if (contactType === 'hr') {
    return 'Contact an HR Advisor';
  }
  if (contactType === 'payroll') {
    return `Contact a ${BRAND_NAME} Payroll Advisor`;
  }
  return `Contact a ${BRAND_NAME} Ultimate Advisor`;
}

const AdvisorModal: FunctionComponent<{ dialog: DialogProps; contactType?: ContactAdvisorType }> = props => {
  const { dialog, contactType } = props;
  const [sent, setSent] = useState(false);
  const [contactAdvisor] = useMutation<ContactAdvisor.Mutation, ContactAdvisor.Variables>(contactAdvisorMutation);

  return (
    <Form<AdvisorValues>
      initialValues={{
        contactType: contactType || '',
        message: '',
        prefersPhone: false,
        phoneNumber: '',
      }}
      validationSchema={{
        message: Form.Yup.string().required("You haven't asked a question"),
        prefersPhone: Form.Yup.boolean().notRequired(),
        phoneNumber: Form.Yup.string().when('prefersPhone', {
          is: true,
          then: Form.Yup.string().required('Please enter a phone number'),
        }),
      }}
      onSubmit={async values => {
        await contactAdvisor({
          variables: {
            type: values.contactType as ContactAdvisorType,
            message: values.message,
            phoneNumber: values.phoneNumber,
          },
        });
        setSent(true);
      }}
    >
      {props => {
        const onClose = () => {
          // reset for reopening
          setSent(false);
          props.resetForm();
          dialog.close();
        };

        return (
          <Modal
            title={getTitle(contactType)}
            isVisible={dialog.isVisible}
            controlEl={dialog.controlEl}
            onCancel={onClose}
            width={[1, null, 600]}
          >
            <AdvisorModalBody formValues={props.values} sent={sent} dialog={dialog} onClose={onClose} />
          </Modal>
        );
      }}
    </Form>
  );
};

const contactAdvisorMutation = gql`
  mutation ContactAdvisor($type: ContactAdvisorType!, $message: String!, $phoneNumber: String) {
    contactAdvisor(type: $type, message: $message, phoneNumber: $phoneNumber) {
      success
    }
  }
`;

export default AdvisorModal;
