import React, { Component } from 'react';
import gql from 'graphql-tag';

import { EmptyState, LoadingScreen } from 'z-frontend-elements';
import { withErrorBoundary } from 'z-frontend-app-bootstrap';
import { Query } from 'z-frontend-network';
import { DialogProps, Modal } from 'z-frontend-overlays';
import { Box } from 'zbase';

import { InboxActionQuery } from '../../gqlTypes';
import InboxActionModalContent from '../InboxActionModalContent';

type ViewInboxActionModalProps = {
  dialog: DialogProps;
  inboxActionId: string;
  text: string;
  currentEmployeeId: string;
  refreshList?: () => Promise<any>;
  companyId?: string;
};

class ViewInboxActionModal extends Component<ViewInboxActionModalProps> {
  render() {
    const { dialog, text, refreshList, currentEmployeeId, inboxActionId, companyId } = this.props;
    const viewModalProps = {
      title: text,
      isVisible: dialog.isVisible,
      onCancel: () => {
        if (refreshList) {
          refreshList();
        }
        dialog.close();
      },
      controlEl: dialog.controlEl,
      width: [1, null, 600, 900],
      'data-testid': 'InboxActionModal',
    };

    return (
      <Modal {...viewModalProps}>
        <Query<InboxActionQuery.Query, InboxActionQuery.Variables>
          query={inboxActionQuery}
          variables={{ id: inboxActionId }}
          renderLoading={() => (
            <Box minHeight={100} style={{ position: 'relative' }}>
              <LoadingScreen />
            </Box>
          )}
          renderError={() => <EmptyState />}
        >
          {({ data }) => (
            <InboxActionModalContent
              inboxAction={data.inboxAction}
              currentEmployeeId={currentEmployeeId}
              companyId={companyId}
            />
          )}
        </Query>
      </Modal>
    );
  }
}

const subActionsFragment = gql`
  fragment InboxSubActionsFragment on InboxSubAction {
    id
    description
    signatureSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      signature
      ctaText
    }
    bankSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      bankBranchName
      ctaText
    }
    singleValueSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      ctaText
      type
      name
      value
      choices {
        id
        value
      }
    }
    addressSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      ctaText
    }
    confirmationSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      ctaText
      ctaInfo
    }
    contentReviewSubActions {
      id
      descriptionWithNamesAndVariables
      isCompleted
      ctaText
      decision
      comment
    }
  }
`;

const inboxActionQuery = gql`
  query InboxActionQuery($id: ID!) {
    inboxAction(id: $id) {
      id
      descriptionWithNamesAndVariables
      titleWithNamesAndVariables
      attachments {
        id
        url
        filename
      }
      completionDate
      completedBy {
        id
        preferredOrFirstName
        last_name
        photoUrl
      }
      hideCannotComplete
      inboxSubActions {
        ...InboxSubActionsFragment
      }
      status
    }
  }
  ${subActionsFragment}
`;

export default withErrorBoundary<ViewInboxActionModalProps>()(ViewInboxActionModal);
