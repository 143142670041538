import React, { Component } from 'react';

import { ResultComponentProps } from 'zbase';
import { buildEmberRouteRedirect, QueryParamsProps } from 'z-frontend-app-bootstrap';

import { StyledLink } from './Link';

type EmberAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & QueryParamsProps;

export type EmberLinkProps = ResultComponentProps<EmberAnchorProps>;

class EmberRouteLink extends Component<EmberLinkProps> {
  render() {
    const { to, routeParams, queryParams, ...props } = this.props;

    const href = buildEmberRouteRedirect({ to, routeParams, queryParams });
    return <StyledLink {...props} href={href} />;
  }
}
export default EmberRouteLink;
