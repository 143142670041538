import React, { StatelessComponent } from 'react';

import { FormFooter } from 'z-frontend-forms';

type SubActionFormFooterProps = {
  ctaText: string;
  /**
   * Whether to show a "Cannot complete" button
   */
  showCannotCompleteButton?: boolean;
  onClickCannotComplete?: any;
};

const SubActionFormFooter: StatelessComponent<SubActionFormFooterProps> = ({
  onClickCannotComplete,
  ctaText,
  showCannotCompleteButton,
}) => {
  return (
    <FormFooter
      primaryText={ctaText || 'Completed this task'}
      cancelShown={false}
      tertiaryShown={showCannotCompleteButton}
      tertiaryText="Cannot complete this request"
      tertiaryOnClick={onClickCannotComplete}
    />
  );
};

export default SubActionFormFooter;
