import React from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';

import { Box, Icon, TextInline } from 'zbase';
import { Banner } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';
import { useSwitches } from 'z-frontend-network';
import { SWITCHES } from 'z-frontend-app-bootstrap';

import { OptoutTrialQuery } from '../../../gqlTypes';
import { getDaysLeftFromNow, getTimeLeft } from './utils/date.utils';
import { optoutTrialQuery } from './queries';

const PayrollDiscount = styled(TextInline)`
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid ${color('caution.a')};
`;

const companyProfileInfoRegex = /company-profile\/plan-information/;

export const OptoutTrialBanner: React.FunctionComponent = () => {
  const { hash } = useLocation();
  const { loading, data } = useQuery<OptoutTrialQuery.Query>(optoutTrialQuery);
  const switches = useSwitches();

  const showPayrollDiscount = switches?.[SWITCHES.optOutFreeTrialPayrollDiscount];

  const company = data?.dashboard?.company || {};
  const { optOutFreeTrial } = company;

  if (loading || !optOutFreeTrial) {
    return null;
  }

  // if not active then dont show banner
  if (optOutFreeTrial.status !== 'active') {
    return null;
  }

  const daysleft = getDaysLeftFromNow(optOutFreeTrial.endsOn);

  // dont show link to manage trial if on company profile info page
  const isOnCompanyProfileInfo = companyProfileInfoRegex.test(hash);

  return (
    <Box mb={3}>
      <Banner type="caution" centerContent showIcon={false} isClosable={false}>
        <TextInline color="caution.a">
          <Icon iconName="time" mr={2} />
          Your free trial expires in {daysleft} day{daysleft === 1 ? '' : 's'}.
        </TextInline>
        {showPayrollDiscount && (
          <PayrollDiscount color="caution.a">
            {`Add Payroll in the next ${getTimeLeft(optOutFreeTrial.endsOn)} left to get 20% off. `}
          </PayrollDiscount>
        )}
        {isOnCompanyProfileInfo ? null : (
          <Link ml={2} href="/dashboard/#/company-profile/plan-information">
            Manage Trial
          </Link>
        )}
      </Banner>
    </Box>
  );
};
