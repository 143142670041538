import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { DialogManager, Modal } from 'z-frontend-layout';

import { Box, Flex, TextBlock, TextInline } from 'zbase';
import { Accordion, AccordionContent, AccordionHeader } from 'z-frontend-composites';
import { Button, Link, LinkButton } from 'z-frontend-elements';

import { SkipMicroTrial, StartMicroTrial } from '../gqlTypes';
import { Features } from '../constants';

const startMicroTrialMutation = gql`
  mutation StartMicroTrial($key: String!) {
    startMicroTrial(key: $key) {
      success
      errorCode
      errorMessage
      microTrialSubscription {
        id
      }
    }
  }
`;

const skipMicroTrialMutation = gql`
  mutation SkipMicroTrial($microTrialId: ID!) {
    registerMicroTrialOptOut(microTrialId: $microTrialId) {
      microTrialId
      createdAt
    }
  }
`;

type MicroTrialModalsProps = {
  microTrialId: string;
  microTrialKey: string;
  features: string[];
  daysLeft: number;
  render: (openModal: () => void) => JSX.Element;
  onTrialSkip: () => void;
};

const MicroTrialModalVideo: React.FunctionComponent<{ url: string }> = props => (
  <Flex w="100%" justify="center" height="30vh" mt={4}>
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      src={props.url}
      allowFullScreen
      allow="autoplay; encrypted-media"
    />
  </Flex>
);

const MicroTrialModals: React.FunctionComponent<MicroTrialModalsProps> = props => {
  const [doStartMicroTrialMutation, { loading: isMicroTrialMutationInProgress }] = useMutation<
    StartMicroTrial.Mutation,
    StartMicroTrial.Variables
  >(startMicroTrialMutation);

  const [skipMicroTrial] = useMutation<SkipMicroTrial.Mutation, SkipMicroTrial.Variables>(skipMicroTrialMutation);

  const computeModalTitle = (features: string[], daysLeft: number) => {
    switch (JSON.stringify(features)) {
      case JSON.stringify([Features.Talent]):
        return `Try Performance Management for ${daysLeft} days on us`;
    }
  };

  const modalTitle = computeModalTitle(props.features, props.daysLeft);

  const computeModalBody = (features: string[], daysLeft: number) => {
    switch (JSON.stringify(features)) {
      case JSON.stringify([Features.Talent]):
        return (
          <>
            <TextBlock>
              We’re offering a free, {daysLeft}-day trial of our Performance Management app so you can try our
              user-friendly tools for goal setting, performance reviews, and one-on-one meetings.
            </TextBlock>
            <TextBlock bold mt={2}>
              By starting the trial now, you will enable Performance Management for everyone in your organization,
              including managers and employees.{' '}
              <Link target="_blank" href="https://www.zenefits.com/hr/performance-management/">
                Learn more about how this works.
              </Link>
            </TextBlock>
            <TextBlock mt={2}>
              Don’t worry, you won’t be charged immediately after the trial ends, and you can change your mind or start
              the trial again at any time.
            </TextBlock>
            <MicroTrialModalVideo url="https://www.youtube.com/embed/YNo0WmFluGg?start=12" />
            <Accordion border={false} mt={2} mb={0}>
              <AccordionHeader pt={0} pb={0} pl={1} fontStyle="paragraphs.s">
                Read about our Terms and Conditions
              </AccordionHeader>
              <AccordionContent pb={0} pt={1} pl={1} fontStyle="paragraphs.s">
                Only <TextInline textKey="brandName" /> Essentials plan customers are eligible to receive sixty (60) day
                free trial of the performance management add-on. You must maintain the{' '}
                <TextInline textKey="brandName" /> Essentials plan throughout the sixty (60) day free trial. The free
                trial automatically ends at the end of the free trial period. To continue use of the performance
                management add-on, you must upgrade to either the <TextInline textKey="brandName" />
                Growth or Zen base plan. No discount is provided if you upgrade to the{' '}
                <TextInline textKey="brandName" /> Growth or Zen base plan prior to the end of the free trial. No other
                discounts, offers, or incentives can be applied in conjunction with or combined with this promotion.
                This is a limited time offer and is subject to availability. The promotion is not transferable.{' '}
                <TextInline textKey="brandName" /> shall in its sole discretion determine eligibility and reserves the
                right to modify or terminate this promotion at any time without prior notice.
              </AccordionContent>
            </Accordion>
          </>
        );
    }
  };

  const modalBody = computeModalBody(props.features, props.daysLeft);

  const startMicroTrial = async () => {
    const result = await doStartMicroTrialMutation({ variables: { key: props.microTrialKey } });
    if (result.data.startMicroTrial.success) {
      switch (JSON.stringify(props.features)) {
        case JSON.stringify([Features.Talent]):
          window.location.href = '/app/talent';
      }
    }
  };

  return (
    <DialogManager
      render={dialog => {
        const modalProps = {
          title: modalTitle,
          onCancel: dialog.close,
          isVisible: dialog.isVisible,
          controlEl: dialog.controlEl,
        };

        return (
          <>
            <Modal size="small" {...modalProps}>
              <Modal.Body>{modalBody}</Modal.Body>
              <Modal.Footer>
                <Flex justify="space-between">
                  <LinkButton
                    onClick={() => {
                      skipMicroTrial({
                        variables: {
                          microTrialId: props.microTrialId,
                        },
                      });
                      props.onTrialSkip();
                      dialog.close();
                    }}
                  >
                    Skip Trial
                  </LinkButton>
                  <Box>
                    <Button mode="normal" mr={3} onClick={dialog.close}>
                      Start Trial Later
                    </Button>
                    <Button mode="primary" inProgress={isMicroTrialMutationInProgress} onClick={startMicroTrial}>
                      Start Trial Now
                    </Button>
                  </Box>
                </Flex>
              </Modal.Footer>
            </Modal>
            {props.render(dialog.open)}
          </>
        );
      }}
    />
  );
};

export default MicroTrialModals;
