import React from 'react';

import { TextBlock, TextInline } from 'zbase';
import { Banner } from 'z-frontend-composites';
import { getEventLogger } from 'z-frontend-app-bootstrap';

type AccountUpdateModalContentProps = {
  deadlineMonth: string;
  cutAccessDeadline: string;
  daysLeftBeforeDeadline: number;
  lastDayOfCampaign: string;
  lastDayOfMonth: string;
  adminFirstName: string;
};

class AccountUpdateModalContent extends React.Component<AccountUpdateModalContentProps, {}> {
  componentDidMount() {
    getEventLogger().log('lite_customers_to_paid_banner_shown');
  }

  render() {
    const { deadlineMonth, daysLeftBeforeDeadline, cutAccessDeadline, lastDayOfCampaign, adminFirstName } = this.props;

    return (
      <>
        <Banner type="error" isClosable={false} showIcon={false} mb={3}>
          <TextBlock color="negation.a">
            Access extended: <TextInline bold>{daysLeftBeforeDeadline}</TextInline> days left to upgrade your account
            and remain on the <TextInline textKey="brandName" /> people platform.
          </TextBlock>
        </Banner>

        <TextBlock>Hi {adminFirstName},</TextBlock>

        <TextBlock tag="p" />

        <TextBlock>
          Due to a high volume of interest, we are extending your full access to <TextInline textKey="brandName" />{' '}
          through {lastDayOfCampaign}.
        </TextBlock>

        <TextBlock tag="p" />

        <TextBlock>
          If you <TextInline bold>upgrade by {lastDayOfCampaign}</TextInline>, you can receive
          <TextInline bold> a significant discount</TextInline> off our list price for our Essentials, Growth, or Zen
          package. This discount will only last through {deadlineMonth}.
        </TextBlock>

        <TextBlock tag="p" />

        <TextBlock>
          If you choose not to upgrade, you’ll lose access to any products or services through{' '}
          <TextInline textKey="brandName" />, including Time & Attendance (Scheduling), Payroll, Commuter & Flex
          Benefits, FSA, and Background Checks, effective <TextInline bold>{cutAccessDeadline}</TextInline>.
        </TextBlock>
      </>
    );
  }
}

export default AccountUpdateModalContent;
