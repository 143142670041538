import React from 'react';

import { Button } from 'z-frontend-elements';
import { DialogProps, Modal } from 'z-frontend-overlays';
import { Box, Flex, TextBlock } from 'zbase';

interface RedirectConfirmationModalProps {
  dialog: DialogProps;
  onConfirm: Function;
  onCancel: Function;
}

const RedirectConfirmationModal: React.FunctionComponent<RedirectConfirmationModalProps> = props => {
  const { dialog } = props;

  const modalProps = {
    title: 'You’re Leaving TriNet',
    onSubmit: () => {
      props.onConfirm();
      dialog.close();
    },
    onCancel: () => {
      props.onCancel();
      dialog.close();
    },
    isVisible: dialog.isVisible,
  };

  return (
    <Box>
      <Modal {...modalProps}>
        <Modal.Body>
          {' '}
          <Box flex="1">
            <TextBlock>You are now leaving TriNet and opening a third-party site. </TextBlock>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Flex justify="flex-end" flex="1 0">
            <Button mr={2} mode="normal" onClick={() => props.onCancel()}>
              Cancel
            </Button>
            <Button mode="primary" mr={2} onClick={() => props.onConfirm()}>
              Continue
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default RedirectConfirmationModal;
