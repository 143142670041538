import React, { useState, FunctionComponent } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Box, Flex } from 'zbase';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';

import { SubAction } from './SubAction';
import TextInlineWithLink from './TextInlineWithLink';
import { getButtonsFromCtaInfo, getCtaButtonPosition } from './utils';
import { ConfirmationSubAction as ConfirmationSubActionType } from '../../../schema/schemaTypes';
import { CompleteConfirmationSubAction } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import InboxAttachments from '../inbox-attachments/InboxAttachments';
import ConfirmationSubActionFooter from './ConfirmationSubActionFooter';

type ConfirmationSubActionProps = Pick<
  ConfirmationSubActionType,
  'id' | 'descriptionWithNamesAndVariables' | 'ctaInfo' | 'isCompleted'
> &
  SharedSubActionProps;

const ConfirmationSubAction: FunctionComponent<ConfirmationSubActionProps> = props => {
  const {
    id,
    attachments,
    currentEmployeeId,
    isCompleted: isSubActionCompleted,
    descriptionWithNamesAndVariables,
    ctaInfo,
    isOnlyOneSubAction,
    showCannotCompleteButton,
    onClickCannotComplete,
    hideOnlyOneSubActionCTA,
    isActionCompletedOrCannotComplete,
    companyId,
  } = props;
  // Whether the call to complete the sub action is in progress
  const [isCompleteInProgress, setIsCompleteInProgress] = useState(false);

  const [completeSubAction] = useMutation<
    CompleteConfirmationSubAction.Mutation,
    CompleteConfirmationSubAction.Variables
  >(completeConfirmationSubActionMutation);

  const onSubActionClose = async (value: boolean) => {
    setIsCompleteInProgress(true);

    await completeSubAction({
      variables: {
        id,
        value,
        employeeId: currentEmployeeId,
      },
    });
    getEventLogger().log('inbox-action-save-sub-action', {
      // don't log fields like title which could contain sensitive info
      subActionId: id,
      subActionType: 'confirmation',
    });

    setIsCompleteInProgress(false);
  };

  const {
    showWithoutModalFooter: showInlineCtaButtons,
    showWithModalFooter: showCtaButtonsInFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  const ctaButtons = getButtonsFromCtaInfo(ctaInfo, onSubActionClose, companyId);

  return (
    <>
      <SubAction isCompleted={isSubActionCompleted}>
        <Box w={1}>
          <Flex direction="row" align="center" justify="space-between" mt={1}>
            <TextInlineWithLink dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }} />
            {showInlineCtaButtons && <Flex justify="flex-end">{ctaButtons}</Flex>}
          </Flex>
        </Box>
      </SubAction>

      <InboxAttachments attachments={attachments} />

      {showCtaButtonsInFooter && (
        <ConfirmationSubActionFooter
          showCannotCompleteButton={showCannotCompleteButton}
          isCompleteInProgress={isCompleteInProgress}
          onClickCannotComplete={onClickCannotComplete}
          ctaButtons={ctaButtons}
        />
      )}
    </>
  );
};

const completeConfirmationSubActionMutation = gql`
  mutation CompleteConfirmationSubAction($id: ID!, $employeeId: ID!, $value: Boolean) {
    completeConfirmationSubAction(id: $id, employeeId: $employeeId, value: $value) {
      id
      isCompleted
      completionDate
      completedBy_id
    }
  }
`;

export default ConfirmationSubAction;
