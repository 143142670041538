import { getDashboardApps, parseZenefitsUrl, DashboardApp, ZAPP_ORDER } from 'z-frontend-layout';
import { checkPermission, Permissions, Switches } from 'z-frontend-network';
import { SubscriptionInfoWithIds } from 'z-frontend-app-bootstrap';

import { DashboardRole } from '../../types';
import { CheckoutPackageProductSku, ZAppInstallSubscription } from '../../../schema/schemaTypes';
import { showUpgradeBadgeAppAllowList, SHOW_UPGRADE_BADGE_SWITCH } from './AppTileUpgradeBadge';
import {
  jazzHRAndRecruitingAppUniqueIds,
  CONTRACTOR_ENABLED_APPS,
  FULL_COMPANY_ADMIN,
  INTEGRATIONS_TO_OVERRIDE_EMBER_ROUTE,
  IOM_DISABLED_APPS,
  JAZZ_HR_RECRUITING_PROD,
  MAIN_ADMIN,
  OKTA_ZAPP_UNIQUE_ID,
  PRE_INSTALL_APPS_UNIQUE_ID_LIST,
  RECRUITING_APPS_UNIQUE_ID_LIST,
  RECRUITING_MARKETING_APP_TILE,
  RECRUITING_MARKETING_URL,
} from './constants';
import { basePlanSkus } from '../../constants';

export const DashboardZAppStatusEnum = {
  DISABLED: 25,
  NOT_ENROLLED: 100,
};

export function parseAppStatus(status: number): string {
  if (status < DashboardZAppStatusEnum.DISABLED) {
    return 'disabled';
  } else if (status <= DashboardZAppStatusEnum.NOT_ENROLLED) {
    return 'notEnrolled';
  } else {
    return 'enabled';
  }
}

export function filterAppsBySwitches(apps: DashboardApp[], switches: any) {
  return apps.filter(card => {
    const switchName = card.showIfSwitchEnabled;
    if (!switchName) {
      return true;
    }

    if (switchName.startsWith('!')) {
      if (switches[switchName.substring(1)]) {
        return false;
      }
    } else if (!switches[switchName]) {
      return false;
    }
    return true;
  });
}

export type AppTileCard = {
  status: string;
  uniqueId: string;
  name: string;
  iconUrl: string;
  appUrl?: string;
  role: string;
  isThirdPartyApp?: boolean;
  // partner apps which need to be preinstalled after company checkout (AD doesn't need to install them manually)
  isPreInstallApp?: boolean;
  isVisibleForEmployee?: boolean;
  isExternal?: boolean;
  shouldBlockForTrial?: boolean;
  /**
   * Whether to hide the "+Upgrade" badge at the top-right corner of the tile.
   */
  hideUpgradeBadge?: boolean;
};

type AppTileBreakdown = {
  integrationCards: AppTileCard[];
  integrationCardsForEmployee: AppTileCard[];
  dashboardCards: AppTileCard[];
  preInstallIntegrationCards: AppTileCard[];
};

export function getAppTiles(
  zAppInstallSubscriptions: ZAppInstallSubscription[],
  switches: any,
  isSpoofing: boolean,
  prospectAccountType: string,
): AppTileBreakdown {
  const cards = getDashboardApps(zAppInstallSubscriptions, switches, isSpoofing);
  const filtered = filterAppsBySwitches(cards, switches);
  return getAppTileBreakdown(filtered, switches, prospectAccountType);
}

export function getAppTileBreakdown(filtered: DashboardApp[], switches?: any, prospectAccountType?: string) {
  const dashboardCards: AppTileCard[] = [];
  const integrationCards: AppTileCard[] = [];
  const preInstallIntegrationCards: AppTileCard[] = [];
  const integrationCardsForEmployee: AppTileCard[] = [];
  filtered.forEach(card => {
    const { appInstall } = card.subscription;
    const { uniqueId } = appInstall.app;

    const appUrlInfo = getAppUrlInfo(card);

    dashboardCards.push({
      uniqueId,
      name: appInstall.app.shortTitle,
      iconUrl: appInstall.app.appIconSqUrl,
      status: parseAppStatus(card.subscription.inheritedStatus),
      role: appInstall.app.role,
      isExternal: appUrlInfo.isExternal,
      shouldBlockForTrial: false, // TODO: requires more modals?? :(
      appUrl: appUrlInfo.url as string, // FIXME: this isn't necessarily a string
    });

    // NOTE-DZH: unclear why this is `status` instead of `inheritedStatus`; just matching Ember here
    const status = parseAppStatus(appInstall.status);
    const isIntegrationApp = !uniqueId.startsWith('1.');
    const isPreInstallIntegrationApp = PRE_INSTALL_APPS_UNIQUE_ID_LIST.includes(uniqueId);
    if (isIntegrationApp && status !== 'disabled') {
      if (!isPreInstallIntegrationApp) {
        // all integration apps are visible to admin ; pre-install apps handled separately
        if (INTEGRATIONS_TO_OVERRIDE_EMBER_ROUTE.includes(uniqueId)) {
          integrationCards.push({
            uniqueId,
            status,
            name: appInstall.app.shortTitle,
            iconUrl: appInstall.app.appIconSqUrl,
            role: appInstall.app.role,
            isThirdPartyApp: true,
            isVisibleForEmployee: false,
            appUrl: appUrlInfo.url as string,
          });
        } else {
          integrationCards.push({
            uniqueId,
            status,
            name: appInstall.app.shortTitle,
            iconUrl: appInstall.app.appIconSqUrl,
            role: appInstall.app.role,
            isThirdPartyApp: true,
            isVisibleForEmployee: false,
          });
        }
      } else {
        // pre-install apps will be visible to admin always but need special handling
        preInstallIntegrationCards.push({
          uniqueId,
          status: getStatusForPreInstallIntegration(uniqueId, status),
          name: appInstall.app.shortTitle,
          iconUrl: appInstall.app.appIconSqUrl,
          role: appInstall.app.role,
          isThirdPartyApp: true,
          isVisibleForEmployee: false,
          appUrl: getAppUrlForPreInstallIntegration(uniqueId, appInstall, prospectAccountType),
          isPreInstallApp: true,
          isExternal: getIsExternalForPreInstallIntegration(uniqueId),
        });
      }
      // only some integration apps are visible to employees
      const employeeStatus = parseAppStatus(card.subscription.inheritedStatus);
      if (appInstall.app.preferences.isVisibleForEmployee && employeeStatus === 'enabled') {
        integrationCardsForEmployee.push({
          uniqueId,
          name: appInstall.app.shortTitle,
          iconUrl: appInstall.app.appIconSqUrl,
          status: employeeStatus,
          role: appInstall.app.role,
          isThirdPartyApp: true,
          isVisibleForEmployee: true,
          // in case the integration app is visible for employee, then fetch the URL from preferences
          appUrl: appInstall.app.preferences.employee_app_url,
        });
      }
    }
  });

  const appMap = dashboardCards.reduce<{ [key: string]: AppTileCard }>((appMap, appCard) => {
    if (appCard.status !== 'disabled') {
      appMap[appCard.uniqueId] = appCard;
    }
    return appMap;
  }, {});

  const sortedDashboardCards = ZAPP_ORDER.reduce<AppTileCard[]>((sortedApps, appId) => {
    if (appMap.hasOwnProperty(appId)) {
      sortedApps.push(appMap[appId]);
    }
    return sortedApps;
  }, []);

  // we want recruiting app to be first item in preInstallIntegrationCards and integrationCardsForEmployee
  // https://stackoverflow.com/questions/23921683/javascript-move-an-item-of-an-array-to-the-front

  preInstallIntegrationCards.sort((x, y) => {
    return x.uniqueId === JAZZ_HR_RECRUITING_PROD ? -1 : y.uniqueId === JAZZ_HR_RECRUITING_PROD ? 1 : 0;
  });

  integrationCardsForEmployee.sort((x, y) => {
    return x.uniqueId === JAZZ_HR_RECRUITING_PROD ? -1 : y.uniqueId === JAZZ_HR_RECRUITING_PROD ? 1 : 0;
  });

  return {
    integrationCards,
    preInstallIntegrationCards,
    integrationCardsForEmployee,
    dashboardCards: sortedDashboardCards,
  };
}

function getAppUrlInfo(card: DashboardApp) {
  const buttons = card.buttons();
  let linkTo;
  if (buttons && buttons.length) {
    // eslint-disable-next-line prefer-destructuring
    linkTo = buttons[0].linkTo;
  } else {
    linkTo = parseZenefitsUrl(card.subscription.appInstall.app.appUrl);
  }

  // buttons() in dashboardApps.ts is not extracting the route, while in Ember it does.
  // This is why this logic is needed for both cases above and not just the else case.
  if (typeof linkTo === 'object' && linkTo['route']) {
    linkTo = linkTo.route;
  }

  return {
    // Note: url could be a route name or URL
    url: linkTo,
    isExternal: /(^http(s?):\/\/|^\/app\/)/.test(linkTo as string),
  };
}

function getAppUrlForPreInstallIntegration(uniqueId: string, appInstall: any, prospectAccountType: string) {
  const isJazzHrRecruitApp = RECRUITING_APPS_UNIQUE_ID_LIST.includes(uniqueId);
  const isPLGTrialCompany = prospectAccountType === 'freeLimitedTrial';
  const app_url = isJazzHrRecruitApp
    ? isPLGTrialCompany
      ? RECRUITING_MARKETING_URL
      : appInstall.app.preferences.employee_app_url
    : '';
  return app_url;
}

function getStatusForPreInstallIntegration(uniqueId: string, status: string) {
  const isJazzHrRecruitApp = RECRUITING_APPS_UNIQUE_ID_LIST.includes(uniqueId);
  const statusForPreInstallApp = isJazzHrRecruitApp ? 'enabled' : status;
  return statusForPreInstallApp;
}

function getIsExternalForPreInstallIntegration(uniqueId: string) {
  // if true, clicking app tile in AD view won't take us to integrations page, rather use the appUrl instead
  const isJazzHrRecruitApp = RECRUITING_APPS_UNIQUE_ID_LIST.includes(uniqueId);
  return isJazzHrRecruitApp;
}

function getEligibleAppTiles(
  visibleTiles: AppTileCard[],
  isFullCompanyAdmin: boolean,
  employmentType: string,
  role: string,
) {
  let appTiles = [];
  if ((role === 'ADM' && isFullCompanyAdmin) || employmentType === 'CO') {
    appTiles = visibleTiles.filter(tile => CONTRACTOR_ENABLED_APPS.includes(tile.uniqueId));
  } else {
    appTiles = visibleTiles.filter(tile => !IOM_DISABLED_APPS.includes(tile.uniqueId));
  }
  return appTiles;
}

export function getVisibleAppTiles(
  availableTiles: AppTileBreakdown,
  role: DashboardRole,
  switches: Switches,
  permissions: Permissions,
  subscriptionData: SubscriptionInfoWithIds,
  isProspectAccount: boolean,
  isContractorPaymentsCompany: boolean,
  isCpWithIomCompany: boolean,
  employmentType: string,
) {
  const { dashboardCards, integrationCards, preInstallIntegrationCards, integrationCardsForEmployee } = availableTiles;

  const adminViewCards = [...integrationCards, ...dashboardCards, ...preInstallIntegrationCards];
  const zenefitsRecruitingCard = adminViewCards.find(appTile =>
    jazzHRAndRecruitingAppUniqueIds.includes(appTile.uniqueId),
  );

  const isFullCompanyAdmin = checkPermission(permissions, FULL_COMPANY_ADMIN);
  const isMainAdmin = checkPermission(permissions, MAIN_ADMIN);

  const isEssentialsGrowthOrZen = basePlanSkus.includes(subscriptionData?.tier as CheckoutPackageProductSku);
  const shouldShowRecruitingMarketingTile =
    !zenefitsRecruitingCard && isFullCompanyAdmin && isEssentialsGrowthOrZen && !isProspectAccount;

  let visibleTiles = [...dashboardCards];

  if (role === 'ADM') {
    visibleTiles = visibleTiles.concat(preInstallIntegrationCards);

    if (shouldShowRecruitingMarketingTile && !isCpWithIomCompany) {
      visibleTiles.push(RECRUITING_MARKETING_APP_TILE);
    }

    visibleTiles = visibleTiles.concat(integrationCards);

    if (isCpWithIomCompany && !isMainAdmin) {
      // if full company admin, show contractor payments apps. for others, show benefits apps
      visibleTiles = getEligibleAppTiles(visibleTiles, isFullCompanyAdmin, employmentType, role);
    }
  } else {
    visibleTiles = visibleTiles.concat(integrationCardsForEmployee);
    if (isCpWithIomCompany) {
      // if contractor, show contractor related apps. for others, show benefits apps
      visibleTiles = getEligibleAppTiles(visibleTiles, isFullCompanyAdmin, employmentType, role);
    }
  }

  if (switches?.[SHOW_UPGRADE_BADGE_SWITCH]) {
    const dashboardExperimentApps = visibleTiles.filter(
      appTileCard =>
        appTileCard.status === 'notEnrolled' && showUpgradeBadgeAppAllowList.includes(appTileCard.uniqueId),
    );

    if (dashboardExperimentApps && dashboardExperimentApps.length > 0) {
      visibleTiles = visibleTiles.filter(appTileCard => !dashboardExperimentApps.includes(appTileCard));
      visibleTiles = visibleTiles.concat(dashboardExperimentApps);
    }
  }

  // Hiding Help Center app for all
  visibleTiles = visibleTiles.filter(tile => tile.name !== 'Expert');
  if (isContractorPaymentsCompany) {
    if (!isCpWithIomCompany) {
      visibleTiles = visibleTiles.filter(tile => tile.uniqueId !== OKTA_ZAPP_UNIQUE_ID);
    }
    visibleTiles.map(tile => {
      if (tile.uniqueId === '1.com.zenefits.Hiring' || tile.uniqueId === '1.com.zenefits.HiringForManagers') {
        tile.name = 'Contracting';
      } else if (tile.uniqueId === '1.com.zenefits.PayrollAdmin' || tile.uniqueId === '1.com.zenefits.Paystubs') {
        tile.name = 'Payments';
      }
    });
  }
  return visibleTiles.filter(card => card.role === 'ALL' || card.role === role);
}
