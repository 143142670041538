import React from 'react';

import { styled } from 'z-frontend-theme';
import { Box } from 'zbase';
import { color, fontSizes, space } from 'z-frontend-theme/utils';
import { Link } from 'z-frontend-elements';
import { getHelpLink, useContractorLiteCompanyFeature } from 'z-frontend-layout';

const FooterItem = styled(Box)`
  display: inline-block;
  padding: 0 ${space(2)};
  font-size: ${fontSizes(1)}; /* smaller than default */
`;

const FooterItemDisclaimerCP = styled(Box)`
  display: inline-block;
  padding: 16px 24px;
  font-size: ${fontSizes(1)}; /* smaller than default */
  color: ${color('grayscale.d')};
  text-align: left;
`;

const PageFooter: React.FunctionComponent = () => {
  const isContractorPaymentsCompany = useContractorLiteCompanyFeature();
  return (
    <Box as="footer" py={3} textAlign="center" borderTop data-testid="PageFooter">
      {!isContractorPaymentsCompany && (
        <FooterItem>
          <Link href={getHelpLink()} target="_blank">
            Help
          </Link>
        </FooterItem>
      )}
      <FooterItem>
        <Link href="https://www.zenefits.com/legal/tou/">Terms</Link>
      </FooterItem>
      <FooterItem>
        <Link href="https://www.zenefits.com/legal/privacy-policy/">Privacy</Link>
      </FooterItem>
      {!isContractorPaymentsCompany && (
        <FooterItem>
          <Link href="https://www.zenefits.com/legal/broker-licenses/">Insurance Licenses</Link>
        </FooterItem>
      )}
      {isContractorPaymentsCompany && (
        <FooterItemDisclaimerCP>
          Independent contractor(s) paid through the tool will not receive TriNet workers’ compensation coverage and
          this is the client’s / contractor’s responsibility. The TriNet Contractor Payments solution/tool may not be
          used to issue, file, or pay a 1099 to a WSE. Misclassification of independent contractors can lead to
          significant exposure. TriNet offers its clients tools to assist and referral to the law firm Fisher Phillips.
        </FooterItemDisclaimerCP>
      )}
    </Box>
  );
};

export default PageFooter;
