import React, { useState } from 'react';

import { Box, Flex, Icon, Image, TextInline } from 'zbase';
import { pendoHelpers } from 'z-frontend-app-bootstrap';
import { Link } from 'z-frontend-elements';
import { styled, Hide, IconNameString } from 'z-frontend-theme';

import { UserInfoBusinessCase } from '../types';
import { getBuyLink } from '../../utils/growth';

const baseTours = [
  {
    name: 'HR Tours',
    iconName: 'account',
    isOpened: false,
    tourItems: [
      {
        tourName: 'HR & Dashboard Overview',
        tourId: '19-598701',
        pendoGuideId: pendoHelpers.guideIds.demoCenter.overview,
      },
      {
        tourName: 'Employee Profile',
        tourId: '19-348712',
        pendoGuideId: 'zSZslDRsoygwOOG4QfZo6pxVnWY',
      },
      {
        tourName: 'Hiring',
        tourId: '19-348805',
        pendoGuideId: '9B11W4_8udcBDqXtG_YJzyuva-4',
      },
      {
        tourName: 'Time Off',
        tourId: '19-348844',
        pendoGuideId: 'QUeEw5AYoNiJP7Sq3XDIswV-n6Q',
      },
    ],
  },
  {
    name: 'Payroll Tours',
    iconName: 'money',
    isOpened: false,
    tourItems: [
      {
        tourName: 'Time & Attendance Overview',
        tourId: '19-348864',
        pendoGuideId: '6r3VBUy2ZZRBNmfuuAUwDjz955E',
      },
      {
        tourName: 'Payroll Overview',
        tourId: '19-348868',
        pendoGuideId: 'Tq_IKCqYADLWHkdgW5lcIOFHsUw',
      },
      {
        tourName: '3-Click Regular Pay Run',
        tourId: '19-351680',
        pendoGuideId: '5ltJ-FnNLj45TdxZ72IpCJkFV2g',
      },
      {
        tourName: 'Off-Cycle Pay Run',
        tourId: '19-351681',
        pendoGuideId: 'kfW3dOSLjfjcvOJ_kFtEfxnxmCM',
      },
      {
        tourName: 'Integrated Pay Stubs',
        tourId: '19-351687',
        pendoGuideId: 'k6ilACruWj_b4p5pd21u1kn2D-c',
      },
    ],
  },
  {
    name: 'Benefits Tours',
    iconName: 'plus-square',
    isOpened: false,
    tourItems: [
      {
        tourName: 'Benefits Administration Overview',
        tourId: '19-352635',
        pendoGuideId: 'i-jZ2-vFYLmbBZrIA5CEBv6sdG4',
      },
      {
        tourName: "Worker's Insurance Details",
        tourId: '19-352668',
        pendoGuideId: 'zd_h4Y2PwaFJ0HHlUO0oGWY20rc',
      },
      {
        tourName: 'Other Benefits Offerings',
        tourId: '19-352690',
        pendoGuideId: 'DzAOjdSLX7raB9LDmRwE1o58VGE',
      },
    ],
  },
];

const DarkBlueBox = styled(Box)`
  /* stylelint-disable-next-line color-no-hex */
  background-color: #0f2b5b;
`;

const StyledCollapsibleDropdownSection = styled(Box)`
  &.open-demo-dropdown-section {
    min-height: 50px;
    transition: all 0.3s ease-in-out;
  }

  &.close-demo-dropdown-section {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`;

const StyledDropDownIcon = styled(Icon)`
  text-align: center;
`;

const IconImage = styled(Image)`
  max-height: 100%;
`;

const CollapsibleSection: React.FunctionComponent = props => {
  const [tours, setTours] = useState(baseTours);

  const handleToggle = (tourName: string) => {
    const currTourIndex = baseTours.findIndex(tour => tour.name === tourName);

    const newDataTours = baseTours.map(tour => ({ ...tour }));
    newDataTours.forEach(tour => {
      tour.isOpened = false;
    });
    newDataTours[currTourIndex].isOpened = !tours[currTourIndex].isOpened;
    setTours(newDataTours);
  };

  return (
    <DarkBlueBox>
      {tours.map(tour => (
        <Box pt={3} px={4} color="grayscale.g" key={tour.name} onClick={() => handleToggle(tour.name)}>
          <Flex align="center">
            <StyledDropDownIcon
              iconName={tour.iconName as IconNameString}
              mr={3}
              s="large"
              w={20}
              text-align="center"
            />
            <Link fontStyle="paragraphs.m" color="grayscale.g">
              {tour.name}
            </Link>
          </Flex>

          <StyledCollapsibleDropdownSection
            className={tour.isOpened ? 'open-demo-dropdown-section' : 'close-demo-dropdown-section'}
            pb={0}
            pt={3}
          >
            {tour.tourItems.map(item => (
              <Link key={item.tourId} onClick={() => pendoHelpers.triggerGuide(item.pendoGuideId)}>
                <Flex py={3} px={4} pl={5} align="center" color="grayscale.g">
                  <Box fontStyle="paragraphs.s" color="grayscale.g">
                    {item.tourName}
                  </Box>
                </Flex>
              </Link>
            ))}
          </StyledCollapsibleDropdownSection>
        </Box>
      ))}
    </DarkBlueBox>
  );
};

type DemoCenterDropdownProps = {
  userInfo: UserInfoBusinessCase;
};

const DemoCenterDropdown: React.FunctionComponent<DemoCenterDropdownProps> = props => {
  const { firstName, lastName, userEmail, companyName } = props.userInfo;
  const businessCaseUrl = `https://zenefits.com/business-case/questionnaire/?person_firstname=${firstName}&person_lastname=${lastName}&email=${userEmail}&company_name=${companyName}`;
  const competitorComparisonUrl = 'https://zenefits.com/compare-zenefits/';
  const pricingUrl = getBuyLink();

  return (
    <Flex color="grayscale.g" fontStyle="paragraphs.m" w="270px" bg="secondary.a" column py={3}>
      <Link href="/app/demo-center/#/overview">
        <Flex py={3} px={4} align="center" color="grayscale.g">
          <Icon iconName="home" mr={3} s="large" />
          <Box>Demo Center Home</Box>
        </Flex>
      </Link>
      <Hide forBreakpoints={[true]}>
        <DarkBlueBox>
          <Link onClick={() => pendoHelpers.triggerGuide(pendoHelpers.guideIds.demoCenter.overview)}>
            <Flex py={3} px={4} color="grayscale.g">
              <Flex w={20} height={24} justify="center" mr={3}>
                <IconImage src="/static/img/growth/zenefits-dropdown-logo.svg" alt="" />
              </Flex>
              <Box>
                <TextInline textKey="brandName" /> Overview
              </Box>
            </Flex>
          </Link>
        </DarkBlueBox>
        <CollapsibleSection />
      </Hide>
      <Link href={competitorComparisonUrl} target="_blank">
        <Flex py={3} px={4} align="center" color="grayscale.g">
          <Flex w={20} height={24} justify="center" align="center" mr={3}>
            <IconImage src="/static/img/growth/compare_us.png" alt="" />
          </Flex>
          <Box>Compare Us</Box>
        </Flex>
      </Link>
      <Link href={businessCaseUrl} target="_blank">
        <Flex py={3} px={4} align="center" color="grayscale.g">
          <Flex w={20} height={24} justify="center" mr={3}>
            <StyledDropDownIcon iconName="plus-circle-o" s="large" w={20} text-align="center" />
          </Flex>
          <Box>Create Business Case</Box>
        </Flex>
      </Link>
      <Link href={pricingUrl}>
        <Flex py={3} px={4} align="center" color="grayscale.g">
          <Flex w={20} height={21} justify="center" align="center" mr={3}>
            <IconImage src="/static/img/growth/pricing.png" alt="" />
          </Flex>
          <Box>Checkout</Box>
        </Flex>
      </Link>
    </Flex>
  );
};

export default DemoCenterDropdown;
