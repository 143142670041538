import React from 'react';

import { Avatar } from 'z-frontend-composites';
import { ReadMore } from 'z-frontend-elements';
import { Box, Flex, Heading } from 'zbase';
import { MentionText } from 'z-frontend-forms';

interface TaggedEmployee {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

type BirthdayPostProps = {
  description: string;
  taggedEmployees?: TaggedEmployee[];
  postTitle: string;
  photoUrl: string;
};

type EmployeeDetail = {
  label: string;
  tooltipText?: string;
};

const BirthdayPost: React.FunctionComponent<BirthdayPostProps> = (props: BirthdayPostProps) => {
  const { description, taggedEmployees, postTitle, photoUrl } = props;
  const mentionsMap: { [key: string]: EmployeeDetail } = {};
  if (taggedEmployees) {
    taggedEmployees.forEach(
      m =>
        (mentionsMap[m.id] = {
          label: `${m.firstName} ${m.lastName}`,
          tooltipText: `${m.firstName} ${m.lastName}\n${m.email}`,
        }),
    );
  }

  return (
    <Flex border={false} bg="grayscale.white">
      <Avatar photoUrl={photoUrl} s="large" />
      <Flex column>
        <Flex column ml={4}>
          <Flex>
            <Heading level={4} fontStyle="controls.l">
              {postTitle}
            </Heading>
          </Flex>
        </Flex>
        <Box ml={4} mt={2}>
          <ReadMore
            isExpandControlHiddenOnResize
            lines={2}
            expandedText={
              <MentionText text={description} whiteSpace="pre-wrap" wordBreak="break-word" mentions={mentionsMap} />
            }
          >
            <MentionText display="inline" text={description} mentions={mentionsMap} />
          </ReadMore>
        </Box>
      </Flex>
    </Flex>
  );
};

export default BirthdayPost;
