import React, { FunctionComponent, StatelessComponent } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';

import { Box, Flex } from 'zbase';
import {
  Form,
  FormDateInput,
  FormFileUploader,
  FormNumberInput,
  FormSimpleSelect,
  FormTextInput,
} from 'z-frontend-forms';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';
import { Link } from 'z-frontend-elements';
import { Modal } from 'z-frontend-overlays';

import SubActionFormFooter from './SubActionFormFooter';
import { SingleValueSubAction as SingleValueSubActionType } from '../../../schema/schemaTypes';
import TextInlineWithLink from './TextInlineWithLink';
import { SubAction } from './SubAction';
import { CompleteSingleValueSubAction } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import { getCtaButtonPosition } from './utils';
import InboxAttachments from '../inbox-attachments/InboxAttachments';

type SingleValueFormBodyProps = Pick<
  SingleValueSubActionType,
  'id' | 'descriptionWithNamesAndVariables' | 'ctaText' | 'isCompleted' | 'value' | 'name' | 'choices' | 'type'
>;

type SingleValueSubActionProps = SingleValueFormBodyProps & SharedSubActionProps;

const formFieldName = 'singleValue';
const genders = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' },
];

const SingleValueFormBody: StatelessComponent<SingleValueFormBodyProps> = props => {
  const commonFieldProps = {
    name: formFieldName,
    label: '', // use aria-label instead
    disabled: props.isCompleted,
    'aria-label': props.name,
    containerProps: { mb: 0 },
  };

  if (props.choices && props.choices.length) {
    const optionList = props.choices.map(choice => ({
      label: choice.value, // seems like this should be choice.label, but matching Ember for now
      value: choice.value,
    }));
    return (
      <FormSimpleSelect<string> {...commonFieldProps}>
        {({ SelectOption }) =>
          optionList.map(option => (
            <SelectOption key={option.value} option={option.value}>
              {option.label}
            </SelectOption>
          ))
        }
      </FormSimpleSelect>
    );
  }
  if (props.type === 'email') {
    return <FormTextInput type="email" {...commonFieldProps} />;
  }
  if (props.type === 'integer' || props.type === 'float' || props.type === 'zip') {
    return (
      <FormNumberInput
        includeThousandsSeparator={props.type !== 'zip'}
        allowDecimal={props.type === 'float'}
        {...commonFieldProps}
      />
    );
  }
  if (props.type === 'date' || props.type === 'dob') {
    return <FormDateInput {...commonFieldProps} />;
  }
  if (props.type === 'gender') {
    return (
      <FormSimpleSelect<string> {...commonFieldProps}>
        {({ SelectOption }) =>
          genders.map(option => (
            <SelectOption key={option.id} option={option.id}>
              {option.name}
            </SelectOption>
          ))
        }
      </FormSimpleSelect>
    );
  }
  if (props.type === 'file') {
    return (
      <FormFileUploader
        isLink
        linkName="Upload"
        category="single_value_sub_action_file_type"
        {...commonFieldProps}
        additionalInformation={
          <Link target="_blank" href={props.value}>
            Preview
          </Link>
        }
      />
    );
  }
  // consider making this handle all remaining types
  if (props.type === 'text' || props.type === 'ssn' || props.type === 'zip' || props.type === 'state') {
    return <FormTextInput {...commonFieldProps} />;
  }
  return null;
};

function getValidationSchema(type: string) {
  if (type === 'integer' || type === 'float' || type === 'zip') {
    return Form.Yup.number()
      .nullable()
      .required('This is required.');
  }
  if (type === 'date' || type === 'dob') {
    return Form.Yup.date().required('This is required.');
  }
  if (type === 'email') {
    return Form.Yup.string()
      .email('Must be a valid email.')
      .required('This is required.');
  }
  return Form.Yup.string().required('This is required.');
}

const SingleValueSubAction: FunctionComponent<SingleValueSubActionProps> = props => {
  const {
    id,
    attachments,
    currentEmployeeId,
    name,
    value,
    type,
    choices,
    isCompleted: isSubActionCompleted,
    ctaText,
    descriptionWithNamesAndVariables,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
    isActionCompletedOrCannotComplete,
    showCannotCompleteButton,
    onClickCannotComplete,
  } = props;

  const [completeSubAction] = useMutation<
    CompleteSingleValueSubAction.Mutation,
    CompleteSingleValueSubAction.Variables
  >(completeSingleValueSubActionMutation);

  const {
    showWithoutModalFooter: showFormFooterWithoutModalFooter,
    showWithModalFooter: showFormFooterWithModalFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  return (
    <Form<{ singleValue: string }>
      initialValues={{
        [formFieldName]: value || '',
      }}
      validationSchema={{
        [formFieldName]: getValidationSchema(type),
      }}
      onSubmit={async values => {
        let submitValue = values[formFieldName];
        if (type === 'file') {
          submitValue = get(submitValue, '[0].fileUrl');
        }

        await completeSubAction({
          variables: {
            id,
            value: submitValue,
            employeeId: currentEmployeeId,
          },
        });
        getEventLogger().log('inbox-action-save-sub-action', {
          subActionId: id,
          subActionType: 'singleValue',
        });
      }}
    >
      <SubAction isCompleted={isSubActionCompleted}>
        <Box w={1}>
          <Box mb={3} mt={1}>
            <TextInlineWithLink dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }} />
          </Box>

          <Flex align="flex-start" justify="space-between" mb={4}>
            <Box w={1 / 2}>
              <SingleValueFormBody
                type={type}
                name={name}
                choices={choices}
                isCompleted={isSubActionCompleted}
                value={value}
              />
            </Box>
            {showFormFooterWithoutModalFooter && <SubActionFormFooter ctaText={ctaText} />}
          </Flex>
        </Box>
      </SubAction>

      <InboxAttachments attachments={attachments} />

      {showFormFooterWithModalFooter && (
        <Modal.Footer borderTop>
          <SubActionFormFooter
            ctaText={ctaText}
            showCannotCompleteButton={showCannotCompleteButton}
            onClickCannotComplete={onClickCannotComplete}
          />
        </Modal.Footer>
      )}
    </Form>
  );
};

const completeSingleValueSubActionMutation = gql`
  mutation CompleteSingleValueSubAction($id: ID!, $employeeId: ID!, $value: String!) {
    completeSingleValueSubAction(id: $id, employeeId: $employeeId, value: $value) {
      id
      isCompleted
      completionDate
      completedBy_id
      descriptionWithNamesAndVariables
      ctaText
      type
      name
      value
      choices {
        id
        value
      }
    }
  }
`;

export default SingleValueSubAction;
