import { DashboardApp } from './dashboardApp';

export class TalentAdminApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Performance Management',
        isHighlighted: false,
        linkTo: 'talent-intro',
      },
    ];
  }
}

export class TalentEmployeeApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Performance Management',
        isHighlighted: false,
        linkTo: '/app/talent/#/overview',
      },
    ];
  }
}

export class WellbeingAdminApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Well-being',
        isHighlighted: false,
        linkTo: '/app/well-being/#/overview',
      },
    ];
  }
}

export class WellbeingEmployeeApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Well-being',
        isHighlighted: false,
        linkTo: '/app/well-being/#/overview',
      },
    ];
  }
}

export class EngagementAdminApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Engagement',
        isHighlighted: false,
        linkTo: '/app/engagement/#/',
      },
    ];
  }
}

export class EngagementEmployeeApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'Engagement',
        isHighlighted: false,
        linkTo: '/app/engagement/#/',
      },
    ];
  }
}
