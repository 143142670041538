import React from 'react';

import { Flex, TextInline } from 'zbase';

import { UserInfoBusinessCase } from '../types';
import ProductTitleWithDropdown from './ProductTitleWithDropdown';

type ProductTitleProps = {
  canSeeDemoCenter: boolean;
  productTitleKey: string;
  productTitleDefault: string;
  userInfo?: UserInfoBusinessCase;
};

class ProductTitle extends React.Component<ProductTitleProps> {
  render() {
    const { canSeeDemoCenter, productTitleKey, productTitleDefault, userInfo } = this.props;

    const productTitleText = getProductTitleText(canSeeDemoCenter, productTitleKey, productTitleDefault);

    if (canSeeDemoCenter) {
      return <ProductTitleWithDropdown productTitleText={productTitleText} userInfo={userInfo} />;
    } else if (productTitleText) {
      // Default product title is just text
      return (
        <Flex align="center" fontStyle="headings.s" color="secondary.a" data-testid="ProductTitle">
          {productTitleText}
        </Flex>
      );
    } else {
      return null;
    }
  }
}

export default ProductTitle;

function getProductTitleText(
  canSeeDemoCenter: boolean,
  productTitleKey: string,
  productTitleDefault: string,
): JSX.Element {
  return canSeeDemoCenter ? (
    <TextInline>Demo Center</TextInline>
  ) : productTitleKey ? (
    <TextInline textKey={productTitleKey} textDefault={productTitleDefault} />
  ) : productTitleDefault ? (
    <TextInline>{productTitleDefault}</TextInline>
  ) : null;
}
