import React, { useEffect, useState } from 'react';

import { Accordion, AccordionContent, AccordionHeader } from 'z-frontend-composites';
import { Button, Link } from 'z-frontend-elements';
import { Flex, TextBlock, TextInline } from 'zbase';
import { DialogManager, Modal } from 'z-frontend-overlays';
import {
  doFeatureListsMatch,
  getMicroTrialFeaturesVerboseName,
  DISMISS_PROMO_TILES_KEY,
  Features,
  MicroTrialModals,
  MICROTRIAL_PROMO_VIEWED_KEY,
} from 'z-frontend-microtrials';

import AppTile from '../apps/AppTile';
import { getPerformanceManagementSubAppTiles } from './utils';
import SectionHeading from '../SectionHeading';

type MicroTrialAppTilesProps = {
  microTrialFeatures: string[];
  microTrialId?: string;
  duration?: number;
  microTrialKey?: string;
  hasTrialStarted: boolean;
};

type MicroTrialsPromoAppTilesInnerProps = {
  microTrialFeatures: string[];
  openPromoModal?: () => void;
  hasTrialStarted: boolean;
};

const MicroTrialsPromoAppTilesInner: React.FunctionComponent<MicroTrialsPromoAppTilesInnerProps> = props => {
  const hasViewedPromoModal = () => {
    return window.localStorage.getItem(MICROTRIAL_PROMO_VIEWED_KEY) === 'true';
  };

  const markPromoModalAsViewed = () => {
    window.localStorage.setItem(MICROTRIAL_PROMO_VIEWED_KEY, 'true');
  };

  useEffect(() => {
    if (doFeatureListsMatch(props.microTrialFeatures, [Features.Talent])) {
      if (!props.hasTrialStarted && !hasViewedPromoModal()) {
        // Show the promo modal once, as soon as user logs into dashboard
        props.openPromoModal();
        markPromoModalAsViewed();
      }
    }
  }, []);

  if (doFeatureListsMatch(props.microTrialFeatures, [Features.Talent])) {
    return (
      <>
        {getPerformanceManagementSubAppTiles(null).map(card => (
          <AppTile
            data-testid={`microtrialV1Promo${card.name}${props.hasTrialStarted ? 'PostTrialStart' : 'PreTrialStart'}`}
            onClick={e => {
              if (!props.hasTrialStarted) {
                // if trial has started, then we need to allow user to proceed to ZApp
                e.preventDefault();
                props.openPromoModal();
              }
            }}
            width={[1 / 3, 1 / 4, 1 / 5, 1 / 5, 1 / 5]}
            key={card.name}
            hideUpgradeBadge
            {...card}
          />
        ))}
      </>
    );
  }
};

const AppListWrapper: React.FunctionComponent<{
  heading?: string;
  openDismissModal?: () => void;
  hasTrialStarted: boolean;
}> = props => {
  return (
    <>
      <Flex wrap mx={-3} className="js-walkme-dashboard-apps">
        <SectionHeading>{props.heading || 'Apps'}</SectionHeading>
        <Link
          data-testid={`microtrialPromoLearnMore${props.hasTrialStarted ? 'PostTrialStart' : 'PreTrialStart'}`}
          onClick={props.openDismissModal}
          mt={5}
          fontStyle="paragraphs.s"
          ml={2}
        >
          Why is this showing up on my feed?
        </Link>
      </Flex>
      <Flex wrap mx={-3} className="js-walkme-dashboard-apps">
        {props.children}
      </Flex>
    </>
  );
};

const arePromoTilesDismissed = () => {
  return window.localStorage.getItem(DISMISS_PROMO_TILES_KEY) === 'true';
};

const MicroTrialsPromoAppTiles: React.FunctionComponent<MicroTrialAppTilesProps> = props => {
  const [promoTilesDismissed, setPromoTilesDismissed] = useState(arePromoTilesDismissed());

  if (!props.microTrialFeatures) return null;

  const getAppListHeading = (microTrialFeatures: string[]) => {
    if (doFeatureListsMatch([...microTrialFeatures], [Features.Talent])) {
      return 'Try performance management for free';
    }
    return null;
  };
  const appListHeading = getAppListHeading(props.microTrialFeatures);

  const dismissPromoTiles = () => {
    window.localStorage.setItem(DISMISS_PROMO_TILES_KEY, 'true');
    setPromoTilesDismissed(true);
  };

  return (
    appListHeading &&
    !promoTilesDismissed && (
      <DialogManager
        render={dismissPromoDialog => (
          <>
            <Modal
              data-testid="microTrialsPromoLearnMoreModal"
              title="Revert to Original Layout"
              onCancel={dismissPromoDialog.close}
              isVisible={dismissPromoDialog.isVisible}
              controlEl={dismissPromoDialog.controlEl}
              size="small"
            >
              <Modal.Body>
                <TextBlock fontStyle="paragraphs.m">
                  {`We're offering a free trial of ${getMicroTrialFeaturesVerboseName(
                    props.microTrialFeatures,
                  )} for a limited time. If you don’t like to see the trials within
            your apps, we will revert back to original layout for your convenience.`}
                </TextBlock>
                <Accordion border={false} mt={2} mb={0}>
                  <AccordionHeader pt={0} pb={0} pl={1} fontStyle="paragraphs.s">
                    Read about our Terms and Conditions
                  </AccordionHeader>
                  <AccordionContent pb={0} pt={1} pl={1} fontStyle="paragraphs.s">
                    Only <TextInline textKey="brandName" /> Essentials plan customers are eligible to receive sixty (60)
                    day free trial of the performance management add-on. You must maintain the{' '}
                    <TextInline textKey="brandName" /> Essentials plan throughout the sixty (60) day free trial. The
                    free trial automatically ends at the end of the free trial period. To continue use of the
                    performance management add-on, you must upgrade to either the <TextInline textKey="brandName" />{' '}
                    Growth or Zen base plan. No discount is provided if you upgrade to the
                    <TextInline textKey="brandName" /> Growth or Zen base plan prior to the end of the free trial. No
                    other discounts, offers, or incentives can be applied in conjunction with or combined with this
                    promotion. This is a limited time offer and is subject to availability. The promotion is not
                    transferable. <TextInline textKey="brandName" /> shall in its sole discretion determine eligibility
                    and reserves the right to modify or terminate this promotion at any time without prior notice.
                  </AccordionContent>
                </Accordion>
              </Modal.Body>
              <Modal.Footer>
                <Flex justify="flex-end">
                  <Button
                    data-testid={`microTrialsPromoLearnMoreModalCancelButton${
                      props.hasTrialStarted ? 'PostTrialStart' : 'PreTrialStart'
                    }`}
                    mode="normal"
                    onClick={dismissPromoDialog.close}
                    mr={3}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid={`microTrialsPromoLearnMoreModalRemoveButton${
                      props.hasTrialStarted ? 'PostTrialStart' : 'PreTrialStart'
                    }`}
                    mode="primary"
                    type="submit"
                    onClick={dismissPromoTiles}
                  >
                    Yes, Remove
                  </Button>
                </Flex>
              </Modal.Footer>
            </Modal>
            {props.hasTrialStarted ? (
              <AppListWrapper
                heading={appListHeading}
                openDismissModal={dismissPromoDialog.open}
                hasTrialStarted={props.hasTrialStarted}
              >
                <MicroTrialsPromoAppTilesInner
                  microTrialFeatures={props.microTrialFeatures}
                  hasTrialStarted={props.hasTrialStarted}
                />
              </AppListWrapper>
            ) : (
              <MicroTrialModals
                microTrialId={props.microTrialId}
                microTrialKey={props.microTrialKey}
                features={props.microTrialFeatures}
                daysLeft={props.duration}
                onTrialSkip={dismissPromoTiles}
                render={openModal => (
                  <AppListWrapper
                    heading={appListHeading}
                    openDismissModal={dismissPromoDialog.open}
                    hasTrialStarted={props.hasTrialStarted}
                  >
                    <MicroTrialsPromoAppTilesInner
                      microTrialFeatures={props.microTrialFeatures}
                      openPromoModal={openModal}
                      hasTrialStarted={props.hasTrialStarted}
                    />
                  </AppListWrapper>
                )}
              />
            )}
          </>
        )}
      />
    )
  );
};

export default MicroTrialsPromoAppTiles;
