import { AppTileCard } from '../apps/app-utils';

export function getPerformanceManagementSubAppTiles(performanceManagementAppTile: AppTileCard): AppTileCard[] {
  const appTileOverrides = [
    {
      name: 'Reviews',
      appUrl: '/app/talent/#/reviews',
      appIconSqUrl: '/static/img/icons/zapps/icon-z2-performance-management-reviews.svg',
      iconUrl: '/static/img/icons/zapps/icon-z2-performance-management-reviews.svg',
    },
    {
      name: 'One-on-Ones',
      appUrl: '/app/talent/#/one-on-ones',
      appIconSqUrl: '/static/img/icons/zapps/icon-z2-performance-management-one-on-ones.svg',
      iconUrl: '/static/img/icons/zapps/icon-z2-performance-management-one-on-ones.svg',
    },
    {
      name: 'Goals',
      appUrl: '/app/talent/#/goals',
      appIconSqUrl: '/static/img/icons/zapps/icon-z2-performance-management-goals.svg',
      iconUrl: '/static/img/icons/zapps/icon-z2-performance-management-goals.svg',
    },
  ];
  return appTileOverrides.map(overrides => ({ ...performanceManagementAppTile, ...overrides }));
}
