import React, { Component } from 'react';

import { DashboardApp } from 'z-frontend-layout';
import { Query } from 'z-frontend-network';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { TextBlock } from 'zbase';

import { EmployeesQuery } from '../../gqlTypes';
import WorkerList, { WorkerSectionHeading } from './WorkerList';
import { DashboardRole, EmploymentType } from '../../types';
import { employeeQuery } from '../../initialQueries';

type WorkerContainerProps = {
  role: DashboardRole;
  apps: DashboardApp[];
  canViewAdmin: boolean;
  cpWorkersOnly: boolean;
};

export class WorkerContainer extends Component<WorkerContainerProps> {
  render() {
    return (
      <ErrorBoundary
        FallbackComponent={() => (
          <>
            <WorkerSectionHeading role={this.props.role} />
            <TextBlock mt={3}>Sorry, we were unable to load team members.</TextBlock>
          </>
        )}
      >
        <Query<EmployeesQuery.Query>
          query={employeeQuery}
          handleLoading={false} // leave it up to skeleton loading
          context={{ disabledBatching: true }}
        >
          {({ data, loading }) => {
            const { dashboard, readOnlyAccess } = data;
            const readOnlyPermission = dashboard ? dashboard.permission && dashboard.permission.readOnly : false;
            const readOnlySku = readOnlyAccess ? !readOnlyAccess.canEdit : false;

            // for cpWorkersOnly true, show only Contractors in Workers section.
            let companyWorkers = dashboard ? dashboard.employees : [];
            if (companyWorkers && this.props.cpWorkersOnly) {
              companyWorkers = companyWorkers.filter(worker => {
                if (worker.employmentType === EmploymentType.CO) {
                  return true;
                }
                return false;
              });
            }

            return (
              <WorkerList
                currentlyLoggedInWorker={dashboard && dashboard.employee}
                workers={companyWorkers}
                teammates={dashboard ? dashboard.dashboardTeammates : []}
                readOnly={readOnlyPermission || readOnlySku}
                loading={loading}
                {...this.props}
              />
            );
          }}
        </Query>
      </ErrorBoundary>
    );
  }
}
