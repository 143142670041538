import React, { Component } from 'react';

import { Banner, BannerFlexContainerProps } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { PluralText, TextInline } from 'zbase';

import { ActiveBillingDetailsQuery } from '../gqlTypes';

type RenewalNoticeProps = {
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails;
  includeWalkMe?: boolean;
} & BannerFlexContainerProps;

function showRenewalNotice(billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails): boolean {
  if (!billingDetails.renewalDate) {
    return false;
  }

  const { numberOfDaysBeforeRenewal } = billingDetails;
  const isRenewalSoon = numberOfDaysBeforeRenewal >= 0 && numberOfDaysBeforeRenewal <= 60;
  if (!isRenewalSoon) {
    return false;
  }

  const { planDetails } = billingDetails;
  const currentPlanIsAnnual = planDetails.contractLength > 0;
  const currentPlanIsMonthly = !currentPlanIsAnnual;
  if (currentPlanIsMonthly) {
    return false;
  }

  const { isRenewingContract } = billingDetails;
  if (!isRenewingContract) {
    return false;
  }

  return true;
}

export default class RenewalNotice extends Component<RenewalNoticeProps> {
  render() {
    const { billingDetails, includeWalkMe, ...containerProps } = this.props;

    if (!showRenewalNotice(billingDetails)) {
      return null;
    }
    return (
      <Banner type="info" {...containerProps}>
        <TextInline>
          <PluralText
            count={billingDetails.numberOfDaysBeforeRenewal}
            none="Your plan is auto-renewing today. "
            one="Your plan is auto-renewing in {count} day. "
            other="Your plan is auto-renewing in {count} days. "
          />
          <Link
            href="/dashboard/#/company-profile/renewal"
            className={includeWalkMe ? 'js-walkme-dashboard-renewal-center-banner' : null}
          >
            See your new prices and plans
          </Link>
        </TextInline>
      </Banner>
    );
  }
}
