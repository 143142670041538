import React from 'react';

import { Link } from 'z-frontend-elements';
import { TextBlock } from 'zbase';

// IMAGE FILES
export const talentManagementImage = require('./images/managerCoachingEmployee.png');
export const wellbeingImage = require('./images/highFive.png');
export const compensationManagementImage = require('./images/moneyManagement.png');
export const engagementImage = require('./images/engagementSurvey.png');

// STORAGE KEYS
export const MICROTRIAL_PROMO_VIEWED_KEY = 'microtrial_promo_viewed';
export const DISMISS_MICROTRIAL_BANNER_KEY = 'dismiss_microtrial_banner_key';
export const DISMISS_PROMO_TILES_KEY = 'dismiss_promo_app_tiles';

// TYPES
export enum Features {
  Talent = 'talent',
  Wellbeing = 'wellbeing',
  Engagement = 'engagement',
  Compensation = 'compensation',
}

export type MicroTrialBanner = {
  features: Features[];
  description: string;
  image?: string;
  redirectUrl: string;
  heading?: string;
};

export type MicroTrialModalButton = {
  title: string;
  pendoGuideId?: string;
  url?: string;
};

export type MicroTrialModalContent = {
  summary?: JSX.Element;
  image?: string;
  video?: string;
};

export type MicroTrialWelcomeModalProps = {
  microTrialKey: string;
  microTrialName: string;
  content: MicroTrialModalContent;
  primaryButton: MicroTrialModalButton;
  secondaryButton?: MicroTrialModalButton;
};

export const VERBOSE_FEATURE_NAMES: { [key: string]: string } = {
  [Features.Talent]: 'Performance management',
  [Features.Wellbeing]: 'Wellbeing',
  [Features.Engagement]: 'Engagement',
  [Features.Compensation]: 'Compensation management',
};

export const BannerContent: MicroTrialBanner[] = [
  {
    features: [Features.Talent],
    description:
      'Improve employee performance with user-friendly tools for goal-setting, performance reviews, and one-on-one meetings.',
    image: talentManagementImage,
    redirectUrl: '/app/talent/#/overview',
    heading: 'Try Performance Management for free for 60 days ',
  },
  {
    features: [Features.Engagement],
    description: 'People teams can easily drive actions from insights to boost employee sentiment and morale.',
    image: engagementImage,
    redirectUrl: '/app/engagement/#/overview',
    heading: 'Try Engagement Survey for free for 60 days ',
  },
  {
    features: [Features.Wellbeing],
    description: 'Allow employees can collaborate with one another and People teams can send out important information',
    image: wellbeingImage,
    redirectUrl: '/app/well-being/#/overview',
    heading: 'Try People Hub for free for 60 days ',
  },
  {
    features: [Features.Compensation],
    description: 'Align compensation practices with business goals to make improved decisions on employee pay.',
    image: compensationManagementImage,
    redirectUrl: '/dashboard/#/compensation-management-v3',
    heading: 'Try Compensation Management for free for 60 days ',
  },
];

type ModalContentMatrix = {
  [name: string]: MatrixContent;
};

type MatrixContent = {
  videoUrl?: string;
  pendoGuideId: string;
  summary: JSX.Element;
};

export const generateMicrotrialWelcomeModalContent = (role: string) => {
  const modalContentMatrix: ModalContentMatrix = {
    fullCompanyAdmin: {
      videoUrl: 'https://www.youtube.com/embed/qium1nrDQ-U',
      pendoGuideId: 'E-JYuOrUGORWZ2zH4z3oI41gl7s',
      summary: (
        <>
          <TextBlock mb={2}>
            Starting today, your organization will have 60 days to explore Performance Management for free.
          </TextBlock>
          <TextBlock>
            <Link href="https://training.zenefits.com/path/performance-management" target="_blank">
              Learn how to use the Performance Management tools
            </Link>
          </TextBlock>
        </>
      ),
    },
    manager: {
      videoUrl: 'https://www.youtube.com/embed/qium1nrDQ-U',
      pendoGuideId: 'E-JYuOrUGORWZ2zH4z3oI41gl7s',
      summary: (
        <>
          <TextBlock mb={2}>
            See how you can be a better support for your direct reports by creating goals, one-on-ones and reviews.
          </TextBlock>
          <TextBlock>
            <Link href="https://training.zenefits.com/path/manager-onboarding-learning-path-growth" target="_blank">
              Get the most out of Performance Management as a manager
            </Link>
          </TextBlock>
        </>
      ),
    },
    employee: {
      pendoGuideId: '8b7bjRu6tbGrdhhiB6L0Rm7_LB8',
      summary: (
        <>
          <TextBlock mb={2}>
            See how you can better work with your manager by creating goals and tracking one-on-ones and reviews.
          </TextBlock>
          <TextBlock>
            <Link href="https://training.zenefits.com/path/employee-onboarding-learning-path-growth" target="_blank">
              Get the most out of Performance Management as an employee
            </Link>
          </TextBlock>
        </>
      ),
    },
  };

  const welcomeModalContent: MicroTrialWelcomeModalProps = {
    microTrialKey: 'performanceManagement',
    microTrialName: 'Performance Management',
    content: {
      summary: modalContentMatrix[role].summary,
      video: modalContentMatrix[role].videoUrl,
    },
    primaryButton: {
      title: 'Start Tour Now',
      pendoGuideId: modalContentMatrix[role].pendoGuideId,
    },
  };

  return welcomeModalContent;
};
