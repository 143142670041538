import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import moment from 'moment';

import { Banner } from 'z-frontend-composites';
import { useNotifications, Button, Link, LinkButton } from 'z-frontend-elements';
import { DialogManager, Modal } from 'z-frontend-overlays';
import { Flex, Heading, TextBlock, TextInline } from 'zbase';
import { checkPermission, usePermissions, useQueryWithProgress, RolePermissionGrant } from 'z-frontend-network';

import { AgreeWithLifeTimeAgreement, HasAdminAgreedWithContractAgreement } from '../gqlTypes';

const PayrollCorrectionsBannerContainer: React.FunctionComponent = () => {
  const source = 'pay_cor';
  const skipModalKey = 'skip_payroll_correction_modal';
  const payrollTaxTipsUrl = '/app/payroll/#/correction-types';

  const setWithExpiry = (key: string, value: string, ttl: number) => {
    const now = moment().toDate();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = moment().toDate();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  const PAY_COR_BANNER_TTL = 24 * 60 * 60 * 1000; // 1 day
  const isSkipAllowed = moment().toDate() < moment('2022/01/01', 'YYYY/MM/DD').toDate();
  const isSkipped = isSkipAllowed && getWithExpiry(skipModalKey) === 'true';

  const [showModal, setShowModal] = useState<boolean>(!isSkipped);
  const { openNotification } = useNotifications();
  const [agreeMutation] = useMutation<AgreeWithLifeTimeAgreement.Mutation, AgreeWithLifeTimeAgreement.Variables>(
    agreeWithAgreementMutation,
  );
  const { Loading, QueryError, data } = useQueryWithProgress<HasAdminAgreedWithContractAgreement.Query>(
    hasAdminAgreedQuery,
    {
      showInlineLoading: false,
      variables: { source },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  );

  const { permissions } = usePermissions();
  const fullOrPayrollAdminPermission = ['is_main_admin', 'is_full_company_admin', 'is_payroll_admin'];
  const isFullOrPayrollAdmin = fullOrPayrollAdminPermission.some((requestedPermission: RolePermissionGrant) =>
    checkPermission(permissions, requestedPermission),
  );

  if (!isFullOrPayrollAdmin) {
    return null;
  }
  if (Loading || QueryError) {
    return null;
  }
  if (!data.dashboard.company.isLiveOnZenefitsPayroll || data.hasAdminAgreedWithContractAgreement) {
    return null;
  }

  const agree = async () => {
    await agreeMutation({
      variables: { source },
      refetchQueries: [
        {
          query: hasAdminAgreedQuery,
          variables: {
            source,
          },
        },
      ],
    });
    openNotification('Thank you for the acknowledgment.');
  };

  return (
    <>
      <Banner isClosable={false} type="info">
        <TextBlock>
          <TextInline bold>Payroll Accuracy Matters </TextInline>
          To ensure you avoid bank and manual processing costs, please see our{' '}
          <Link href={payrollTaxTipsUrl}>Payroll Tax Tips</Link>. Charges will apply to relevant cases created on or
          after January 1st 2022. <Link onClick={() => setShowModal(true)}>Learn More</Link>
        </TextBlock>
      </Banner>
      {showModal && (
        <DialogManager
          openByDefault
          render={dialog => {
            return (
              <>
                <Modal
                  title="Payroll Accuracy Matters"
                  size="small"
                  onCancel={dialog.close}
                  isVisible={dialog.isVisible}
                  renderHeader={(_, headingRef) => {
                    return (
                      <Modal.Header>
                        <Heading level={5} elementRef={headingRef} tabIndex={-1}>
                          Payroll Accuracy Matters
                        </Heading>
                      </Modal.Header>
                    );
                  }}
                >
                  <Modal.Body>
                    <TextBlock>
                      Did you know that when your data like pay history, hourly pay rates, and available payroll funds
                      are not updated and correct it can result in additional charges from your tax agencies, banking
                      partners as well as other fees? To ensure you avoid this, please see our{' '}
                      <Link href={payrollTaxTipsUrl}>Payroll Tax Tips</Link>. Should you still have an issue, we can
                      provide additional services to assist. Please note, charges will apply to such additional service
                      cases created on or after January 1, 2022.
                    </TextBlock>
                    <br />
                    <TextBlock>
                      Click <TextInline bold>I Understand</TextInline>, to confirm you're informed.
                    </TextBlock>
                  </Modal.Body>
                  <Modal.Footer>
                    <Flex justify="space-between">
                      {isSkipAllowed && (
                        <LinkButton
                          onClick={() => {
                            dialog.close();
                            setShowModal(false);
                            setWithExpiry(skipModalKey, 'true', PAY_COR_BANNER_TTL);
                          }}
                          mr={3}
                        >
                          I'll do this later
                        </LinkButton>
                      )}
                      <Button mode="primary" onClick={agree}>
                        I understand
                      </Button>
                    </Flex>
                  </Modal.Footer>
                </Modal>
              </>
            );
          }}
        />
      )}
    </>
  );
};

const agreeWithAgreementMutation = gql`
  mutation AgreeWithLifeTimeAgreement($source: String!) {
    agreeWithLifeTimeAgreement(source: $source)
  }
`;

const hasAdminAgreedQuery = gql`
  query HasAdminAgreedWithContractAgreement($source: String!) {
    dashboard {
      id
      company {
        id
        isLiveOnZenefitsPayroll
      }
    }
    hasAdminAgreedWithContractAgreement(source: $source)
  }
`;

export default PayrollCorrectionsBannerContainer;
