import React, { MouseEvent } from 'react';

import { Flex, Image, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';
import { ButtonDropdown, IconButton } from 'z-frontend-elements';

const defaultIconUrl = 'https://secure.zenefits.com/static/img/trinet-small-logo-202311.svg';

const StyledFlex = styled(Flex)<{ isRead: boolean }>`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${color('secondary.c')};
  }
  border-left: solid ${color('primary.a')};
  ${props => props.isRead && 'border-left-color: transparent'};
`;

const StyledLeftContent = styled(Flex)`
  min-width: 0;
`;

const ImageContainer = styled(Flex)`
  align-self: flex-start;
`;

type InboxActionItemProps = {
  /**
   * Text of the action item
   */
  text: string;
  /**
   * Url of the icon for the item.
   * @default 'https://secure.zenefits.com/static/img/trinet-small-logo-202311.svg'
   */
  iconUrl?: string;
  /**
   * Whether the item has been read or not.
   * @default false
   */
  isRead?: boolean;
  /**
   * Click event handler for the action item itself.
   */
  onClick: (e?: any) => any;
  /**
   * Click event handler for the dismiss button in dropdown.
   * The dismiss button will not show if this prop is undefined.
   */
  onDismissButtonClick?: (event: MouseEvent) => void;
  /**
   * Whether to show the View button in dropdown
   * @default false
   */
  showViewAction?: boolean;
  /**
   * Whether to show the Details button in dropdown
   * @default false
   */
  showDetailsAction?: boolean;
  /**
   * Click event handler for the details button in dropdown.
   * The details button will not show if this prop is undefined.
   */
  onDetailsButtonClick?: (event: MouseEvent) => void;
};

function InboxActionItem(props: InboxActionItemProps) {
  const { text, iconUrl, isRead, onClick, onDismissButtonClick, showViewAction, onDetailsButtonClick } = props;
  const showDismissAction = !!onDismissButtonClick;
  const showDetailsAction = props.showDetailsAction && !!onDetailsButtonClick;
  const showButtonDropdown = showViewAction || showDismissAction || showDetailsAction;

  return (
    <Flex border>
      <StyledFlex
        bg="grayscale.white"
        align="center"
        justify={showButtonDropdown ? 'space-between' : 'flex-start'}
        isRead={isRead}
        data-testid="InboxActionItemCardHeader"
        onClick={onClick}
      >
        <StyledLeftContent align="center" py={2}>
          <ImageContainer px={2} flex="0 0 auto">
            <Image src={iconUrl} alt="" width={30} maxHeight={35} />
          </ImageContainer>

          <TextBlock px={1} fontStyle="paragraphs.m" bold={!isRead}>
            {text}
          </TextBlock>
        </StyledLeftContent>
        {showButtonDropdown && (
          <ButtonDropdown
            target={<IconButton iconName="more-vert" iconSize="medium" color="grayscale.d" />}
            popperPlacement="bottom-end"
          >
            {showViewAction && <ButtonDropdown.ItemButton onClick={onClick}>View</ButtonDropdown.ItemButton>}
            {showDismissAction && (
              <ButtonDropdown.ItemButton onClick={onDismissButtonClick}>Archive</ButtonDropdown.ItemButton>
            )}
            {showDetailsAction && (
              <ButtonDropdown.ItemButton onClick={onDetailsButtonClick}>Details</ButtonDropdown.ItemButton>
            )}
          </ButtonDropdown>
        )}
      </StyledFlex>
    </Flex>
  );
}

InboxActionItem.defaultProps = {
  iconUrl: defaultIconUrl,
};

export default InboxActionItem;
