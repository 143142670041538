import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from 'react-apollo';
import { produce } from 'immer';

import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { Query } from 'z-frontend-network';
import { Box } from 'zbase';

import { InboxActionPartialQuery, IncrementInboxActionViews } from '../gqlTypes';
import InboxActionList from './InboxActionList';
import { InboxActionPartial, ZApp } from '../../schema/schemaTypes';
import { INBOX_STATUS_CHOICES } from './constants';

type InboxActionContainerProps = {
  apps: ZApp[];
  fetchAll?: boolean;
  showViewAction?: boolean;
  showDismissAction?: boolean;
  hideHeader?: boolean;
  title?: string;
  emptyMessage?: string;
  actionFilter?: (action: InboxActionPartial) => boolean;
};

export const incrementInboxActionMutationOptions: MutationHookOptions<
  IncrementInboxActionViews.Mutation,
  IncrementInboxActionViews.Variables
> = {
  // update InboxActionPartial cache so that read status changes immediately
  update(cache, { data: { incrementInboxActionViews } }) {
    const modifiedId = incrementInboxActionViews.id;
    const { inboxActionPartials, dashboard } = cache.readQuery<InboxActionPartialQuery.Query>({
      query: inboxActionPartialQuery,
    });

    const newInboxPartials = produce(inboxActionPartials, (draft: any[]) => {
      const inboxPartialToUpdate = draft.find(
        (partial: InboxActionPartialQuery.InboxActionPartials) => partial.id === modifiedId,
      );
      inboxPartialToUpdate.viewCount = 1 + incrementInboxActionViews.viewCount;
    });
    cache.writeQuery<InboxActionPartialQuery.Query>({
      query: inboxActionPartialQuery,
      data: { dashboard, inboxActionPartials: newInboxPartials },
    });
  },
};

const InboxActionContainer: FunctionComponent<InboxActionContainerProps> = props => {
  const [incrementInboxActionViews] = useMutation<
    IncrementInboxActionViews.Mutation,
    IncrementInboxActionViews.Variables
  >(incrementInboxActionViewsMutation, incrementInboxActionMutationOptions);

  const { actionFilter } = props;

  return (
    <ErrorBoundary FallbackComponent={() => null}>
      <Query<InboxActionPartialQuery.Query> query={inboxActionPartialQuery} handleLoading={false} isBackgroundQuery>
        {({ data, loading, refetch }) => {
          if (loading || !data) {
            return null;
          }
          let filteredActions: InboxActionPartialQuery.InboxActionPartials[] = [];
          if (data.inboxActionPartials?.length > 0) {
            filteredActions = data.inboxActionPartials
              .filter(actionFilter)
              .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
          }

          if (filteredActions.length === 0 && props.emptyMessage) {
            return (
              <Box p={3} mt={1} border bg="grayscale.white" color="grayscale.e">
                {props.emptyMessage}
              </Box>
            );
          }

          return (
            <InboxActionList
              actions={filteredActions}
              apps={props.apps}
              refreshList={refetch}
              currentEmployeeId={data.dashboard.employee.id}
              onActionView={incrementInboxActionViews}
              fetchAll={props.fetchAll}
              showViewAction={props.showViewAction}
              showDismissAction={props.showDismissAction}
              hideHeader={props.hideHeader}
              title={props.title}
            />
          );
        }}
      </Query>
    </ErrorBoundary>
  );
};

export const inboxActionPartialQuery = gql`
  query InboxActionPartialQuery {
    dashboard {
      id
      employee {
        id
      }
    }
    inboxActionPartials {
      id
      isForDashboard
      requestDate
      status
      titleWithNamesAndVariables
      type
      viewCount
      zAppId
      tag
      templateTags
    }
  }
`;

export const incrementInboxActionViewsMutation = gql`
  mutation IncrementInboxActionViews($id: ID!) {
    incrementInboxActionViews(actionId: $id) {
      id
      viewCount
    }
  }
`;

InboxActionContainer.defaultProps = {
  actionFilter: (action: InboxActionPartial) => action.isForDashboard && action.status === INBOX_STATUS_CHOICES.SENT,
};
export default InboxActionContainer;
