import React from 'react';

import { Box, TextBlock, TextInline } from 'zbase';
import { isAndroidMobile, isIPhone } from 'z-frontend-app-bootstrap';
import { Banner } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { useSwitches } from 'z-frontend-network';

const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.zenefits.android.apps.people';
const appStoreUrl = 'https://apps.apple.com/us/app/zenefits/id1026354905';

/**
 * This component is used for mobile demo experiment.
 * It renders a banner on iPhone or Android mobile devices.
 * The banner has a link which opens either the App Store or Google Play.
 */
const MobileAppBanner: React.FunctionComponent = () => {
  const switches = useSwitches();

  if (!switches?.mobile_demo_experiment) {
    return null;
  }

  let href = '';

  if (isIPhone()) {
    href = appStoreUrl;
  } else if (isAndroidMobile()) {
    href = googlePlayUrl;
  }

  if (!href) {
    return null;
  }

  return (
    <Box mb={3}>
      <Banner type="info" showIcon={false} isClosable={false}>
        <TextBlock>
          Check out our app for a better mobile experience.{' '}
          <Link href={href} target="_blank" data-testid="OpenZenefitsAppLink">
            Open <TextInline textKey="brandName" /> App
          </Link>
        </TextBlock>
      </Banner>
    </Box>
  );
};

export default MobileAppBanner;
