import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from 'react-apollo';
import { produce } from 'immer';

import { Query } from 'z-frontend-network';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { InboxActionList } from 'z-frontend-layout';

import { InboxActionPartialQuery, IncrementInboxActionViews } from '../../gqlTypes';
import { ZApp } from '../../../schema/schemaTypes';
import SocialFeedForDashboard from '../people-hub/SocialFeedForDashboard';
import { INBOX_STATUS_CHOICES } from './constants';

type InboxActionContainerProps = {
  apps: ZApp[];
  canViewSocialFeed?: boolean;
};

const mutationOptions: MutationHookOptions<IncrementInboxActionViews.Mutation, IncrementInboxActionViews.Variables> = {
  // update InboxActionPartial cache so that read status changes immediately
  update(cache, { data: { incrementInboxActionViews } }) {
    const modifiedId = incrementInboxActionViews.id;
    const { inboxActionPartials, dashboard } = cache.readQuery<InboxActionPartialQuery.Query>({
      query: inboxActionPartialQuery,
    });

    const newInboxPartials = produce(inboxActionPartials, draft => {
      const inboxPartialToUpdate = draft.find(
        (partial: InboxActionPartialQuery.InboxActionPartials) => partial.id === modifiedId,
      );
      inboxPartialToUpdate.viewCount = 1 + incrementInboxActionViews.viewCount;
    });
    cache.writeQuery<InboxActionPartialQuery.Query>({
      query: inboxActionPartialQuery,
      data: { dashboard, inboxActionPartials: newInboxPartials },
    });
  },
};

const InboxActionContainer: FunctionComponent<InboxActionContainerProps> = props => {
  const [incrementInboxActionViews] = useMutation<
    IncrementInboxActionViews.Mutation,
    IncrementInboxActionViews.Variables
  >(incrementInboxActionViewsMutation, mutationOptions);
  return (
    <ErrorBoundary FallbackComponent={() => null}>
      <Query<InboxActionPartialQuery.Query> query={inboxActionPartialQuery} handleLoading={false} isBackgroundQuery>
        {({ data, loading, refetch }) => {
          let filteredActions: InboxActionPartialQuery.InboxActionPartials[] = [];
          if (data && data.inboxActionPartials && data.inboxActionPartials.length > 0) {
            filteredActions = data.inboxActionPartials
              .filter(action => action.isForDashboard && action.status === INBOX_STATUS_CHOICES.SENT)
              .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
          }
          return !loading && data && filteredActions.length > 0 ? (
            <>
              <InboxActionList
                actions={filteredActions}
                apps={props.apps}
                refreshList={refetch}
                currentEmployeeId={data.dashboard.employee.id}
                onActionView={incrementInboxActionViews}
                canViewSocialFeed={props.canViewSocialFeed}
              />
              {props.canViewSocialFeed && <SocialFeedForDashboard hasInboxActions={filteredActions.length > 0} />}
            </>
          ) : props.canViewSocialFeed ? (
            <SocialFeedForDashboard hasInboxActions={false} />
          ) : null;
        }}
      </Query>
    </ErrorBoundary>
  );
};

const inboxActionPartialQuery = gql`
  query InboxActionPartialQuery {
    dashboard {
      id
      employee {
        id
      }
    }
    inboxActionPartials {
      id
      isForDashboard
      requestDate
      status
      titleWithNamesAndVariables
      type
      viewCount
      zAppId
    }
  }
`;

const incrementInboxActionViewsMutation = gql`
  mutation IncrementInboxActionViews($id: ID!) {
    incrementInboxActionViews(actionId: $id) {
      id
      viewCount
    }
  }
`;

export default InboxActionContainer;
