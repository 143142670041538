import React, { StatelessComponent } from 'react';

import { Modal } from 'z-frontend-overlays';
import { Flex } from 'zbase';
import { LinkButton } from 'z-frontend-elements';

type ConfirmationSubActionFooterProps = {
  showCannotCompleteButton?: boolean;
  isCompleteInProgress?: boolean;
  onClickCannotComplete: () => void;
  ctaButtons: React.ReactNode[];
};

const ConfirmationSubActionFooter: StatelessComponent<ConfirmationSubActionFooterProps> = ({
  showCannotCompleteButton,
  isCompleteInProgress,
  onClickCannotComplete,
  ctaButtons,
}) => {
  return (
    <Modal.Footer borderTop mt={4}>
      <Flex justify="space-between" align="center">
        <Flex>
          {showCannotCompleteButton && (
            <LinkButton disabled={isCompleteInProgress} s="medium" onClick={onClickCannotComplete}>
              Cannot complete this request
            </LinkButton>
          )}
        </Flex>
        <Flex justify="flex-end">{ctaButtons}</Flex>
      </Flex>
    </Modal.Footer>
  );
};

export default ConfirmationSubActionFooter;
