import gql from 'graphql-tag';

import { useQueryWithProgress } from 'z-frontend-network';

import { VERBOSE_FEATURE_NAMES } from '../constants';

import { ListTrialFeedbackQuery, TrialFeedbackType } from '../gqlTypes';

export const getMicroTrialFeaturesVerboseName = (features: string[]) => {
  const featureVerboseNames = features.map(feature => VERBOSE_FEATURE_NAMES[feature]);
  const lastFeature = featureVerboseNames.pop();
  if (featureVerboseNames.length === 0) return lastFeature;
  return `${featureVerboseNames.join(', ')} and ${lastFeature}`;
};

export const doFeatureListsMatch = (featureList1: string[], featureList2: string[]) => {
  return JSON.stringify(featureList1.sort()) === JSON.stringify(featureList2.sort());
};

const listTrialFeedbackQuery = gql`
  query ListTrialFeedbackQuery($trialType: TrialFeedbackType!) {
    dashboard {
      id
      company {
        id
        trialFeedback(trialType: $trialType) {
          id
          trialType
          employeeLastName
          employeeFirstName
          feedback
          employee_id
          company_id
        }
      }
    }
  }
`;

export const useTrialFeedback = (trialType: TrialFeedbackType) => {
  return useQueryWithProgress<ListTrialFeedbackQuery.Query, ListTrialFeedbackQuery.Variables>(listTrialFeedbackQuery, {
    variables: { trialType },
  });
};
