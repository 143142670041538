import React, { useState, FunctionComponent } from 'react';

import { BoxProps } from 'zbase';

import Card from '../card/Card';

export type AccordionContextValues = {
  isExpanded: boolean;
  toggleExpanded: () => void;
};

export const AccordionContext = React.createContext<AccordionContextValues>({
  isExpanded: false,
  toggleExpanded: () => {},
});

export type AccordionProps = Omit<BoxProps, 'onChange'> & { onChange?: (isExpanded: boolean) => void };

const Accordion: FunctionComponent<AccordionProps> = props => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { onChange, ...rest } = props;

  const toggleExpanded = () => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    if (props.onChange) {
      props.onChange(newIsExpanded);
    }
  };

  return (
    <AccordionContext.Provider
      value={{
        isExpanded,
        toggleExpanded,
      }}
    >
      <Card data-testid="Accordion" {...rest} />
    </AccordionContext.Provider>
  );
};

export default Accordion;
