import React, { Component } from 'react';
import moment from 'moment';

import { Avatar } from 'z-frontend-composites';
import { Ellipsis, IconButton, ReadMore } from 'z-frontend-elements';
import { Box, Flex, Heading, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';
import { InformationPopover } from 'z-frontend-overlays';

import PostText from '../post-text/PostText';
import VisibilityPopoverCard from './VisibilityPopoverCard';

const EMPLOYEE_TERMINATED_STATUS = 'Ter';
const TIME_UPDATE_INTERVAL_IN_MILLI_SECONDS = 60000; // 1 minute
const customLocale = 'en-relativeTime';

const originalLocale = moment.locale();
moment.defineLocale(customLocale, {
  parentLocale: 'en',
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'now',
    ss: 'now',
    m: '1min',
    mm: '%dmin',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});
// Reset locale because moment.defineLocale() changes the global locale to the newly defined.
moment.locale(originalLocale);

const StyledIconButton = styled(IconButton)`
  padding-top: 0;
  @media (max-width: ${props => props.theme.breakpoints[1]}em) {
    padding: 0;
    min-height: 0;
  }
`;

const LightEllipsis = styled(Ellipsis)`
  margin-top: 3px;
  color: ${color('grayscale.d')};
`;

export type Author = {
  id?: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  title?: string;
  status?: string;
};

interface TaggedEmployee {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

type Permission = {
  canEdit: boolean;
  canDelete: boolean;
};

type PeopleHubAnnouncementPostProps = {
  postId?: string;
  createdAt: string;
  createdBy: Author;
  description: string;
  employees?: TaggedEmployee[];
  permission?: Permission;
};

type EmployeeDetail = {
  label: string;
  tooltipText?: string;
};

type State = {
  createdAt: string;
};

class PeopleHubAnnouncementPost extends Component<PeopleHubAnnouncementPostProps, State> {
  timeUpdateInterval: number;

  constructor(props: PeopleHubAnnouncementPostProps) {
    super(props);
    this.state = {
      createdAt: moment(this.props.createdAt)
        .locale(customLocale)
        .fromNow(),
    };
  }
  componentWillUnmount() {
    clearInterval(this.timeUpdateInterval);
  }

  componentDidMount() {
    this.timeUpdateInterval = setInterval(() => {
      this.setState({
        createdAt: moment(this.props.createdAt)
          .locale(customLocale)
          .fromNow(),
      });
    }, TIME_UPDATE_INTERVAL_IN_MILLI_SECONDS);
  }

  render() {
    const { description, createdBy, employees, permission, postId } = this.props;
    const mentionsMap: { [key: string]: EmployeeDetail } = {};
    if (employees) {
      employees.forEach(
        m =>
          (mentionsMap[m.id] = {
            label: `${m.firstName} ${m.lastName}`,
            tooltipText: `${m.firstName} ${m.lastName}\n${m.email}`,
          }),
      );
    }

    return (
      <Flex border={false} bg="grayscale.white">
        <Avatar firstName={createdBy.firstName} lastName={createdBy.lastName} photoUrl={createdBy.photoUrl} s="large" />
        <Flex column>
          <Flex column ml={4}>
            <Flex>
              <Heading level={4} fontStyle="controls.l">
                {createdBy.firstName} {createdBy.lastName}
              </Heading>
              {createdBy.status === EMPLOYEE_TERMINATED_STATUS ? (
                <TextBlock color="grayscale.d" ml={1} fontStyle={['paragraphs.s', 'paragraphs.s', 'paragraphs.m']}>
                  (Terminated)
                </TextBlock>
              ) : (
                <Flex align="center" w={[100, 150, 180, 250]}>
                  <TextBlock color="grayscale.e" fontStyle="paragraphs.s" px={1} pt={1}>
                    &bull;
                  </TextBlock>
                  <LightEllipsis fontStyle="paragraphs.s">{createdBy.title}</LightEllipsis>
                </Flex>
              )}
            </Flex>
            <Flex>
              <TextBlock color="grayscale.d" pr={1} fontStyle="paragraphs.s" pt={[0, 0, '2px']}>
                {this.state.createdAt}
              </TextBlock>
              {permission && permission.canEdit && (
                <>
                  <TextBlock color="grayscale.e" fontStyle="paragraphs.s" px={1} pt={[0, 0, '2px']}>
                    &bull;
                  </TextBlock>
                  <InformationPopover
                    showArrow
                    placement="right"
                    title="Visibility"
                    targetBody={
                      <StyledIconButton ml={0} iconName="accounts" color="grayscale.d" s="medium" mode="transparent" />
                    }
                  >
                    <VisibilityPopoverCard postId={postId} />
                  </InformationPopover>
                </>
              )}
            </Flex>
          </Flex>
          <Box ml={4} mt={2}>
            <ReadMore
              isExpandControlHiddenOnResize
              lines={2}
              expandedText={
                <PostText text={description} whiteSpace="pre-wrap" wordBreak="break-word" mentions={mentionsMap} />
              }
            >
              <PostText
                display="inline"
                whiteSpace="pre-wrap"
                wordBreak="break-word"
                text={description}
                mentions={mentionsMap}
              />
            </ReadMore>
          </Box>
        </Flex>
      </Flex>
    );
  }
}

export default PeopleHubAnnouncementPost;
