import {
  ISO_COUNTRIES,
  ISO_COUNTRY,
  NEW_SUPPORTED_COUNTRY,
  NEW_SUPPORTED_COUNTRY_INCLUDING_SANCTIONED,
} from 'z-address/addon/constants/work-location';

type CountryList = LabelValue[];
type LabelValue = { label: string; value: string };
type NameToCodeMap = { [name: string]: string };

function transformToLabelList(items: { code: string; name: string }[]) {
  return items.map(item => ({ label: item.name, value: item.code }));
}

// from https://github.com/zenefits/z-address/blob/master/addon/constants/work-location.js
export const ALL_COUNTRIES: CountryList = transformToLabelList(ISO_COUNTRY);

export const ZENEFITS_SUPPORTED_COUNTRIES = transformToLabelList(NEW_SUPPORTED_COUNTRY);

export const ZENEFITS_SUPPORTED_COUNTRIES_INCLUDING_SANCTIONED = transformToLabelList(
  NEW_SUPPORTED_COUNTRY_INCLUDING_SANCTIONED,
);

/**
 * {
 *  'United States': 'USA',
 *  'Canada': 'CAN',
 *  ...
 * }
 */
export const COUNTRY_NAME_TO_CODE_MAP: NameToCodeMap = ISO_COUNTRIES.reduce(
  (acc: NameToCodeMap, country: { name: string; alpha2Code: string; alpha3Code: string }) => {
    acc[country.name] = country.alpha3Code;
    return acc;
  },
  {},
);
