import React, { StatelessComponent } from 'react';

import { Box, Flex, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { Link } from 'z-frontend-elements';

import { InboxAttachment } from '../../../schema/schemaTypes';

const AttachmentLink = styled(Link)`
  line-height: 1.25; /* match label */
`;

const InboxAttachments: StatelessComponent<{ attachments: InboxAttachment[] }> = ({ attachments }) => {
  if (!attachments || !attachments.length) {
    return null;
  }
  return (
    <>
      <Flex my={4} px={4}>
        <TextBlock fontStyle="controls.l" whiteSpace="nowrap" mr={3}>
          Attached files:
        </TextBlock>
        <Box>
          {attachments.map(attachment => (
            <Box key={attachment.id} mb={1}>
              <AttachmentLink href={attachment.url} target="_blank">
                {attachment.filename}
              </AttachmentLink>
            </Box>
          ))}
        </Box>
      </Flex>
    </>
  );
};

export default InboxAttachments;
