import React, { Component } from 'react';

import { Flex, TextBlock } from 'zbase';
import { Avatar } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';

import { getDashboardUrl } from './workerUtil';

// TODO: convert to actual button
class SeeAllWorkersButton extends Component {
  render() {
    const dashboardUrl = getDashboardUrl(window.location.href);
    const directoryUrl = `${dashboardUrl}employeedirectory`;
    return (
      <Link href={directoryUrl}>
        <Flex mt={3} column align="center">
          <Avatar s="xlarge" mb={2} firstName="…" bg="link.normal" />
          <TextBlock fontStyle="paragraphs.m">See All</TextBlock>
        </Flex>
      </Link>
    );
  }
}

export default SeeAllWorkersButton;
