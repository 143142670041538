export const INBOX_STATUS_CHOICES = {
  CREATED: 'created',
  SENT: 'sent',
  COMPLETED: 'completed',
  CANNOT_COMPLETE: 'cannot_complete',
  SNOOZED: 'snoozed',
  DELETED: 'deleted',
  CANCELED: 'canceled',
  PENDING_APPROVAL: 'pending_approval',
};

export const INBOX_EMAIL_STATUS_CHOICES = {
  SENT: 'sent',
  READ: 'read',
  ARCHIVED: 'archived',
};

export const INBOX_ACTION_TYPES = {
  TASK: 'task',
  EMAIL: 'email',
};
