import { cloneDeep, get, set } from 'lodash';

import { Subscription } from '../types';
import { parseZenefitsUrl, ParsedZenefitsUrl } from '../utils';
import { MAINTENANCE, ZAppStatusEnum } from '../constants';

export interface Button {
  title?: string;
  isHighlighted?: boolean;
  linkTo?: ParsedZenefitsUrl;
  args?: Object;
}

export class DashboardApp {
  subscription: Subscription;

  switches: any;

  showIfSwitchEnabled: string;

  constructor(subscription: Subscription, switches: any) {
    // Cloning because subscriptions are marked as read-only upstream;
    const mutableSubscription = cloneDeep(subscription) as Subscription;

    // Unclear if these properties are guaranteed on subscription objects
    // Setting them to make lookups safe
    ['preferences', 'appInstall.preferences', 'appInstall.app.preferences'].forEach(objPath =>
      set(mutableSubscription, objPath, get(mutableSubscription, objPath) || {}),
    );
    this.subscription = mutableSubscription;
    this.switches = switches;
  }

  buttons(): Button[] {
    const zUrl = parseZenefitsUrl(this.subscription.appInstall.app.appUrl);

    return [
      {
        title: 'View',
        isHighlighted: false,
        linkTo: zUrl, // this is different than ember...
        args: typeof zUrl === 'string' ? {} : zUrl.params,
      },
    ];
  }

  uniqueId() {
    return this.subscription.appInstall.app.uniqueId;
  }

  title() {
    return this.subscription.appInstall.app.shortTitle;
  }

  appIconSqUrl() {
    return this.subscription.appInstall.app.appIconSqUrl;
  }

  status() {
    return this.subscription.appInstall.app.status;
  }

  inheritedStatus() {
    return this.subscription.inheritedStatus;
  }

  isUnderMaintenance() {
    return this.subscription.appInstall.app.status === MAINTENANCE;
  }

  secondaryLinks(): any[] {
    return [];
  }

  isChosen() {
    this.subscription.inheritedStatus === ZAppStatusEnum.OK;
  }

  cleanNote(note: any) {
    if (!note || !note.comp || !note.ctx) {
      return null;
    }
    return note;
  }

  notification() {
    // DO NOT OVERRIDE. Use ZAppNotificationService::create().
    return this.subscription.preferences && this.cleanNote(this.subscription.preferences._notif);
  }

  chad() {
    // DO NOT OVERRIDE. Use ZAppActionService::create().
    return this.subscription.preferences && this.cleanNote(this.subscription.preferences._action);
  }

  maintenanceMessage() {
    return (
      this.subscription.appInstall.app.preferences._maintenanceMessage ||
      'This app is temporarily under maintenance. Please try again later.'
    );
  }
}
