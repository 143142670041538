import React from 'react';

import { Flex, Heading, Image, TextInline } from 'zbase';
import { Link } from 'z-frontend-elements';

const image = require('./quickstart-avatar-images/video-guide-1.svg');

export const LearnToUseZenefits: React.FunctionComponent<{}> = props => {
  return (
    <Flex column mb={5} p={0} align={['center', 'center', 'center', 'start']}>
      <Heading level={6} mb={2} color="text.light">
        Learn to use <TextInline textKey="brandName" />
      </Heading>
      <Link href="https://training.zenefits.com/page/accelerate" target="_blank">
        <Image alt="Video preview" src={image} />
      </Link>
    </Flex>
  );
};
