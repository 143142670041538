import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import moment from 'moment';

import { checkPermission, usePermissions, useSwitches } from 'z-frontend-network';

import { MicroTrialSubscriptions } from '../gqlTypes';

const microTrialSubscriptionQuery = gql`
  query MicroTrialSubscriptions {
    microTrialSubscriptions {
      id
      companyId
      microTrialId
      status
      features
      expiresOn
    }
    dashboard {
      id
      features
    }
  }
`;

const MICRO_TRIALS_HOOK_SWITCH = 'plg_experiment_micro_trial_subscriptions';

enum MicroTrialSubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const useMicroTrialSubscription = () => {
  const switches = useSwitches();
  const { loading, data } = useQuery<MicroTrialSubscriptions.Query, MicroTrialSubscriptions.Variables>(
    microTrialSubscriptionQuery,
    {
      skip: !switches?.[MICRO_TRIALS_HOOK_SWITCH],
    },
  );
  const { permissions, permissionsLoaded } = usePermissions();

  if (loading || !permissionsLoaded || !switches) {
    return {
      loading: loading || !permissionsLoaded || !switches,
      canUpgrade: false,
      hasUpgraded: () => false,
      isMicroTrialFeatureActive: () => false,
      isMicroTrialFeatureExpired: () => true,
      getFeatureTrialExpiryDate: () => '',
    };
  }

  const canUpgrade = checkPermission(permissions, 'is_full_company_admin');

  const activeMicroTrialSubscriptions =
    data?.microTrialSubscriptions?.filter(
      microTrialSubscription => microTrialSubscription.status === MicroTrialSubscriptionStatus.ACTIVE,
    ) || [];
  let activeMicroTrialFeatures: string[] = [];
  activeMicroTrialSubscriptions.map(microTrialSubscription => {
    activeMicroTrialFeatures = [...activeMicroTrialFeatures, ...microTrialSubscription.features];
  });

  const inactiveMicroTrialSubscriptions =
    data?.microTrialSubscriptions?.filter(
      microTrialSubscription => microTrialSubscription.status === MicroTrialSubscriptionStatus.INACTIVE,
    ) || [];
  let inactiveMicroTrialFeatures: string[] = [];
  inactiveMicroTrialSubscriptions.map(microTrialSubscription => {
    inactiveMicroTrialFeatures = [...inactiveMicroTrialFeatures, ...microTrialSubscription.features];
  });

  const isMicroTrialFeatureActive = (feature: string) => activeMicroTrialFeatures.includes(feature);
  const isMicroTrialFeatureExpired = (feature: string) =>
    inactiveMicroTrialFeatures.includes(feature) && !isMicroTrialFeatureActive(feature);

  const getMicroTrialSubscriptionByFeature = (feature: string) => {
    /*
    We do not allow companies to have multiple active micro trials,
    so getting the first element should be ok
    */

    const activeSubscription = activeMicroTrialSubscriptions.find(microTrialSubscription =>
      microTrialSubscription.features.includes(feature),
    );
    if (activeSubscription) return activeSubscription;

    const inActiveSubscription = inactiveMicroTrialSubscriptions.find(microTrialSubscription =>
      microTrialSubscription.features.includes(feature),
    );
    if (inActiveSubscription) return inActiveSubscription;
  };

  const getFeatureTrialExpiryDate = (feature: string) => {
    const subscription = getMicroTrialSubscriptionByFeature(feature);
    const expiryDate = subscription?.expiresOn;
    return expiryDate && moment(expiryDate).format('MMMM D, YYYY');
  };

  const hasUpgraded = (feature: string) =>
    data?.dashboard?.features.includes(feature) &&
    !isMicroTrialFeatureActive('talent') &&
    isMicroTrialFeatureExpired('talent');

  return {
    loading,
    canUpgrade,
    hasUpgraded,
    isMicroTrialFeatureActive,
    isMicroTrialFeatureExpired,
    getFeatureTrialExpiryDate,
  };
};

export default useMicroTrialSubscription;
