import React, { Component } from 'react';

import { Query } from 'z-frontend-network';
import { BoxProps } from 'zbase';

import { DashboardRole } from '../types';
import CompanyContact from './CompanyContact';
import { CompanyContactQuery } from '../gqlTypes';
import BenefitsContacts from './contacts/BenefitsContacts';
import { companyContactsQuery } from '../initialQueries';
import { CONTRACTOR_PAYMENTS } from '../constants';

type ContactsContainerProps = {
  role: DashboardRole;
} & BoxProps;

export default class ContactsContainer extends Component<ContactsContainerProps> {
  render() {
    const { role, ...rest } = this.props;
    if (role !== DashboardRole.employee) {
      return null;
    }

    return (
      <Query<CompanyContactQuery.Query> query={companyContactsQuery} handleLoading={false}>
        {({ data, loading }) => {
          const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
          const isContractor = data?.dashboard?.employee?.employmentType === 'CO';
          const showBenefits = !(isContractorPaymentsCompany && isContractor);
          return (
            !loading && (
              <>
                <CompanyContact
                  name={data.dashboard.company.hrProxy.hrContactName}
                  email={data.dashboard.company.hrProxy.hrContactEmail}
                  mt={5}
                  {...rest}
                />
                {showBenefits && (
                  <>
                    <BenefitsContacts contacts={data.dashboard.company.benefitsContacts} mt={5} />
                  </>
                )}
              </>
            )
          );
        }}
      </Query>
    );
  }
}
