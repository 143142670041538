import React, { Component } from 'react';

import { NavBar } from 'z-frontend-layout';

import { DashboardRole } from '../types';

type DashboardModeToggleProps = {
  canViewAdmin: boolean;
  canViewEmployee: boolean;
  role: DashboardRole;
  onRoleChange: (role: DashboardRole) => void;
};

export default class DashboardModeToggle extends Component<DashboardModeToggleProps> {
  render() {
    const { canViewAdmin, canViewEmployee, role, onRoleChange } = this.props;
    if (!canViewAdmin || !canViewEmployee) {
      return null;
    }

    return (
      <NavBar useMobileDropdown={false} mode="product" data-testid="DashboardModeToggle">
        <NavBar.NavLink
          key="admin"
          active={role === DashboardRole.admin}
          as="button"
          onClick={e => {
            e.preventDefault();
            onRoleChange(DashboardRole.admin);
          }}
          className="js-walkme-dashboard-mode-selector-admin"
        >
          Admin
        </NavBar.NavLink>
        <NavBar.NavLink
          key="employee"
          active={role === DashboardRole.employee}
          as="button"
          onClick={e => {
            e.preventDefault();
            onRoleChange(DashboardRole.employee);
          }}
          className="js-walkme-dashboard-mode-selector-personal"
        >
          Personal
        </NavBar.NavLink>
      </NavBar>
    );
  }
}
