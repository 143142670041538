import React, { Component } from 'react';

import { Flex, TextBlock } from 'zbase';
import { Avatar } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';

import { getDashboardUrl } from './workerUtil';

// TODO: convert to actual button
class AddWorkerButton extends Component {
  render() {
    const dashboardUrl = getDashboardUrl(window.location.href);
    const hiringUrl = `${dashboardUrl}hiring/hireoradd/basicinfo`;
    return (
      <Link href={hiringUrl} aria-label="Add Worker">
        <Flex mr={[2, null, 0]} mt={3} column align="center">
          <Avatar s="xlarge" mb={2} firstName="+" bg="primary.a" />
          <TextBlock fontStyle="paragraphs.m">Add</TextBlock>
        </Flex>
      </Link>
    );
  }
}

export default AddWorkerButton;
