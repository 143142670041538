import React, { Component } from 'react';

import { Box, BoxProps, Heading, TextBlock } from 'zbase';
import { Link } from 'z-frontend-elements';

import { BenAdminContact } from '../../../schema/schemaTypes';

type BenefitsContactsProps = {
  contacts: BenAdminContact[];
} & BoxProps;

// from apps/benconnect/src/constants/commons.ts
enum CONTACT_TYPE {
  EMPLOYEE = 0,
  FULFILLMENT = 1,
}

export default class BenefitsContacts extends Component<BenefitsContactsProps> {
  render() {
    const { contacts, ...rest } = this.props;
    const employeeContacts = contacts.filter(contact => contact.contactTypes.includes(CONTACT_TYPE.EMPLOYEE));
    if (!employeeContacts.length) {
      return null;
    }

    return (
      <Box {...rest} textAlign={['center', null, 'left']}>
        <Heading level={6} color="text.light" mb={2}>
          Benefits Contacts
        </Heading>
        {employeeContacts.map((contact, idx) => (
          <Box key={`${contact.firstName}-${contact.lastName}`} pt={idx > 0 ? 4 : 0}>
            <TextBlock wordBreak="break-word" color="text.dark">
              {contact.preferredFirstName || contact.firstName} {contact.lastName}
            </TextBlock>
            {contact.email && (
              <TextBlock wordBreak="break-word">
                Email: <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
              </TextBlock>
            )}
            {contact.phone && (
              <TextBlock wordBreak="break-word">
                Phone:{' '}
                <Link href={`tel:${contact.phone}`}>
                  {contact.phone} {contact.phoneExtension && ` ext ${contact.phoneExtension}`}
                </Link>
              </TextBlock>
            )}
          </Box>
        ))}
      </Box>
    );
  }
}
