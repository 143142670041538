import React, { FunctionComponent, ReactNode } from 'react';

import { buildEmberRouteRedirect } from 'z-frontend-app-bootstrap';
import { Button, ButtonMode } from 'z-frontend-elements';

const BUTTON_NAMES = ['tertiary', 'secondary', 'primary'];

type RedirectLinkProps = {
  redirectUrl: string;
  companyId?: string;
};

const RedirectButton: FunctionComponent<RedirectLinkProps> = props => {
  const { redirectUrl, companyId, ...rest } = props;
  const isReactUrl = redirectUrl && redirectUrl.includes('/app/');
  let href;
  if (redirectUrl.match(/https?:\/\//)) {
    href = redirectUrl;
  } else if (isReactUrl) {
    href = redirectUrl;
  } else {
    // assume Ember route
    href = buildEmberRouteRedirect({ companyId, to: redirectUrl });
  }
  return <Button.Link href={href} {...rest} />;
};

export function getButtonsFromCtaInfo(ctaInfo: string, onActionClose: (value: boolean) => void, companyId?: string) {
  const buttonComponents: ReactNode[] = [];
  const parsedCtaInfo = JSON.parse(ctaInfo);

  BUTTON_NAMES.forEach(buttonName => {
    const buttonInfo = parsedCtaInfo[buttonName];
    if (!buttonInfo || (buttonInfo.hasOwnProperty('shouldDisplay') && !buttonInfo['shouldDisplay'])) {
      return;
    }

    const commonProps = {
      key: buttonName,
      s: 'small' as 'small',
      mode: buttonInfo.primaryColor ? ('primary' as ButtonMode) : undefined,
      ml: 2,
    };

    if (buttonInfo.shouldRedirect) {
      buttonComponents.push(
        <RedirectButton redirectUrl={buttonInfo.redirectUrl} companyId={companyId} {...commonProps}>
          {buttonInfo.buttonText || 'Click to begin'}
        </RedirectButton>,
      );
    } else if (buttonInfo.shouldCloseAction) {
      buttonComponents.push(
        <Button
          onClick={() => {
            onActionClose(buttonInfo.value);
          }}
          {...commonProps}
        >
          {buttonInfo.buttonText || 'Completed this task'}
        </Button>,
      );
    } else if (buttonName === 'primary') {
      // always show primary button of some kind
      buttonComponents.push(
        <Button
          onClick={() => {
            onActionClose(buttonInfo.value);
          }}
          {...commonProps}
        >
          {buttonInfo.buttonText || 'Done!'}
        </Button>,
      );
    }
  });

  return buttonComponents;
}

export function getCtaButtonPosition(
  isSubActionCompleted: boolean,
  isActionCompletedOrCannotComplete: boolean,
  isOnlyOneSubAction: boolean,
  hideOnlyOneSubActionCTA: boolean,
) {
  const showWithoutModalFooter = !isSubActionCompleted && !isOnlyOneSubAction;
  const showWithModalFooter =
    !isActionCompletedOrCannotComplete && !isSubActionCompleted && isOnlyOneSubAction && !hideOnlyOneSubActionCTA;
  return { showWithoutModalFooter, showWithModalFooter };
}
