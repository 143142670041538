import React, { Component } from 'react';
import { get } from 'lodash';

import { Query } from 'z-frontend-network';

import { CompanyQuery } from '../../gqlTypes';
import CompanyLogo from './CompanyLogo';
import { companyQuery } from '../../initialQueries';

export default class CompanyLogoContainer extends Component {
  render() {
    return (
      <Query<CompanyQuery.Query> query={companyQuery} handleLoading={false}>
        {({ data, loading }) => (
          <CompanyLogo
            loading={loading}
            companyName={get(data, 'dashboard.companyDisplayName') || get(data, 'dashboard.company.name')}
            logoUrl={get(data, 'dashboard.company.logoUrl')}
          />
        )}
      </Query>
    );
  }
}
