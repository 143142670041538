import React from 'react';

import { Banner } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { Icon, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';
import { useMicroTrialSubscription } from 'z-frontend-microtrials';

const DISMISSED_LOCAL_STORAGE_KEY = 'talentMicroTrialBannerDismissed';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${color('grayscale.white')};
  cursor: pointer;

  &:link,
  &:visited {
    color: ${color('grayscale.white')};
  }

  &:hover,
  &.simulate-hover {
    color: ${color('grayscale.f')};
  }

  &:active,
  &.simulate-active {
    color: ${color('grayscale.f')};
  }
`;

const TalentMicroTrialBanner: React.FC<{}> = () => {
  const {
    loading: microTrialSubscriptionLoading,
    isMicroTrialFeatureActive,
    getFeatureTrialExpiryDate,
  } = useMicroTrialSubscription();

  if (
    microTrialSubscriptionLoading ||
    !isMicroTrialFeatureActive('talent') ||
    window.localStorage.getItem(DISMISSED_LOCAL_STORAGE_KEY)
  ) {
    return null;
  }

  return (
    <Banner
      showIcon={false}
      type="promo"
      centerContent
      onClose={() => window.localStorage.setItem(DISMISSED_LOCAL_STORAGE_KEY, '1')}
    >
      <TextBlock textAlign="center" color="grayscale.white" fontStyle="paragraphs.m">
        <Icon mr={1} iconName="star" />
        You’ve unlocked Performance Management free - Trial ends on {getFeatureTrialExpiryDate('talent')}.{' '}
        <StyledLink href="/app/talent">Explore</StyledLink>
      </TextBlock>
    </Banner>
  );
};

export default TalentMicroTrialBanner;
