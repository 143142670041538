import React, { FunctionComponent } from 'react';

import { EmptyState } from 'z-frontend-elements';
import { DialogProps, Modal } from 'z-frontend-overlays';
import { Flex, Label, TextBlock, TextInline } from 'zbase';

type Props = { inboxActionId: string; details: Record<string, string>; dialog: DialogProps };

const DetailsModal: FunctionComponent<Props> = props => {
  const { inboxActionId, details, dialog } = props;

  const getModalContent = () => {
    if (!details) {
      return <EmptyState message="No details available to display" iconName="alert-circle" />;
    }

    return Object.entries(details).map(([label, value], index) => (
      <Flex key={`${inboxActionId}-detail-${label}`} mt={index > 0 ? 2 : 0}>
        <Flex w={4 / 12}>
          <Label mr={3}>
            <TextBlock textAlign="left" color="grayscale.c" textTransform="uppercase">
              <TextInline>{label}</TextInline>
            </TextBlock>
          </Label>
        </Flex>
        <Flex w={8 / 12}>
          <TextBlock fontStyle="paragraphs.m">{value}</TextBlock>
        </Flex>
      </Flex>
    ));
  };

  return (
    <Modal
      title="Item Details"
      onCancel={dialog.close}
      isVisible={dialog.isVisible}
      controlEl={dialog.controlEl}
      shouldCancelOnClickAway
    >
      <Modal.Body>{getModalContent()}</Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
