import { useContext } from 'react';

import { SegmentContext } from './SegmentManager';

export default function useSegmentTraits() {
  const { loadingState, loadData, visitorTraits } = useContext(SegmentContext);
  if (loadingState === 'notStarted' && loadData) {
    loadData();
  }
  return { loadingState, visitorTraits };
}
