import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import { Box } from 'zbase';
import { AddressValue, Form, FormAddressUS } from 'z-frontend-forms';
import { Modal } from 'z-frontend-overlays';
import { getEventLogger, sanitizeHtml } from 'z-frontend-app-bootstrap';

import TextInlineWithLink from './TextInlineWithLink';
import { SubAction } from './SubAction';
import SubActionFormFooter from './SubActionFormFooter';
import { AddressSubAction as AddressSubActionType, AddressSubActionInput } from '../../../schema/schemaTypes';
import { CompleteAddressSubAction } from '../../gqlTypes';
import { SharedSubActionProps } from '../../types';
import { getCtaButtonPosition } from './utils';
import InboxAttachments from '../inbox-attachments/InboxAttachments';

type AddressSubActionProps = Pick<
  AddressSubActionType,
  | 'id'
  | 'descriptionWithNamesAndVariables'
  | 'ctaText'
  | 'isCompleted'
  | 'street1'
  | 'street2'
  | 'city'
  | 'state'
  | 'postalCode'
  | 'country'
> &
  SharedSubActionProps;

const AddressSubAction: FunctionComponent<AddressSubActionProps> = props => {
  const {
    id,
    attachments,
    currentEmployeeId,
    isCompleted: isSubActionCompleted,
    ctaText,
    descriptionWithNamesAndVariables,
    street1,
    street2,
    city,
    state,
    postalCode,
    country,
    isOnlyOneSubAction,
    showCannotCompleteButton,
    onClickCannotComplete,
    hideOnlyOneSubActionCTA,
    isActionCompletedOrCannotComplete,
  } = props;

  const [completeSubAction] = useMutation<CompleteAddressSubAction.Mutation, CompleteAddressSubAction.Variables>(
    completeAddressSubActionMutation,
  );

  const {
    showWithoutModalFooter: showFormFooterWithoutModalFooter,
    showWithModalFooter: showFormFooterWithModalFooter,
  } = getCtaButtonPosition(
    isSubActionCompleted,
    isActionCompletedOrCannotComplete,
    isOnlyOneSubAction,
    hideOnlyOneSubActionCTA,
  );

  return (
    <Form<{ address: AddressValue }>
      initialValues={{
        address: {
          city: city || '',
          state: state || '',
          country: country || 'US',
          line1: street1 || '',
          line2: street2 || '',
          zip: postalCode || '',
        },
      }}
      onSubmit={async values => {
        const { city, state, country, line1, line2, zip } = values.address;
        const mappedAddress: AddressSubActionInput = {
          city,
          state,
          country,
          street1: line1,
          street2: line2,
          postalCode: zip,
        };
        await completeSubAction({
          variables: {
            id,
            address: mappedAddress,
            employeeId: currentEmployeeId,
          },
        });
        getEventLogger().log('inbox-action-save-sub-action', {
          // don't log fields like title which could contain sensitive info
          subActionId: id,
          subActionType: 'address',
        });
      }}
      validationSchema={FormAddressUS.getValidationSchema('address')}
    >
      <SubAction isCompleted={isSubActionCompleted}>
        <Box w={1}>
          <Box mb={3} mt={1}>
            <TextInlineWithLink dangerouslySetInnerHTML={{ __html: sanitizeHtml(descriptionWithNamesAndVariables) }} />
          </Box>

          <FormAddressUS name="address" includeCountry={false} autocomplete disabled={isSubActionCompleted} />

          {showFormFooterWithoutModalFooter && <SubActionFormFooter ctaText={ctaText} />}
        </Box>
      </SubAction>

      <InboxAttachments attachments={attachments} />

      {showFormFooterWithModalFooter && (
        <Modal.Footer borderTop>
          <SubActionFormFooter
            ctaText={ctaText}
            showCannotCompleteButton={showCannotCompleteButton}
            onClickCannotComplete={onClickCannotComplete}
          />
        </Modal.Footer>
      )}
    </Form>
  );
};

const completeAddressSubActionMutation = gql`
  mutation CompleteAddressSubAction($id: ID!, $employeeId: ID!, $address: AddressSubActionInput!) {
    completeAddressSubAction(id: $id, employeeId: $employeeId, address: $address) {
      id
      isCompleted
      completionDate
      completedBy_id
      descriptionWithNamesAndVariables
      ctaText
    }
  }
`;

export default AddressSubAction;
