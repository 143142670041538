import React, { Component } from 'react';
import { MutationFunctionOptions } from 'react-apollo';

import { Link } from 'z-frontend-elements';
import { Box, Flex, Heading } from 'zbase';

import { InboxActionPartial, ZApp } from '../../schema/schemaTypes';
import { IncrementInboxActionViews } from '../gqlTypes';
import { INBOX_ACTION_TYPES, INBOX_EMAIL_STATUS_CHOICES, NETSUITE_APP_IDS } from './constants';
import InboxActionItemContainer from './InboxActionItemContainer';

import { formatDate } from '../../src/utils/index';

const INBOX_TASKS_TO_SHOW = 2;

type InboxActionListProps = {
  actions: InboxActionPartial[];
  apps: ZApp[];
  currentEmployeeId: string;
  onActionView: (
    options?: MutationFunctionOptions<IncrementInboxActionViews.Mutation, IncrementInboxActionViews.Variables>,
  ) => Promise<any>;
  refreshList?: () => Promise<any>;
  title?: string;
  canViewSocialFeed?: boolean;
  fetchAll?: boolean;
  showViewAction?: boolean;
  showDismissAction?: boolean;
  hideHeader?: boolean;
};

export function isInboxItemRead(inboxItem: InboxActionPartial): boolean {
  if (inboxItem.type === INBOX_ACTION_TYPES.EMAIL) {
    return inboxItem.status === INBOX_EMAIL_STATUS_CHOICES.READ;
  }
  // type is task
  return inboxItem.viewCount > 0;
}

export default class InboxActionList extends Component<InboxActionListProps> {
  getCompanyIdFromUrl() {
    const matches = window.location.href.match(/dashboard\/?\?company=(\d+)\/#\//);
    return matches && Number.parseInt(matches[1], 10);
  }

  getDashboardBaseUrl() {
    const companyId = this.getCompanyIdFromUrl();
    return companyId ? `/dashboard/?company=${companyId}/#/` : `/dashboard/#/`;
  }

  getViewAllLink(canViewSocialFeed: boolean): string {
    if (canViewSocialFeed) {
      return '/app/hr-communication-hub/#/overview';
    }
    return `${this.getDashboardBaseUrl()}inbox-view`;
  }

  render() {
    const {
      actions,
      apps,
      onActionView,
      canViewSocialFeed,
      fetchAll,
      showViewAction,
      showDismissAction,
      hideHeader,
      ...rest
    } = this.props;
    const heading = this.props.title || 'Tasks';
    if (!actions.length) {
      return null;
    }
    const actionsToRender = fetchAll ? actions : actions.slice(0, INBOX_TASKS_TO_SHOW);
    const appIconMap = apps.reduce<{ [key: string]: string }>((memo, app) => {
      memo[app.uniqueId] = app.appIconSqUrl;
      return memo;
    }, {});

    const getText = (inboxItem: any) => {
      if (NETSUITE_APP_IDS.includes(inboxItem.zAppId) && inboxItem.requestDate) {
        return `${inboxItem.titleWithNamesAndVariables} (${formatDate(inboxItem.requestDate)})`;
      }
      return inboxItem.titleWithNamesAndVariables;
    };

    return (
      <>
        {!hideHeader && (
          <Flex justify="space-between" mb={2} mt={[0, null, 4]}>
            <Heading level={6} color="text.light" textAlign={['center', null, 'left']}>
              {heading}
            </Heading>
            <Link href={this.getViewAllLink(canViewSocialFeed)} fontStyle="paragraphs.m">
              View All
            </Link>
          </Flex>
        )}

        <Box className="js-walkme-inbox-action-group" border={false}>
          {actionsToRender.map(inboxItem => {
            return (
              <InboxActionItemContainer
                key={inboxItem.id}
                inboxActionId={inboxItem.id}
                text={getText(inboxItem)}
                isRead={isInboxItemRead(inboxItem)}
                iconUrl={appIconMap[inboxItem.zAppId]}
                onActionClick={async () => {
                  await onActionView({
                    variables: {
                      id: inboxItem.id,
                    },
                  });
                }}
                showViewAction={showViewAction}
                showDismissAction={showDismissAction}
                {...rest}
              />
            );
          })}
        </Box>
      </>
    );
  }
}
