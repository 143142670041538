export default function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return null;
}

export const isIPhone = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};

export const isAndroidMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent) && /mobile/i.test(userAgent)) {
    return true;
  }

  return false;
};
