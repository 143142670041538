import React from 'react';

import { Box, TextBlock } from 'zbase';
import { OnboardingImplementationHelpCard } from 'z-frontend-onboarding-components';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';

import { DashboardRole } from '../types';

export const OnboardingHelpContainer: React.FunctionComponent<{ role: DashboardRole }> = props => {
  return (
    <>
      {props.role === DashboardRole.admin && (
        <Box p={0} mt={4}>
          <ErrorBoundary
            FallbackComponent={() => (
              <TextBlock mt={3}>Sorry, we were unable to load onboarding help resources.</TextBlock>
            )}
          >
            <OnboardingImplementationHelpCard showVideo size="small" bg="transparent" />
          </ErrorBoundary>
        </Box>
      )}
    </>
  );
};
