import React, { useRef } from 'react';

import { styled } from 'z-frontend-theme';
import { TextBlock } from 'zbase';
import { color, zIndex } from 'z-frontend-theme/utils';
import { useSwitches } from 'z-frontend-network';

export const SHOW_UPGRADE_BADGE_SWITCH = 'show_upgrade_banner_on_dasherized_app_tile';

const Ribbon = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  width: 68%;
  height: 68%;
  overflow: hidden;
  z-index: ${zIndex('sticky')};
  & .label {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 10%;
    right: 10%;
    transform: rotate(45deg);
  }
  path {
    fill: ${color('primary.a')};
  }
`;

export const showUpgradeBadgeAppAllowList = [
  '1.com.zenefits.TalentAdmin',
  '1.com.zenefits.WellbeingAdmin',
  '1.com.zenefits.PeopleAnalyticsAdmin',
];

type AppTileUpgradeBadgeProps = {
  uniqueId: string;
  status: string;
};

const AppTileUpgradeBadge: React.FunctionComponent<AppTileUpgradeBadgeProps> = ({
  uniqueId,
  status,
}: AppTileUpgradeBadgeProps) => {
  const switches = useSwitches();
  const isSwitchOn = switches?.[SHOW_UPGRADE_BADGE_SWITCH];
  const isAppOnWhiteList = showUpgradeBadgeAppAllowList.includes(uniqueId);
  const isAppNotEnrolled = status === 'notEnrolled';
  const showUpgradeBanner = isSwitchOn && isAppOnWhiteList && isAppNotEnrolled;

  const ribbonRef = useRef(null);
  return showUpgradeBanner ? (
    <Ribbon ref={ribbonRef}>
      <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M88 88L0 0H88V88Z" />
      </svg>
      <div className="label">
        <TextBlock textAlign="center" ellipsis fontStyle="paragraphs.m" color="grayscale.white">
          +Upgrade
        </TextBlock>
      </div>
    </Ribbon>
  ) : null;
};

export default AppTileUpgradeBadge;
