import {
  AU_STATES as AU_STATE_NAMES,
  BR_STATES as BR_STATE_NAMES,
  CA_STATES as CA_STATE_NAMES,
  IN_STATES as IN_STATE_NAMES,
  STATE_LABELS as STATE_LABELS_MAP,
  SUPPORTED_STATES as SUPPORTED_STATES_LIST,
  US_STATES as US_STATE_NAMES,
} from 'z-address/addon/constants/work-location';

export const COUNTRIES_WITH_NO_STATE = ['SG', 'GB', 'NL', 'DE', 'IE', 'FR', 'IS', 'UY'];

type StateList = LabelValue[];
type LabelValue = { label: string; value: string };

function transformToLabelList(items: { code: string; name: string }[]) {
  return items.map(item => ({ label: item.name, value: item.code }));
}

// https://github.com/zenefits/z-address/blob/master/addon/constants/work-location.js
export const CA_STATES: StateList = transformToLabelList(CA_STATE_NAMES);

export const AU_STATES: StateList = transformToLabelList(AU_STATE_NAMES);

export const IN_STATES: StateList = transformToLabelList(IN_STATE_NAMES);

export const BR_STATES: StateList = transformToLabelList(BR_STATE_NAMES);

export const US_STATES: StateList = transformToLabelList(US_STATE_NAMES);

type CountryStateMap = { [key: string]: StateList };

export const SUPPORTED_STATES: CountryStateMap = Object.entries(SUPPORTED_STATES_LIST).reduce(
  (supportedStates, [countryCode, region]) => {
    supportedStates[countryCode] = transformToLabelList(region);
    return supportedStates;
  },
  {} as CountryStateMap,
);

export const STATE_LABELS: { [key: string]: string } = STATE_LABELS_MAP;
