import { InboxAttachment } from '../schema/schemaTypes';

export enum DashboardRole {
  admin = 'ADM',
  employee = 'EMP',
}

export type SubActionType =
  | 'addressSubActions'
  | 'bankSubActions'
  | 'confirmationSubActions'
  | 'signatureSubActions'
  | 'singleValueSubActions';

export type SharedSubActionProps = {
  currentEmployeeId: string;
  // When there is only one sub action, we show the cta buttons in a modal footer with top border.
  isOnlyOneSubAction: boolean;
  /**
   * Attachments should only be passed in when there is only one sub action and it's not completed.
   * In that case we display the attachments inside SubAction so that it appears before the CTA buttons.
   */
  attachments?: InboxAttachment[];
  /**
   * Whether to show a "Cannot complete" button
   */
  showCannotCompleteButton?: boolean;
  onClickCannotComplete?: any;
  /**
   * Hide sub action CTA buttons if there is only one sub action. Used when cannot complete form is showed to avoid two
   * modal footers.
   */
  hideOnlyOneSubActionCTA?: boolean;
  /**
   * Whether the main action is completed or cannot be completed.
   */
  isActionCompletedOrCannotComplete?: boolean;
};

export enum EmploymentType {
  FT = 'FT',
  PT = 'PT',
  IN = 'IN',
  TP = 'TP',
  CO = 'CO',
}
