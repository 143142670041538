import { Switches } from 'z-frontend-network';
import { getEventLogger } from 'z-frontend-app-bootstrap';

export type PromoDetails = {
  switch: string;
  shortMessage: string;
  fullMessageLine1: string;
  fullMessageLine2: string;
  isExternal?: boolean;
  href?: string;
  action?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const emptyDetails: PromoDetails = {
  switch: '',
  shortMessage: '',
  fullMessageLine1: '',
  fullMessageLine2: '',
};

const goalsActivationDetails: PromoDetails = {
  switch: 'goals_activation',
  shortMessage: 'new',
  fullMessageLine1: 'set',
  fullMessageLine2: 'goals',
  action: (e: React.MouseEvent<HTMLElement>) => {
    getEventLogger().log('goals_activation', {
      sourceOfClick: 'dashboardtile',
    });
  },
};

const uniqueIdToPromotionMap: Record<string, PromoDetails> = {
  '1.com.zenefits.Influitive': {
    switch: 'in_product_promo_clubzen',
    shortMessage: 'join',
    fullMessageLine1: 'join',
    fullMessageLine2: 'now',
    action: (e: React.MouseEvent<HTMLElement>) => {
      getEventLogger().log('in_product_promo_clubzen', {
        sourceOfClick: 'dashboardtile',
      });
    },
  },
  '1.com.zenefits.HRAnalyticsAdmin': {
    switch: 'in_product_promo_visier',
    shortMessage: 'new',
    fullMessageLine1: 'new',
    fullMessageLine2: 'content',
    action: (e: React.MouseEvent<HTMLElement>) => {
      getEventLogger().log('in_product_promo_visier', {
        sourceOfClick: 'dashboardtile',
      });
    },
  },
  '378.com.abenity': {
    switch: 'in_product_promo_abenity',
    shortMessage: 'new',
    fullMessageLine1: 'new',
    fullMessageLine2: '',
    action: (e: React.MouseEvent<HTMLElement>) => {
      getEventLogger().log('in_product_promo_abenity', {
        sourceOfClick: 'dashboardtile',
      });
    },
  },
  '1.com.zenefits.PayrollAdmin': {
    switch: 'in_product_promo_new_payroll_content',
    shortMessage: 'new',
    fullMessageLine1: 'new',
    fullMessageLine2: 'content',
    action: (e: React.MouseEvent<HTMLElement>) => {
      getEventLogger().log('in_product_promo_new_payroll_content', {
        sourceOfClick: 'dashboardtile',
      });
    },
  },
  '1.com.zenefits.TalentAdmin': goalsActivationDetails,
  '1.com.zenefits.TalentEmployee': goalsActivationDetails,
};

export function getPromoDetails(uniqueId: string, switches: Switches) {
  const promoDetails = uniqueIdToPromotionMap[uniqueId as keyof typeof uniqueIdToPromotionMap];
  if (promoDetails && switches && switches[promoDetails.switch]) {
    return promoDetails;
  }
  return emptyDetails;
}
