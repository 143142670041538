import { TextInline } from 'zbase';
import { styled } from 'z-frontend-theme';
import { linkStyle } from 'z-frontend-elements';

/**
 * There is HTML from backend that contains <a> tag and we display it directly.
 * This is to give the <a> tags the same style as our Link component.
 */
const TextInlineWithLink = styled(TextInline)`
  a {
    ${linkStyle}
  }
`;

export default TextInlineWithLink;
