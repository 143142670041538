import moment from 'moment';

export const AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';

// TODO: just use AllEmployee
export type Worker = {
  id: string;
  preferredOrFirstName: string;
  lastName: string;
  photoUrl?: string;
  hireDate: string;
  status: string;
  allStatus: string;
  isPastHireDate?: boolean;
  reportToEmployeeId?: string;
  employmentType: string;
};

// from yp3/client-app/packages/dashboard/pods/index/controller.js
export function employeeComparator(employeeA: Worker, employeeB: Worker): number {
  const hireDateA = moment(employeeA.hireDate);
  const hireDateB = moment(employeeB.hireDate);
  let hireDateDiff = hireDateA.diff(hireDateB);
  // When hire date has invalid data
  if (!hireDateA.isValid() && !hireDateB.isValid()) {
    hireDateDiff = 0;
  } else if (!hireDateA.isValid()) {
    return 1;
  } else if (!hireDateB.isValid()) {
    return -1;
  }

  if (hireDateDiff === 0) {
    const informalFirstNameA = employeeA.preferredOrFirstName;
    const informalFirstNameB = employeeB.preferredOrFirstName;
    const lastNameA = employeeA.lastName;
    const lastNameB = employeeB.lastName;
    if (informalFirstNameA !== informalFirstNameB) {
      return informalFirstNameA < informalFirstNameB ? -1 : 1;
    }
    if (lastNameA !== lastNameB) {
      return lastNameA < lastNameB ? -1 : 1;
    }
    return 0;
  }
  return -hireDateDiff;
}

export const getDashboardUrl = (url: string) => {
  const matches = window.location.href.match(/dashboard\/?\?company=(\d+)\/#\//);
  const companyId = matches && Number.parseInt(matches[1], 10);
  const dashboardUrl = companyId ? `/dashboard/?company=${companyId}/#/` : `/dashboard/#/`;
  return dashboardUrl;
};
