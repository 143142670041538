import React from 'react';

import { Link } from 'z-frontend-elements';
import { growthUtils } from 'z-frontend-layout';
import { TextInline } from 'zbase';

type PromoBannerTextProps = { linkText: string; infoText: string };

export default class PromoBannerText extends React.Component<PromoBannerTextProps> {
  render() {
    return (
      <TextInline>
        <TextInline bold>
          <Link href={growthUtils.getBuyLink()} mx={1}>
            {this.props.linkText}
          </Link>
        </TextInline>
        {this.props.infoText}
      </TextInline>
    );
  }
}
