import React, { Component } from 'react';
import { get } from 'lodash';

import { Query } from 'z-frontend-network';
import { Hide } from 'z-frontend-theme';
import { Heading } from 'zbase';
import { Skeleton } from 'z-frontend-elements';

import { NameQuery } from '../gqlTypes';
import { nameQuery } from '../initialQueries';

function getWelcomeMessage(name: string) {
  if (name && name.toLowerCase() !== 'admin') {
    return `Hello, ${name}`;
  }
  return 'Welcome back';
}

export default class WelcomeHeading extends Component {
  render() {
    return (
      <Query<NameQuery.Query> query={nameQuery} handleLoading={false}>
        {({ loading, data }) => (
          <Hide forBreakpoints={[true, true]}>
            <Heading level={2} data-testid="WelcomeHeading">
              {loading ? (
                <Skeleton width={200} />
              ) : (
                getWelcomeMessage(get(data, 'dashboard.employee.preferredOrFirstName'))
              )}
            </Heading>
          </Hide>
        )}
      </Query>
    );
  }
}
