import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';

import { buildEmberRouteRedirect, PrerequisiteRedirect } from 'z-frontend-app-bootstrap';

import { RedirectModalConfig } from '../../redirects/prerequisiteRedirectBody';
import RedirectConfirmationModal from './RedirectConfirmationModal';

type ModalWithQueryProps = {
  /**
   * Config data for the modal
   */
  modalConfig: RedirectModalConfig;
  /**
   * An object containing prerequisite redirect information
   */
  prerequisiteRedirect: PrerequisiteRedirect;
};

function useRedirectContentQuery(modalConfig: RedirectModalConfig) {
  const { loading, error, data } = useQuery(modalConfig.query);
  if (loading) {
    return null;
  }
  if (error || !data) {
    console.error('GraphQL error with query from modalConfig.query:', error);
    return modalConfig && modalConfig.errorBody;
  }
  return modalConfig && modalConfig.body(data);
}

/**
 * A component that fetches modal content and renders a confirmation modal
 */
const ModalWithQuery: FunctionComponent<ModalWithQueryProps> = props => {
  const { modalConfig } = props;
  const modalContent = useRedirectContentQuery(modalConfig);
  const { redirectUrl, redirectEmberRoute, showFooter, title } = modalConfig;
  const footerText = showFooter && modalConfig.footerButtonText;

  const onSubmit = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else if (redirectEmberRoute) {
      window.location.href = buildEmberRouteRedirect({ to: redirectEmberRoute });
    }
  };

  return (
    <RedirectConfirmationModal
      modalContent={modalContent}
      modalTitle={title}
      submitActionText={footerText}
      onSubmit={onSubmit}
    />
  );
};

export default ModalWithQuery;
