import { OmniSearchSources, ZAppUniqueIds } from './types';

export const tabletBreakpoints = [false, false, true, true, true];

export const defaultSources: OmniSearchSources = ['employees', 'actions', 'help'];

export const helpCenterType = 'helpCenter';

export const benefitsShoppingPageRelatedZAppUniqueIds = [
  /**
   * We only check admin apps, but not employee apps such as MedicalInsuranceEmployee because:
   * 1. An admin account might only have MedicalInsuranceAdmin, but not have MedicalInsuranceEmployee.
   * 2. Employee app status is not reliable to determine whether the customer has acquired the product.
   *    For example, there are cases we see MedicalInsuranceAdmin status is 100,
   *    but MedicalInsuranceEmployee is 200.
   */
  ZAppUniqueIds.medicalInsuranceAdmin,
  ZAppUniqueIds.dentalInsuranceAdmin,
  ZAppUniqueIds.visionInsuranceAdmin,
  ZAppUniqueIds.fsaAdmin,
  ZAppUniqueIds.hsaAdmin,
  ZAppUniqueIds.commuterBenefitsAdmin,
  ZAppUniqueIds._401kAdmin,
];

export const IOM = 'iom';
export const CONTRACTOR_PAYMENTS = 'contractorPayments';
