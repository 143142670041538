import { useContext } from 'react';

import { PrerequisiteContext } from './PrerequisiteManager';

export default function useRefreshPrerequisites() {
  const prerequisiteContext = useContext(PrerequisiteContext);
  return () => {
    // If we aren't in prerequisite context it means that we're in an embedded app
    if (prerequisiteContext) {
      prerequisiteContext.refreshPrerequisites();
    }
  };
}
