import { DashboardApp } from './dashboardApp';

export class PeopleHubAdminApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'People Hub',
        isHighlighted: false,
        linkTo: '/app/hr-communication-hub/#/overview',
      },
    ];
  }
}

export class PeopleHubEmployeeApp extends DashboardApp {
  buttons() {
    return [
      {
        title: 'People Hub',
        isHighlighted: false,
        linkTo: '/app/hr-communication-hub/#/overview',
      },
    ];
  }
}
