import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { useSegmentTraits } from 'z-frontend-app-bootstrap';
import { checkPermission, usePermissions, useSwitches } from 'z-frontend-network';

import { MicroTrialsQuery } from '../gqlTypes';

export const microTrialsQuery = gql`
  query MicroTrialsQuery($useYPInPlaceOfSegment: Boolean = false) {
    dashboard {
      id
      features
    }
    microTrials(params: { status: active }) @skip(if: $useYPInPlaceOfSegment) {
      id
      name
      key
      createdAt
      durationInDays
      status
      features
      isOfferRequired
    }
    microTrialOffers @include(if: $useYPInPlaceOfSegment) {
      id
      name
      key
      createdAt
      durationInDays
      status
      features
      isOfferRequired
    }
  }
`;

// TODO: move this mapping to a table
export const MICRO_TRIAL_ID_BY_AUDIENCE_KEY: { [key: string]: string } = {
  performance_management_v_1: '3',
  performance_management_v_1_test: '3',
};

const MICRO_TRIALS_SWITCH = 'plg_experiment_micro_trials';
export const SEGMENT_SWITCH = 'plg_microtrials_use_yp3_instead_of_segment';

export const getSegmentMicroTrials = (
  visitorTraits: { [key: string]: string | number | boolean },
  microTrials: MicroTrialsQuery.MicroTrials[],
  audienceKeyToMicroTrialIdMap: { [key: string]: string },
): MicroTrialsQuery.MicroTrials[] | null => {
  // Return all the MTs the company has access to, according to Segment
  const audienceKeys =
    Object.keys(visitorTraits)?.filter(trait => audienceKeyToMicroTrialIdMap[trait] && visitorTraits[trait]) || [];
  const microTrialIds = audienceKeys.map(audienceKey => audienceKeyToMicroTrialIdMap[audienceKey]);
  return microTrials?.filter(microTrial => microTrialIds.includes(microTrial.id)) || [];
};

const getMicroTrial = (
  visitorTraits: { [key: string]: string | number | boolean },
  microTrials: MicroTrialsQuery.MicroTrials[],
  currentFeatures: string[],
  useSegment: boolean,
): MicroTrialsQuery.MicroTrials | null => {
  let filteredMicroTrials = microTrials;
  if (useSegment) {
    filteredMicroTrials = getSegmentMicroTrials(visitorTraits, microTrials, MICRO_TRIAL_ID_BY_AUDIENCE_KEY);
  }
  // don't show a microtrial if the company already has the feature!
  const trialsWithNewFeatures = filteredMicroTrials.filter(
    microTrial => !microTrial.features.every(microTrialFeature => currentFeatures?.includes(microTrialFeature)),
  );
  return trialsWithNewFeatures[0];
};

const useMicroTrials = () => {
  const { loadingState, visitorTraits } = useSegmentTraits();
  const switches = useSwitches();
  const useYp3 = switches?.[SEGMENT_SWITCH];
  const { loading: microTrialsLoading, data } = useQuery<MicroTrialsQuery.Query, MicroTrialsQuery.Variables>(
    microTrialsQuery,
    {
      variables: {
        useYPInPlaceOfSegment: !!useYp3,
      },
    },
  );
  const { permissions, permissionsLoaded } = usePermissions();

  const loading = microTrialsLoading || loadingState !== 'loaded' || !switches || !permissionsLoaded;
  const isFullAdmin = checkPermission(permissions, 'is_full_company_admin');

  if (loading) {
    return { loading, microTrial: null };
  }

  if (!switches?.[MICRO_TRIALS_SWITCH] || !isFullAdmin) {
    return { loading, microTrial: null };
  }

  const microTrial =
    getMicroTrial(visitorTraits, data?.microTrials || data?.microTrialOffers, data?.dashboard.features, !useYp3) ||
    null;

  return { loading, microTrial };
};

export default useMicroTrials;
