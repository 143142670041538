import React, { useEffect, FunctionComponent } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';

import { useQueryWithProgress, useSwitches } from 'z-frontend-network';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { TextBlock } from 'zbase';
import { ActionModal, Modal } from 'z-frontend-overlays';
import { DialogProps } from 'z-frontend-layout';

import { GetPendingPayrollDeadlinesQuery } from '../../gqlTypes';

const PAYROLL_DEADLINE = 3;

const getPendingPayrollDeadlinesQuery = gql`
  query GetPendingPayrollDeadlinesQuery {
    getPendingPayrollDeadlines {
      deadline
      firstCheckDate
    }
  }
`;
type Props = {
  dialog: DialogProps;
};
const getDiffDays = (deadline: Date) => {
  const todaysdate = moment();
  const deadlineDate = moment(deadline);
  return deadlineDate.diff(todaysdate, 'days');
};

const PayrollDeadlineModal: FunctionComponent<Props> = props => {
  const switches = useSwitches();
  const { Loading, QueryError, data } = useQueryWithProgress<GetPendingPayrollDeadlinesQuery.Query>(
    getPendingPayrollDeadlinesQuery,
    {
      showInlineLoading: false,
    },
  );
  const { dialog } = props;
  const deadline = data?.getPendingPayrollDeadlines?.deadline;
  const diffdays = deadline ? getDiffDays(deadline) : null;
  const firstCheckDate = data?.getPendingPayrollDeadlines?.firstCheckDate;

  useEffect(() => {
    const showDialog = diffdays && diffdays > 0 && diffdays < PAYROLL_DEADLINE;
    if (switches?.['onboarding_redesign_enabled'] && deadline && showDialog) {
      dialog.open();
    }
  }, [deadline]);

  if (Loading || QueryError || !data?.getPendingPayrollDeadlines) {
    return <></>;
  }

  const modalProps = {
    buttons: [
      {
        text: 'Continue Setup',
        onClick: () => (window.location.href = '/app/company-setup/#/tasks_overview'),
      },
    ],
    onCancel: dialog.close,
    isVisible: dialog.isVisible,
    controlEl: dialog.controlEl,
    renderHeader: () => {
      return (
        <Modal.Header>
          Only {diffdays} {diffdays === 1 ? 'day' : 'days'} left to complete setup
        </Modal.Header>
      );
    },
  };
  const modalMessage = (
    <TextBlock>
      If you want to run payroll by your first desired pay date ({moment(firstCheckDate).format('MMM DD')}), you'll need
      to complete your setup. Please continue setup. When finished you will be able to access your dashboard again.
    </TextBlock>
  );

  return (
    <ActionModal {...modalProps} omitCancelButton>
      {modalMessage}
    </ActionModal>
  );
};

const PayrollDeadlineModalContainer: FunctionComponent<Props> = props => {
  return (
    <ErrorBoundary FallbackComponent={() => null}>
      <PayrollDeadlineModal dialog={props.dialog} />
    </ErrorBoundary>
  );
};

export default PayrollDeadlineModalContainer;
