import React, { Component } from 'react';

import { Banner, BannerFlexContainerProps } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { PluralText, TextInline } from 'zbase';

import { ActiveBillingDetailsQuery } from '../gqlTypes';

export const READ_ONLY_TRIAL_SKU_NAME = 'READ_ONLY_ACCESS_TRIAL';

type ReadOnlyNoticeProps = {
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails;
} & BannerFlexContainerProps;

function showReadOnlyUpgradeNotice(billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails): boolean {
  if (!billingDetails.renewalDate) {
    return false;
  }

  // NOTE: we intentionally want to show this even if they're past their renewal date.
  // const { numberOfDaysBeforeRenewal } = billingDetails;
  // if (numberOfDaysBeforeRenewal < 0) {
  //   return false;
  // }

  const planDetails = billingDetails && billingDetails.planDetails;
  const skuName = planDetails && planDetails.name;
  if (skuName === READ_ONLY_TRIAL_SKU_NAME) {
    return true;
  }

  return false;
}

export default class ReadOnlyUpgradeCountDownNotice extends Component<ReadOnlyNoticeProps> {
  render() {
    const { billingDetails, ...containerProps } = this.props;
    if (!showReadOnlyUpgradeNotice(billingDetails)) {
      return null;
    }
    return (
      <Banner type="info" isClosable={false} {...containerProps}>
        <TextInline>
          <PluralText
            count={billingDetails.numberOfDaysBeforeRenewal}
            none="Today is the last day to upgrade your account. "
            one="You have {count} day left to upgrade your account. "
            other="You have {count} days left to upgrade your account. "
          />
          After {billingDetails.renewalDate}, you will not have access to the <TextInline textKey="brandName" />{' '}
          platform through this account.
          <Link href="/public/#/billing/?creationType=manual&amp;contractType=annual&amp;plan=ROA">
            {' '}
            Extend read only trial
          </Link>{' '}
          to have read only access to your data or{' '}
          <Link href="/public/#/billing/choose-plan">upgrade to a paid plan.</Link>
        </TextInline>
      </Banner>
    );
  }
}
