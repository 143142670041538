import React, { useContext, FunctionComponent } from 'react';

import { styled } from 'z-frontend-theme';
import { BoxProps, Icon } from 'zbase';

import Card from '../card/Card';
import { AccordionContext } from './Accordion';

const StyledCardHeader = styled(Card.Header)`
  :hover {
    cursor: pointer;
  }
`;

const AccordionHeaderIcon = styled(Icon)`
  margin-left: auto;
  transition: transform 0.3s ease-in-out;

  &.expanded {
    transform: rotate(-180deg);
  }
`;

const AccordionHeader: FunctionComponent<BoxProps> = props => {
  const { children, borderBottom = false, onChange, ...rest } = props;
  const { isExpanded, toggleExpanded } = useContext(AccordionContext);

  return (
    <StyledCardHeader
      borderBottom={borderBottom}
      actionRender={() => (
        <AccordionHeaderIcon
          className={isExpanded ? 'expanded' : ''}
          s="large"
          iconName="chevron-down"
          color="grayscale.b"
          data-testid="Accordion-Icon"
        />
      )}
      onClick={toggleExpanded}
      {...rest}
    >
      {children}
    </StyledCardHeader>
  );
};

export default AccordionHeader;
